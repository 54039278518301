import Sidebar from '../components/Student/Sidebar'
import Dashboard from '../components/Student/UserDashboard/Dashboard'
import Bg from '../img/pexels-tima-miroshnichenko-5198252-10@1x.png'


const UserDashboardScreen = () => {
	return (
		<div style={{
			backgroundImage: `url(${Bg})`,
			backgroundRepeat: 'no-repeat',
			width: '100%',
			minHeight: '100vh',
		}} >
			<div className="container-fluid" >
				<div className="row">
					<div className="col-12 col-md-2">
						<Sidebar />
					</div>
					<div className="col-12 col-md-10 mt-5">
						<Dashboard />
					</div>
				</div>
			</div>
		</div>
	)
}

export default UserDashboardScreen
