import { useEffect, useState } from "react";
// import { useContext } from "react";
// import ProfileContext from "../../../Context/ProfileContext";

import Sidebar from "../Sidebar";

import Loader from "../../../helpers/Loader/Loader";

import Card from "./Card";
import axios from "../../../API/apiHandler";

const Shop = () => {
  // const tempProducts = [];
  // if (products.length > 0) {
  //   for (let i in products) {
  //     let randomIndex = Math.floor(Math.random() * products.length);

  //     while (tempProducts.includes(products[randomIndex])) {
  //       randomIndex = Math.floor(Math.random() * products.length);
  //     }
  //     tempProducts[i] = products[randomIndex];
  //   }
  // }

  // let catProductsOne = products.filter(
  //   (product) => product.category.cat_name === categories[0]
  // );
  // let catProductsTwo = products.filter(
  //   (product) => product.category.cat_name === categories[1]
  // );
  // let catProductsThree = products.filter(
  //   (product) => product.category.cat_name === categories[2]
  // );
  // let catProductsFour = products.filter(
  //   (product) => product.category.cat_name === categories[3]
  // );

  const [fetchedShops, setFetchedShops] = useState([]);
  // const [fetchedCategory, setFetchedCategory] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // const [catSelect, setCatSelect] = useState({
  //   category: "",
  // });

  // const handleChange = (e) => {
  //   setCatSelect({
  //     ...catSelect,
  //     [e.target.name]: e.target.value,
  //   });
  // };

  // useEffect(() => {
  //   const fetchCategory = async () => {
  //     setIsLoading(true);
  //     try {
  //       const token = JSON.parse(localStorage.getItem("fastapi"));
  //       const res = await axios.get("/api/v1/shop/categories", {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Bearer ${token}`,
  //         },
  //       });
  //       if (res.status === 200 || res.status === 201) {
  //         setFetchedCategory(res?.data);
  //       }
  //     } catch (error) {
  //     } finally {
  //       setIsLoading(false);
  //     }
  //   };

  //   fetchCategory();
  // }, []);

  useEffect(() => {
    

    const fetchShop = async () => {
      setIsLoading(true);
      try {
        const token = JSON.parse(localStorage.getItem("fastapi"));
        const res = await axios.get(
          "/api/v1/shop",
          
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setFetchedShops(res?.data);
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    };

    fetchShop();
    
  }, []);

  const [search, setSearch] = useState("");

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-2">
            <Sidebar />
          </div>
          <div className="col-10 mt-5">
            <div className="container mt-4">
              <div className="d-flex flex-column">
                <div className="row mb-2">
                  <div className="col-12">
                    <h3>Purchase an Item</h3>
                  </div>
                </div>

                {/* <div className="row">
                  <div className="col-12 mt-2">
                    <div className="d-flex gap-2 justify-content-between">
                      <div>
                        <label
                          htmlFor="specificareaofstudy"
                          className="col-form-label"
                        >
                          Categories{" "}
                        </label>
                        <select
                          className="form-select"
                          aria-label="Default select"
                          required
                          name=""
                        >
                          <option value="">Select category</option>
                          {fetchedCategory.length > 0 ? (
                            fetchedCategory.map((category, index) => (
                              <option
                                className="uppercase font-bold"
                                key={index}
                                value={category.id}
                                onChange={handleChange}
                              >
                                {category.cat_name}
                              </option>
                            ))
                          ) : (
                            <option>No categories yet</option>
                          )}
                        </select>
                      </div>
                    </div>
                  </div>
                </div> */}

                <div className="row">
                  <div className="col mt-4">
                    {/* full width search box filter */}
                    <div className="input-group mb-3">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search for courses"
                        aria-label="Search for courses"
                        aria-describedby="button-addon2"
                        onChange={(e) => {
                          setSearch(e.target.value);
                        }}
                      />
                      <button
                        className="btn btn-outline-secondary"
                        type="button"
                        id="button-addon2"
                      >
                        Search
                      </button>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-12 categories py-5">
                    {isLoading ? (
                      <Loader />
                    ) : (
                      fetchedShops
                        .filter((fetchedShop) => {
                          return search.toLowerCase() === ""
                            ? fetchedShop
                            : fetchedShop.item_name
                                .toLowerCase()
                                .includes(search.toLowerCase());
                        })
                        .map((fetchedShop, index) => (
                          <div
                            className="d-flex gap-3 align-items-center"
                            key={index}
                          >
                            <Card
                              id={fetchedShop.id}
                              name={fetchedShop.item_name}
                              price={fetchedShop.item_price}
                              summary={fetchedShop.item_summary}
                              short_desc={fetchedShop.item_short_desc}
                              manufacturer={fetchedShop.item_manufacturer}
                              batch_no={fetchedShop.item_batch_no}
                              warranty={fetchedShop.item_warranty_days}
                              type={fetchedShop.item_type}
                              discount={fetchedShop.item_discount}
                              category={fetchedShop.category.cat_name}
                              model={fetchedShop.item_model_no}
                              code={fetchedShop.item_code}
                              img={fetchedShop.item_banner_img}
                            />
                          </div>
                        ))
                    )}
                    {/* <div className="categories-item mt-5">
                      <div className="title-md">
                        <h3 className="fs-3 text-primary">{"Best Category"}</h3>
                      </div>
                      {isLoading ? (
                        <Loader />
                      ) : (
                        fetchedShops.map((fetchedShop, index) => (
                          <div className="text-center" key={index}>
                            <Card
                              name={fetchedShop.item_name}
                              price={fetchedShop.item_price}
                              summary={fetchedShop.item_summary}
                              short_desc={fetchedShop.item_short_desc}
                              manufacturer={fetchedShop.item_manufacturer}
                              batch_no={fetchedShop.item_batch_no}
                              warranty={fetchedShop.item_warranty_days}
                              type={fetchedShop.item_type}
                              discount={fetchedShop.item_discount}
                              category={fetchedShop.category.cat_name}
                              model={fetchedShop.item_model_no}
                              code={fetchedShop.item_code}
                              img={fetchedShop.item_banner_img}
                            />
                          </div>
                        ))
                      )}
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Shop;
