import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// import { lazy, Suspense } from 'react';
import { Toaster } from "react-hot-toast";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "primereact/resources/themes/saga-orange/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";

import "swiper/css";
import "swiper/css/bundle";

//public
import HomeScreen from "./screens/Home/HomeScreen";
import Login from "./components/Signin/Login";
import PasswordResetComponent from "./components/Signin/ResetPsw";
import Register from "./components/Signup/Register";
import Err404 from "./components/Err404/Err404";
import Ad from "./screens/Home/Ad";
import OurCourse from "./screens/Home/OurCourse";
import Details from "./screens/Home/Details";

// profile and otp
import UserProfile from "./components/Student/pagination/pages/UserProfile";
import Otp from "./components/Student/pagination/pages/Otp/Otp";
import Pg2 from "./components/Student/pagination/pages/Pg2";
import Pg3 from "./components/Student/pagination/pages/Pg3";
import Pg4 from "./components/Student/pagination/pages/Pg4";
// import CompleteProfileScreen from "./screens/student/CompleteProfileScreen";

//student
import FundingsScreen from "./screens/student/Funding/FundingsScreen";
import SchorlarshipScreen from "./screens/student/Scholarship/SchorlarshipScreen";
import UserDashboardScreen from "./screens/UserDashboardScreen";
import LibrariesScreen from "./screens/LibrariesScreen";
import StudyHubScreen from "./screens/StudyHubScreen";
import Conferencing from "./components/Student/Conferencing/Conferencing";
import CommunityScreen from "./screens/CommunityScreen";
import Settings from "./components/Student/Settings/Settings";
import Support from "./components/Student/Support/Support";
import SettingsScreen from "./screens/student/Settings/SettingsScreen";
import CompareCourses from "./components/Student/Courses/CompareCourses";
import DetailsScholarship from "./components/Student/Scholarship/DetailsScholarship";
//import CompareScholarships from './components/Student/Scholarship/CompareScholarships';
import CoursesScreen from "./screens/student/CoursesScreen/CoursesScreen";
// import DetailFunding from "./components/Student/Funding/DetailFunding";
import Class from "./components/Student/StudyHub/Class";
import UnitsScreen from "./screens/admin/UnitsScreen/UnitsScreen";
import TopicsScreen from "./screens/admin/Topics/TopicsScreen";
import TopicDetails from "./components/admin/Topics/TopicDetails";
import Shop from "./components/Student/Shop/Shop";

//admin
import AdminDashScreen from "./screens/admin/AdminDashScreen";
import MarketScreen from "./screens/admin/MarketScreen";
import FundingScreen from "./screens/admin/funding/FundingScreen";
import ScholarshipScreen from "./screens/admin/ScholarshipScreen/ScholarshipScreen";
import InstitutionScreen from "./screens/admin/Institutions/InstitutionScreen";
import ApplicationScreen from "./screens/admin/Applications/ApplicationScreen";
import CourseAdminScreen from "./screens/admin/Courses/CourseAdminScreen";
import AdminCourseDetails from "./components/admin/Courses/CourseDetails";
import InstructorScreen from "./screens/admin/Intructors/InstructorScreen";
import EventDetails from "./components/admin/Events/Update";
import Students from "./components/admin/Students/Students";
import LibraryScreen from "./screens/admin/LibraryScreen/LibraryScreen";
import ScholarshipDetails from "./components/admin/scholarship/ScholarshipDetails";
import Applications from "./components/admin/scholarship/Applications";
import FundApplications from "./components/admin/Funding/FundApplications";
import Coupon from "./components/admin/Coupon/Coupon";

import Cart from "./components/Student/Cart/Cart";
import OrderTracker from "./components/Student/Cart/Pay/Tracking";
import CourseDetails from "./components/Student/Courses/CourseDetails";
import ShopItemDetails from "./components/Student/Shop/ShopItemDetails";
import PrivateRoute from "./utils/auth/PrivateRoute";
import AdminRoute from "./utils/auth/AdminRoute";
import FundingDetails from "./components/admin/Funding/FundingDetails";
import FundDetails from "./components/Student/Funding/FundDetails";
//import SchAppDetails from './components/admin/scholarship/SchAppDetails';

const App = () => {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/">
            {/*public routes*/}
            <Route index={true} path="" element={<HomeScreen />} />
            <Route index={true} path="login" element={<Login />} />
            <Route index={true} path="signup" element={<Register />} />
            <Route path="*" element={<Err404 />} />
            <Route path="reset-password" element={<PasswordResetComponent />} />
            <Route path="schedule" element={<Ad />} />
            <Route path="our-courses" element={<OurCourse />} />
            <Route path="more/:id" element={<Details />} />

            <Route element={<PrivateRoute />}>
              {/*post-login & profile update*/}
              <Route path="verify" element={<Otp />} />
              <Route path="cv" element={<Pg2 />} />
              <Route path="edu" element={<Pg3 />} />
              <Route path="exp" element={<Pg4 />} />
              <Route path="profile" element={<UserProfile />} />
            </Route>

            {/*student routes*/}
            <Route element={<PrivateRoute />}>
              {/* <Route path="profile" element={<CompleteProfileScreen />} /> */}
              <Route path="dashboard" element={<UserDashboardScreen />} />
              <Route path="settings" element={<SettingsScreen />} />

              <Route path="library" element={<LibrariesScreen />} />
              <Route path="studyhub" element={<StudyHubScreen />} />
              <Route path="studyhub/class/:id" element={<Class />} />
              <Route
                path="institution/courses/units/:id"
                element={<UnitsScreen />}
              />

              <Route path="conferencing" element={<Conferencing />} />
              <Route path="scholarships" element={<SchorlarshipScreen />} />
              <Route path="scholarship/:id" element={<DetailsScholarship />} />
              <Route path="fundings" element={<FundingsScreen />} />
              <Route path="fundings/details/:id" element={<FundDetails />} />
              <Route path="community" element={<CommunityScreen />} />
              <Route path="settings" element={<Settings />} />
              <Route index={true} path="support" element={<Support />} />
            </Route>

            <Route element={<PrivateRoute />}>
              {/*courses*/}
              <Route path="courses" element={<CoursesScreen />} />
              <Route path="compare" element={<CompareCourses />} />

              {/*cart*/}
              <Route path="cart" element={<Cart />} />
              <Route path="cart/order" element={<OrderTracker />} />

              <Route path="details/:id" element={<CourseDetails />} />
              <Route path="item-details/:id" element={<ShopItemDetails />} />

              <Route path="shop" element={<Shop />} />
            </Route>

            <Route element={<PrivateRoute />}>
              {/*scholarships*/}

              <Route path="sch/applications" element={<Applications />} />

              {/*fundings*/}
              {/* <Route path="fundings/details" element={<DetailFunding />} /> */}
              <Route path="fund/details/:id" element={<FundingDetails />} />
            </Route>

            {/*admin*/}
            <Route element={<PrivateRoute />}>
              <Route element={<AdminRoute />} isAdminRoute>
                <Route
                  path="admin-dash"
                  element={<AdminDashScreen />}
                  isAdminRoute
                />
                <Route
                  path="scholarship"
                  element={<ScholarshipScreen />}
                  isAdminRoute
                />
                <Route
                  path="sch/details/:id"
                  element={<ScholarshipDetails />}
                  isAdminRoute
                />
                <Route
                  path="fund/applications"
                  element={<FundApplications />}
                  isAdminRoute
                />
                <Route path="funding" element={<FundingScreen />} />
                <Route path="coupon" element={<Coupon />} />

                <Route
                  path="institutions"
                  element={<InstitutionScreen />}
                  isAdminRoute
                />
                <Route
                  path="institution/courses"
                  element={<CourseAdminScreen />}
                  isAdminRoute
                />
                <Route
                  path="institution/courses/:id"
                  element={<AdminCourseDetails />}
                  isAdminRoute
                />
                <Route
                  path="requests"
                  element={<ApplicationScreen />}
                  isAdminRoute
                />
                <Route
                  path="topics/details/:id"
                  element={<TopicDetails />}
                  isAdminRoute
                />
                <Route
                  path="institution/course/unit/topics/:id"
                  element={<TopicsScreen />}
                  isAdminRoute
                />
                <Route
                  path="events"
                  element={<InstructorScreen />}
                  isAdminRoute
                />
                <Route
                  path="event/:id"
                  element={<EventDetails />}
                  isAdminRoute
                />
                <Route path="admin/users" element={<Students />} isAdminRoute />
                <Route
                  path="admin/library"
                  element={<LibraryScreen />}
                  isAdminRoute
                />
                <Route path="market" element={<MarketScreen />} isAdminRoute />
              </Route>
            </Route>
          </Route>
        </Routes>
      </Router>

      <Toaster
        position="top-right"
        reverseOrder={false}
        autoClose="5000"
        toastOptions={{
          className: "",
          style: {
            margin: "40px",
            background: "#011",
            zIndex: 1,
            color: "#fff",
          },
        }}
      ></Toaster>

      <ToastContainer />
    </>
  );
};

export default App;
