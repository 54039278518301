import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";

import axios from "../../../API/apiHandler";

const UpdateUnit = ({ uuid, unit }) => {
  const [fetchedInstructors, setFetchedInstructors] = useState({});

  const [unitForm, setUnitForm] = useState({
    inst_id: 1,
    unit_full_name: "",
    unit_short_code: "",
    unit_summary: "",
    status: true,
    unit_short_desc: "",
    unit_instructor: 0,
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setUnitForm((prevUnitForm) => {
      return {
        ...prevUnitForm,
        [name]: value,
      };
    });
  };

  useEffect(() => {
    setUnitForm(
      unit
      //   {
      //   inst_id: unit.inst_id,
      //   unit_full_name: unit.unit_full_name,
      //   unit_short_code: unit.unit_short_code,
      //   unit_summary: unit.unit_summary,
      //   status: unit.status,
      //   unit_short_desc: unit.unit_short_desc,
      //   unit_instructor: unit.unit_instructor,
      // }
    );
  }, [unit]);

  // useEffect(() => {
  //   const fetchUnit = async () => {
  //     const token = JSON.parse(localStorage.getItem("fastapi"));
  //     const response = await axios.get(`/api/v1/units/${uuid}`, {
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `Bearer ${token}`,
  //       },
  //     });
  //     setUnitForm(response?.data);
  //   };
  //   fetchUnit();
  // }, [uuid, unitForm]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const inst_id = unitForm.inst_id;
    const unit_full_name = unitForm.unit_full_name;
    const unit_short_code = unitForm.unit_short_code;
    const unit_summary = unitForm.unit_summary;
    const status = unitForm.status;
    const unit_short_desc = unitForm.unit_short_desc;
    const unit_instructor = unitForm.unit_instructor;

    const unitData = {
      inst_id,
      unit_full_name,
      unit_short_code,
      unit_summary,
      status,
      unit_short_desc,
      unit_instructor,
    };

    try {
      const token = JSON.parse(localStorage.getItem("fastapi"));
      const response = await axios.put(`/api/v1/units/${uuid}`, unitData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      });

      if (response.status === 201 || response.status === 200) {
        toast.success("Unit updated successfully");
        window.location.reload();
      }
    } catch (error) {
      toast.error("Error adding unit");
    }
  };

  useEffect(() => {
    const fetchInstructors = async () => {
      const token = JSON.parse(localStorage.getItem("fastapi"));
      const response = await axios.get(`/api/v1/users/instructors/${1}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      setFetchedInstructors(response?.data);
    };

    (async () => {
      await fetchInstructors();
    })();
  }, []);

  const [visible, setVisible] = useState(false);
  const footerContent = (
    <div>
      <Button
        label="Cancel"
        icon="pi pi-times"
        onClick={() => setVisible(false)}
        className="p-button-text"
      />
      {/* <Button type='submit' label="Confirm" icon="pi pi-check" onClick={handleSubmit} autoFocus /> */}
    </div>
  );

  return (
    <div className="w-full">
      <Button
        label="Update"
        icon="pi pi-users"
        onClick={() => setVisible(true)}
      />

      <Dialog
        header="Update Unit"
        visible={visible}
        style={{ width: "50vw" }}
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}
        onHide={() => setVisible(false)}
        footer={footerContent}
      >
        <div className=" p-4 text-dark">
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-12 p-3">
                <div className="inputBox p-2 mb-2">
                  <span>unit name :</span>
                  <input
                    type="text"
                    placeholder="machine learning"
                    onChange={handleChange}
                    required
                    value={unitForm.unit_full_name}
                    name="unit_full_name"
                    className="w-full focus:outline-none border-2 border-green-500 p-2 rounded-md"
                  />
                </div>

                <div className="inputBox p-2 mb-2">
                  <span>unit summary :</span>
                  <input
                    type="text"
                    placeholder="summary"
                    onChange={handleChange}
                    required
                    value={unitForm.unit_summary}
                    name="unit_summary"
                    className="w-full focus:outline-none border-2 border-green-500 p-2 rounded-md"
                  />
                </div>

                <div className="flex">
                  <div className="inputBox p-2 mb-2">
                    <span>Unit short description :</span>
                    <input
                      type="text"
                      placeholder="brief details"
                      onChange={handleChange}
                      required
                      value={unitForm.unit_short_desc}
                      name="unit_short_desc"
                      className="w-full focus:outline-none border-2 border-green-500 p-2 rounded-md"
                    />
                  </div>

                  <div className="inputBox p-2 mb-2">
                    <span>unit code :</span>
                    <input
                      type="text"
                      placeholder="COM 2301"
                      onChange={handleChange}
                      required
                      value={unitForm.unit_short_code}
                      name="unit_short_code"
                      className="w-full focus:outline-none border-2 border-green-500 p-2 rounded-md"
                    />
                  </div>
                </div>

                <div>
                  <label className="form-label mt-2" htmlFor="unit_instructor">
                    Unit Instructor
                  </label>
                  <select
                    aria-label="Default select"
                    onChange={handleChange}
                    required
                    value={unitForm.unit_instructor}
                    name="unit_instructor"
                    className="w-full form-select focus:outline-none border-2 border-green-500 p-2 rounded-md"
                  >
                    <option>Select Unit Intructor</option>
                    {fetchedInstructors?.length > 0 ? (
                      fetchedInstructors?.map((instructor, index) => (
                        <option value={instructor.id} key={index}>
                          {instructor.first_name} {instructor.last_name}
                        </option>
                      ))
                    ) : (
                      <option>No Instructors yet</option>
                    )}
                  </select>
                </div>
              </div>
            </div>

            <input
              type="submit"
              value="Update Unit"
              className="bg-green-500 text-white py-6 px-4 rounded-md border-2 border-green-500 hover:bg-transparent hover:text-green-500 transition duration-300 cursor-pointer"
            />
          </form>
        </div>
      </Dialog>
    </div>
  );
};

export default UpdateUnit;
