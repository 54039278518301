import React from 'react'
import { Link } from 'react-router-dom'

const CompareCard = (props) => {

	const {  name, intake, cost, study_area, duration, accreditation, institution } = props


	return (
		<div>
			<div className="card m-2">
				<div className="card-header">
					<div className="d-flex">
						<div>
							<div className="d-flex flex-column">
								<img src="https://picsum.photos/200/" alt="" className=" border border-info m-2 " style={{ width: '6rem', borderRadius: '50%', height: '6rem' }} />
							</div>
						</div>

						<div>
							<div className="d-flex flex-column">
								<h5>{institution}</h5>
								<span>(Main Campus)</span>
							</div>
						</div>

						<div>
							<div className="d-flex flex-column gap-1">
								<Link to="/details" className="btn btn-info p-1">
									<i className='fa fa-info'></i> Details</Link>
								<Link to="/" onClick={() => alert('added to cart')} className="btn btn-primary p-1">
									<i className='fa fa-pen'></i> Enrol</Link>
							</div>
						</div>
					</div>
				</div>

				<div className="card-body">
					<div className="d-flex flex-column">
						<span>
							<strong>Course:</strong> {name}
						</span>
						<span>
							<strong>Accreditation:</strong> {accreditation}
						</span>
						<span>
							<strong>First Offered:</strong> {intake}
						</span>
						<span>
							<strong>Full Course Cost:</strong> {cost}
						</span>
						<span>
							<strong>Intakes:</strong> {intake}
						</span>
						<span>
							<strong>Offered Online:</strong> {study_area}
						</span>
						<span>
							<strong>Offered On Campus:</strong> {study_area}
						</span>
						<span>
							<strong>On Campus Residence:</strong> FCFS Basis
						</span>

						<div className="mt-3 d-flex flex-column">
							<h6>Academics</h6>
							<ul>
								<li>Duration of Study: {duration}</li>
								<li>Course Structure: 4 semesters per year</li>
								<li>Course Load: 15 credit hours per semester</li>
								<li>Core Units: ...</li>
								<li>Elective Units: ...</li>
								<li>Industrial attachment: ...</li>
								<li>Pass Mark: 40%</li>

							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default CompareCard
