import { HashLink as Link } from "react-router-hash-link";
import { useEffect, useState } from "react";
import axios from "../../API/apiHandler";
import "./style.css";
import LoadingOverlay from "../../components/Load/LoadingOverlay";
import Logo from "../../images/acts-logo@1x.png";
// import CryptoJS from 'crypto-js';
import Card from "./Card";

const Ad = () => {
  const [enrolled, setEnrolled] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    
    const fetchCourses = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(
          "/api/v1/courses/published",
          
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        const courses = response?.data;
        courses.sort((a, b) => b.id - a.id);
        setEnrolled(courses);
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    };

    fetchCourses();

    
  }, []);
  // const [randomCourses, setRandomCourses] = useState([]);
  // useEffect(() => {
  //   const shuffledCourses = enrolled.sort(() => 0.5 - Math.random());
  //   const selectedCourses = shuffledCourses.slice(0, enrolled?.length);
  //   setRandomCourses(selectedCourses);
  // }, [enrolled]);

  const handleClicka = () => {
    window.location.href = "mailto:academy@acts-net.org";
  };
  const ig = () => {
    window.location.href = "https://www.instagram.com/actspathways/";
  };
  const flickR = () => {
    window.location.href = "https://www.flickr.com/photos/virtual_academy/";
  };
  const tweet = () => {
    window.location.href = "https://twitter.com/ActsPathways";
  };
  const fb = () => {
    window.location.href = "https://www.facebook.com/ACTSNET";
  };
  // const secretKey = process.env.REACT_APP_SECRET

  // const encrypt = (unit) => {
  // 	const idString = unit.toString();
  // 	if (!idString || isNaN(parseInt(idString))) {
  // 		return '';
  // 	}
  // 	const encrypted = CryptoJS.AES.encrypt(idString, secretKey).toString();
  // 	return encrypted
  // }

  // const encrypt = (unit) => {
  // 	const idString = unit?.toString?.();
  // 	if (!idString || isNaN(parseInt(idString))) {
  // 		return '';
  // 	}
  // 	const secretKey = CryptoJS.lib.WordArray.random(16);
  // 	const iv = CryptoJS.lib.WordArray.random(12);
  // 	const encrypted = CryptoJS.GCM.encrypt(idString, secretKey, {
  // 		iv: iv
  // 	});
  // 	const ciphertext = encrypted.ciphertext.toString(CryptoJS.enc.Hex);
  // 	const tag = encrypted.tag.toString(CryptoJS.enc.Hex);
  // 	return `${iv}:${ciphertext}:${tag}:${secretKey.toString(CryptoJS.enc.Hex)}`;
  // }

  return (
    <>
      {isLoading ? (
        <LoadingOverlay />
      ) : (
        <div className="container-fluid">
          <div className="top-nav " id="home">
            <div className="container">
              <div className="row justify-content-between">
                <div className="col-auto">
                  <span onClick={handleClicka} style={{ cursor: "pointer" }}>
                    <i className="bx bxs-envelope"></i> academy@acts-net.org
                  </span>
                </div>
                <div className="col-auto social-icons">
                  <Link onClick={fb} style={{ backgroundColor: "#FFF" }}>
                    <i className="bx bxl-facebook text-info"></i>
                  </Link>
                  <Link onClick={tweet} style={{ backgroundColor: "#FFF" }}>
                    <i className="bx bxl-twitter text-primary"></i>
                  </Link>
                  <Link onClick={ig} style={{ backgroundColor: "#FFF" }}>
                    <i className="bx bxl-instagram text-danger"></i>
                  </Link>
                  <Link onClick={flickR} style={{ backgroundColor: "#FFF" }}>
                    <i className="bx bxl-flickr text-dark"></i>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <nav className="navbar navbar-expand-lg navbar-light bg-white sticky-top">
            <div className="container">
              <Link className="navbar-brand" to="#">
                <img
                  src={Logo}
                  style={{ width: "13%", height: "13%" }}
                  // className="text-light bg-warning"
                  alt="Logo"
                  loading="lazy"
                />
              </Link>
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarNav"
                aria-controls="navbarNav"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div className="collapse navbar-collapse" id="navbarNav">
                <ul className="navbar-nav ms-auto">
                  <li className="nav-item">
                    <Link className="nav-link" smooth to="/">
                      HomePage
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" smooth to="/schedule">
                      Events
                    </Link>
                  </li>
                </ul>
                <Link to="/signup" className="btn btn-outline-success ms-lg-3">
                  Signup
                </Link>
              </div>
            </div>
          </nav>

          <section
            id=""
            className="event1 bg-secondary"
            style={{ overflow: "scroll" }}
          >
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-12 py-5">
                  <div className="row">
                    <div className="col-12">
                      <div className="text-center ">
                        <h6 className="text-light">The Best</h6>
                        <h1 className="text-white fs-2">Courses Offered</h1>
                      </div>

                      {enrolled.map((enrol, idx) => (
                        <Card
                          id={enrol.id}
                          name={enrol.course_full_name}
                          course_code={enrol.course_code}
                          summary={enrol.course_summary}
                          short_desc={enrol.course_short_desc}
                          intake={enrol.course_first_offered}
                          cost={enrol.course_full_amount}
                          study_area={enrol.study_area}
                          duration={enrol.course_length}
                          accreditation={enrol.course_accreditation}
                          category={enrol.category.cat_name}
                          institution={enrol.institution.inst_name}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <footer>
            <div className="footer-top text-center">
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-lg-6 text-center">
                    <h4 className="navbar-brand">
                      ACTS Pathways Academy<span className="dot">.</span>
                    </h4>
                    <p>
                      Our vision is knowledge for better livelihoods. Our
                      Mission is to strengthen the capacity and policies of
                      African countries and institutions to harness science,
                      technology and innovation for sustainable development
                    </p>
                    <div className="col-auto social-icons">
                      <Link onClick={fb}>
                        <i className="bx bxl-facebook"></i>
                      </Link>
                      <Link onClick={tweet}>
                        <i className="bx bxl-twitter"></i>
                      </Link>
                      <Link onClick={ig}>
                        <i className="bx bxl-instagram"></i>
                      </Link>
                      <Link onClick={flickR}>
                        <i className="bx bxl-flickr"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="footer-bottom text-center">
              <p className="mb-0">Copyright@2023. All rights Reserved</p>
            </div>
          </footer>
        </div>
      )}
    </>
  );
};

export default Ad;
