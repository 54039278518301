import React from "react";
import axios from "../../../API/apiHandler";
import { toast } from "react-hot-toast";
const Card = (props) => {
  const {
    id,
    name,
    price,
    manufacturer,
    warranty,
    type,
    discount,
    category,
    short_desc,
  } = props;
  //quantity, summary, , model, codebatch
  const token = JSON.parse(localStorage.getItem("fastapi"));
  const deleteItem = async (id) => {
    try {
      await axios.delete(`/api/v1/shop/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      toast.error(error.response.data.details);
    }
  };

  return (
    <div>
      <div className="card w-100 mb-3">
        <div className="row g-0">
          <div className="col-md-4">
            <img
              src="https://via.placeholder.com/300x200"
              className="img-fluid rounded-start"
              alt="item pic"
            />
          </div>
          <div className="col-md-8">
            <div className="card-body">
              <h5 className="card-title text-primary fw-bold">
                Product Name: {name}
              </h5>
              <p className="card-text">{short_desc}</p>
              <div className="row">
                <div className="col-md-6">
                  <ul className="list-group list-group-flush">
                    <li className="list-group-item">
                      Price: ${price - discount}
                    </li>
                    <li className="list-group-item">Discount: ${discount}</li>
                    <li className="list-group-item">
                      Warranty: {warranty}days
                    </li>
                  </ul>
                </div>
                <div className="col-md-6">
                  <ul className="list-group list-group-flush">
                    <li className="list-group-item">Category: {category}</li>
                    <li className="list-group-item">Type: {type}</li>
                    <li className="list-group-item">
                      Manufacturer: {manufacturer}
                    </li>
                  </ul>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-md-4">
                  <button
                    type="button"
                    className="btn btn-outline-primary w-100"
                  >
                    <i className="fas fa-pen me-2"></i> Update
                  </button>
                </div>
                <div className="col-md-4">
                  <button
                    type="button"
                    className="btn btn-outline-dark text-dark"
                    style={{ borderBottom: "1px dashed blue" }}
                  >
                    See Details
                  </button>
                </div>
                <div className="col-md-4">
                  <button
                    type="button"
                    className="btn btn-outline-danger w-100"
                    onClick={() => deleteItem(id)}
                  >
                    <i className="fas fa-trash-alt me-2"></i> Delete
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
