import { useContext, useEffect, useState, useMemo } from "react";
import ProfileContext from "../../../../Context/ProfileContext";
import { toast } from "react-hot-toast";
import Select from "react-select";
import countryList from "react-select-country-list";
import { useNavigate } from "react-router-dom";

import axios from "../../../../API/apiHandler";
import avatar from "../../../../images/profile.png";
import Bg from "../../../../img/pexels-kampus-production-5940841-1@1x.png";

const Pg2 = () => {
  const options = useMemo(() => countryList().getData(), []);
  const navigate = useNavigate();

  const {
    firstname,
    setFirstname,
    lastname,
    setLastname,
    otherName,
    setOtherName,
    dob,
    setDob,
    gender,
    setGender,
    nationality,
    setNationality,
    country,
    setCountry,
    state,
    setState,
    city,
    setCity,
    auth,
    token,
    file,
    setFile,
  } = useContext(ProfileContext);

  const [loading, setLoading] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const personalData = {
      first_name: firstname,
      last_name: lastname,
      other_names: otherName,
      nationality: nationality,
      dob: dob,
      gender: gender,
      country: country,
      state: state,
      city: city,
      profile_skip: false,
      confirmed: true,
      policy_agreed: true,
      profile_completed: true,
    };

    try {
      const updateProfilePromise = await axios.put(
        "/api/v1/user/profile",
        personalData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const avat = file ? file : avatar;
      const formData = new FormData();
      formData.append("avatar", avat);

      const avatarPromise = await axios.post("/api/v1/users/avatar", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });

      await Promise.all([updateProfilePromise, avatarPromise]);

      toast.success("Personal info Saved");
      setLoading(false);
      navigate("/edu");
    } catch (error) {
      if (error.response?.data.detail) {
        toast.error(error.response?.data.detail);
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    

    const fetchImg = async () => {
      try {
        const res = await axios.get(
          `/api/v1/users/avatar/${auth}`,
          
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            responseType: "arraybuffer",
          }
        );
        if (res?.data) {
          const base64 = btoa(
            new Uint8Array(res.data).reduce(
              (data, byte) => data + String.fromCharCode(byte),
              ""
            )
          );
          setFile(`data:;base64,${base64}`);
        }
      } catch (error) {
        if (error.response?.data.detail) {
          toast.error(error.response?.data.detail);
        }
      }
    };
    fetchImg();
    
  }, [auth, setFile, token]);

  const handleImage = (e) => {
    setFile(e.target.files[0]);
  };

  const handleNationalityChange = (selectedOption) => {
    setNationality(selectedOption.value);
  };

  const handleCountryChange = (selectedOption) => {
    setCountry(selectedOption.label);
  };

  return (
    <>
      <div
        className="container-fluid"
        style={{
          backgroundImage: `url(${Bg})`,
          backgroundRepeat: "no-repeat",
          width: "100%",
          minHeight: "100vh",
          loading: "lazy",
        }}
      >
        <div className="container">
          <div className="card border-info border-2 mb-3 mt-5">
            <h2 className="m-2 fs-3 " style={{ color: "#094886" }}>
              Personal Details
            </h2>

            <form onSubmit={handleSubmit} className="">
              <div className="row">
                <div className="col-12 m-2">
                  <div className="d-flex flex-column align-items-center justify-content-center gap-3">
                    <span className="mb-1 text-black">
                      Click to Upload Profile Picture
                    </span>
                    <div className="form-group">
                      <label className="custom-file-label" htmlFor="avatar">
                        <img
                          className="img-thumbnail  rounded-circle"
                          src={file ? file : avatar}
                          alt="..."
                          style={{ width: "100px", height: "100px" }}
                        />
                      </label>
                      <div className="custom-file d-none">
                        <input
                          type="file"
                          onChange={handleImage}
                          className="custom-file-input"
                          id="avatar"
                          accept="image/*"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6 col-lg-4">
                  <div className="m-2">
                    <div className="mb-3 mt-3">
                      <label htmlFor="firstname" className="form-label">
                        First Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="firstname"
                        placeholder="first name"
                        name="firstname"
                        value={firstname}
                        onChange={(e) => {
                          setFirstname(e.target.value);
                        }}
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="lastname" className="form-label">
                        Last Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="lastname"
                        placeholder="last name"
                        name="lastname"
                        value={lastname}
                        onChange={(e) => {
                          setLastname(e.target.value);
                        }}
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="otherName" className="form-label">
                        Other Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="otherName"
                        placeholder="other name"
                        name="otherName"
                        value={otherName}
                        onChange={(e) => {
                          setOtherName(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-sm-6 col-lg-4">
                  <div className="m-2">
                    <label htmlFor="dob" className="col-form-label">
                      Date of Birth
                    </label>
                    <div className="col-auto mb-3">
                      <div className="input-group date" id="datepicker">
                        <input
                          type="date"
                          className="form-control"
                          id="date"
                          name="dob"
                          value={dob}
                          onChange={(e) => {
                            // const inputDate = e.target.value;
                            // const dateObj = new Date(inputDate);
                            // const formattedDate = dateObj.toISOString().slice(0, 10);
                            // setDob(formattedDate)
                            setDob(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <label htmlFor="gender" className="col-form-label">
                      Select your gender
                    </label>
                    <select
                      className="form-select mb-3"
                      aria-label="Default select example"
                      value={gender}
                      name="gender"
                      onChange={(e) => {
                        setGender(e.target.value);
                      }}
                    >
                      <option defaultValue>Gender</option>
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                      <option value="other">Other</option>
                    </select>

                    <label htmlFor="nationality" className="col-form-label">
                      Select Your Nationality
                    </label>
                    <Select
                      options={options}
                      className="form-select mb-3"
                      aria-label="Default select example"
                      name="nationality"
                      value={nationality?.value}
                      onChange={handleNationalityChange}
                    />
                  </div>
                </div>

                <div className="col-sm-6 col-lg-4">
                  <div className="m-2">
                    <div>
                      <label htmlFor="country" className="col-form-label">
                        Select Your Country
                      </label>
                      <Select
                        options={options}
                        className="form-select mb-3"
                        aria-label="Default select example"
                        value={country?.label}
                        name="country"
                        onChange={handleCountryChange}
                      />
                    </div>

                    <div className="mb-3">
                      <label htmlFor="state" className="form-label">
                        Enter your State
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="state"
                        placeholder="State"
                        name="state"
                        value={state}
                        onChange={(e) => {
                          setState(e.target.value);
                        }}
                      />
                    </div>

                    <div className="mb-3">
                      <label htmlFor="city" className="form-label">
                        Enter your City
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="city"
                        placeholder="City"
                        name="city"
                        value={city}
                        onChange={(e) => {
                          setCity(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div className="mt-4 d-flex justify-content-end">
                  {loading ? (
                    <button
                      className="btn btn-outline-primary w-25 text-dark"
                      type="button"
                      disabled
                    >
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      Saving...
                    </button>
                  ) : (
                    <button
                      type="submit"
                      className="btn btn-primary w-25 text-dark"
                    >
                      Next (3/5)
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Pg2;
