import { HashLink as Link } from "react-router-hash-link";
import Scheduler from "./Scheduler";
import { useEffect, useState } from "react";
import axios from "../../API/apiHandler";
import "./style.css";
import LoadingOverlay from "../../components/Load/LoadingOverlay";
import Logo from "../../images/acts-logo@1x.png";

const Ad = () => {
  const [enrolled, setEnrolled] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchCourses = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get("/api/v1/courses/published", {
        headers: {
          "Content-Type": "application/json",
        },
      });

      const courses = response?.data;
      setEnrolled(courses);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const [randomCourses, setRandomCourses] = useState([]);
  useEffect(() => {

    const shuffledCourses = enrolled.sort(() => 0.5 - Math.random());
    const selectedCourses = shuffledCourses.slice(0, 3);
    setRandomCourses(selectedCourses);

    
  }, [enrolled]);

  useEffect(() => {
    fetchCourses();
  }, []);

  const handleClicka = () => {
    window.location.href = "mailto:academy@acts-net.org";
  };
  const ig = () => {
    window.location.href = "https://www.instagram.com/actspathways/";
  };
  const flickR = () => {
    window.location.href = "https://www.flickr.com/photos/virtual_academy/";
  };
  const tweet = () => {
    window.location.href = "https://twitter.com/ActsPathways";
  };
  const fb = () => {
    window.location.href = "https://www.facebook.com/ACTSNET";
  };

  return (
    <>
      {isLoading ? (
        <LoadingOverlay />
      ) : (
        <div className="container-fluid">
          <div className="top-nav " id="home">
            <div className="container">
              <div className="row justify-content-between">
                <div className="col-auto">
                  <span onClick={handleClicka} style={{ cursor: "pointer" }}>
                    <i className="bx bxs-envelope"></i> academy@acts-net.org
                  </span>
                </div>
                <div className="col-auto social-icons">
                  <Link onClick={fb} style={{ backgroundColor: "#FFF" }}>
                    <i className="bx bxl-facebook text-info"></i>
                  </Link>
                  <Link onClick={tweet} style={{ backgroundColor: "#FFF" }}>
                    <i className="bx bxl-twitter text-primary"></i>
                  </Link>
                  <Link onClick={ig} style={{ backgroundColor: "#FFF" }}>
                    <i className="bx bxl-instagram text-danger"></i>
                  </Link>
                  <Link onClick={flickR} style={{ backgroundColor: "#FFF" }}>
                    <i className="bx bxl-flickr text-dark"></i>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <nav className="navbar navbar-expand-lg navbar-light bg-white sticky-top">
            <div className="container">
              <Link className="navbar-brand" to="#">
                <img
                  src={Logo}
                  style={{ width: "13%", height: "13%" }}
                  className="text-light bg-warning"
                  alt="Logo"
                  loading="lazy"
                />
                {/* <span className="dot">ACTS</span>*/}
              </Link>
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarNav"
                aria-controls="navbarNav"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div className="collapse navbar-collapse" id="navbarNav">
                <ul className="navbar-nav ms-auto fs-6">
                  <li className="nav-item">
                    <Link className="nav-link" smooth to="/">
                      HomePage
                    </Link>
                  </li>
                  {/* <li className="nav-item">
										<Link className="nav-link" smooth to="#calender">Calender</Link>
									</li> */}
                  <li className="nav-item">
                    <Link className="nav-link" smooth to="/our-courses">
                      Courses
                    </Link>
                  </li>
                </ul>
                <Link to="/signup" className="btn btn-outline-success ms-lg-3">
                  Signup
                </Link>
              </div>
            </div>
          </nav>
          <section id="calender" className="event1">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-12 py-5">
                  <div className="row">
                    <div className="col-12">
                      <div className="text-center ">
                        <h6 className="text-light">Our Schedule</h6>
                        <h1 className="text-success fs-2">
                          Events and Activities
                        </h1>
                      </div>
                      <Scheduler />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section id="courses" className=" bg-secondary">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-12 py-5">
                  <div className="row">
                    <div className="col-12">
                      <div className="text-center mb-2 ">
                        <h6 className="text-light">Our Best</h6>
                        <h1 className="text-white fs-2">Popular Courses</h1>
                      </div>
                      <section className="text-center">
                        <div className="container">
                          <div className="row g-4" style={{ height: "100%" }}>
                            {randomCourses.map((course, idx) => (
                              <div className="col-lg-4 col-md-6" key={idx}>
                                <div
                                  className="service"
                                  style={{ height: "100%" }}
                                >
                                  <i className="bx bx-book fs-1 display-1 text-primary"></i>
                                  <h1 className="text-success fs-5 mt-2">
                                    {course?.course_full_name}
                                  </h1>
                                  <p
                                    className="text-black"
                                    style={{ whiteSpace: "pre-wrap" }}
                                    dangerouslySetInnerHTML={{
                                      __html: course?.course_short_desc,
                                    }}
                                  />
                                  <Link
                                    to={`/details/${course.id}`}
                                    className="btn btn-success mt-3 enrol_cta"
                                  >
                                    Interested{" "}
                                    <i className="bx bx-right-arrow-alt"></i>
                                  </Link>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <footer>
            <div className="footer-top text-center">
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-lg-6 text-center">
                    <h4 className="navbar-brand">
                      ACTS Pathways Academy<span className="dot">.</span>
                    </h4>
                    <p>
                      Our vision is knowledge for better livelihoods. Our
                      Mission is to strengthen the capacity and policies of
                      African countries and institutions to harness science,
                      technology and innovation for sustainable development
                    </p>
                    <div className="col-auto social-icons">
                      <Link onClick={fb}>
                        <i className="bx bxl-facebook"></i>
                      </Link>
                      <Link onClick={tweet}>
                        <i className="bx bxl-twitter"></i>
                      </Link>
                      <Link onClick={ig}>
                        <i className="bx bxl-instagram"></i>
                      </Link>
                      <Link onClick={flickR}>
                        <i className="bx bxl-flickr"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="footer-bottom text-center">
              <p className="mb-0">Copyright@2023. All rights Reserved</p>
            </div>
          </footer>
        </div>
      )}
    </>
  );
};

export default Ad;
