import { useState, useContext, useEffect } from "react";
import { toast } from "react-hot-toast";

import ProfileContext from "../../../Context/ProfileContext";
import axios from "../../../API/apiHandler";

const Update = ({ lib }) => {
  const { token } = useContext(ProfileContext);

  const [library, setLibrary] = useState({
    inst_id: 1,
    resource_title: "",
    resource_desc: "",
    resource_type: "",
    resource_author: "",
    resource_subject: "",
    resource_edition: 0,
    resource_isbn10: "",
    resource_isbn13: "",
    resource_price: 0,
    resource_publish: true,
    resource_serial_no: "",
    resource_publisher: "",
    resource_publication_date: "",
    status: true,
  });

  const handleChange = (event) => {
    const { name, value, type, files } = event.target;
    setLibrary((prevLibrary) => {
      return {
        ...prevLibrary,
        [name]: type === "file" ? files[0] : value,
      };
    });
  };

  useEffect(() => {
    setLibrary(
      lib
      //   {
      //   inst_id: lib.inst_id,
      //   resource_title: lib.resource_title,
      //   resource_desc: lib.resource_desc,
      //   resource_type: lib.resource_type,
      //   resource_author: lib.resource_author,
      //   resource_subject: lib.resource_subject,
      //   resource_edition: lib.resource_edition,
      //   resource_isbn10: lib.resource_isbn10,
      //   resource_isbn13: lib.resource_isbn13,
      //   resource_price: lib.resource_price,
      //   resource_publish: lib.resource_publish,
      //   resource_serial_no: lib.resource_serial_no,
      //   resource_publisher: lib.resource_publisher,
      //   resource_publication_date: lib.resource_publication_date,
      //   status: lib.status,
      // }
    );
  }, [lib]);

  // useEffect(() => {

  //   const getLibrary = async () => {
  //     try {
  //       const res = await axios.get(
  //         `/api/v1/library/resources/${uuid}`,

  //         {
  //           headers: {
  //             "Content-Type": "application/json",
  //             Authorization: `Bearer ${token}`,
  //           },
  //         }
  //       );
  //       setLibrary(res.data);
  //     } catch (err) {}
  //   };
  //   getLibrary();

  // }, [token, uuid, library]);

  const handleUpdate = async (e) => {
    e.preventDefault();

    const inst_id = library.inst_id;
    const resource_title = library.resource_title;
    const resource_desc = library.resource_desc;
    const resource_type = library.resource_type;
    const resource_author = library.resource_author;
    const resource_subject = library.resource_subject;
    const resource_edition = library.resource_edition;
    const resource_isbn10 = library.resource_isbn10;
    const resource_isbn13 = library.resource_isbn13;
    const resource_price = library.resource_price;
    const resource_publish = library.resource_publish;
    const resource_serial_no = library.resource_serial_no;
    const resource_publisher = library.resource_publisher;
    const resource_publication_date = library.resource_publication_date;
    const status = library.status;

    const libData = {
      inst_id,
      resource_title,
      resource_desc,
      resource_type,
      resource_author,
      resource_subject,
      resource_edition,
      resource_isbn10,
      resource_isbn13,
      resource_price,
      resource_publish,
      resource_serial_no,
      resource_publisher,
      resource_publication_date,
      status,
    };

    try {
      const res = await axios.put(
        `/api/v1/library/resources/${lib?.id}`,
        libData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (res.status === 201 || res.status === 200) {
        toast.success("resource updated successfully");
      }
    } catch (err) {
      if (err.response.data.detail) {
        toast.error(err.response.data.detail);
      } else {
        toast.error("Failed, Try Again later");
      }
    }
  };

  return (
    <div>
      <div className="row mb-2">
        <div className="col">
          <div>
            <button
              type="button"
              className="btn btn-warning btn-block shadow"
              data-bs-toggle="modal"
              data-bs-target="#updatelib"
            >
              Update
            </button>

            <div
              className="modal fade"
              style={{ overflow: "scroll !important" }}
              id="updatelib"
              data-bs-backdrop="static"
              data-bs-keyboard="false"
              tabIndex="-1"
              aria-labelledby="updatelibLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-lg ">
                <div className="modal-content">
                  <div className="modal-header">
                    <h1
                      className="modal-title text-blue-900 fs-5"
                      id="updatelibLabel"
                    >
                      Update library details
                    </h1>
                    <button
                      type="button"
                      className="btn-close text-dark"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="modal-body">
                    <div className="modalcontainer text-dark">
                      <form onSubmit={handleUpdate}>
                        <div className="row">
                          <div className="col">
                            <div className="inputBox">
                              <span>Title :</span>
                              <input
                                type="text"
                                placeholder=""
                                onChange={handleChange}
                                required
                                value={library.resource_title}
                                name="resource_title"
                              />
                            </div>
                            <div className="inputBox">
                              <span>Author :</span>
                              <input
                                type="text"
                                placeholder=""
                                onChange={handleChange}
                                required
                                value={library.resource_author}
                                name="resource_author"
                              />
                            </div>

                            <div className="flex">
                              <div className="inputBox">
                                <span>Subject :</span>
                                <input
                                  type="text"
                                  placeholder=""
                                  onChange={handleChange}
                                  required
                                  value={library.resource_subject}
                                  name="resource_subject"
                                />
                              </div>
                              <div className="inputBox">
                                <span>Edition :</span>
                                <input
                                  type="text"
                                  placeholder=""
                                  onChange={handleChange}
                                  required
                                  value={library.resource_edition}
                                  name="resource_edition"
                                />
                              </div>

                              <div>
                                <span>Resource Type :</span>
                                <select
                                  className="form-select"
                                  aria-label="Default select"
                                  onChange={handleChange}
                                  required
                                  value={library.resource_type}
                                  name="resource_type"
                                >
                                  <option value="">Select</option>
                                  <option value="video/mp4">Video</option>
                                  <option value="audio/mp3">Audio</option>
                                  <option value="application/pdf">Pdf</option>
                                </select>
                              </div>
                            </div>

                            <div className="inputBox mb-3">
                              <span>Detailed Description :</span>
                              <textarea
                                className="form-control"
                                row="8"
                                name="resource_desc"
                                placeholder="Enter detailed description"
                                onChange={handleChange}
                                required
                                value={library.resource_desc}
                              />
                            </div>

                            <div className="flex">
                              <div className="inputBox">
                                <span>Publication Date :</span>
                                <input
                                  type="date"
                                  placeholder="12/02/2023"
                                  onChange={handleChange}
                                  required
                                  value={library.resource_publication_date}
                                  name="resource_publication_date"
                                />
                              </div>
                              <div className="inputBox">
                                <span>Price:</span>
                                <input
                                  type="number"
                                  placeholder="100"
                                  onChange={handleChange}
                                  required
                                  value={library.resource_price}
                                  name="resource_price"
                                />
                              </div>
                              <div className="inputBox">
                                <span>Serial Number :</span>
                                <input
                                  type="text"
                                  placeholder=""
                                  onChange={handleChange}
                                  required
                                  value={library.resource_serial_no}
                                  name="resource_serial_no"
                                />
                              </div>
                            </div>

                            <div className="flex">
                              <div className="inputBox">
                                <span>Publisher :</span>
                                <input
                                  type="text"
                                  placeholder=""
                                  onChange={handleChange}
                                  required
                                  value={library.resource_publisher}
                                  name="resource_publisher"
                                />
                              </div>
                              <div className="inputBox">
                                <span>isbn10:</span>
                                <input
                                  type="text"
                                  placeholder=""
                                  onChange={handleChange}
                                  required
                                  value={library.resource_isbn10}
                                  name="resource_isbn10"
                                />
                              </div>
                              <div className="inputBox">
                                <span>isbn13 :</span>
                                <input
                                  type="text"
                                  placeholder=""
                                  onChange={handleChange}
                                  required
                                  value={library.resource_isbn13}
                                  name="resource_isbn13"
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <input
                          type="submit"
                          value="Confirm"
                          className="submit-btn"
                        />
                      </form>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary  text-dark"
                      data-bs-dismiss="modal"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Update;
