import { useState, useEffect } from "react";
import { toast } from "react-hot-toast";
// import { useParams } from "react-router-dom";

import axios from "../../../API/apiHandler";

const Update = ({ sch }) => {
  // const { id } = useParams();
  const id = sch.id;

  const [scholarshipForm, setScholarshipForm] = useState({
    inst_id: 1,
    sch_title: "",
    sch_amount: 0,
    sch_eligibility: "",
    sch_area_of_study: "",
    sch_level_of_study: "",
    sch_type_of_funding: "",
    sch_short_desc: "",
    sch_full_desc: "",
    sch_deadline_date: "",
    published: true,
  });

  useEffect(() => {
    setScholarshipForm({
      inst_id: sch.inst_id,
      sch_title: sch.sch_title,
      sch_amount: sch.sch_amount,
      sch_eligibility: sch.sch_eligibility,
      sch_area_of_study: sch.sch_area_of_study,
      sch_level_of_study: sch.sch_level_of_study,
      sch_type_of_funding: sch.sch_type_of_funding,
      sch_short_desc: sch.sch_short_desc,
      sch_full_desc: sch.sch_full_desc,
      sch_deadline_date: sch.sch_deadline_date,
      published: sch.published,
    });
  }, [sch]);

  // useEffect(() => {
    

  //   const fetchScholarship = async () => {
  //     try {
  //       const token = JSON.parse(localStorage.getItem("fastapi"));
  //       const response = await axios.get(
  //         `/api/v1/scholarships/${id}`,
          
  //         {
  //           headers: {
  //             Authorization: `Bearer ${token}`,
  //             "Content-Type": "application/json",
  //           },
  //         }
  //       );
  //       if (response.status === 200 || response.status === 201) {
  //         setScholarshipForm({ ...scholarshipForm, ...response?.data });
  //       }
  //     } catch (error) {}
  //   };
  //   fetchScholarship();
    
  // }, [id, scholarshipForm]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setScholarshipForm((prevScholarshipForm) => {
      return {
        ...prevScholarshipForm,
        [name]: value,
      };
    });
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    const inst_id = scholarshipForm.inst_id;
    const sch_title = scholarshipForm.sch_title;
    const sch_amount = scholarshipForm.sch_amount;
    const sch_eligibility = scholarshipForm.sch_eligibility;
    const sch_area_of_study = scholarshipForm.sch_area_of_study;
    const sch_level_of_study = scholarshipForm.sch_level_of_study;
    const sch_type_of_funding = scholarshipForm.sch_type_of_funding;
    const sch_short_desc = scholarshipForm.sch_short_desc;
    const sch_full_desc = scholarshipForm.sch_full_desc;
    const sch_deadline_date = scholarshipForm.sch_deadline_date;
    const published = scholarshipForm.published;

    const scholarshipUpdate = {
      inst_id,
      sch_title,
      sch_amount,
      sch_eligibility,
      sch_area_of_study,
      sch_level_of_study,
      sch_type_of_funding,
      sch_short_desc,
      sch_full_desc,
      sch_deadline_date,
      published,
    };

    try {
      const token = JSON.parse(localStorage.getItem("fastapi"));
      const response = await axios.put(
        `/api/v1/scholarships/${id}`,
        scholarshipUpdate,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status === 201 || response.status === 200) {
        toast.success("updated successfully");
        window.location.reload();
      }
    } catch (error) {}
  };

  return (
    <>
      <div>
        <div className="d-grid">
          <button
            type="button"
            className="btn btn-warning text-warning shadow"
            style={{
              boxShadow: "inset 2px 2px 6px #222",
              border: "2px solid #011",
            }}
            data-bs-toggle="modal"
            data-bs-target="#updatescholarship"
          >
            <span className="">
              <i className="fas fa-plus-circle"></i>
              Update
            </span>
          </button>
        </div>

        <div
          className="modal fade w-full container"
          id="updatescholarship"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex="-1"
          aria-labelledby="updatescholarshipLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg w-full">
            <div className="modal-content">
              <div className="modal-header">
                <h1
                  className="modal-title text-primary fs-5"
                  id="updatescholarshipLabel"
                >
                  Update scholarship details
                </h1>
                <button
                  type="button"
                  className="btn-close text-dark"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className="modalcontainer text-dark">
                  <form onSubmit={handleUpdate}>
                    <div className="row">
                      <div className="col">
                        <div className="inputBox">
                          <span>scholarship Title :</span>
                          <input
                            type="text"
                            placeholder=""
                            onChange={handleChange}
                            name="sch_title"
                            required
                            value={scholarshipForm.sch_title}
                          />
                        </div>
                        <div className="inputBox">
                          <span>Level of Study :</span>
                          <input
                            type="text"
                            placeholder=""
                            onChange={handleChange}
                            required
                            value={scholarshipForm.sch_level_of_study}
                            name="sch_level_of_study"
                          />
                        </div>
                        <div className="inputBox">
                          <span>Amount :</span>
                          <input
                            type="text"
                            placeholder=""
                            onChange={handleChange}
                            required
                            value={scholarshipForm.scholarship_amount}
                            name="sch_amount"
                          />
                        </div>
                        <div className="flex">
                          <div className="inputBox">
                            <span>Type Of Funding :</span>
                            <input
                              type="text"
                              placeholder=""
                              onChange={handleChange}
                              required
                              value={scholarshipForm.sch_type_of_funding}
                              name="sch_type_of_funding"
                            />
                          </div>
                        </div>

                        <div className="flex">
                          <div className="inputBox">
                            <span>Areea Of Study :</span>
                            <input
                              type="text"
                              placeholder=""
                              onChange={handleChange}
                              required
                              value={scholarshipForm.sch_area_of_study}
                              name="sch_area_of_study"
                            />
                          </div>

                          <div className="inputBox mb-3">
                            <span>Deadline :</span>
                            <input
                              type="date"
                              className="form-control"
                              name="sch_deadline_date"
                              placeholder=""
                              onChange={handleChange}
                              required
                              value={scholarshipForm.sch_deadline_date}
                            />
                          </div>
                        </div>
                        <div className="inputBox">
                          <span>Eligibility :</span>
                          <input
                            type="text"
                            placeholder=""
                            onChange={handleChange}
                            required
                            value={scholarshipForm.sch_eligibility}
                            name="sch_eligibility"
                          />
                        </div>

                        <div className="inputBox">
                          <span>Short Description :</span>
                          <textarea
                            className="form-control mb-4"
                            row="4"
                            placeholder="Enter short description"
                            onChange={handleChange}
                            required
                            value={scholarshipForm.sch_short_desc}
                            name="sch_short_desc"
                          />
                        </div>

                        <div className="inputBox">
                          <span>Full Description :</span>
                          <textarea
                            className="form-control mb-4"
                            row="4"
                            placeholder="Enter detailedf description"
                            onChange={handleChange}
                            required
                            value={scholarshipForm.sch_full_desc}
                            name="sch_full_desc"
                          />
                        </div>
                      </div>
                    </div>

                    <input
                      type="submit"
                      value="Update"
                      className="submit-btn"
                    />
                  </form>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-outline-danger  text-dark"
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Update;
