import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";

import axios from "../../../API/apiHandler";

const UpdateCourse = ({ CourseData }) => {
  const [courseForm, setCourseForm] = useState({
    inst_id: 1,
    cat_id: 0,
    course_full_name: "",
    course_code: "",
    course_study_area: "",
    course_study_level: "",
    course_short_desc: "",
    course_summary: "",
    course_full_amount: 0,
    course_length: "",
    course_first_offered: 0,
    course_accreditation: "",
  });

  useEffect(() => {
    setCourseForm({
      inst_id: CourseData?.course?.inst_id,
      cat_id: CourseData?.course?.cat_id,
      course_full_name: CourseData?.course?.course_full_name,
      course_code: CourseData?.course?.course_code,
      course_study_area: CourseData?.course?.course_study_area,
      course_study_level: CourseData?.course?.course_study_level,
      course_short_desc: CourseData?.course?.course_short_desc,
      course_summary: CourseData?.course?.course_summary,
      course_full_amount: CourseData?.course?.course_full_amount,
      course_length: CourseData?.course?.course_length,
      course_first_offered: CourseData?.course?.course_first_offered,
      course_accreditation: CourseData?.course?.course_accreditation,
    });
  }, [CourseData]);

  const [fetchedCategory, setFetchedCategory] = useState([]);

  function handleChange(event) {
    const { name, value, type, checked } = event.target;
    setCourseForm((prevCourseForm) => {
      return {
        ...prevCourseForm,
        [name]: type === "checkbox" ? checked : value,
      };
    });
  }

  const [bannerImg, setBannerImg] = useState(null);
  const handleBannerChange = (e) => {
    setBannerImg(e.target.files[0]);
  };
  const [load, setLoad] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoad(true);

    const inst_id = courseForm.inst_id;
    const cat_id = courseForm.cat_id;
    const course_full_name = courseForm.course_full_name;
    const course_code = courseForm.course_code;
    const course_study_area = courseForm.course_study_area;
    const course_study_level = courseForm.course_study_level;
    const course_short_desc = courseForm.course_short_desc;
    const course_summary = courseForm.course_summary;
    const course_full_amount = courseForm.course_full_amount;
    const course_length = courseForm.course_length;
    const course_first_offered = courseForm.course_first_offered;
    const course_accreditation = courseForm.course_accreditation;

    const coursedata = {
      inst_id,
      cat_id,
      course_full_name,
      course_code,
      course_study_area,
      course_study_level,
      course_short_desc,
      course_summary,
      course_full_amount,
      course_length,
      course_first_offered,
      course_accreditation,
    };

    try {
      const token = JSON.parse(localStorage.getItem("fastapi"));
      const response = await axios.put(
        `/api/v1/courses/${CourseData?.course?.id}`,
        coursedata,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // const cid = response?.data.id
      if (bannerImg !== null) {
        const formdata = new FormData();
        formdata.append("banner", bannerImg);

        await axios.PUT(
          `/api/v1/courses/banner/${CourseData?.course?.id}`,
          formdata,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
      }
      if (response.status === 201 || response.status === 200) {
        toast.success("Course Updated Successfully");
        window.location.reload();
      }
    } catch (error) {
      if (error.response.status === 401) {
        toast.error("Unauthorized");
      } else {
        toast.error("something isnt right");
      }
    } finally {
      setLoad(false);
    }
  };

  useEffect(() => {
    try {
      const token = JSON.parse(localStorage.getItem("fastapi"));
      axios
        .get("/api/v1/courses/categories", {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setFetchedCategory(res?.data);
        });
    } catch (error) {}
  }, []);

  return (
    <div>
      <div className="row">
        <div className="col">
          <button
            type="button"
            className="btn btn-warning shadow"
            data-bs-toggle="modal"
            data-bs-target="#updatecourse"
          >
            Update
          </button>

          <div
            className="modal fade"
            style={{ overflow: "scroll !important" }}
            id="updatecourse"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex="-1"
            aria-labelledby="updatecourseLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-lg ">
              <div className="modal-content">
                <div className="modal-header">
                  <h1
                    className="modal-title text-blue-900 fs-5"
                    id="updateschoolLabel"
                  >
                    New course details
                  </h1>
                  <button
                    type="button"
                    className="btn-close text-dark"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                  <div className="modalcontainer text-dark">
                    <form onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="col">
                          <div>
                            <label htmlFor="banner" className="form-label">
                              Upload Banner
                            </label>

                            <div className="file-card">
                              <div className="file-input">
                                <input
                                  type="file"
                                  className="form-group"
                                  onChange={handleBannerChange}
                                />
                                <button
                                  type="button"
                                  className={
                                    bannerImg === null
                                      ? "bg-info"
                                      : "bg-success"
                                  }
                                >
                                  <i className="fa fa-upload"></i>
                                  {bannerImg === null
                                    ? "choose Banner"
                                    : "Banner  Uploaded"}
                                </button>
                              </div>
                            </div>
                          </div>
                          <div className="inputBox">
                            <span>Course name :</span>
                            <input
                              type="text"
                              placeholder="Computer science"
                              onChange={handleChange}
                              required
                              value={courseForm.course_full_name}
                              name="course_full_name"
                            />
                          </div>

                          <div>
                            <span>Course Category :</span>
                            <select
                              className="form-select"
                              aria-label="Default select"
                              onChange={handleChange}
                              required
                              value={courseForm.cat_id}
                              name="cat_id"
                            >
                              {fetchedCategory?.length > 0 ? (
                                fetchedCategory?.map((category, index) => (
                                  <option value={category.id} key={index}>
                                    {category.cat_name}
                                  </option>
                                ))
                              ) : (
                                <option>No category yet</option>
                              )}
                            </select>
                          </div>

                          <div className="inputBox">
                            <span>course_accreditation :</span>
                            <input
                              type="text"
                              placeholder=""
                              onChange={handleChange}
                              required
                              value={courseForm.course_accreditation}
                              name="course_accreditation"
                            />
                          </div>
                          <div className="inputBox">
                            <span>course code :</span>
                            <input
                              type="text"
                              placeholder="COM 2301"
                              onChange={handleChange}
                              required
                              value={courseForm.course_code}
                              name="course_code"
                            />
                          </div>

                          <div className="inputBox">
                            <span>course length :</span>
                            <input
                              type="text"
                              placeholder="years, semesters"
                              onChange={handleChange}
                              required
                              value={courseForm.course_length}
                              name="course_length"
                            />
                          </div>

                          <div className="flex">
                            <div className="inputBox">
                              <span>course study area :</span>
                              <input
                                type="text"
                                placeholder="Technology"
                                onChange={handleChange}
                                required
                                value={courseForm.course_study_area}
                                name="course_study_area"
                              />
                            </div>
                            <div className="inputBox">
                              <span>course study level :</span>
                              <input
                                type="text"
                                placeholder="diploma"
                                onChange={handleChange}
                                required
                                value={courseForm.course_study_level}
                                name="course_study_level"
                              />
                            </div>
                          </div>

                          <div className="inputBox mb-3">
                            <label htmlFor="course_summary">
                              Course Summary:
                            </label>
                            <textarea
                              className="form-control"
                              rows="10"
                              name="course_summary"
                              id="course_summary"
                              placeholder="Enter detailed description"
                              onChange={handleChange}
                              required
                              value={courseForm.course_summary}
                            />
                          </div>

                          <div className="inputBox">
                            <label htmlFor="course_short_desc">
                              Short Description:
                            </label>
                            <textarea
                              className="form-control mb-4"
                              cols="50"
                              rows="4"
                              name="course_short_desc"
                              id="course_short_desc"
                              placeholder="Enter short description"
                              onChange={handleChange}
                              required
                              value={courseForm.course_short_desc}
                            />
                          </div>

                          <div className="flex">
                            <div className="inputBox">
                              <span>course first offered:</span>
                              <input
                                type="number"
                                placeholder="2023"
                                onChange={handleChange}
                                required
                                value={courseForm.course_first_offered}
                                name="course_first_offered"
                              />
                            </div>
                            <div className="inputBox">
                              <span>Cost :</span>
                              <input
                                type="number"
                                placeholder=""
                                onChange={handleChange}
                                required
                                value={courseForm.course_full_amount}
                                name="course_full_amount"
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      {load ? (
                        <button disabled className="submit-btn">
                          <i className="fa fa-spinner fa-spin"></i> Please
                          wait...
                        </button>
                      ) : (
                        <input
                          type="submit"
                          value="Update Course"
                          className="submit-btn"
                        />
                      )}
                    </form>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary text-dark"
                    data-bs-dismiss="modal"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateCourse;
