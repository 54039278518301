import { Outlet, useLocation, Navigate } from "react-router-dom";
import { useContext } from "react";
import { toast } from 'react-hot-toast';
import jwt_decode from 'jwt-decode';
import ProfileContext from '../../Context/ProfileContext';

const PrivateRoute = () => {
	const { token, setToken } = useContext(ProfileContext);
	const location = useLocation();

	if (!token) {
		toast.error("You are not logged in. Please log in.");
		return <Navigate to={"/login"} state={{ from: location }} replace={true} />;
	}

	try {
		const decoded = jwt_decode(token);
		const currentTime = Date.now() / 1000;
		if (decoded.exp < currentTime) {
			toast.error("Your session has expired. Please log in again.");
			setToken("");
			return <Navigate to={"/login"} state={{ from: location }} replace={true} />;
		}

		const { sub } = decoded;
		if (!sub) {
			toast.error("Invalid token. Please log in again.");
			setToken("");
			return <Navigate to={"/login"} state={{ from: location }} replace={true} />;
		}

		return <Outlet />;
	} catch (error) {
		toast.error("Invalid token. Please log in again.");
		setToken("");
		return <Navigate to={"/login"} state={{ from: location }} replace={true} />;
	}
};

export default PrivateRoute;
