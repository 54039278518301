import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
// import { useCartContext } from '../../../Context/CartContext'
import Logo from "../../../images/acts-logo@1x.png";
import axios from "../../../API/apiHandler";
// import ProfileContext from "../../../Context/ProfileContext";
import Publish from "./Publish";

const Card = (props) => {
  // const { token } = useContext(ProfileContext);
  // const { Logo } = useCartContext()
  const { id, name, course_code, intake, cost, duration, short_desc } = props;
  //  study_area,accreditation, category,institution,,

  const [CourseData, setCourseData] = useState({});
  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const token = JSON.parse(localStorage.getItem("fastapi"));
        const response = await axios.get(
          `/api/v1/courses/${id}/published`,

          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setCourseData(response?.data);
      } catch (error) {}
    };

    if (id) {
      fetchCourses();
    }
  }, [id]);

  const [file, setFile] = useState(null);

  useEffect(() => {
    const fetchImg = async () => {
      try {
        const token = JSON.parse(localStorage.getItem("fastapi"));
        const res = await axios.get(
          `/api/v1/courses/banner/${id}`,

          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            responseType: "arraybuffer",
          }
        );
        if (res?.data) {
          const base64 = btoa(
            new Uint8Array(res?.data).reduce(
              (data, byte) => data + String.fromCharCode(byte),
              ""
            )
          );
          setFile(`data:;base64,${base64}`);
        }
      } catch (error) {
        if (error.response?.data.detail) {
          toast.error(error.response?.data.detail);
        }
      }
    };
    fetchImg();
  }, [id]);

  const published = CourseData?.publish_details;

  return (
    <Link to={`/institution/courses/${id}`}>
      <article className="blog-post">
        <div className="blog-post_img">
          <img src={Logo} alt={name} />
        </div>
        <div className="blog-post_info">
          <div className="blog-post_info_date">
            <div className="d-flex justify-content-between">
              <div className="d-flex gap-4">
                <span>🗓Intake:{intake}</span>
                <span>🕘Duration:{duration}</span>
                <span>💰 ${cost}</span>
              </div>

              <div>
                {published ? (
                  <span className="badge bg-success fs-5">Published</span>
                ) : (
                  <span className="badge bg-danger fs-5">Not published</span>
                )}
              </div>
            </div>
          </div>
          <h2 className="blog-post_title">
            {name} [{course_code}]
          </h2>
          <p
            style={{ whiteSpace: "pre-wrap" }}
            dangerouslySetInnerHTML={{ __html: short_desc }}
          />
          {/* <p style={{ whiteSpace: 'pre-wrap' }} dangerouslySetInnerHTML={{ __html: summary }} /> */}

          <div className="d-flex gap-2 align-items-center justify-content-between">
            <div className="d-flex gap-5 align-items-center justify-content-start">
              <Publish uuid={id} />
              <Link
                to={`/institution/courses/units/${id}`}
                className="btn btn-warning "
              >
                <i className="fas fa-arrow-right"></i> Units
              </Link>
            </div>
          </div>
        </div>
      </article>
    </Link>
  );
};

export default Card;
