import { useContext, useEffect, useState } from "react";
import { Link, Navigate } from "react-router-dom";
// import { HashLink as Url } from "react-router-hash-link";
// import { InputText } from "primereact/inputtext";

import { useCartContext } from "../../Context/CartContext";
import ProfileContext from "../../Context/ProfileContext";
import axios from "../../API/apiHandler";
import avatar from "../../images/profile.png";
import Logo from "../../images/acts-logo@1x.png";

const Nav = () => {
  const { logout } = useCartContext();
  const { file, setFile, auth, token } = useContext(ProfileContext);

  const [fetchedProfile, setFetchedProfile] = useState({});

  useEffect(() => {
    

    const fetchUser = async () => {
      try {
        const res = await axios.get(
          `/api/v1/user/profile/${auth}`,
          
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setFetchedProfile(res?.data);

      } catch (err) {
        if (err.response.status === 401) Navigate("/login");
      }
    };
    fetchUser();
    
  }, [token, auth]);

  useEffect(() => {
    

    const fetchImg = async () => {
      try {
        const res = await axios.get(`/api/v1/users/avatar/${auth}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          responseType: "arraybuffer",
        });
        if (res?.data) {
          const base64 = btoa(
            new Uint8Array(res.data).reduce(
              (data, byte) => data + String.fromCharCode(byte),
              ""
            )
          );
          setFile(`data:;base64,${base64}`);
        }
        
      } catch (error) {}
    };
    fetchImg();
    
    
  }, [auth, setFile, token]);

  return (
    <div>
      <nav className="navbar navbar-expand-lg navbar-dark bg-orange-500 fixed-top">
        <div className="container-fluid ">
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#sidebar"
            aria-controls="offcanvasExample"
          >
            <span
              className="navbar-toggler-icon"
              data-bs-target="#sidebar"
            ></span>
          </button>
          <Link
            className="navbar-brand me-auto ms-lg-0 ms-3 text-uppercase fw-semibold"
            to="#"
          >
            acts admin
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#topNavBar"
            aria-controls="topNavBar"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="topNavBar">
            <div className="d-flex ms-auto align-items-center gap-3">
              <div className="d-flex ms-auto">
                {/* <span className="p-input-icon-left">
                  <i className="pi pi-search" />
                  <InputText placeholder="Keyword Search" />
                </span> */}
              </div>

              {/* <Url
                className="d-flex align-items-center justify-content-center shadow-sm w-40px h-40px position-relative link-secondary"
                data-bs-toggle="offcanvas"
                to="#offcanvasNotifications"
                role="button"
                aria-controls="offcanvasNotifications"
              >
                <svg viewBox="0 0 24 24" height="18" width="18" xmlns="http://www.w3.org/2000/svg"><path d="M10,21.75a2.087,2.087,0,0,0,4.005,0" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" /><path d="M12 3L12 0.75" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" /><path d="M12,3a7.5,7.5,0,0,1,7.5,7.5c0,7.046,1.5,8.25,1.5,8.25H3s1.5-1.916,1.5-8.25A7.5,7.5,0,0,1,12,3Z" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" className='' /></svg>
                <i className="fas fa-bell fs-4 text-white"></i>
                <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill text-bg-danger">
                  2+<span className="visually-hidden">unread messages</span>
                </span>
              </Url> */}

              <div className="d-flex justify-content-end align-items-center mx-3">
                <Link
                  className="btn btn-link text-decoration-none position-relative"
                  to="#"
                  role="button"
                  id="avatar-dropdown-toggle"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <img
                    src={file === null ? avatar : file}
                    alt="Avatar"
                    className="rounded-circle border-2 borderinfo"
                    style={{ width: "40px", height: "40px" }}
                  />
                </Link>

                <ul
                  className="dropdown-menu dropdown-menu-end"
                  aria-labelledby="avatar-dropdown-toggle"
                >
                  <li className="d-flex align-items-center">
                    <img
                      src={file || avatar}
                      alt="Avatar"
                      className="rounded-circle border ms-1 border-1 border-primary"
                      style={{ width: "35px", height: "35px" }}
                    />
                    <div className="flex-grow-1 ms-2 mb-2">
                      <h4 className="mb-0 text-info">
                        {fetchedProfile?.last_name || "Admin"}
                      </h4>
                      <p className="card-text">{fetchedProfile?.email}</p>
                    </div>
                  </li>
                  <li className="d-flex align-items-center ms-3">
                    <i className="fas fa-user-circle"></i>
                    <Link className="dropdown-item" to="/profile">
                      Profile & Account
                    </Link>
                  </li>
                  <li className="d-flex align-items-center ms-3">
                    <i className="fas fa-cog"></i>
                    <Link className="dropdown-item" to="#">
                      Settings
                    </Link>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>
                  <li className="d-flex align-items-center ms-3">
                    <i className="fas fa-sign-out-alt"></i>
                    <button onClick={logout} className="dropdown-item" to="#">
                      Logout
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </nav>

      <div
        className="offcanvas offcanvas-end"
        tabIndex="-1"
        id="offcanvasNotifications"
        aria-labelledby="offcanvasNotificationsLabel"
      >
        <div className="offcanvas-header px-5">
          <h3
            className="offcanvas-title  text-info font-bold"
            id="offcanvasNotificationsLabel"
          >
            Notifications
          </h3>

          <div className="d-flex align-items-start">
            <div className="dropdown">
              <Link
                to="#"
                className="dropdown-toggle no-arrow w-20px h-20px me-2 text-body"
                role="button"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  height="16"
                  width="16"
                >
                  <g>
                    <circle
                      cx="3.25"
                      cy="12"
                      r="3.25"
                      style={{ fill: "currentColor" }}
                    />
                    <circle
                      cx="12"
                      cy="12"
                      r="3.25"
                      style={{ fill: "currentColor" }}
                    />
                    <circle
                      cx="20.75"
                      cy="12"
                      r="3.25"
                      style={{ fill: "currentColor" }}
                    />
                  </g>
                </svg>
              </Link>
              <ul className="dropdown-menu">
                <li className="d-flex align-items-center ms-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    className="me-1 text-warning fs-6"
                    height="14"
                    width="14"
                  >
                    <g>
                      <path
                        d="M23.22,2.06a1.49,1.49,0,0,0-2,.59l-8.5,15.43L6.46,11.29a1.5,1.5,0,1,0-2.21,2l7.64,8.34a1.52,1.52,0,0,0,2.42-.29L23.81,4.1A1.5,1.5,0,0,0,23.22,2.06Z"
                        style={{ fill: "currentColor" }}
                      />
                      <path
                        d="M2.61,14.63a1.5,1.5,0,0,0-2.22,2l4.59,5a1.52,1.52,0,0,0,2.11.09,1.49,1.49,0,0,0,.1-2.12Z"
                        style={{ fill: "currentColor" }}
                      />
                      <path
                        d="M10.3,13a1.41,1.41,0,0,0,2-.54L16.89,4.1a1.5,1.5,0,1,0-2.62-1.45L9.68,11A1.41,1.41,0,0,0,10.3,13Z"
                        style={{ fill: "currentColor" }}
                      />
                    </g>
                  </svg>
                  <Link className="dropdown-item" to="#">
                    Mark all as read
                  </Link>
                </li>
                <li className="d-flex align-items-center ms-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    className="me-1 text-warning fs-6"
                    height="14"
                    width="14"
                  >
                    <g>
                      <path
                        d="M21.5,2.5H2.5a2,2,0,0,0-2,2v3a1,1,0,0,0,1,1h21a1,1,0,0,0,1-1v-3A2,2,0,0,0,21.5,2.5Z"
                        style={{ fill: "currentColor" }}
                      />
                      <path
                        d="M21.5,10H2.5a1,1,0,0,0-1,1v8.5a2,2,0,0,0,2,2h17a2,2,0,0,0,2-2V11A1,1,0,0,0,21.5,10Zm-6.25,3.5A1.25,1.25,0,0,1,14,14.75H10a1.25,1.25,0,0,1,0-2.5h4A1.25,1.25,0,0,1,15.25,13.5Z"
                        style={{ fill: "currentColor" }}
                      />
                    </g>
                  </svg>
                  <Link className="dropdown-item" to="#">
                    Archive all
                  </Link>
                </li>
                <li className="d-flex align-items-center ms-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    className="me-1 text-warning fs-6"
                    height="14"
                    width="14"
                  >
                    <g>
                      <path
                        d="M21,19.5a1,1,0,0,0,0-2A1.5,1.5,0,0,1,19.5,16V11.14a8.65,8.65,0,0,0-.4-2.62l-11,11Z"
                        style={{ fill: "currentColor" }}
                      />
                      <path
                        d="M14.24,21H9.76a.25.25,0,0,0-.24.22,2.64,2.64,0,0,0,0,.28,2.5,2.5,0,0,0,5,0,2.64,2.64,0,0,0,0-.28A.25.25,0,0,0,14.24,21Z"
                        style={{ fill: "currentColor" }}
                      />
                      <path
                        d="M1,24a1,1,0,0,0,.71-.28l22-22a1,1,0,0,0,0-1.42,1,1,0,0,0-1.42,0l-5,5A7.31,7.31,0,0,0,13,3.07V1a1,1,0,0,0-2,0V3.07a8,8,0,0,0-6.5,8.07V16A1.5,1.5,0,0,1,3,17.5a1,1,0,0,0,0,2h.09L.29,22.29a1,1,0,0,0,0,1.42A1,1,0,0,0,1,24Z"
                        style={{ fill: "currentColor" }}
                      />
                    </g>
                  </svg>
                  <Link className="dropdown-item" to="#">
                    Disable notifications
                  </Link>
                </li>
                <li>
                  <hr className="dropdown-divider" />
                </li>
                <li className="d-flex align-items-center ms-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    className="me-1 text-warning fs-6"
                    height="14"
                    width="14"
                  >
                    <g>
                      <rect
                        x="4.25"
                        y="4.5"
                        width="5.75"
                        height="7.25"
                        rx="1.25"
                        style={{ fill: "currentColor" }}
                      />
                      <path
                        d="M24,10a3,3,0,0,0-3-3H19V2.5a2,2,0,0,0-2-2H2a2,2,0,0,0-2,2V20a3.5,3.5,0,0,0,3.5,3.5h17A3.5,3.5,0,0,0,24,20ZM3.5,21.5A1.5,1.5,0,0,1,2,20V3a.5.5,0,0,1,.5-.5h14A.5.5,0,0,1,17,3V20a3.51,3.51,0,0,0,.11.87.5.5,0,0,1-.09.44.49.49,0,0,1-.39.19ZM22,20a1.5,1.5,0,0,1-3,0V9.5a.5.5,0,0,1,.5-.5H21a1,1,0,0,1,1,1Z"
                        style={{ fill: "currentColor" }}
                      />
                      <rect
                        x="12"
                        y="6.05"
                        width="3.5"
                        height="2"
                        rx="0.75"
                        style={{ fill: "currentColor" }}
                      />
                      <rect
                        x="12"
                        y="10.05"
                        width="3.5"
                        height="2"
                        rx="0.75"
                        style={{ fill: "currentColor" }}
                      />
                      <rect
                        x="4"
                        y="14.05"
                        width="11.5"
                        height="2"
                        rx="0.75"
                        style={{ fill: "currentColor" }}
                      />
                      <rect
                        x="4"
                        y="18.05"
                        width="9"
                        height="2"
                        rx="0.75"
                        style={{ fill: "currentColor" }}
                      />
                    </g>
                  </svg>
                  <Link className="dropdown-item" to="#">
                    What's new?
                  </Link>
                </li>
              </ul>
            </div>

            <button
              type="button"
              className="btn-close text-dark text-black"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            ></button>
          </div>
        </div>

        <div className="offcanvas-body p-0">
          <div className="list-group list-group-flush">
            <Link to="#" className="list-group-item list-group-item-action">
              <div className="d-flex">
                <div className="avatar avatar-circle avatar-xs me-2">
                  <img
                    src="https://d33wubrfki0l68.cloudfront.net/5dfa4398a7f2beddbcfa617402e193f2f13aaa94/2ecb0/assets/images/profiles/profile-28.jpeg"
                    alt="..."
                    className="avatar-img rounded border "
                    width="30"
                    height="30"
                  />
                </div>

                <div className="d-flex flex-column flex-grow-1">
                  <div className="d-flex w-100 justify-content-between">
                    <h5 className="mb-1 text-info">Steven</h5>
                    <small className="text-muted">10 minutes ago</small>
                  </div>

                  <div className="d-flex flex-column">
                    <p className="mb-1">
                      RE: Email pre-population from external source
                    </p>
                    <small className="text-secondary">
                      Not sure if we'll need any further instruction on how to
                      utilise the encoded ID in links from the new email
                      broadcast tool.
                    </small>
                  </div>
                </div>
              </div>
            </Link>

            <Link to="#" className="list-group-item list-group-item-action">
              <div className="d-flex">
                <div className="avatar avatar-circle avatar-xs me-2">
                  <img
                    src="https://outwittrade.com/wp-content/uploads/2021/01/digitalocean-logo.png"
                    alt="..."
                    className="avatar-img rounded border "
                    style={{ width: "70px", height: "50px" }}
                  />
                </div>

                <div className="d-flex flex-column flex-grow-1">
                  <div className="d-flex w-100 justify-content-between">
                    <h5 className="mb-1">Digital Ocean</h5>
                    <small className="text-muted">14 minutes ago</small>
                  </div>

                  <div className="d-flex flex-column">
                    <p className="mb-1">Customer invoice</p>
                    <small className="text-secondary">
                      This is a notice that an invoice has been generated on
                      05/04/2023.
                    </small>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>

      <div
        className="offcanvas offcanvas-start sidebar-nav"
        tabIndex="-1"
        id="sidebar"
      >
        <div className="offcanvas-body p-0">
          <nav
            className=" navbar justify-content-center align-items-center"
            id="wrapper"
          >
            <div
              className="bg-light border-right p-3 p-xs-0 bg-info"
              id="sidebar-wrapper"
            >
              <div className="justify-content-center mt-0">
                <img
                  src={Logo}
                  alt="profile"
                  className="img-thumbnail d-xs-none d-lg-block"
                  style={{ width: "100px", height: "100px" }}
                />
              </div>
            </div>
            <div className="gap-2 list-group list-group-flush flex-lg-column">
              <Link
                to="/admin-dash"
                className="list-group-item list-group-item-action bg-light"
              >
                <i className="fas fa-tachometer-alt"></i>Dashboard
              </Link>
              <Link
                to="/events"
                className="list-group-item list-group-item-action bg-light"
              >
                <i className="fas fa-book-open"></i> Events
              </Link>
              <Link
                to="/admin/users"
                className="list-group-item list-group-item-action bg-light"
              >
                <i className="fas fa-book"></i> Students
              </Link>
              <Link
                to="/institutions"
                className="list-group-item list-group-item-action bg-light"
              >
                <i className="fas fa-video"></i> Institutions
              </Link>
              <Link
                to="/institution/courses"
                className="list-group-item list-group-item-action bg-light"
              >
                <i className="fas fa-book"></i> Courses
              </Link>
              <Link
                to="/admin/library"
                className="list-group-item list-group-item-action bg-light"
              >
                <i className="fas fa-wallet"></i> Library
              </Link>
              <Link
                to="/scholarship"
                className="list-group-item list-group-item-action bg-light"
              >
                <i className="fas fa-cog"></i> Scholarship
              </Link>
              <Link
                to="/funding"
                className="list-group-item list-group-item-action bg-light"
              >
                <i className="fa fa-question-circle"></i> Funding
              </Link>
              <Link
                to="/coupon"
                className="list-group-item list-group-item-action bg-light"
              >
                <i className="fa fa-question-circle"></i> Coupon
              </Link>
              {/* <Link
                to="/market"
                className="list-group-item list-group-item-action bg-light"
              >
                <i className="fa fa-question-circle"></i> Market
              </Link> */}
              <Link
                to="/requests"
                className="list-group-item list-group-item-action bg-light"
              >
                <i className="fa fa-pen"></i> Applications
              </Link>

              <div className="mt-3 p-2">
                <button onClick={logout} className="btn bg-blue-900 text-white">
                  <i className="fas fa-sign-out-alt"></i> SignOut
                </button>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Nav;
