import { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import CartCard from "./CartCard";
import axios from "../../../API/apiHandler";

import ConfirmOrder from "./Pay/ConfirmOrder";
import Transactions from "./Transactions";
import { useCartContext } from "../../../Context/CartContext";
import ProfileContext from "../../../Context/ProfileContext";
import avatar from "../../../images/profile.png";

const Cart = () => {
  const navigate = useNavigate();
  const { file } = useContext(ProfileContext);

  const {
    cart: cartItems,
    total_items,
    total_amount,
    clearCart,
    shop,
    setCourseIds,
  } = useCartContext();

  const carts = shop?.cart;

  // persist course ids to local storage
  useEffect(() => {
    setCourseIds([cartItems.map((cartItem) => cartItem?.id).join(", ") || 0]);
  }, [cartItems, setCourseIds]);

  const handleCheckOut = (e) => {
    e.preventDefault();

    if (cartItems.length) {
      cartItems.map(async (cartItem) => {
        const course_id = cartItem.id;
        const intake_id = cartItem.intake_id;

        const params = { course_id, intake_id };

        try {
          const token = JSON.parse(localStorage.getItem("fastapi"));
          const res = await axios.post(
            "/api/v1/courses/applications/courses",
            params,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          );
          if (res.status === 201) {
            toast.success("Enrollment Successful");
          }
        } catch (err) {
          if (err.response?.data?.detail) {
            toast.error(err.response.data.detail);
          } else {
            toast.error("Enrollment Failed, Try Again later");
          }
        }
      });
    }
  };

  // const shopCheckOut = (e) => {
  //   e.preventDefault();

  //   if (carts.length) {
  //     carts.map(async (cartItem) => {
  //       const id = cartItem.id;
  //       const qty = cartItem.quantity;

  //       const params = { qty };

  //       try {
  //         const res = await axios.post(`/api/v1/shop/checkout/${id}`, params, {
  //           headers: {
  //             "Content-Type": "application/json",
  //           },
  //         });

  //         if (res.status === 201 || res.status === 200) {
  //           toast.success("Checkout Successful");
  //         }
  //       } catch (error) {}
  //     });
  //   }
  // };

  const [courses, setCourses] = useState([]);
  useEffect(() => {
    const fetchEnrolled = async () => {
      const token = JSON.parse(localStorage.getItem("fastapi"));
      try {
        const response = await axios.get(
          "/api/v1/studyhub/course/applications",
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response) {
          setCourses(response?.data);
        }
      } catch (error) {}
    };

    fetchEnrolled();
  }, []);

  const [coupon, setCoupon] = useState(null);
  const [discount, setDiscount] = useState(0);

  // const validateCoupon = async (e) => {
  //   e.preventDefault();
  //   if (coupon === null) {
  //     return toast.error("Please Enter a Coupon Code");
  //   }

  //   const token = JSON.parse(localStorage.getItem("fastapi"));
  //   try {
  //     const response = await axios.get(
  //       `/api/v1/copouns/coupons/${coupon}/validate`,
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     );
  //     if (response) {
  //       toast.success(response?.data?.detail);
  //       setDiscount(response?.data?.discount);
  //     }
  //   } catch (error) {
  //     if (error.response?.data.detail) {
  //       toast.error(error.response?.data.detail);
  //     } else {
  //       toast.error("Coupon Validation Failed");
  //     }
  //   }
  // };

  return (
    <div>
      <div className="container">
        <div>
          <section
            className="min-h-100 h-custom"
            style={{ backgroundColor: "#eee" }}
          >
            <div className="container py-4 h-100">
              <div className="row d-flex justify-content-center align-items-center min-h-100">
                <div className="col">
                  <div className="card">
                    <div className="card-body p-3">
                      <div className="row">
                        <div className="col-lg-7">
                          <div className="d-flex justify-content-between mb-3">
                            <div>
                              <h5 className="mb-3">
                                <button
                                  onClick={() => navigate("/courses")}
                                  className="text-body btn"
                                >
                                  <i className="fas fa-long-arrow-alt-left me-2"></i>
                                  Continue Searching
                                </button>
                              </h5>
                            </div>

                            <div>
                              <Transactions />
                            </div>
                          </div>
                          <hr />

                          <div className="d-flex justify-content-between align-items-center mb-4">
                            <div>
                              <p className="mb-1">My Cart</p>
                              <p className="mb-0">
                                You have {total_items} items in your cart
                              </p>
                            </div>
                            <div>
                              <p className="mb-0">
                                {/* <span className="text-muted">Sort by:</span>{" "}
                                <Link to="" className="text-body">
                                  price{" "}
                                  <i className="fas fa-angle-down mt-1"></i>
                                </Link> */}
                              </p>
                            </div>
                            {cartItems?.length > 0 && (
                              <div>
                                <button
                                  className="btn btn-outline-danger"
                                  type="button"
                                  onClick={() => clearCart()}
                                >
                                  Clear Cart
                                </button>
                              </div>
                            )}
                          </div>

                          <div>
                            {cartItems &&
                              cartItems.map((cartItem) => {
                                return (
                                  <CartCard
                                    key={cartItem.id}
                                    cartItem={cartItem}
                                  />
                                );
                              })}

                            {carts &&
                              carts.map((carts) => {
                                return (
                                  <CartCard key={carts.id} carts={carts} />
                                );
                              })}
                          </div>
                        </div>
                        <div className="col-lg-5">
                          <div
                            className="card text-white rounded-3"
                            style={{ backgroundColor: "#094886" }}
                          >
                            <div className="card-body">
                              <div className="d-flex justify-content-between align-items-center mb-4">
                                <h5 className="mb-0">Card details</h5>
                                <img
                                  src={file ? file : avatar}
                                  className="img-fluid rounded-3"
                                  style={{ width: "45px" }}
                                  alt="Avatar"
                                />
                              </div>

                              <p className="small mb-2">Card Type</p>
                              <Link to="" className="text-white">
                                <i className="fab fa-cc-mastercard fa-2x me-2"></i>
                              </Link>
                              <Link to="" className="text-white">
                                <i className="fab fa-cc-visa fa-2x me-2"></i>
                              </Link>
                              <Link to="" className="text-white">
                                <i className="fab fa-cc-amex fa-2x me-2"></i>
                              </Link>
                              <Link to="" className="text-white">
                                <i className="fab fa-cc-paypal fa-2x"></i>
                              </Link>
                              <form className="mt-4">
                                {/* handle coupon */}
                                {total_amount > 0 ? (
                                  <div className="mb-3 d-flex gap-1">
                                    <div className="">
                                      <label className="form-label">
                                        Coupon Code
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Coupon Code"
                                        onChange={(e) =>
                                          setCoupon(e.target.value)
                                        }
                                        required
                                      />
                                    </div>
                                    {/* <button
                                      className="btn h-8 btn-outline-light p-2"
                                      onClick={validateCoupon}
                                    >
                                      Validate
                                    </button> */}
                                  </div>
                                ) : null}

                                <hr className="my-2 text-warning" />
                                <hr className="text-light" />
                                <hr className="my-2 text-warning" />

                                {total_amount > 0 ? (
                                  <>
                                    <div className="d-flex justify-content-between mb-4 fs-5">
                                      <p className="mb-2">Subtotal:</p>
                                      <p className="mb-2">
                                        ${(total_amount - discount).toFixed(2)}
                                      </p>
                                    </div>

                                    <ConfirmOrder
                                      cost={total_amount - discount}
                                      cartItems={cartItems}
                                      courses={courses}
                                      carts={carts}
                                      handleCheckOut={handleCheckOut}
                                      coupon={coupon}
                                      // shopCheckOut={shopCheckOut}
                                    />
                                  </>
                                ) : (
                                  <h5>You have no items in your cart</h5>
                                )}
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default Cart;
