
import * as moment from "moment";
import { toast } from "react-hot-toast";
import axios from "../API/apiHandler";

export const showEvent = (event) => {
  return {
    type: "SHOW_EVENT",
    payload: event,
  };
};

export const showEvents = (events) => {
  return {
    type: "SHOW_EVENTS",
    payload: events,
  };
};

export const ShowEventApi = (id) => async (dispatch) => {
  const result = await axios.get(`/api/v1/Events/events/${id}`, {
    headers: {
      "Content-Type": "application/json",
    },
  });

  try {
    const {
      title,
      id,
      start_date,
      end_date,
      start_time,
      end_time,
      description,
      location,
      url,
      facilitators,
    } = await result.data;
    const convertedEvent = {
      title,
      description,
      id: id,
      start_date: moment(start_date)
        .set("hour", start_time.split(":")[0])
        .set("minute", start_time.split(":")[1])
        .format("ddd DD MMM YY LT"),
      end_date: moment(end_date)
        .set("hour", end_time.split(":")[0])
        .set("minute", end_time.split(":")[1])
        .format("ddd DD MMM YY LT"),
      location,
      url,
      facilitators,
    };
    await dispatch(showEvent(convertedEvent));
  } catch (err) {
    const error = await err.response.data.message.details;
    return error;
  }
};

export const ShowEventsApi = () => async (dispatch) => {
  const result = await axios.get("/api/v1/Events");

  try {
    const convertedDates = await result.data.map((event) => {
      const startDateWithTime = `${event.start_date}T${event.start_time}`;
      const endDateWithTime = `${event.end_date}T${event.end_time}`;

      return {
        title: event.title,
        start_date: new Date(startDateWithTime),
        end_date: new Date(endDateWithTime),
        start_time: event.start_time,
        end_time: event.end_time,
        id: event.id,
        description: event.description,
        location: event.location,
        url: event.url,
        facilitators: event.facilitators,
      };
    });
    await dispatch(showEvents(convertedDates));
  } catch (err) {
    const error = await err.response.data.message.details;
    return error;
  }
};


export const deleteEvent = (id) => {
  return {
    type: "DELETE_EVENT",
    payload: id,
  };
};

const token = JSON.parse(localStorage.getItem("fastapi"));

export const deleteEventApi = (id) => async (dispatch) => {
  const result = await axios.delete(`/api/v1/Events/${id}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  try {
    const deleted = await result.data.message;
    await dispatch(deleteEvent(id));
    window.location.reload();
    return { deleted };
  } catch (err) {
    const message = await result.data.message.detail;
    toast.error(message);
    return { message };
  }
};

const addEvent = (newEvent) => {
  return {
    type: "ADD_EVENT",
    payload: newEvent,
  };
};

export const addEventApi = (values) => async (dispatch) => {
  await axios
    .post("/", {
      title: values.title,
      start: values.start,
      end: values.end,
      description: values.description,
      location: values.location,
      url: values.url,
      facilitators: values.facilitators,
    })
    .then((res) => {
      if (res && res.data) {
        dispatch(addEvent(res.data));

        return "success";
      }
    })
    .catch((res) => {
    });
};

// const updateEvent = (updatedEvent) => {
//   return {
//     type: "UPDATE_EVENT",
//     payload: updatedEvent,
//   };
// };

const now = new Date();
const isoString = now.toISOString();

export const updateEventApi = (values, id) => async (dispatch) => {
  try {
    const result = await axios.put(`/api/v1/Events/events/${id}`, {
      title: values.title,
      start_date: values.start_date,
      end_date: values.end_date,
      start_time: values.start_time,
      end_time: values.end_time,
      description: values.description,
      location: values.location,
      url: values.url,
      reminders: values.reminders,
      time_zone: values.time_zone,
      facilitators: values.facilitators,
      created_at: isoString,
    });
    // const response = result.data;
    return "response was successful";
  } catch (err) {
  }

  //    .then(res=>{
  //     if(res && res.data){

  //
  //         return;
  //     }
  //    })
};
