import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { useState, useEffect, useContext } from "react";
import axios from "../../../API/apiHandler";
// import { toast } from "react-hot-toast";
import ProfileContext from "../../../Context/ProfileContext";

export default function TransactionChart() {
  const { token, auth } = useContext(ProfileContext);

  const [payments, setPayments] = useState([]);

  useEffect(() => {
    const fetchPayments = async () => {
      try {
        const response = await axios.get("/api/v1/pespal/all-payments", {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        if (response) {
          const pay = response?.data;
          const groupedPayments = {};

          pay.forEach((payment) => {
            const month = new Date(payment.created_date).toLocaleString(
              "default",
              { month: "short" }
            );

            if (groupedPayments[month]) {
              groupedPayments[month] += payment.amount;
            } else {
              groupedPayments[month] = payment.amount;
            }
          });

          const chartData = Object.entries(groupedPayments).map(
            ([name, Income]) => ({ name, Income })
          );

          setPayments(chartData);
        }
      } catch (error) {}
    };

    fetchPayments();
  }, [token, auth]);

  return (
    <>
      <strong className="text-blue-900 fs-5">Transactions(USD)</strong>
      <ResponsiveContainer width="100%" aspect={3 / 2}>
        <BarChart
          width={500}
          height={300}
          data={payments}
          margin={{
            top: 20,
            right: 10,
            left: -10,
            bottom: 0,
          }}
          barSize={20}
        >
          <CartesianGrid strokeDasharray="3 3 0 0" vertical={false} />
          <XAxis dataKey="name" tick={{ fill: "black" }} />
          <YAxis tick={{ fill: "#000" }} />
          <Tooltip />
          <Legend />
          <Bar dataKey="Income" fill="orange" stackId="stack" />
        </BarChart>
      </ResponsiveContainer>
    </>
  );
}
