import Nav from '../../../components/admin/Nav'
import Units from '../../../components/admin/Units/Units'

const UnitsScreen = () => {
	return (
		<div>
			<div className="container-fluid">
				<div className="row">
					<div className="col-12 col-md-2">
						<Nav />
					</div>
					<div className="col-12 col-md-10 mt-5">
						<Units />
					</div>
				</div>
			</div>
		</div>
	)
}

export default UnitsScreen
