import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { TabView, TabPanel } from "primereact/tabview";
//import defaultBanner from '../../../images/acts-logo@1x.png'

import axios from "../../../API/apiHandler";
import Card from "./Card";

import CourseCategory from "./CourseCategory";
import CourseIntake from "./CourseIntake";
import Criteria from "./Criteria";

const CoursesAdmin = () => {
  const [CourseData, setCourseData] = useState([]);

  useEffect(() => {
    

    const fetchPublished = async () => {
      try {
        const token = JSON.parse(localStorage.getItem("fastapi"));
        const response = await axios.get(
          "/api/v1/courses/published",
          
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            withCredentials: true,
          }
        );
        // const courses = response?.data
        // courses.sort((a, b) => b.id - a.id)
        setCourseData(response?.data);

      } catch (error) {}
    };
    fetchPublished();

    
    
  }, []);

  const [banner, setBanner] = useState(null);
  const handleBannerChange = (e) => {
    setBanner(e.target.files[0]);
  };

  const [fetchedCategory, setFetchedCategory] = useState([]);

  const [fetchedCourses, setFetchedCourses] = useState({});
  const [courseForm, setCourseForm] = useState({
    inst_id: 1,
    cat_id: 0,
    course_full_name: "",
    course_code: "",
    course_study_area: "",
    course_study_level: "",
    course_short_desc: "",
    course_summary: "",
    course_full_amount: 0,
    course_length: "",
    course_first_offered: 0,
    course_accreditation: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setCourseForm((prevCourseForm) => {
      return {
        ...prevCourseForm,
        [name]: value,
      };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const inst_id = courseForm.inst_id;
    const cat_id = courseForm.cat_id;
    const course_full_name = courseForm.course_full_name;
    const course_code = courseForm.course_code;
    const course_study_area = courseForm.course_study_area;
    const course_study_level = courseForm.course_study_level;
    const course_short_desc = courseForm.course_short_desc;
    const course_summary = courseForm.course_summary;
    const course_full_amount = courseForm.course_full_amount;
    const course_length = courseForm.course_length;
    const course_first_offered = courseForm.course_first_offered;
    const course_accreditation = courseForm.course_accreditation;

    const courseData = {
      inst_id,
      cat_id,
      course_full_name,
      course_code,
      course_study_area,
      course_study_level,
      course_short_desc,
      course_summary,
      course_full_amount,
      course_length,
      course_first_offered,
      course_accreditation,
    };

    try {
      const token = JSON.parse(localStorage.getItem("fastapi"));
      const response = await axios.post("/api/v1/courses", courseData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      toast.success("Course Added Successfully");

      const cid = response?.data?.id;
      const formdata = new FormData();
      formdata.append("banner", banner);

      const res = await axios.put(`/api/v1/courses/banner/${cid}`, formdata, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      if (res.status === 201 || res.status === 200) {
        toast.success("BANNER added successfully...!");
      }

      window.location.reload();
    } catch (error) {
      if (error.response?.data.detail) {
        toast.error(error.response?.data.detail);
      } else {
        toast.error("something went wrong...!");
      }
    }
  };

  useEffect(() => {
    
    const fetchCourses = async () => {
      try {
        const token = JSON.parse(localStorage.getItem("fastapi"));
        const response = await axios.get(
          "/api/v1/courses",
          
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            withCredentials: true,
          }
        );
        setFetchedCourses(response?.data);

      } catch (error) {}
    };

    fetchCourses();

    
    
  }, []);

  useEffect(() => {
    

    try {
      const token = JSON.parse(localStorage.getItem("fastapi"));
      axios
        .get(
          "/api/v1/courses/categories",
          
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setFetchedCategory(res?.data);
        });

    } catch (error) {}

    
    
  }, []);

  return (
    <div>
      <div className="row mt-5" style={{ overflow: "scroll" }}>
        <div className="col mb-2">
          <h5>{} Courses</h5>
        </div>
        <div className="row mb-3">
          <div className="col">
            <div className="d-flex justify-content-between">
              <div className="d-grid">
                <button
                  type="button"
                  className="btn btn-warning btn-block shadow"
                  style={{
                    boxShadow: "inset 2px 2px 6px #222",
                    border: "2px solid #011",
                  }}
                  data-bs-toggle="modal"
                  data-bs-target="#addcourse"
                >
                  <span className="fs-5">
                    <i className="fas fa-plus-circle"></i> Add Course (2)
                  </span>
                </button>
              </div>

              <CourseCategory />

              <CourseIntake />

              <Criteria />
            </div>

            <div
              className="modal fade"
              style={{ overflow: "scroll !important" }}
              id="addcourse"
              data-bs-backdrop="static"
              data-bs-keyboard="false"
              tabIndex="-1"
              aria-labelledby="addcourseLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-lg ">
                <div className="modal-content">
                  <div className="modal-header">
                    <h1
                      className="modal-title text-blue-900 fs-5"
                      id="addschoolLabel"
                    >
                      New course details
                    </h1>
                    <button
                      type="button"
                      className="btn-close text-dark"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="modal-body">
                    <div className="modalcontainer text-dark">
                      <form onSubmit={handleSubmit}>
                        <div className="row">
                          <div className="col">
                            <div>
                              <label htmlFor="banner" className="form-label">
                                Upload Banner
                              </label>

                              <div className="file-card">
                                <div className="file-input">
                                  <input
                                    type="file"
                                    className="form-group"
                                    onChange={handleBannerChange}
                                  />
                                  <button
                                    type="button"
                                    className={
                                      banner === null ? "bg-info" : "bg-success"
                                    }
                                  >
                                    <i className="fa fa-upload"></i>
                                    {banner === null
                                      ? "choose Banner"
                                      : "Banner  Uploaded"}
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div className="inputBox">
                              <span>Course name :</span>
                              <input
                                type="text"
                                placeholder="Computer science"
                                onChange={handleChange}
                                required
                                value={courseForm.course_full_name}
                                name="course_full_name"
                              />
                            </div>

                            <div>
                              <span>Course Category :</span>
                              <select
                                className="form-select"
                                aria-label="Default select"
                                onChange={handleChange}
                                required
                                value={courseForm.cat_id}
                                name="cat_id"
                              >
                                {fetchedCategory?.length > 0 ? (
                                  fetchedCategory?.map((category, index) => (
                                    <option value={category.id} key={index}>
                                      {category.cat_name}
                                    </option>
                                  ))
                                ) : (
                                  <option>No category yet</option>
                                )}
                              </select>
                            </div>

                            <div className="inputBox">
                              <span>course_accreditation :</span>
                              <input
                                type="text"
                                placeholder=""
                                onChange={handleChange}
                                required
                                value={courseForm.course_accreditation}
                                name="course_accreditation"
                              />
                            </div>
                            <div className="inputBox">
                              <span>course code :</span>
                              <input
                                type="text"
                                placeholder="COM 2301"
                                onChange={handleChange}
                                required
                                value={courseForm.course_code}
                                name="course_code"
                              />
                            </div>

                            <div className="inputBox">
                              <span>course length :</span>
                              <input
                                type="text"
                                placeholder="years, semesters"
                                onChange={handleChange}
                                required
                                value={courseForm.course_length}
                                name="course_length"
                              />
                            </div>

                            <div className="flex">
                              <div className="inputBox">
                                <span>course study area :</span>
                                <input
                                  type="text"
                                  placeholder="Technology"
                                  onChange={handleChange}
                                  required
                                  value={courseForm.course_study_area}
                                  name="course_study_area"
                                />
                              </div>
                              <div className="inputBox">
                                <span>course study level :</span>
                                <input
                                  type="text"
                                  placeholder="diploma"
                                  onChange={handleChange}
                                  required
                                  value={courseForm.course_study_level}
                                  name="course_study_level"
                                />
                              </div>
                            </div>

                            <div className="inputBox mb-3">
                              <label htmlFor="course_summary">
                                Course Summary:
                              </label>
                              <textarea
                                className="form-control"
                                cols="50"
                                rows="10"
                                name="course_summary"
                                id="course_summary"
                                placeholder="Enter detailed description"
                                onChange={handleChange}
                                required
                                value={courseForm.course_summary}
                              />
                            </div>

                            <div className="inputBox">
                              <label htmlFor="course_short_desc">
                                Short Description:
                              </label>
                              <textarea
                                className="form-control mb-4"
                                cols="50"
                                rows="4"
                                name="course_short_desc"
                                id="course_short_desc"
                                placeholder="Enter short description"
                                onChange={handleChange}
                                required
                                value={courseForm.course_short_desc}
                              />
                            </div>

                            <div className="flex">
                              <div className="inputBox">
                                <span>course first offered:</span>
                                <input
                                  type="number"
                                  placeholder="2023"
                                  onChange={handleChange}
                                  required
                                  value={courseForm.course_first_offered}
                                  name="course_first_offered"
                                />
                              </div>
                              <div className="inputBox">
                                <span>Cost :</span>
                                <input
                                  type="number"
                                  placeholder=""
                                  onChange={handleChange}
                                  required
                                  value={courseForm.course_full_amount}
                                  name="course_full_amount"
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <input
                          type="submit"
                          value="Confirm"
                          className="submit-btn"
                        />
                      </form>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary text-dark"
                      data-bs-dismiss="modal"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* published */}
        <TabView className="p-3">
          <TabPanel header="Published Courses" leftIcon="pi pi-check-circle">
            <div className="row mb-3">
              <div className="col">
                <h4
                  className="text-info mb-3 fs-5"
                  style={{ color: "#094886" }}
                >
                  Published Courses({CourseData.length})
                </h4>
                {CourseData.length > 0 ? (
                  CourseData.map((fetchedCourse, index) => (
                    <div className="" key={index}>
                      <Card
                        id={fetchedCourse.id}
                        name={fetchedCourse.course_full_name}
                        course_code={fetchedCourse.course_code}
                        summary={fetchedCourse.course_summary}
                        short_desc={fetchedCourse.course_short_desc}
                        intake={fetchedCourse.course_first_offered}
                        cost={fetchedCourse.course_full_amount}
                        study_area={fetchedCourse.study_area}
                        duration={fetchedCourse.course_length}
                        accreditation={fetchedCourse.course_accreditation}
                        category={fetchedCourse.category.cat_name}
                        institution={fetchedCourse.institution.inst_name}
                      />
                    </div>
                  ))
                ) : (
                  <div className="text-info mt-5 text-center">
                    <span>No Published Course yet...</span>
                  </div>
                )}
              </div>
            </div>
          </TabPanel>
          <TabPanel header="All Courses" leftIcon="pi pi-times-circle">
            <div className="row ">
              <div className="col">
                <h4
                  className="text-dark mb-3 fs-5"
                  style={{ color: "#094886" }}
                >
                  All Courses({fetchedCourses.length})
                </h4>

                {fetchedCourses.length > 0 ? (
                  fetchedCourses.map((fetchedCourse, index) => (
                    <div className="" key={index}>
                      <Card
                        id={fetchedCourse.id}
                        name={fetchedCourse.course_full_name}
                        course_code={fetchedCourse.course_code}
                        summary={fetchedCourse.course_summary}
                        short_desc={fetchedCourse.course_short_desc}
                        intake={fetchedCourse.course_first_offered}
                        cost={fetchedCourse.course_full_amount}
                        study_area={fetchedCourse.study_area}
                        duration={fetchedCourse.course_length}
                        accreditation={fetchedCourse.course_accreditation}
                        category={fetchedCourse.category.cat_name}
                        institution={fetchedCourse.institution.inst_name}
                      />
                    </div>
                  ))
                ) : (
                  <div className="text-danger mt-5 text-center">
                    <span>No Courses yet....</span>
                  </div>
                )}
              </div>
            </div>
          </TabPanel>
        </TabView>
      </div>
    </div>
  );
};

export default CoursesAdmin;
