import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { Link } from "react-router-dom";

import axios from "../../../API/apiHandler";
import Card from "./Card";
import FundDoc from "./FundDoc";

const Funding = () => {
  const [fundForm, setFundForm] = useState({
    inst_id: 1,
    funding_title: "",
    funding_amount: 0,
    funding_area: "",
    funding_type: "",
    funding_location: "",
    funding_duration: 0,
    funding_eligible_entities: "",
    funding_deadline_date: "",
    funding_short_desc: "",
    funding_full_desc: "",
    published: true,
  });

  const [fetchedFundings, setFetchedFundings] = useState([]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFundForm((prevFundForm) => {
      return {
        ...prevFundForm,
        [name]: value,
      };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const inst_id = fundForm.inst_id;
    const funding_title = fundForm.funding_title;
    const funding_amount = fundForm.funding_amount;
    const funding_area = fundForm.funding_area;
    const funding_type = fundForm.funding_type;
    const funding_location = fundForm.funding_location;
    const funding_duration = fundForm.funding_duration;
    const funding_eligible_entities = fundForm.funding_eligible_entities;
    const funding_deadline_date = fundForm.funding_deadline_date;
    const funding_short_desc = fundForm.funding_short_desc;
    const funding_full_desc = fundForm.funding_full_desc;
    const published = fundForm.published;

    const fundData = {
      inst_id,
      funding_title,
      funding_amount,
      funding_area,
      funding_type,
      funding_location,
      funding_duration,
      funding_eligible_entities,
      funding_deadline_date,
      funding_short_desc,
      funding_full_desc,
      published,
    };

    try {
      const token = JSON.parse(localStorage.getItem("fastapi"));
      const response = await axios.post("/api/v1/funding", fundData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      if (response.status === 201 || response.status === 200) {
        toast.success("Funding added successfully");
        window.location.reload();
      }
    } catch (error) {
      toast.error("failed to submit funding");
    }
  };

  useEffect(() => {
    

    const fetchFundings = async () => {
      try {
        const token = JSON.parse(localStorage.getItem("fastapi"));
        const response = await axios.get(
          "/api/v1/funding",
          
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        response && setFetchedFundings(response.data);
      } catch (error) {}
    };
    fetchFundings();

    
  }, []);

  return (
    <div className="container mt-5">
      <div className="row mt-4">
        <div className="col mb-2">
          <h5>Fundings</h5>
        </div>

        <div className="row mb-2">
          <div className="col">
            <div>
              <div className="d-flex gap-3 justify-content-evenly">
                <div className="d-grid">
                  <button
                    type="button"
                    className="btn btn-warning btn-block shadow"
                    style={{
                      boxShadow: "inset 2px 2px 6px #222",
                      border: "2px solid #011",
                    }}
                    data-bs-toggle="modal"
                    data-bs-target="#addfunding"
                  >
                    <span className="fs-5">
                      <i className="fas fa-plus-circle"></i>
                      Add new Fundings
                    </span>
                  </button>
                </div>

                <div>
                  <FundDoc />
                </div>
              </div>
              <div className="d-grid mt-3">
                <Link
                  to="/fund/applications"
                  type="button"
                  className="btn btn-warning btn-block shadow"
                  style={{
                    boxShadow: "inset 2px 2px 6px #222",
                    border: "2px solid #011",
                  }}
                >
                  <span className="fs-6">
                    <i className="fas fa-arrow-right"></i>
                    All Applications
                  </span>
                </Link>
              </div>

              <div
                className="modal fade"
                style={{ overflow: "scroll !important" }}
                id="addfunding"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex="-1"
                aria-labelledby="addfundingLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-lg ">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h1
                        className="modal-title text-blue-900 fs-5"
                        id="addfundingLabel"
                      >
                        New funding details
                      </h1>
                      <button
                        type="button"
                        className="btn-close text-dark"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-body">
                      <div className="modalcontainer text-dark">
                        <form onSubmit={handleSubmit}>
                          <div className="row">
                            <div className="col">
                              <div className="inputBox">
                                <span>Funding Title :</span>
                                <input
                                  type="text"
                                  placeholder=""
                                  onChange={handleChange}
                                  name="funding_title"
                                  required
                                  value={fundForm.funding_title}
                                />
                              </div>
                              <div className="inputBox">
                                <span>Funding Area :</span>
                                <input
                                  type="text"
                                  placeholder=""
                                  onChange={handleChange}
                                  required
                                  value={fundForm.funding_area}
                                  name="funding_area"
                                />
                              </div>
                              <div className="inputBox">
                                <span>Amount :</span>
                                <input
                                  type="text"
                                  placeholder=""
                                  onChange={handleChange}
                                  required
                                  value={fundForm.funding_amount}
                                  name="funding_amount"
                                />
                              </div>
                              <div className="flex">
                                <div className="inputBox">
                                  <span>Funding Type :</span>
                                  <input
                                    type="text"
                                    placeholder="Nairobi"
                                    onChange={handleChange}
                                    required
                                    value={fundForm.funding_type}
                                    name="funding_type"
                                  />
                                </div>

                                <div className="inputBox">
                                  <span>Location :</span>
                                  <input
                                    type="text"
                                    placeholder=""
                                    onChange={handleChange}
                                    required
                                    value={fundForm.funding_location}
                                    name="funding_location"
                                  />
                                </div>
                              </div>

                              <div className="flex">
                                <div className="inputBox">
                                  <span>Duration :</span>
                                  <input
                                    type="number"
                                    placeholder=""
                                    onChange={handleChange}
                                    required
                                    value={fundForm.funding_duration}
                                    name="funding_duration"
                                  />
                                </div>
                                <div className="inputBox mb-3">
                                  <span>Deadline :</span>
                                  <input
                                    type="date"
                                    className="form-control"
                                    name="funding_deadline_date"
                                    placeholder=""
                                    onChange={handleChange}
                                    required
                                    value={fundForm.funding_deadline_date}
                                  />
                                </div>
                              </div>
                              <div className="inputBox">
                                <span>Eligible entities :</span>
                                <input
                                  type="text"
                                  placeholder=""
                                  onChange={handleChange}
                                  required
                                  value={fundForm.funding_eligible_entities}
                                  name="funding_eligible_entities"
                                />
                              </div>

                              <div className="inputBox">
                                <span>Short Description :</span>
                                <textarea
                                  className="form-control mb-4"
                                  row="4"
                                  placeholder="Enter short description"
                                  onChange={handleChange}
                                  required
                                  value={fundForm.funding_short_desc}
                                  name="funding_short_desc"
                                />
                              </div>

                              <div className="inputBox">
                                <span>Full Description :</span>
                                <textarea
                                  className="form-control mb-4"
                                  row="4"
                                  placeholder="Enter detailedf description"
                                  onChange={handleChange}
                                  required
                                  value={fundForm.funding_full_desc}
                                  name="funding_full_desc"
                                />
                              </div>
                            </div>
                          </div>

                          <input
                            type="submit"
                            value="ADD"
                            className="submit-btn"
                          />
                        </form>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-secondary text-dark"
                        data-bs-dismiss="modal"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col">
            {fetchedFundings.length > 0 ? (
              fetchedFundings.map((fetchedfunding, index) => (
                <div className="" key={index}>
                  <Card
                    id={fetchedfunding.id}
                    title={fetchedfunding.funding_title}
                    amount={fetchedfunding.funding_amount}
                    duration={fetchedfunding.funding_duration}
                    eligibles={fetchedfunding.funding_eligible_entities}
                    deadline={fetchedfunding.funding_deadline_date}
                    short_desc={fetchedfunding.funding_short_desc}
                    full_desc={fetchedfunding.funding_full_desc}
                    location={fetchedfunding.funding_location}
                    type={fetchedfunding.funding_type}
                    area={fetchedfunding.funding_area}
                    institution={fetchedfunding.institution.inst_name}
                  />
                </div>
              ))
            ) : (
              <div className="text-danger mt-5 text-center">
                <span>No fundings yet....</span>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Funding;
