import { ProgressSpinner } from 'primereact/progressspinner';

function LoadingOverlay() {

	return (
		<div className="flex flex-col items-center justify-center h-screen">
			<h1 className="text-6xl font-bold mb-8">Loading...</h1>
			<ProgressSpinner style={{ width: '50px', height: '50px' }} strokeWidth="8" fill="var(--surface-ground)" animationDuration=".4s" />
		</div>
	);
}

export default LoadingOverlay;
