import React from 'react';
import loader from "../../images/loader.svg";
import "./Loader.scss";

const Loader = () => {
  return (
    <div className='container-fluid'>
      <div className='loader flex justify-center align-center'>
        <img src = {loader} alt = "..." />
      </div>
    </div>
  )
}

export default Loader
