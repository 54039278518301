import React, { useState, useContext } from "react";
import { Button } from "primereact/button";
import axios from "../../../../API/apiHandler";
import { toast } from "react-toastify";
import ProfileContext from "../../../../Context/ProfileContext";

export default function ConfirmOrder({ cartItems, courses, coupon }) {
  const [isLoading, setIsLoading] = useState(false);

  const { auth, token, setOrderId } = useContext(ProfileContext);

  // const [fetchedProfile, setFetchedProfile] = useState({});

  // useEffect(() => {

  //   const fetchUser = async () => {
  //     try {
  //       const res = await axios.get(`/api/v1/user/profile/${auth}`, {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Bearer ${token}`,
  //         },
  //       });
  //       setFetchedProfile(res?.data);
  //     } catch (err) {}
  //   };
  //   fetchUser();
  // }, [token, auth]);

  const [success, setSuccess] = useState(false);

  const handleCheckOutAndInitialOrder = async (e) => {
    e.preventDefault();
    setIsLoading(true);
  
    // Check if any course in the cart is already enrolled
    const hasAlreadyEnrolled = cartItems.some((cartItem) =>
      courses.some((course) => course.id === cartItem.id)
    );
  
    if (hasAlreadyEnrolled) {
      toast.error(
        "You've already enrolled in one or more of the courses in your cart. Please check your StudyHub to confirm."
      );
      setIsLoading(false);
      return;
    }
  
    const order = {
      course_ids: cartItems.map((cartItem) => cartItem?.id) || [],
      // address: country ? country + " - " + city : `User ${auth} - Not Set`,
      // description: cartItems
      //   ? cartItems?.map((cartItem) => cartItem?.name).join(", ")
      //   : "course application checkout",
      coupon_code: coupon || "",
      user_id: auth,
    };
  
    if (cartItems.length) {
      try {
        await Promise.all(
          cartItems.map(async (cartItem) => {
            const course_id = cartItem.id;
            const intake_id = cartItem.intake_id;
            const params = { course_id, intake_id };
  
            const token = JSON.parse(localStorage.getItem("fastapi"));
            await axios.post(
              "/api/v1/courses/applications/courses",
              params,
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${token}`,
                },
              }
            );
  
          })
        );
      } catch (err) {
        if (err.response?.data?.detail) {
          toast.error(err.response.data.detail);
        } else {
          toast.error("Enrollment Failed. Please try again later.");
        }
      }
    }
  
    try {
      const res = await axios.post("/api/v1/pespal/submit-order/", order, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
  
      if (res.status === 200 || res.status === 201) {
        setOrderId(res?.data?.order_tracking_id);
        window.location.href = res?.data?.redirect_url;
        toast.info(res.data.message);
        setIsLoading(false);
        setSuccess(true);
      }
    } catch (error) {
      setIsLoading(false);
      toast.error(error.response.data.message);
    }
  };
  
  return (
    <div>
      {isLoading ? (
        <Button
          label="Loading..."
          icon="pi pi-spin pi-spinner"
          className="p-button-raised p-3 p-button-info animate-pulse w-full"
          disabled={isLoading}
        />
      ) : success ? (
        <p className="text-center fw-600 text-green-600">
          Payment Initiated. Redirecting to Pesapal...
        </p>
      ) : (
        <Button
          label="Confirm Order"
          className="p-button-raised p-3 w-full"
          onClick={handleCheckOutAndInitialOrder}
          disabled={isLoading || success}
        />
      )}
    </div>
  );
}
