import { useState, useEffect } from "react";
// import ProfileContext from '../../../Context/ProfileContext';

import axios from "../../../API/apiHandler";
import Card from "./Card";

const Funding = () => {
  const [fetchedFundings, setFetchedFundings] = useState({});

  // const {
  // 	levelofstudy,
  // 	setLevelofstudy,
  // 	genareaoffunding,
  // 	setGenAreaoffunding,
  // 	location,
  // 	setLocation,
  // 	eligibility,
  // 	setEligibility,
  // } = useContext(ProfileContext);

  useEffect(() => {
    

    const fetchFundings = async () => {
      try {
        const token = JSON.parse(localStorage.getItem("fastapi"));
        const response = await axios.get(
          "/api/v1/funding",
          
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        response && setFetchedFundings(response.data);
      } catch (error) {}
    };
    fetchFundings();
    
  }, []);

  const [search, setSearch] = useState("");

  return (
    <div>
      <div>
        <div className="container mt-5" style={{ overflow: "scroll" }}>
          <div className="d-flex flex-column">
            <div className="row mb-2">
              <div className="col-12">
                <h3>Apply Now</h3>
              </div>
            </div>

            {/* <div className="row">
							<div className="col">
								<div className="d-flex gap-2 justify-content-evenly">
									<div>
										<label htmlFor="levelofstudy" className="col-form-label">Select level of study</label>
										<select className="form-select mb-3" aria-label="levelofstudy" value={levelofstudy}
											onChange={(e) => {
												setLevelofstudy(e.target.value);
											}}>
											<option defaultValue>select from dropdown</option>
											<option value="diploma">diploma</option>
											<option value="undergraduate">Undergraduate</option>
											<option value="masters">Masters</option>
											<option value="PHD">pHD</option>
										</select>
									</div>

									<div>
										<label htmlFor="areaoffunding" className="col-form-label">General area of Funding</label>
										<select className="form-select mb-3" aria-label="areaofstudy" value={genareaoffunding}
											onChange={(e) => { setGenAreaoffunding(e.target.value); }}>
											<option defaultValue>select from dropdown</option>
											<option value="computer science">Computer Science</option>
											<option value="engineering">Engineering</option>
											<option value="business">Business</option>
											<option value="law">Law</option>
										</select>
									</div>

									<div>
										<label htmlFor="location" className="col-form-label">Select Your Location</label>
										<select className="form-select mb-3" aria-label="location" value={location} onChange={(e) => { setLocation(e.target.value); }}>
											<option defaultValue>select from dropdown</option>
											<option value="Nairobi">Nairobi</option>
											<option value="Mombasa">Mombasa</option>
											<option value="Kisumu">Kisumu</option>
											<option value="Nakuru">Nakuru</option>
										</select>
									</div>

									<div>
										<label htmlFor="eligibility" className="col-form-label">Eligibility </label>
										<select className="form-select mb-3" aria-label="eligibility" value={eligibility} onChange={(e) => { setEligibility(e.target.value); }}>
											<option defaultValue>select from dropdown</option>
											<option value="visa">Access to VISA</option>
											<option value="18+">Over 18 years</option>
											<option value="financial">Financially stable</option>
											<option value="english">English Proficiency</option>
										</select>
									</div>
								</div>
							</div>
						</div> */}

            <div className="row">
              <div className="col mt-4">
                {/* full width search box filter */}
                <div className="input-group mb-3">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search for fundings"
                    aria-label="Search for fundings"
                    aria-describedby="button-addon2"
                    onChange={(e) => {
                      setSearch(e.target.value);
                    }}
                  />
                  <button
                    className="btn btn-outline-secondary"
                    type="button"
                    id="button-addon2"
                  >
                    Search
                  </button>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12 mt-4">
                {fetchedFundings.length > 0 ? (
                  fetchedFundings
                    .filter((fetchedfunding) => {
                      return search.toLowerCase() === ""
                        ? fetchedfunding
                        : fetchedfunding.funding_title
                            .toLowerCase()
                            .includes(search.toLowerCase());
                    })
                    .map((fetchedfunding, index) => (
                      <div className="" key={index}>
                        <Card
                          id={fetchedfunding.id}
                          title={fetchedfunding.funding_title}
                          amount={fetchedfunding.funding_amount}
                          duration={fetchedfunding.funding_duration}
                          eligibles={fetchedfunding.funding_eligible_entities}
                          deadline={fetchedfunding.funding_deadline_date}
                          short_desc={fetchedfunding.funding_short_desc}
                          full_desc={fetchedfunding.funding_full_desc}
                          location={fetchedfunding.funding_location}
                          type={fetchedfunding.funding_type}
                          area={fetchedfunding.funding_area}
                          institution={fetchedfunding.institution.inst_name}
                        />
                      </div>
                    ))
                ) : (
                  <div className="text-danger mt-5 text-center">
                    <span>No fundings yet....</span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Funding;
