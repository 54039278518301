import { Link } from "react-router-dom";
import { toast } from "react-hot-toast";
import { useState, useEffect } from "react";
import axios from "../../../API/apiHandler";
import Update from "./Update";
import logo from "../../../images/acts-logo@1x.png";

const Card = (props) => {
  const {
    id,
    name,
    short_name,
    short_desc,
    reg_date,
    industry,
    city,
    address,
    country,
    employees_no,
    state,
    inst,
  } = props;

  const [banner, setBanner] = useState(null);

  useEffect(() => {
    

    const fetchImg = async () => {
      const token = JSON.parse(localStorage.getItem("fastapi"));
      try {
        const res = await axios.get(
          `/api/v1/institutions/banner/${1}`,
          
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            responseType: "arraybuffer",
          }
        );
        if (res) {
          const base64 = btoa(
            new Uint8Array(res.data).reduce(
              (data, byte) => data + String.fromCharCode(byte),
              ""
            )
          );
          setBanner(`data:;base64,${base64}`);
        }
      } catch (error) {
        if (error.response?.data.detail) {
          toast.error(error.response?.data.detail);
        }
      }
    };

    fetchImg();
    
  }, []);

  // detailed_desc,

  return (
    <div>
      <div className="card w-100 mb-3">
        <div className="row g-0">
          <div className="col-md-4">
            <img
              src={banner || logo}
              className="img-fluid rounded-start"
              alt={name}
            />
          </div>
          <div className="col-md-8">
            <div className="card-body">
              <h5 className="card-title fs-5" style={{ color: "#094886" }}>
                {name}({short_name})
              </h5>
              <p className="card-text">{short_desc}</p>
              <div className="row">
                <div className="col-md-6">
                  <ul className="list-group list-group-flush">
                    <li className="list-group-item">EST: {reg_date}</li>
                    <li className="list-group-item">City: {city}</li>
                    <li className="list-group-item">Country: {country}</li>
                    <li className="list-group-item">Address: {address}</li>
                  </ul>
                </div>
                <div className="col-md-6">
                  <ul className="list-group list-group-flush">
                    <li className="list-group-item">Industry: {industry}</li>
                    <li className="list-group-item">State: {state}</li>
                    <li className="list-group-item">
                      # Employees: {employees_no}
                    </li>
                  </ul>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-md-6">
                  <Update id={id} school={inst} />
                </div>
                <div className="col-md-6">
                  <Link
                    to="/institution/courses"
                    className="btn btn-primary bg-blue-600 w-100 flex gap-1 items-center justify-center py-2 text-dark rounded"
                    style={{ borderBottom: "1px solid blue" }}
                  >
                    <i className="fas fa-book me-2"></i> Courses Offered
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
