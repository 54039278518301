import { toast } from "react-hot-toast";
import { useEffect, useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "../../API/apiHandler";
import Logo from "../../images/acts-logo@1x.png";
import { useCartContext } from "../../Context/CartContext";
import ProfileContext from "../../Context/ProfileContext";

const Card = (props) => {
  const navigate = useNavigate();
  const {
    id,
    name,
    short_desc,
    intake,
    cost,
    duration,
    category,
    institution,
  } = props;
  const { token } = useContext(ProfileContext);
  const [, setFile] = useState(null);

  const [CourseData, setCourseData] = useState({});
  useEffect(() => {
    

    const fetchCourses = async () => {
      try {
        const token = JSON.parse(localStorage.getItem("fastapi"));
        const response = await axios.get(
          `/api/v1/courses/${id}/published`,
          
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setCourseData(response?.data);
      } catch (error) {}
    };

    fetchCourses();
    
  }, [id]);

  useEffect(() => {
    

    const fetchImg = async () => {
      try {
        const res = await axios.get(
          `/api/v1/courses/banner/${id}`,
          
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            responseType: "arraybuffer",
          }
        );
        if (res?.data) {
          const base64 = btoa(
            new Uint8Array(res.data).reduce(
              (data, byte) => data + String.fromCharCode(byte),
              ""
            )
          );
          setFile(`data:;base64,${base64}`);
        }
      } catch (error) {}
    };
    fetchImg();
    
  }, [token, id]);

  const lastIntake = CourseData?.intakes?.length
    ? CourseData.intakes[CourseData.intakes.length - 1]
    : null;

  const intake_id = lastIntake ? lastIntake.id : null;
  const starting = lastIntake ? lastIntake?.intake_date_from : null;
  const ending = lastIntake ? lastIntake?.intake_date_to : null;

  const currentDate = new Date();
  const startDate = new Date(starting);
  const endDate = new Date(ending);

  const { addToCart } = useCartContext();

  const enrol = async () => {
    if (!token) {
      toast.error("Please login to continue");
      return navigate("/login");
    }
    await addToCart(
      id,
      name,
      intake,
      cost,
      duration,
      category,
      institution,
      intake_id
    );
    // toast.success(`${name} added to cart`);
  };

  return (
    <>
      <article className="blog-post border border-1 border-warning shadow mb-2">
        <div className="blog-post_img">
          <img src={Logo} alt={name} />
        </div>
        <div className="blog-post_info">
          <div className="blog-post_info_date">
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex gap-4">
                <span>🗓Intake:{intake}</span>
                <span>🕘Duration:{duration}</span>
              </div>
              <span className="fs-3 text-warning"> ${cost}</span>
            </div>

            <div>
              {startDate > currentDate ? (
                <span className="badge bg-success fs-6">Upcoming</span>
              ) : endDate < currentDate ? (
                <span className="badge bg-danger fs-6">Closed</span>
              ) : (
                <span className="badge bg-primary fs-6">Ongoing</span>
              )}
            </div>
          </div>
          <h2 className="blog-post_title">{name}</h2>
          <p
            className="text-black"
            style={{ whiteSpace: "pre-wrap" }}
            dangerouslySetInnerHTML={{ __html: short_desc }}
          />
          <div className="d-flex gap-5 align-items-center justify-content-start">
            <Link to={`/more/${id}`} className="blog-post_cta">
              See details
            </Link>
            <button
              className="btn btn-outline-success p-4 fs-6 text-bold enrol_cta"
              onClick={enrol}
            >
              <i className="fas fa-shopping-cart fw-700" /> Enrol Now
            </button>
          </div>
        </div>
      </article>
    </>
  );
};

export default Card;
