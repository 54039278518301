import React, { useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";

import { toast } from "react-hot-toast";
import { LazyLoadImage } from "react-lazy-load-image-component";

import axios from "../../API/apiHandler";

import Logo from "../../images/acts-logo@1x.png";
import loginImage from "../../images/login.webp";

import ProfileContext from "../../Context/ProfileContext";

const Login = () => {
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);
  const [email, setEmail] = useState(null);
  const { setToken, setAuth } = useContext(ProfileContext);

  const [loginData, setLoginData] = useState({
    username: "",
    password: "",
  });

  const { username, password } = loginData;
  const [showPwd, setShowPwd] = useState(false);

  function handleChange(event) {
    const { name, value } = event.target;
    setLoginData((prevLoginData) => {
      return {
        ...prevLoginData,
        [name]: value,
      };
    });
  }

  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);

    const user = loginData.username;
    const pass = loginData.password;

    const logins = {
      username: user,
      password: pass,
    };

    if (!logins) toast.error("please fill all fields...!");

    try {
      const res = await axios.post("/api/v1/auth/login", logins, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        withCredentials: true,
      });
      const token = res?.data?.access_token;
      setToken(token);

      const profile_completed = res?.data?.user.profile_completed;
      const roles = res?.data?.user.user_type;
      const id = res?.data?.user.id;

      setAuth(id);

      if (roles === "student" && res?.status === 200) {
        if (profile_completed === true) {
          navigate("/dashboard");
          toast.success("Logged in");
        } else {
          navigate("/verify");
        }
      } else if (roles === "admin" && res?.status === 200) {
        navigate("/admin-dash");
      } else {
        return navigate("/login");
      }

      setLoginData({
        username: "",
        password: "",
      });
    } catch (err) {
      if (err.response.data.detail) {
        toast.error(err.response.data.detail);
      } else if (err.response.status === 401) {
        toast.error("Invalid username or password..!");
      } else if (err.code === "ECONNREFUSED") {
        toast.error("Server is not available. Please try again later.");
      } else {
        toast.error("An unexpected error occurred. Please try again later.");
      }
    } finally {
      setLoading(false);
    }
  };

  const [successMessage, setSuccessMessage] = useState(null);

  const handlePswReset = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const res = await axios.post(
        `/api/v1/emailing-services/send-password-reset?email=${email}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (res?.status === 200) {
        setSuccessMessage("reset link has been sent to your email");
        toast.success("reset link has been sent to your email");
      }
    } catch (err) {
      if (err.response.data.detail) {
        toast.error(err.response.data.detail);
      } else if (err.response.status === 401) {
        toast.error("Invalid email..!");
      } else if (err.code === "ECONNREFUSED") {
        toast.error("Server is not available. Please try again later.");
      } else {
        toast.error("An unexpected error occurred. Please try again later.");
      }
    } finally {
      setLoading(false);
      // setVisible(false);
    }
  };

  return (
    <section className="vh-100" style={{ backgroundColor: "#094886" }}>
      <div className="container h-100">
        <div className="row d-flex justify-content-center align-items-center h-100">
          <div className="col col-xl-10">
            <div className="card">
              <div className="row g-0">
                <div className="col-md-6 col-lg-5 d-none d-md-block">
                  <LazyLoadImage
                    src={loginImage}
                    alt="login form"
                    effect="blur"
                    className="img-fluid"
                  />
                </div>
                <div className="col-md-6 col-lg-7 d-flex align-items-center">
                  <div className="card-body text-black">
                    <form onSubmit={handleSubmit}>
                      <div className="d-flex align-items-center mb-3">
                        <img
                          className="fa-2x "
                          src={Logo}
                          alt="logo"
                          style={{ width: "90px" }}
                        />
                        <span className="h4 fw-bold mb-0 ">
                          Acts Pathways Academy
                        </span>
                      </div>

                      <h5
                        className="fw-normal mb-3"
                        style={{ letteSpacing: "1px" }}
                      >
                        Signin to your account
                      </h5>

                      <div className="form-outline mb-4">
                        <input
                          type="email"
                          id="email"
                          className="form-control form-control-lg"
                          onChange={handleChange}
                          name="username"
                          autoComplete="off"
                          placeholder="enter your email"
                          value={username}
                          required
                        />
                        <label className="form-label" htmlFor="email">
                          Username
                        </label>
                      </div>

                      <div
                        className="form-outline mb-4"
                        style={{ position: "relative" }}
                      >
                        <input
                          type={showPwd ? "text" : "password"}
                          id="password"
                          className="form-control form-control-lg"
                          onChange={handleChange}
                          autoComplete="off"
                          placeholder="enter your passcode"
                          name="password"
                          value={password}
                          required
                        />
                        <button
                          style={{
                            background: "none",
                            border: "none",
                            fontSize: "1.3em",
                            cursor: "pointer",
                            position: "absolute",
                            right: 10,
                            top: "30%",
                            transform: "translateY(-50%)",
                          }}
                          type="button"
                          onClick={() => setShowPwd(!showPwd)}
                        >
                          {showPwd ? (
                            <i
                              className="fa fa-eye-slash"
                              style={{ color: "#333" }}
                            />
                          ) : (
                            <i
                              className="fa fa-eye"
                              style={{ color: "#999" }}
                            />
                          )}
                        </button>
                        <label className="form-label" htmlFor="password">
                          Password
                        </label>
                      </div>

                      <div className="pt-1 mb-4">
                        {loading ? (
                          <button className="btn btn-outline-warning btn-lg text-warning btn-block w-100">
                            <div className="d-flex justify-content-center">
                              <div
                                className="spinner-border text-blue-900"
                                role="status"
                              >
                                <span className="visually-hidden">
                                  Loading...
                                </span>
                              </div>
                            </div>
                          </button>
                        ) : (
                          <button
                            className="btn btn-primary btn-lg text-warning btn-block w-100"
                            type="submit"
                            disabled={!password || !username}
                          >
                            Login
                          </button>
                        )}
                      </div>

                      <button
                        type="button"
                        onClick={() => setVisible(true)}
                        className=" mb-2 small text-muted text-primary"
                      >
                        Forgot Password?
                      </button>

                      <p className="mb-3" style={{ color: "#000" }}>
                        Don't have an account?
                        <Link to="/signup" style={{ color: "#393f81" }}>
                          Register here
                        </Link>
                      </p>
                      <Link to="#!" className="small text-muted">
                        Terms of use-
                      </Link>

                      <Link to="#!" className="small text-muted">
                        Privacy policy
                      </Link>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="card flex justify-content-center">
        <Dialog
          header="Reset Password"
          visible={visible}
          style={{ width: "30vw" }}
          onHide={() => setVisible(false)}
        >
          <form onSubmit={handlePswReset}>
            <div className="d-flex justify-content-start flex-column m-2">
              <label htmlFor="email" className="p-d-block">
                Email Address:
              </label>
              <input
                type="email"
                placeholder="Enter your registered email"
                id="email"
                className="p-inputtext p-component"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                name="email"
              />
            </div>

            <div className="d-flex justify-content-center align-items-center mt-3">
              {loading ? (
                <Button
                  label="Sending..."
                  className=" p-button-warning p-mr-2"
                  disabled
                />
              ) : (
                <Button
                  label="Get Reset Link"
                  className="p-button-success p-mr-2"
                  type="submit"
                />
              )}
            </div>
            <p className="m-2 text-center">{successMessage}</p>
          </form>
        </Dialog>
      </div>
    </section>
  );
};

export default Login;
