import React from 'react'
import { Link } from 'react-router-dom'

//import Logo from '../images/acts-logo@1x.png'
// import style from './style.module.css'

const Err404 = () => {


	return (
		<>
			<div className="container">
				<div className="row">
					<div className="col-sm-12 col-md-12 mt-5 mb-5">
						<div className="d-flex justify-content-center flex-column gap-2">
						<h1 className="text-center display-5 text-danger fw-700">404</h1>
						<h2 className="text-center text-black">Page Not Found</h2>
						{/* home btn */}
						<Link to="/" className="btn btn-outline-primary btn-lg btn-block mt-5">
							Go to Home
						</Link>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default Err404
