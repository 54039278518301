import { useState, useEffect } from "react";
import { toast } from "react-hot-toast";
import { Link } from "react-router-dom";

import axios from "../../../API/apiHandler";
import Card from "./Card";
import ScholarshipDoc from "./ScholarshipDoc";

const Scholarship = () => {
  const [scholarshipForm, setScholarshipForm] = useState({
    inst_id: 1,
    sch_title: "",
    sch_amount: 0,
    sch_eligibility: "",
    sch_area_of_study: "",
    sch_level_of_study: "",
    sch_type_of_funding: "",
    sch_short_desc: "",
    sch_full_desc: "",
    sch_deadline_date: "",
    published: true,
  });

  const [fetchedScholarships, setFetchedScholarships] = useState([]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setScholarshipForm((prevScholarshipForm) => {
      return {
        ...prevScholarshipForm,
        [name]: value,
      };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const inst_id = scholarshipForm.inst_id;
    const sch_title = scholarshipForm.sch_title;
    const sch_amount = scholarshipForm.sch_amount;
    const sch_eligibility = scholarshipForm.sch_eligibility;
    const sch_area_of_study = scholarshipForm.sch_area_of_study;
    const sch_level_of_study = scholarshipForm.sch_level_of_study;
    const sch_type_of_funding = scholarshipForm.sch_type_of_funding;
    const sch_short_desc = scholarshipForm.sch_short_desc;
    const sch_full_desc = scholarshipForm.sch_full_desc;
    const sch_deadline_date = scholarshipForm.sch_deadline_date;
    const published = scholarshipForm.published;

    const scholarshipData = {
      inst_id,
      sch_title,
      sch_amount,
      sch_eligibility,
      sch_area_of_study,
      sch_level_of_study,
      sch_type_of_funding,
      sch_short_desc,
      sch_full_desc,
      sch_deadline_date,
      published,
    };

    try {
      const token = JSON.parse(localStorage.getItem("fastapi"));
      const response = await axios.post(
        "/api/v1/scholarships",
        scholarshipData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status === 201 || response.status === 200) {
        toast.success("Scholarship added successfully");
        window.location.reload();
      }
    } catch (error) {
      toast.error("Couldn't submit scholarship");
    }
  };

  const fetchScholarships = async () => {
    try {
      const token = JSON.parse(localStorage.getItem("fastapi"));
      const response = await axios.get("/api/v1/scholarships", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      setFetchedScholarships(response?.data);
    } catch (error) {}
  };

  useEffect(() => {
    fetchScholarships();
  }, []);

  return (
    <div>
      <div className="container-lg mt-5" style={{ overflow: "scroll" }}>
        <div className="row">
          <div className="col mb-2">
            <h5>Scholarships</h5>
          </div>

          <div className="row mb-2">
            <div className="col">
              <div>
                <div className="d-flex justify-content-between">
                  <div className="d-grid">
                    <button
                      type="button"
                      className="btn btn-warning btn-block shadow"
                      style={{
                        boxShadow: "inset 2px 2px 6px #222",
                        border: "2px solid #011",
                      }}
                      data-bs-toggle="modal"
                      data-bs-target="#addscholarship"
                    >
                      <span className="fs-5">
                        <i className="fas fa-plus-circle"></i>
                        Add new scholarships
                      </span>
                    </button>
                  </div>

                  <div>
                    <ScholarshipDoc />
                  </div>
                </div>
                <div className="d-grid mt-3">
                  <Link
                    to="/sch/applications"
                    type="button"
                    className="btn btn-warning btn-block shadow"
                    style={{
                      boxShadow: "inset 2px 2px 6px #222",
                      border: "2px solid #011",
                    }}
                  >
                    <span className="fs-6">
                      <i className="fas fa-arrow-right"></i>
                      All Applications
                    </span>
                  </Link>
                </div>

                <div
                  className="modal fade"
                  style={{ overflow: "scroll !important" }}
                  id="addscholarship"
                  data-bs-backdrop="static"
                  data-bs-keyboard="false"
                  tabIndex="-1"
                  aria-labelledby="addscholarshipLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog modal-lg ">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h1
                          className="modal-title text-blue-900 fs-5"
                          id="addscholarshipLabel"
                        >
                          New scholarship details
                        </h1>
                        <button
                          type="button"
                          className="btn-close text-dark"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                      </div>
                      <div className="modal-body">
                        <div className="modalcontainer text-dark">
                          <form onSubmit={handleSubmit}>
                            <div className="row">
                              <div className="col">
                                <div className="inputBox">
                                  <span>scholarship Title :</span>
                                  <input
                                    type="text"
                                    placeholder=""
                                    onChange={handleChange}
                                    name="sch_title"
                                    required
                                    value={scholarshipForm.sch_title}
                                  />
                                </div>
                                <div className="inputBox">
                                  <span>Level of Study :</span>
                                  <input
                                    type="text"
                                    placeholder=""
                                    onChange={handleChange}
                                    required
                                    value={scholarshipForm.sch_level_of_study}
                                    name="sch_level_of_study"
                                  />
                                </div>
                                <div className="inputBox">
                                  <span>Amount :</span>
                                  <input
                                    type="number"
                                    placeholder=""
                                    onChange={handleChange}
                                    required
                                    value={scholarshipForm.scholarship_amount}
                                    name="sch_amount"
                                  />
                                </div>
                                <div className="flex">
                                  <div className="inputBox">
                                    <span>Type Of Funding :</span>
                                    <input
                                      type="text"
                                      placeholder=""
                                      onChange={handleChange}
                                      required
                                      value={
                                        scholarshipForm.sch_type_of_funding
                                      }
                                      name="sch_type_of_funding"
                                    />
                                  </div>
                                </div>

                                <div className="flex">
                                  <div className="inputBox">
                                    <span>Areea Of Study :</span>
                                    <input
                                      type="text"
                                      placeholder=""
                                      onChange={handleChange}
                                      required
                                      value={scholarshipForm.sch_area_of_study}
                                      name="sch_area_of_study"
                                    />
                                  </div>

                                  <div className="inputBox mb-3">
                                    <span>Deadline :</span>
                                    <input
                                      type="date"
                                      className="form-control"
                                      name="sch_deadline_date"
                                      placeholder=""
                                      onChange={handleChange}
                                      required
                                      value={scholarshipForm.sch_deadline_date}
                                    />
                                  </div>
                                </div>
                                <div className="inputBox">
                                  <span>Eligibility :</span>
                                  <input
                                    type="text"
                                    placeholder=""
                                    onChange={handleChange}
                                    required
                                    value={scholarshipForm.sch_eligibility}
                                    name="sch_eligibility"
                                  />
                                </div>

                                <div className="inputBox">
                                  <span>Short Description :</span>
                                  <textarea
                                    className="form-control mb-4"
                                    row="4"
                                    placeholder="Enter short description"
                                    onChange={handleChange}
                                    required
                                    value={scholarshipForm.sch_short_desc}
                                    name="sch_short_desc"
                                  />
                                </div>

                                <div className="inputBox">
                                  <span>Full Description :</span>
                                  <textarea
                                    className="form-control mb-4"
                                    row="4"
                                    placeholder="Enter detailedf description"
                                    onChange={handleChange}
                                    required
                                    value={scholarshipForm.sch_full_desc}
                                    name="sch_full_desc"
                                  />
                                </div>
                              </div>
                            </div>

                            <input
                              type="submit"
                              value="Confirm"
                              className="submit-btn"
                            />
                          </form>
                        </div>
                      </div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          className="btn btn-secondary  text-dark"
                          data-bs-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              {fetchedScholarships.length > 0 ? (
                fetchedScholarships.map((fetchedScholarship, index) => (
                  <div className="" key={index}>
                    <Card
                      id={fetchedScholarship.id}
                      title={fetchedScholarship.sch_title}
                      amount={fetchedScholarship.sch_amount}
                      eligibles={fetchedScholarship.sch_eligibility}
                      deadline={fetchedScholarship.sch_deadline_date}
                      short_desc={fetchedScholarship.sch_short_desc}
                      full_desc={fetchedScholarship.sch_full_desc}
                      type={fetchedScholarship.sch_type_of_funding}
                      area={fetchedScholarship.sch_area_of_study}
                      institution={fetchedScholarship.institution.inst_name}
                      level={fetchedScholarship.sch_level_of_study}
                      sch={fetchedScholarship}
                    />
                  </div>
                ))
              ) : (
                <div className="text-danger mt-5 text-center">
                  <span>No scholarships yet....</span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Scholarship;
