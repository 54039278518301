import { useState, useEffect, useContext } from "react";
import ProfileContext from "../../../Context/ProfileContext";
import axios from "../../../API/apiHandler";
// import { toast } from "react-hot-toast";

// import del from "../../../images/trash-logo.svg";
// import logo from "../../../images/acts-logo@1x.png";

import MsgCard from "./MsgCard";

const Community = () => {
  const { token, auth } = useContext(ProfileContext);
  const [sender, setSender] = useState("");

  useEffect(() => {
    

    const fetchUser = async () => {
      try {
        const res = await axios.get(
          `/api/v1/user/profile/${auth}`,
          
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setSender(res?.data?.first_name);
      } catch (err) {}
    };
    fetchUser();
    
  }, [token, auth]);

  // const [isConnected, setIsConnected] = useState(socket.connected);
  const now = new Date();
  const isoString = now.toISOString();

  const [messages, setMessages] = useState([]);
  const [msg, setMsg] = useState({
    content: "",
    date_created: isoString,
    sender: sender,
  });

  const { content } = msg;
  function handleChange(event) {
    const { name, value } = event.target;
    setMsg((prevMsg) => {
      return {
        ...prevMsg,
        [name]: value,
      };
    });
  }

  // useEffect(() => {
  //   socket.on("connect", () => {
  //     setIsConnected(socket.connected);
  //   });
  //   socket.on("disconnect", () => {
  //     setIsConnected(socket.connected);
  //   });

  //   socket.on("get_messages", (data) => {
  //     setMessages((prevMessages) => [
  //       ...prevMessages,
  //       { ...data, type: "get_messages" },
  //     ]);
  //   });
  // }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      content: msg.content,
      date_created: msg.date_created,
    };

    try {
      const token = JSON.parse(localStorage.getItem("fastapi"));
      await axios.post("/api/v1/chat", data, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      setMessages((prevMessages) => [
        ...prevMessages,
        { ...msg, type: "sent" },
      ]);
      setMsg({
        content: "",
        date_created: isoString,
        sender: sender,
      });
    } catch (error) {}
  };

  useEffect(() => {
    

    const token = JSON.parse(localStorage.getItem("fastapi"));
    const fetchMessages = async () => {
      try {
        const response = await axios.get(
          "/api/v1/chat",
          
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        setMessages(response?.data);
      } catch (error) {}
    };
    fetchMessages();
    
  }, []);

  // const today = new Date();
  // const time = today.getHours() + ":" + today.getMinutes();
  // const date =
  //   today.getDate() + "/" + (today.getMonth() + 1) + "/" + today.getFullYear();

  const [search, setSearch] = useState("");

  return (
    <>
      <div className="container h-100 mt-4">
        <div
          className="h-100 d-flex flex-column bg-light"
          style={{ maxHeight: "90vh", minHeight: "90vh" }}
        >
          <div className="d-flex  p-4 border-bottom bg-white top-0">
            <input
              type="text"
              placeholder="Search for a text message"
              className="form-control"
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
              }}
            />
          </div>
          <div
            className="flex-grow-1 overflow-auto p-4"
            style={{ overflow: "scroll" }}
          >
            {messages
              .filter((message) => {
                return search.toLowerCase() === ""
                  ? message
                  : message?.content
                      .toLowerCase()
                      .includes(search.toLowerCase());
              })
              .map((message, index) => (
                <MsgCard key={index} message={message} msg={msg} />
              ))}
          </div>
          <div className="p-4 bg-white mt- bottom-0">
            <form onSubmit={handleSubmit} className="d-flex align-items-center">
              <input
                type="text"
                name="content"
                placeholder="Type a message"
                className="flex-grow-1 form-control me-2"
                value={content}
                onChange={handleChange}
              />
              <button type="submit" className="btn btn-outline-primary">
                <i className="fa fa-paper-plane text-success" />
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Community;
