import { useState, useEffect } from "react";
import { toast } from "react-hot-toast";
import { useParams } from "react-router-dom";
// import styled from "styled-components";

import axios from "../../../API/apiHandler";
import Update from "./Update";

const Topics = () => {
  const { id } = useParams();
  const [libraryResources, setLibraryResources] = useState({});
  const [fetchedTopics, setFetchedTopics] = useState({});
  const [topicForm, setTopicForm] = useState({
    unit_id: id,
    topic_full_name: "",
    topic_short_code: "",
    topic_summary: "",
    topic_short_desc: "",
    topic_total_points: 1,
    mandatory: false,
    parent_id: 0,
    topic_core_text: 0,
    topic_core_audio: 0,
    topic_core_video: 0,
  });

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    setTopicForm((prevTopicForm) => {
      return {
        ...prevTopicForm,
        [name]: type === "checkbox" ? checked : value,
      };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const unit_id = topicForm.unit_id;
    const topic_full_name = topicForm.topic_full_name;
    const topic_short_code = topicForm.topic_short_code;
    const topic_summary = topicForm.topic_summary;
    const topic_short_desc = topicForm.topic_short_desc;
    const topic_total_points = topicForm.topic_total_points;
    const mandatory = topicForm.mandatory;
    const parent_id = topicForm.parent_id;
    const topic_core_text = topicForm.topic_core_text;
    const topic_core_audio = topicForm.topic_core_audio;
    const topic_core_video = topicForm.topic_core_video;

    const topicData = {
      unit_id,
      topic_full_name,
      topic_short_code,
      topic_summary,
      topic_short_desc,
      topic_total_points,
      mandatory,
      parent_id,
      topic_core_text,
      topic_core_audio,
      topic_core_video,
    };

    try {
      const token = JSON.parse(localStorage.getItem("fastapi"));
      const response = await axios.post("/api/v1/units/topics", topicData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 200 || response.status === 201) {
        toast.success("Topic added ");
        window.location.reload();
      }
    } catch (err) {
      if (err.response.data.detail) {
        toast.error(err.response.data.detail);
      } else {
        toast.error("Failed, Try Again later");
      }
    }
  };

  useEffect(() => {
    const fetchTopics = async () => {
      const token = JSON.parse(localStorage.getItem("fastapi"));
      try {
        const response = await axios.get(`/api/v1/units/${id}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        setFetchedTopics(response?.data);
      } catch (err) {
        if (err.response.data.detail) {
          toast.error(err.response.data.detail);
        } else {
        }
      }
    };
    fetchTopics();
  }, [id]);

  useEffect(() => {
    const fetchLibraryResources = async () => {
      const token = JSON.parse(localStorage.getItem("fastapi"));
      try {
        const response = await axios.get(`/api/v1/library/institution/${1}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        if (response?.status === 200 || response?.status === 201) {
          setLibraryResources(response?.data);
        }
      } catch (err) {
        if (err.response.data.detail) {
          toast.error(err.response.data.detail);
        } else {
        }
      }
    };

    fetchLibraryResources();
  }, []);

  const handleDelete = async (id) => {
    const token = JSON.parse(localStorage.getItem("fastapi"));
    try {
      const response = await axios.delete(`/api/v1/units/topics/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 200 || response.status === 201) {
        toast.success("Topic deleted successfully");
        window.location.reload();
      }
    } catch (err) {
      if (err.response.data.detail) {
        toast.error(err.response.data.detail);
      } else {
        toast.error("Failed, Try Again later");
      }
    }
  };

  return (
    <div>
      <div className="container mt-5">
        <div className="row">
          <div className="col-12">
            <h5 className="fs-2" style={{ color: "#094886" }}>
              Topics{">>"} {fetchedTopics?.unit_full_name}
            </h5>
          </div>

          <div className="row">
            <div className="col-12">
              <div className=" mt-2">
                <div className="d-grid">
                  <button
                    type="button"
                    className="btn btn-warning btn-block shadow"
                    style={{
                      boxShadow: "inset 2px 2px 6px #222",
                      border: "2px solid #011",
                    }}
                    data-bs-toggle="modal"
                    data-bs-target="#addtopic"
                  >
                    <span className="fs-6">Add new Topic</span>
                  </button>
                </div>

                <h4 className="mb-3 mt-4 fs-6" style={{ color: "#094886" }}>
                  {fetchedTopics?.topics?.length} topics
                </h4>

                <div
                  className="modal fade"
                  style={{ overflow: "scroll !important" }}
                  id="addtopic"
                  data-bs-backdrop="static"
                  data-bs-keyboard="false"
                  tabIndex="-1"
                  aria-labelledby="addtopicLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog modal-lg ">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h1
                          className="modal-title text-success fs-5"
                          id="addschoolLabel"
                        >
                          New topic details
                        </h1>
                        <button
                          type="button"
                          className="btn-close text-dark"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                      </div>
                      <div className="modal-body">
                        <div className="modalcontainer text-dark">
                          <form onSubmit={handleSubmit}>
                            <div className="row">
                              <div className="col">
                                <div>
                                  <label
                                    className="form-label mt-3"
                                    htmlFor="topic_core_video"
                                  >
                                    Core Video
                                  </label>
                                  <select
                                    className=" form-select"
                                    aria-label="Default select"
                                    onChange={handleChange}
                                    required
                                    value={topicForm.topic_core_video}
                                    name="topic_core_video"
                                  >
                                    <option>Select video</option>
                                    {libraryResources?.length > 0 ? (
                                      libraryResources?.map(
                                        (resource, index) =>
                                          resource.resource_type ===
                                            "video/mp4" && (
                                            <option
                                              value={resource.id}
                                              key={index}
                                            >
                                              {resource.resource_title}{" "}
                                            </option>
                                          )
                                      )
                                    ) : (
                                      <option>No video found</option>
                                    )}
                                  </select>
                                </div>

                                <div>
                                  <label
                                    className="form-label mt-3"
                                    htmlFor="topic_core_audio"
                                  >
                                    Core Audio
                                  </label>
                                  <select
                                    className=" form-select"
                                    aria-label="Default select"
                                    onChange={handleChange}
                                    required
                                    value={topicForm.topic_core_audio}
                                    name="topic_core_audio"
                                  >
                                    <option>Select Audio</option>
                                    {libraryResources?.length > 0 ? (
                                      libraryResources.map(
                                        (resource, index) =>
                                          resource.resource_type ===
                                            "audio/mp3" && (
                                            <option
                                              value={resource.id}
                                              key={index}
                                            >
                                              {resource.resource_title}{" "}
                                            </option>
                                          )
                                      )
                                    ) : (
                                      <option>No Audio yet</option>
                                    )}
                                  </select>
                                </div>

                                <div>
                                  <label
                                    className="form-label mt-3"
                                    htmlFor="topic_core_text"
                                  >
                                    Core Text
                                  </label>
                                  <select
                                    className=" form-select"
                                    aria-label="Default select"
                                    onChange={handleChange}
                                    required
                                    value={topicForm.topic_core_text}
                                    name="topic_core_text"
                                  >
                                    <option>Select document</option>
                                    {libraryResources?.length > 0 ? (
                                      libraryResources.map(
                                        (resource, index) =>
                                          resource.resource_type ===
                                            "application/pdf" && (
                                            <option
                                              value={resource.id}
                                              key={index}
                                            >
                                              {resource.resource_title}{" "}
                                            </option>
                                          )
                                      )
                                    ) : (
                                      <option>No documents yet</option>
                                    )}
                                  </select>
                                </div>

                                <div className="inputBox">
                                  <span>topic name :</span>
                                  <input
                                    type="text"
                                    placeholder="Suppot vector"
                                    onChange={handleChange}
                                    required
                                    value={topicForm.topic_full_name}
                                    name="topic_full_name"
                                  />
                                </div>

                                <div className="inputBox">
                                  <span>topic code :</span>
                                  <input
                                    type="text"
                                    placeholder="COM 2301"
                                    onChange={handleChange}
                                    required
                                    value={topicForm.topic_short_code}
                                    name="topic_short_code"
                                  />
                                </div>
                              </div>

                              <div className="inputBox mb-3">
                                <span>Summary :</span>
                                <textarea
                                  className="form-control"
                                  row="8"
                                  name="topic_summary"
                                  placeholder="Enter detailed description"
                                  onChange={handleChange}
                                  required
                                  value={topicForm.topic_summary}
                                />
                              </div>
                              <div className="inputBox">
                                <span>Short Descrition :</span>
                                <textarea
                                  className="form-control mb-4"
                                  row="4"
                                  placeholder="Enter short description"
                                  onChange={handleChange}
                                  required
                                  value={topicForm.topic_short_desc}
                                  name="topic_short_desc"
                                />
                              </div>

                              <div className="form-check d-flex justify-content-center mb-4">
                                <input
                                  className="form-check-input me-2"
                                  type="checkbox"
                                  id="mandatory"
                                  name="mandatory"
                                  checked={topicForm.mandatory}
                                  onChange={handleChange}
                                />
                                <label
                                  htmlFor="mandatory"
                                  className="form-check-label"
                                >
                                  Mandatory
                                </label>
                              </div>
                            </div>

                            <input
                              type="submit"
                              value="ADD"
                              className="submit-btn"
                            />
                          </form>
                        </div>
                      </div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          className="btn btn-secondary text-dark"
                          data-bs-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-12">
                {fetchedTopics?.topics?.length > 0 ? (
                  <div className="card mt-3">
                    {fetchedTopics?.topics?.map((topic, index) => (
                      <div key={index}>
                        <hr className="text-dark" />
                        <div className="card-body d-flex gap-4 flex-column">
                          <div className="d-flex align-items-center justify-content-around">
                            <h5
                              className="item-name fs-4 fw-600"
                              style={{ color: "#094886" }}
                            >
                              {topic?.topic_full_name}
                            </h5>

                            <div className="d-flex gap-2 flex-wrap justify-content-between">
                              <div className="d-flex flex-column gap-2 align-items-start justify-content-start">
                                <span className="item-price-new">
                                  Institution:
                                  {fetchedTopics?.institution?.inst_name}
                                </span>
                                <span className="item-price-new">
                                  Unit: {fetchedTopics?.unit_full_name}
                                </span>
                                <span className="item-price-new">
                                  Instructor: {fetchedTopics?.instructor}
                                </span>
                              </div>

                              <div className="d-flex gap-2 flex-column align-items-start justify-content-end">
                                <span className="item-price-new">
                                  Code: {topic?.topic_short_code}
                                </span>
                                <span className="item-price-new">
                                  Mandatory: {topic?.mandatory}
                                </span>
                              </div>
                            </div>
                          </div>

                          <span className="item-creator mt-2">
                            <p
                              style={{ whiteSpace: "pre-wrap" }}
                              dangerouslySetInnerHTML={{
                                __html: topic?.topic_summary,
                              }}
                            />
                          </span>
                        </div>
                        <div className="gap-3 m-2 d-flex align-items-center">
                          {/* <Link
                            to={`/topics/details/${topic.id}`}
                            className="btn btn-primary py-3"
                          >
                            See details
                          </Link> */}
                          <Update id={topic?.id} topic={topic} />
                          {/* delete */}
                          <button
                            type="button"
                            className="btn btn-danger py-3"
                            onClick={() => {
                              handleDelete(topic?.id);
                            }}
                          >
                            Delete
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <h3 className="text-center text-danger fs-4">
                    No topics found
                  </h3>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Topics;
