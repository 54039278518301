import { useState, useEffect } from "react";

import axios from "../../../API/apiHandler";
import CompareCard from "./CompareCard";
import { Link } from "react-router-dom";

const CompareCourses = () => {
  const [CourseData, setCourseData] = useState([]);

  useEffect(() => {
    

    const fetchCourses = async () => {
      try {
        const token = JSON.parse(localStorage.getItem("fastapi"));
        const response = await axios.get(
          "/api/v1/courses",
          
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            withCredentials: true,
          }
        );
        response && setCourseData(response?.data);
      } catch (error) {}
    };
    fetchCourses();
    
  }, []);

  return (
    <div>
      <div className="container mt-3">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div>
              <div className="mx-3">
                <h3>Compare Courses</h3>
              </div>
              <div className="d-flex my-2 flex-row-reverse">
                <Link to="/courses" className="btn btn-danger p-2">
                  <i className="fas fa-arrow-left"></i> Back to Courses
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="dflex">
          <div className="row">
            <div className="row">
              <div className="col">
                {CourseData.length > 0 ? (
                  CourseData.map((fetchedCourse, index) => (
                    <div className="" key={index}>
                      <CompareCard
                        id={fetchedCourse.id}
                        name={fetchedCourse.course_full_name}
                        course_code={fetchedCourse.course_code}
                        summary={fetchedCourse.course_summary}
                        short_desc={fetchedCourse.course_short_desc}
                        intake={fetchedCourse.course_first_offered}
                        cost={fetchedCourse.course_full_amount}
                        study_area={fetchedCourse.study_area}
                        duration={fetchedCourse.course_length}
                        accreditation={fetchedCourse.course_accreditation}
                        category={fetchedCourse.category.cat_name}
                        institution={fetchedCourse.institution.inst_name}
                      />
                    </div>
                  ))
                ) : (
                  <div className="text-danger mt-5 text-center">
                    <span>No Courses to compare yet....</span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompareCourses;
