import { BsFillShieldLockFill, BsTelephoneFill } from "react-icons/bs";
import { CgSpinner } from "react-icons/cg";
import { useEffect } from "react";

import OtpInput from "otp-input-react";
import { toast, Toaster } from "react-hot-toast";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { useContext, useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import ProfileContext from "../../../../../Context/ProfileContext";
import axios from "../../../../../API/apiHandler";

const App = () => {
  const { token } = useContext(ProfileContext);
  const navigate = useNavigate();
  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(false);
  const [showOTP, setShowOTP] = useState(false);
  const [user, setUser] = useState(null);
  const [ph, setPh] = useState("");
  const [, setFetchedProfile] = useState({});
  // const mobile = fetchedProfile?.phone_number;

  const onSignup = async (e) => {
    e.preventDefault();
    setLoading(true);

    const phone_number = "+" + ph;
    const encodedPhoneNumber = encodeURIComponent(phone_number);

    try {
      const res = await axios.post(
        `api/v1/otp/send/verification_code?phone_number=${encodedPhoneNumber}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (res?.data?.status) {
        setLoading(false);
        setShowOTP(true);
        toast.success(`OTP code sent to ${phone_number}`);
      }
    } catch (error) {}
  };

  const onOTPVerify = async (e) => {
    e.preventDefault();
    setLoading(true);

    const phone_number = "+" + ph;
    const verification_code = otp;
    const url = `/api/v1/otp/verify/check?phone_number=${encodeURIComponent(
      phone_number
    )}&verification_code=${encodeURIComponent(verification_code)}`;

    try {
      const res = await axios.post(url, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      const success = res?.data?.status;
      if (success === "approved") {
        setUser(success);
        setLoading(false);
        navigate("/profile");
        toast.success("OTP verified successfully");
      } else {
        toast.error("wrong otp code");
      }
    } catch (error) {}
  };

  useEffect(() => {
    

    const fetchUser = async () => {
      try {
        const uid = localStorage.getItem("id");
        const res = await axios.get(
          `/api/v1/user/profile/${uid}`,
          
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setFetchedProfile(res?.data);
      } catch (error) {
        if (error.response.status === 401) Navigate("/login");
      }
    };

    fetchUser();
    
  }, [token]);

  return (
    <section className="bg-blue-900 flex items-center justify-center h-screen">
      <div>
        <Toaster toastOptions={{ duration: 3000 }} />
        <div id="recaptcha-container"></div>
        {user ? (
          <div className=" bg-blue-400 w-80 flex flex-col gap-4 p-4">
            <h2 className="text-center text-white font-medium text-2xl mb-2">
              👍Verified...!
            </h2>

            <button className="bg-orange-500 w-full flex gap-1 items-center justify-center py-2.5 text-white rounded">
              {loading && <CgSpinner size={20} className="mt-1 animate-spin" />}
              <Link to="/profile">Proceed...</Link>
            </button>
          </div>
        ) : (
          <div className="w-80 flex flex-col gap-4 rounded-lg p-4">
            <h3 className="text-center leading-normal text-white font-medium text-3xl mb-6">
              Welcome to <br /> ACTS Pathways Academy
            </h3>
            {showOTP ? (
              <>
                <div className="bg-white text-emerald-600 w-fit mx-auto p-4 rounded-full">
                  <BsFillShieldLockFill size={30} />
                </div>
                <label
                  htmlFor="otp"
                  className="font-bold text-xl text-white text-center"
                >
                  Enter your OTP
                </label>
                <OtpInput
                  value={otp}
                  onChange={setOtp}
                  OTPLength={6}
                  otpType="number"
                  disabled={false}
                  autoFocus
                  className="opt-container "
                ></OtpInput>
                <button
                  onClick={onOTPVerify}
                  className="bg-orange-500 w-full flex gap-1 items-center justify-center py-2.5 text-white rounded"
                >
                  {loading && (
                    <CgSpinner size={20} className="mt-1 animate-spin" />
                  )}
                  <span>Verify OTP</span>
                </button>
              </>
            ) : (
              <>
                <div className="bg-white text-emerald-700 w-fit mx-auto p-4 rounded-full">
                  <BsTelephoneFill size={30} />
                </div>
                <label
                  htmlFor=""
                  className="font-bold text-xl text-white text-center"
                >
                  Verify your phone number
                </label>
                <PhoneInput country={"ke"} value={ph} onChange={setPh} />
                <button
                  onClick={onSignup}
                  className="bg-orange-500 w-full flex gap-1 items-center justify-center py-2.5 text-white rounded"
                >
                  {loading && (
                    <CgSpinner size={25} className="mt-1 animate-spin" />
                  )}
                  <span>Send code via SMS</span>
                </button>
              </>
            )}
          </div>
        )}
      </div>
    </section>
  );
};

export default App;
