import { useContext, useEffect, useState } from "react";
import { Link, Navigate } from "react-router-dom";
//import {MdShoppingCart} from "react-icons/md";
import Logo from "../../images/acts-logo@1x.png";
import ProfileContext from "../../Context/ProfileContext";
import { InputText } from "primereact/inputtext";
import { useCartContext } from "../../Context/CartContext";
import { toast } from "react-hot-toast";

import axios from "../../API/apiHandler";
import avatar from "../../images/profile.png";

// const DASHBOARD_SIDEBAR_LINKS = [
// 	{
// 		key: '',
// 		label: '',
// 		path: '',
// 		icon: ''
// 	}
// ]

const Sidebar = () => {
  const { total_items, shop, logout } = useCartContext();
  const totalShop = shop?.total_items;

  const { file, setFile, auth, token } = useContext(ProfileContext);

  const [fetchedProfile, setFetchedProfile] = useState({});

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const res = await axios.get(
          `/api/v1/user/profile/${auth}`,

          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setFetchedProfile(res?.data);
      } catch (err) {
        if (err.response.status === 401) Navigate("/login");
      }
    };
    fetchUser();
  }, [token, auth]);

  useEffect(() => {
    const fetchImg = async () => {
      try {
        const res = await axios.get(
          `/api/v1/users/avatar/${auth}`,

          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            responseType: "arraybuffer",
          }
        );
        if (res?.data) {
          const base64 = btoa(
            new Uint8Array(res.data).reduce(
              (data, byte) => data + String.fromCharCode(byte),
              ""
            )
          );
          setFile(`data:;base64,${base64}`);
        }
      } catch (error) {}
    };
    fetchImg();
  }, [auth, setFile, token]);

  return (
    <>
      <nav
        className="navbar navbar-expand-lg navbar-dark fixed-top"
        style={{ backgroundColor: "#094886" }}
      >
        <div className="container-fluid">
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#sidebar"
            aria-controls="offcanvasExample"
          >
            <span
              className="navbar-toggler-icon"
              data-bs-target="#sidebar"
            ></span>
          </button>
          <Link className="navbar-brand ms-lg-0 ms-1" to="#">
            <span className="text-warning">A</span>PA
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#topNavBar"
            aria-controls="topNavBar"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="topNavBar">
            <div className="d-flex ms-auto align-items-center gap-3">
              {/*cart*/}
              <Link to="/cart" className="ms-auto flex-1 mx-3">
                <i className="fas fa-shopping-cart fs-4 text-light position-relative">
                  <span className="text-white position-absolute top-0 start-100 translate-middle">
                    {total_items + totalShop}
                  </span>
                </i>
              </Link>
              <form className="d-flex ms-auto">
                {/* <div className="input-group">
                  <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText placeholder="Keyword Search" />
                  </span>
                </div> */}
              </form>
              <div className="d-flex justify-content-end align-items-center mx-3">
                <Link
                  className="btn btn-link text-decoration-none position-relative"
                  to="#"
                  role="button"
                  id="avatar-dropdown-toggle"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <img
                    src={file === null ? avatar : file}
                    alt="Avatar"
                    className="rounded-circle border border-1 border-white"
                    style={{ width: "35px", height: "35px" }}
                  />
                </Link>

                <ul
                  className="dropdown-menu dropdown-menu-end"
                  aria-labelledby="avatar-dropdown-toggle"
                >
                  <li className="d-flex align-items-center">
                    <img
                      src={file || avatar}
                      alt="Avatar"
                      className="rounded-circle border ms-1 border-1 border-info"
                      style={{ width: "35px", height: "35px" }}
                    />
                    <div className="flex-grow-1 ms-2 mb-2">
                      <h4 className="mb-0 text-info">
                        {fetchedProfile?.last_name}
                      </h4>
                      <p className="card-text">{fetchedProfile?.email}</p>
                    </div>
                  </li>
                  <li className="d-flex align-items-center ms-3">
                    <i className="fas fa-user-circle"></i>
                    <Link className="dropdown-item" to="/profile">
                      Profile & Account
                    </Link>
                  </li>
                  <li className="d-flex align-items-center ms-3">
                    <i className="fas fa-cog"></i>
                    <Link className="dropdown-item" to="/settings">
                      Settings
                    </Link>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>
                  <li className="d-flex align-items-center ms-3">
                    <i className="fas fa-sign-out-alt"></i>
                    <button onClick={logout} className="dropdown-item ">
                      Logout
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </nav>

      <div
        className="offcanvas offcanvas-start sidebar-nav"
        tabIndex="-1"
        id="sidebar"
      >
        <div className="offcanvas-body p-0">
          <nav
            className=" navbar justify-content-center align-items-center"
            id="wrapper"
          >
            <div
              className="bg-light border-right p-3 bg-info"
              id="sidebar-wrapper"
            >
              <div className="justify-content-center mt-0">
                <img
                  src={Logo}
                  alt="profile"
                  className="img-thumbnail d-xs-none d-lg-block"
                  style={{ width: "100px", height: "100px" }}
                />
              </div>
            </div>
            <div className="gap-2 list-group list-group-flush flex-lg-column">
              <Link
                to="/dashboard"
                className="list-group-item list-group-item-action bg-light"
              >
                <i className="fas fa-tachometer-alt"></i> Dashboard
              </Link>
              <Link
                to="/studyhub"
                className="list-group-item list-group-item-action bg-light"
              >
                <i className="fas fa-book-open"></i> Study Hub
              </Link>
              {/* <Link to='/library' className='list-group-item list-group-item-action bg-light'>
								<i className='fas fa-book'></i> Library
							</Link> */}
              <Link
                to="/courses"
                className="list-group-item list-group-item-action bg-light"
              >
                <i className="pi mx-1 pi-spin pi-star-fill"></i> Courses
              </Link>
              <Link
                to="/scholarships"
                className="list-group-item list-group-item-action bg-light"
              >
                <i className="fa-solid fa-graduation-cap"></i> Scholarships
              </Link>
              <Link
                to="/fundings"
                className="list-group-item list-group-item-action bg-light"
              >
                <i className="fa-solid fa-money-bill-trend-up"></i> Fundings
              </Link>
              <Link
                to="/conferencing"
                className="list-group-item list-group-item-action bg-light"
              >
                <i className="fas fa-video"></i> Conference
              </Link>
              <Link
                to="/community"
                className="list-group-item list-group-item-action bg-light"
              >
                <i className="fas fa-users"></i> Community
              </Link>
              {/* <Link
                to="/shop"
                className="list-group-item list-group-item-action bg-light"
              >
                <i className="fas fa-cog"></i> Market
              </Link> */}
              <Link
                to="/settings"
                className="list-group-item list-group-item-action bg-light"
              >
                <i className="fas fa-cog"></i> Settings
              </Link>
              <Link
                to="/support"
                className="list-group-item list-group-item-action bg-light"
              >
                <i className="fa fa-question-circle"></i> Support
              </Link>

              <hr className="text-info" />
              <div className="mt-3 p-2">
                <button
                  onClick={logout}
                  className="btn text-white"
                  style={{ backgroundColor: "#094886" }}
                >
                  <i className="fas fa-sign-out-alt"></i> Signout
                </button>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
