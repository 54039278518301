import {
  ADD_COURSE_TO_CART,
  ADD_SHOP_ITEM_TO_CART,
  REMOVE_SHOP_ITEM_FROM_CART,
  REMOVE_CART_ITEM,
  CLEAR_CART,
  GET_CART_TOTAL,
  LOGOUT,
} from "../actions";

const cart_reducer = (state, action) => {
  if (action.type === ADD_COURSE_TO_CART) {
    const tempArr = state.cart.filter((item) => item.id === action.payload.id);
    if (tempArr.length < 1) {
      return {
        ...state,
        cart: [...state.cart, action.payload],
      };
    }
    return {
      ...state,
    };
  }

  if (action.type === ADD_SHOP_ITEM_TO_CART) {
    const { id, price } = action.payload;
    const existingItem = state.shop.cart.find((item) => item.id === id);

    if (existingItem) {
      // Item already exists in the cart, increment quantity
      const updatedCart = state.shop.cart.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            quantity: item.quantity + 1,
          };
        }
        return item;
      });

      return {
        ...state,
        shop: {
          ...state.shop,
          cart: updatedCart,
          total_amount: state.shop.total_amount + price,
        },
      };
    }

    // Item does not exist in the cart, add it
    const newItem = { ...action.payload, quantity: 1 };
    return {
      ...state,
      shop: {
        ...state.shop,
        cart: [...state.shop.cart, newItem],
        total_items: state.shop.total_items + 1,
        total_amount: state.shop.total_amount + newItem.quantity * price,
      },
    };
  }

  if (action.type === REMOVE_SHOP_ITEM_FROM_CART) {
  const { payload: id } = action;
  const updatedCart = state.shop.cart.filter(item => item.id !== id);

  const totalAmount = updatedCart.reduce((total, item) => {
    return total + item.price * item.quantity;
  }, 0);

  return {
    ...state,
    shop: {
      ...state.shop,
      cart: updatedCart,
      total_items: state.shop.total_items - 1,
      total_amount: totalAmount,
    },
  };
}


  if (action.type === REMOVE_CART_ITEM) {
    const tempCart = state.cart.filter((item) => item.id !== action.payload);
    return {
      ...state,
      cart: tempCart,
    };
  }

  if (action.type === GET_CART_TOTAL) {
    const total_amount = state.cart.reduce((total, cartItem) => {
      total += cartItem.cost;
      return total;
    }, 0);
    return {
      ...state,
      total_items: state.cart.length,
      total_amount,
    };
  }

  if (action.type === CLEAR_CART) {
    return {
      ...state,
      cart: [],
    };
  }

  if (action.type === LOGOUT) {
    return {
      ...state,
      user: null,
    };
  }

  throw new Error(`No matching "${action.type}" - action type`);
};

export default cart_reducer;
