import React, { useState, useEffect } from "react";
import { toast } from "react-hot-toast";
import axios from "../../../API/apiHandler";
import Nav from "../Nav";

const Coupon = () => {
  const [coupons, setCoupons] = useState([]);
  const [coupon, setCoupon] = useState({
    discount: 0,
    valid_from: "",
    valid_to: "",
  });

  const handleChange = (e) => {
    setCoupon({
      ...coupon,
      [e.target.name]: e.target.value,
    });
  };

  const { discount, valid_from, valid_to } = coupon;

  const handleCouponCreated = async (e) => {
    e.preventDefault();
    const token = JSON.parse(localStorage.getItem("fastapi"));

    const valid_from_iso = new Date(valid_from).toISOString();
    const valid_to_iso = new Date(valid_to).toISOString();

    const coupons = {
      discount: coupon.discount,
      valid_from: valid_from_iso,
      valid_to: valid_to_iso,
      // course_id: coupon.course_id,
    };

    try {
      await axios.post("/api/v1/copouns/course-coupons", coupons, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      toast.success("Coupon created successfully");
      window.location.reload();
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.detail);
      } else {
        toast.error("Something went wrong");
      }
    }
  };

  useEffect(() => {
    const token = JSON.parse(localStorage.getItem("fastapi"));

    const fetchCoupons = async () => {
      try {
        const res = await axios.get("/api/v1/copouns/get-course-coupons", {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        setCoupons(res.data);
      } catch (error) {
        if (error.response) {
          toast.error(error.response.data.detail);
        } else {
          toast.error("Something went wrong");
        }
      }
    };
    fetchCoupons();
  }, []);

  const handleDelete = async (uid) => {
    const token = JSON.parse(localStorage.getItem("fastapi"));

    try {
      await axios.delete(`/api/v1/copouns/course-coupons/${uid}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      toast.success("Coupon deleted!");
      window.location.reload();
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.detail);
      }
      toast.error("Something went wrong");
    }
  };

  // const [CourseData, setCourseData] = useState([]);

  // const fetchCourses = async () => {
  //   try {
  //     const token = JSON.parse(localStorage.getItem("fastapi"));
  //     const response = await axios.get("/api/v1/courses/published", {
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `Bearer ${token}`,
  //       },
  //       withCredentials: true,
  //     });
  //     const courses = response?.data;
  //     courses.sort((a, b) => b.id - a.id);
  //     setCourseData(courses);
  //   } catch (error) {}
  // };

  // useEffect(() => {
  //   fetchCourses();
  // }, []);

  return (
    <>
      <section>
        <div className="container-fluid">
          <div className="row mt-5">
            <div className="col-12 col-md-2">
              <Nav />
            </div>
            <div className="col-12 col-md-10 mt-5">
              <div className="row">
                <div className="col-12">
                  <div className="d-grid">
                    <button
                      type="button"
                      className="btn btn-warning btn-block shadow"
                      style={{
                        boxShadow: "inset 2px 2px 6px #008600",
                        border: "2px solid #011",
                      }}
                      data-bs-toggle="modal"
                      data-bs-target="#addcoupon"
                    >
                      <span className="fs-5">
                        <i className="fas fa-plus-circle"></i> Add Coupon
                      </span>
                    </button>
                  </div>
                </div>
                <div
                  className="modal fade"
                  style={{ overflow: "scroll !important" }}
                  id="addcoupon"
                  data-bs-backdrop="static"
                  data-bs-keyboard="false"
                  tabIndex="-1"
                  aria-labelledby="addcouponLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog modal-lg ">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h1
                          className="modal-title text-blue-900 fs-5"
                          id="addcouponLabel"
                        >
                          New coupon details
                        </h1>
                        <button
                          type="button"
                          className="btn-close text-primary"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                      </div>
                      <div className="modal-body">
                        <div className="modalcontainer text-dark">
                          <form onSubmit={handleCouponCreated}>
                            <div className="row">
                              <div className="w-full flex justify-start items-center gap-4">
                                {/* <div className="inputBox mb-2">
                                  <span>Select Course:</span>
                                  <select
                                    className="form-control"
                                    name="course_id"
                                    value={course_id}
                                    onChange={handleChange}
                                    required
                                  >
                                    <option value="">Select Course</option>
                                    {CourseData.map((course) => (
                                      <option key={course.id} value={course.id}>
                                        {course.course_full_name}
                                      </option>
                                    ))}
                                  </select>
                                </div> */}

                                <div className="flex mx-3 mt-3">
                                  <div className="inputBox">
                                    <span>Valid From:</span>
                                    <input
                                      id="valid_from"
                                      name="valid_from"
                                      type="date"
                                      value={valid_from}
                                      onChange={handleChange}
                                      required
                                      className="form-control"
                                    />
                                  </div>

                                  <div className="inputBox">
                                    <span>Valid Until:</span>
                                    <input
                                      id="valid_to"
                                      name="valid_to"
                                      type="date"
                                      value={valid_to}
                                      onChange={handleChange}
                                      required
                                      className="form-control"
                                    />
                                  </div>
                                </div>

                                <div className="inputBox mt-3">
                                  <span>Discount ($): </span>
                                  <input
                                    type="number"
                                    placeholder=""
                                    onChange={handleChange}
                                    required
                                    value={discount}
                                    name="discount"
                                  />
                                </div>
                              </div>
                            </div>

                            <input
                              type="submit"
                              value="Confirm"
                              className="submit-btn mt-4"
                            />
                          </form>
                        </div>
                      </div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          className="btn btn-secondary text-dark"
                          data-bs-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row mt-5">
                <div className="col-12">
                  <div className="table-responsive">
                    <table className="table table-striped table-hover">
                      <thead>
                        <tr>
                          <th scope="col">Code</th>
                          <th scope="col">Discount</th>
                          <th scope="col">Valid From</th>
                          <th scope="col">Valid Until</th>
                          {/* <th scope="col">Course</th> */}
                          <th scope="col">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {coupons?.length ? (
                          coupons.map((coupon) => (
                            <tr key={coupon.id}>
                              <td>{coupon.code}</td>
                              <td>{coupon.discount}</td>
                              <td>{coupon.valid_from}</td>
                              <td>{coupon.valid_to}</td>
                              {/* <td>
                                {coupon?.course_id
                                  ? (
                                      CourseData.find(
                                        (course) =>
                                          course.id === coupon.course_id
                                      ) || {}
                                    ).course_full_name
                                  : null}
                              </td> */}
                              <td>
                                <span
                                  className="btn btn-danger btn-sm"
                                  onClick={() => handleDelete(coupon.id)}
                                >
                                  <i className="fas fa-trash text-danger text-light" />
                                </span>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="7">No coupons exist</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Coupon;
