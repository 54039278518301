import { useState, useEffect, useContext } from "react";
import { toast } from "react-hot-toast";

import ProfileContext from "../../../Context/ProfileContext";
import axios from "../../../API/apiHandler";

import styled from "styled-components";

import { DefaultPlayer as Video } from "react-html5video";
import "react-html5video/dist/styles.css";
import ReactAudioPlayer from "react-audio-player";

import AddMedia from "./AddMedia";
import Update from "./Update";

const Card = (props) => {
  const { token } = useContext(ProfileContext);

  const {
    id,
    library,
    title,
    desc,
    type,
    subject,
    publisher,
    author,
    price,
    date,
  } = props;

  const [doc, setDoc] = useState([]);
  const [audio, setAudio] = useState([]);
  const [vid, setVid] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [downloadProgress, setDownloadProgress] = useState(0);

  const deleteResource = async (id) => {
    try {
      const res = await axios.delete(`/api/v1/library/resources/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (res.status === 200) {
        toast.success("deleted!");
        window.location.reload();
      }
    } catch (error) {
      if (error.response?.data.detail) {
        toast.error(error.response?.data.detail);
      } else {
        toast.error("failed");
      }
    }
  };

  useEffect(() => {
    const fetchMedia = async () => {
      setIsLoading(true);

      try {
        const res = await axios.get(
          `/api/v1/library/resources/stream/${id}`,

          {
            onDownloadProgress: (progressEvent) => {
              const { loaded, total } = progressEvent;
              const progress = Math.round((loaded / total) * 100);
              setDownloadProgress(progress);
            },
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            responseType: "blob",
          }
        );

        const contentType = res?.headers.get("Content-Type");

        if (contentType === "application/pdf") {
          const blob = new Blob([res?.data], { type: "application/pdf" });
          const url = URL.createObjectURL(blob);
          setDoc(url);
        } else if (contentType.startsWith("audio")) {
          const blob = new Blob([res?.data], { type: "audio/mp3" });
          const url = window.URL.createObjectURL(blob);
          setAudio(url);
        } else if (contentType.startsWith("video")) {
          const blob = new Blob([res?.data], { type: "video/mp4" });
          const url = window.URL.createObjectURL(blob);
          setVid(url);
        } else {
          toast.error("error with the content type");
        }
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    };

    (async () => {
      await fetchMedia();
    })();
  }, [id, token]);

  //useEffect(() => { URL.revokeObjectURL(url) }, [blob])

  return (
    <CourseCard>
      <div className="">
        <div className="item-body d-flex gap-2 flex-column">
          <div className="d-flex justify-content-between gap-2">
            <div className="d-flex mx-2 flex-column">
              <div className="">
                {isLoading ? (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <span
                      className="text-warning"
                      style={{ marginLeft: "10px", fontSize: "16px" }}
                    >
                      {downloadProgress}%
                    </span>
                  </div>
                ) : (
                  type &&
                  type === "video/mp4" && (
                    <Video
                      autoPlay
                      loop
                      muted
                      controls={[
                        "PlayPause",
                        "Seek",
                        "Time",
                        "Volume",
                        "Fullscreen",
                      ]}
                      src={vid}
                      type="video/mp4"
                      style={{
                        width: "100%",
                        maxWidth: "500px",
                        height: "auto",
                      }}
                    >
                      your browser does not support the <code>video </code>
                      element
                    </Video>
                  )
                )}
                {/* <audio controls src={audio}>
                  your browser does not support the <code>audio </code>element
                </audio> */}
                {isLoading ? (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <span
                      className="text-warning"
                      style={{ marginLeft: "10px", fontSize: "16px" }}
                    >
                      {downloadProgress}%
                    </span>
                  </div>
                ) : (
                  type &&
                  type === "audio/mp3" && (
                    <ReactAudioPlayer src={audio} controls />
                  )
                )}

                {isLoading ? (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <span
                      className="text-warning"
                      style={{ marginLeft: "10px", fontSize: "16px" }}
                    >
                      {downloadProgress}%
                    </span>
                  </div>
                ) : (
                  type &&
                  type === "application/pdf" && (
                    <div>
                      <iframe
                        src={doc}
                        title={title}
                        width="100%"
                        height="100%"
                        onClick={() => window.open(doc, "_blank")}
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                  )
                )}
              </div>
            </div>
            <div className="">
              <h5 className="item-name fs-5 " style={{ color: "#094886" }}>
                {title}
              </h5>

              <div className="d-flex flex-wrap justify-content-between">
                <div className="d-flex flex-column align-items-center justify-content-start">
                  <span className="item-price-new">Author:{author}</span>
                  <span className="item-price-new">Price(USD): {price}</span>
                  <span className="item-price-new">Publ. Date:{date}</span>
                </div>

                <div className="d-flex flex-column align-items-center justify-content-start">
                  <span className="item-price-new">Type: {type}</span>
                  <span className="item-price-new">Publisher:{publisher}</span>
                  <span className="item-price-new">Subject: {subject}</span>
                </div>
              </div>
            </div>
          </div>

          <span className="item-creator mt-2">{desc}</span>
        </div>
      </div>

      <div className="item-btns d-flex justify-content-between flex-column">
        <button
          className="item-btn add-to-cart-btn my-1"
          onClick={() => deleteResource(id)}
        >
          Delete
        </button>
        <Update uuid={id} lib={library} />
        <AddMedia uuid={id} type={type} title={title} />
      </div>
    </CourseCard>
  );
};

const CourseCard = styled.div`
  margin-bottom: 20px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: rgba(149, 157, 165, 0.1) 0px 8px 24px;
  display: flex;
  flex-direction: column;
  padding: 0;
  max-width: 100%;

  @media (min-width: 768px) {
    flex-direction: row;
  }

  .item-body {
    margin: 14px 0;
    padding: 4px 18px;

    .item-name {
      font-size: 15px;
      line-height: 1.4;
      font-weight: 800;
    }
    .item-creator {
      font-size: 13.5px;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.7);
    }
    .rating-star-val {
      margin-bottom: 5px;
      font-size: 14px;
      font-weight: 800;
      color: #b4690e;
      margin-right: 6px;
    }
    .rating-count {
      font-size: 12.5px;
      margin-left: 3px;
      font-weight: 500;
      opacity: 0.8;
    }
    .item-price-new {
      font-weight: 700;
      font-size: 15px;
    }
    .item-price-old {
      /*opacity: 0.8;*/
      font-weight: 600;
      /*text-decoration: line-through;*/
      font-size: 15px;
      margin-left: 8px;
    }
  }

  .item-btns {
    display: flex;
    justify-self: flex-start;
    padding: 4px 8px 30px 18px;
    margin-top: auto;
    flex-direction: row;

    .item-btn {
      font-size: 15px;
      display: inline-block;
      padding: 6px 16px;
      font-weight: 700;
      transition: var(--transition);
      white-space: nowrap;

      &.see-details-btn {
        background-color: transparent;
        border: 1px solid var(--clr-black);
        margin-right: 5px;

        &:hover {
          background-color: rgba(0, 0, 0, 0.9);
          color: var(--clr-white);
          color: white;
        }
      }

      &.add-to-cart-btn {
        background: rgba(0, 0, 0, 0.9);
        color: var(--clr-white);
        border: 1px solid rgba(0, 0, 0, 0.9);
        color: white;

        &:hover {
          background-color: transparent;
          color: rgba(0, 0, 0, 0.9);
        }
      }
    }
  }
`;

export default Card;
