import { useState, createContext, useEffect } from "react";
import axios from "../API/apiHandler";

const ProfileContext = createContext({});

export const ProfileProvider = ({ children }) => {
  //token handlers
  const [token, setToken] = useState(() => {
    return JSON.parse(localStorage.getItem("fastapi")) || "";
  });

  useEffect(() => {
    localStorage.setItem("fastapi", JSON.stringify(token));
  }, [token]);

  const [auth, setAuth] = useState(() => {
    return JSON.parse(localStorage.getItem("id")) || "";
  });

  useEffect(() => {
    localStorage.setItem("id", JSON.stringify(auth));
  }, [auth]);

  // order
  const [orderId, setOrderId] = useState(() => {
    return JSON.parse(localStorage.getItem("test")) || "";
  });

  useEffect(() => {
    localStorage.setItem("test", JSON.stringify(orderId));
  }, [orderId]);

  //CART
  const [cartItem, setCartItem] = useState(() => {
    return JSON.parse(localStorage.getItem("cart")) || "[]";
  });

  useEffect(() => {
    localStorage.setItem("cart", JSON.stringify(cartItem));
  }, [cartItem]);

  const AddToCart = (itemId) => {
    setCartItem((prev) => ({ ...prev, [itemId]: (prev[itemId] || 0) + 1 }));
  };

  const RemoveFromCart = (itemId) => {
    setCartItem((prev) => ({ ...prev, [itemId]: (prev[itemId] || 0) - 1 }));
  };

  const ContextValue = {
    cartItem,
    AddToCart,
    RemoveFromCart,
  };

  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [otherName, setOtherName] = useState("");
  const [dob, setDob] = useState("");
  const [gender, setGender] = useState("");
  const [nationality, setNationality] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");

  const [personalDetails, setPersonalDetails] = useState({
    firstname: "",
    lastname: "",
    otherName: "",
    dob: "",
    gender: "",
    nationality: "",
    country: "",
    state: "",
    city: "",
  });
  console.log("context personalDetails", personalDetails);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        if (auth) {
          const res = await axios.get(`/api/v1/user/profile/${auth}`, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          });

          const fname = res?.data?.first_name;
          const lname = res?.data?.last_name;

          setPersonalDetails({
            firstname: fname,
            lastname: lname,
          });

          setFirstname(res?.data?.first_name || "");
          setLastname(res?.data?.last_name || "");
          setOtherName(res?.data?.other_names || "");
          setDob(res?.data?.dob || "");
          setGender(res?.data?.gender || "");
          setNationality(res?.data?.nationality || "");
          setCountry(res?.data?.country || "");
          setState(res?.data?.state || "");
          setCity(res?.data?.city || "");
        }
      } catch (error) {
        // Handle error here
      }
    };

    fetchUser();
  }, [token, auth]); // eslint-disable-line react-hooks/exhaustive-deps

  const [formArrays, setFormArrays] = useState([]);
  // console.log("formArrays", formArrays);

  useEffect(() => {
    const EduDetails = async () => {
      try {
        const res = await axios.get(`/api/v1/user/profile/education/${auth}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        if (res?.data?.length > 0) {
          setFormArrays(res.data);
        }
      } catch (error) {}
    };
    if (auth) {
      EduDetails();
    }
  }, [auth, token]);

  const [formObjects, setFormObjects] = useState([]);

  useEffect(() => {
    const ExpDetails = async () => {
      try {
        const res = await axios.get(`/api/v1/user/profile/experience/${auth}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        if (res?.data?.length > 0) {
          setFormObjects(res.data);
        }
      } catch (error) {}
    };
    if (auth) {
      ExpDetails();
    }
  }, [token, auth]); // eslint-disable-line react-hooks/exhaustive-deps

  const [institution, setInstitution] = useState("");
  const [areaofstudy, setAreaofstudy] = useState("");
  const [transcript, setTranscript] = useState("");
  const [project, setProject] = useState("");
  const [certificate, setCertificate] = useState("");
  const [edstartdate, setEdStartdate] = useState("");
  const [edendDate, setEdEnddate] = useState("");
  const [major, setMajor] = useState("");
  const [specificCertificate, setSpecificCertificate] = useState("");
  const [grade, setGrade] = useState("");
  const [modeofstudy, setModeofstudy] = useState("");
  const [levelofstudy, setLevelofstudy] = useState("");

  const [company, setCompany] = useState("");
  const [industry, setIndustry] = useState("");
  const [reportingto, setReportingto] = useState("");
  const [fieldofpractice, setFieldofpractice] = useState("");
  const [refemail, setRefemail] = useState("");
  const [startdate, setStartdate] = useState("");
  const [endDate, setEnddate] = useState("");
  const [location, setLocation] = useState("");
  const [position, setPosition] = useState("");

  const [file, setFile] = useState(null);
  const [ph, setPh] = useState("");

  return (
    <div>
      <ProfileContext.Provider
        value={{
          token,
          setToken,

          auth,
          setAuth,

          orderId,
          setOrderId,

          cartItem,
          setCartItem,

          ContextValue,
          AddToCart,
          RemoveFromCart,

          formObjects,
          setFormObjects,
          formArrays,
          setFormArrays,

          personalDetails,
          setPersonalDetails,

          firstname,
          setFirstname,
          lastname,
          setLastname,
          otherName,
          setOtherName,
          dob,
          setDob,
          gender,
          setGender,
          nationality,
          setNationality,
          country,
          setCountry,
          state,
          setState,
          city,
          setCity,

          institution,
          setInstitution,
          areaofstudy,
          setAreaofstudy,
          major,
          setMajor,
          edstartdate,
          setEdStartdate,
          edendDate,
          setEdEnddate,
          certificate,
          setCertificate,
          project,
          setProject,
          specificCertificate,
          setSpecificCertificate,
          grade,
          setGrade,
          levelofstudy,
          setLevelofstudy,
          modeofstudy,
          setModeofstudy,
          transcript,
          setTranscript,

          startdate,
          setStartdate,
          endDate,
          setEnddate,
          industry,
          setIndustry,
          company,
          setCompany,
          position,
          setPosition,
          reportingto,
          setReportingto,
          location,
          setLocation,
          fieldofpractice,
          setFieldofpractice,
          refemail,
          setRefemail,

          file,
          setFile,
          ph,
          setPh,
        }}
      >
        {children}
      </ProfileContext.Provider>
    </div>
  );
};

export default ProfileContext;
