import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useNavigate, Link } from "react-router-dom";
import ProfileContext from "../../../../Context/ProfileContext";

import axios from "../../../../API/apiHandler";
import Bg from "../../../../img/pexels-kampus-production-5940841-1@1x.png";

const Pg3 = () => {
  const navigate = useNavigate();
  const { formArrays, setFormArrays, token, auth } = useContext(ProfileContext);
  console.log("formArrays", formArrays);

  const handleChange = (e, index) => {
    const values = [...formArrays];
    values[index][e.target.name] = e.target.value;
    setFormArrays(values);
  };

  const handleAddArrays = () => {
    setFormArrays([
      ...formArrays,
      {
        institution_name: "",
        area_of_study: "",
        grade: "",
        level_of_study: 0,
        is_current: true,
        major: "",
        mode_of_study: "",
        start_date: "",
        end_date: "",
      },
    ]);
  };

  const handleRemoveArrays = (index) => {
    const values = [...formArrays];
    values.splice(index, 1);
    setFormArrays(values);
  };

  const [levels, setLevels] = useState([]);
  useEffect(() => {
    const level = async () => {
      try {
        const res = await axios.get(
          "/api/v1/courses/documents",

          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        setLevels(res?.data);
      } catch (err) {}
    };

    level();
  }, [token]);

  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const educationPromises = formArrays.map(async (value) => {
        const educationData = {
          institution_name: value.institution_name,
          area_of_study: value.area_of_study,
          grade: value.grade,
          level_of_study: value.level_of_study,
          is_current: value.is_current,
          major: value.major,
          mode_of_study: value.mode_of_study,
          start_date: value.start_date,
          end_date: value.end_date,
        };

        const response = await axios.post(
          "/api/v1/user/profile/education",
          educationData,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.status === 201 || response.status === 200) {
          return true; // Success indicator for each education data
        } else {
          return false; // Error indicator for each education data
        }
      });

      const results = await Promise.all(educationPromises);

      if (results.every((result) => result === true)) {
        toast.success("Education Details Saved...");
        setLoading(false);
        navigate("/exp");
      } else {
        toast.error("Failed to save education details");
      }
    } catch (err) {}
  };

  const handleUpdate = async (idx) => {
    try {
      // Find the form data with the matching education_index
      const updatedForm = formArrays.find(
        (form) => form.education_index === idx
      );

      if (!updatedForm) {
        return toast.error(
          "This education Info does not exist, click Next to save it"
        );
      }

      const educationData = {
        institution_name: updatedForm.institution_name,
        area_of_study: updatedForm.area_of_study,
        grade: updatedForm.grade,
        level_of_study: updatedForm.level_of_study,
        is_current: updatedForm.is_current,
        major: updatedForm.major,
        mode_of_study: updatedForm.mode_of_study,
      };

      // Make a PUT request to update the data
      const response = await axios.put(
        `/api/v1/user/profile/education-update?user_id=${auth}&index_id=${idx}`,
        educationData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 201 || response.status === 200) {
        toast.success("Education Details Updated...");
        navigate("/exp");
      }
    } catch (err) {
      toast.error("Failed to update education details");
    }
  };

  return (
    <>
      <div
        className="container-fluid"
        style={{
          backgroundImage: `url(${Bg})`,
          backgroundRepeat: "no-repeat",
          width: "100%",
          minHeight: "100vh",
          loading: "lazy",
        }}
      >
        <div className="container p-3">
          {formArrays?.length === 0 ? (
            <div key={0}>
              <div className="card mb-3 border-primary border-2 mt-5">
                <center>
                  <h2 className="m-2 fs-3 " style={{ color: "#094886" }}>
                    Education Details
                  </h2>
                </center>

                <form onSubmit={handleSubmit} className="m-2">
                  <div className="d-flex flex-row-reverse gap-2">
                    {/* {formArrays.length !== 1 ? (
                      <button
                        type="button"
                        onClick={() => handleRemoveArrays(index)}
                        className="btn btn-outline-danger "
                      >
                        <i className="fas fa-minus"></i> Remove Entry
                      </button>
                    ) : null} */}

                    <button
                      type="button"
                      onClick={() => handleAddArrays()}
                      className="btn btn-outline-primary "
                    >
                      <i className="fas fa-plus"></i> Add Another Entry
                    </button>
                  </div>

                  <div className="row">
                    <div className="col-sm-6 col-lg-4">
                      <div className="m-2">
                        <div className="mb-3 mt-3">
                          <label
                            htmlFor="institution_name"
                            className="form-label"
                          >
                            Institution
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="institution_name"
                            placeholder="enter institution name"
                            name="institution_name"
                            value={formArrays[0]?.institution_name}
                            onChange={(e) => {
                              handleChange(e, 0);
                            }}
                          />
                        </div>
                        <div className="mb-3">
                          <label htmlFor="area_of_study" className="form-label">
                            Area of study
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="area_of_study"
                            placeholder="enter area of study"
                            name="area_of_study"
                            value={formArrays[0]?.area_of_study}
                            onChange={(e) => {
                              handleChange(e, 0);
                            }}
                          />
                        </div>
                        <div className="mb-3">
                          <label htmlFor="major" className="form-label">
                            Major/ Speciality
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="major"
                            placeholder="major"
                            name="major"
                            value={formArrays[0]?.major}
                            onChange={(e) => {
                              handleChange(e, 0);
                            }}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-6 col-lg-4">
                      <div className="m-2">
                        <label htmlFor="start_date" className="col-form-label">
                          Start Date
                        </label>
                        <div className="col-auto mb-3">
                          <div className="input-group date" id="datepicker">
                            <input
                              type="date"
                              className="form-control"
                              id="start_date"
                              name="start_date"
                              value={formArrays[0]?.start_date}
                              onChange={(e) => {
                                handleChange(e, 0);
                              }}
                            />
                          </div>
                        </div>

                        <label htmlFor="is_current" className="col-form-label">
                          Currently Studying Here?
                        </label>
                        <select
                          className="form-select mb-3"
                          id="is_current"
                          name="is_current"
                          value={formArrays[0]?.is_current}
                          onChange={(e) => {
                            handleChange(e, 0);
                          }}
                        >
                          <option defaultValue>Select</option>
                          <option value="false">No</option>
                          <option value="true">Yes</option>
                        </select>

                        <label htmlFor="end_date" className="col-form-label">
                          End Date
                        </label>
                        <div className="col-auto mb-3">
                          <div className="input-group date" id="datepicker">
                            <input
                              type="date"
                              className="form-control"
                              id="end_date"
                              name="end_date"
                              value={formArrays[0]?.end_date}
                              onChange={(e) => {
                                handleChange(e, 0);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-6 col-lg-4">
                      <div className="m-2">
                        <label htmlFor="grade" className="col-form-label">
                          Select Grade
                        </label>
                        <select
                          className="form-select mb-3"
                          value={formArrays[0]?.grade}
                          id="grade"
                          name="grade"
                          onChange={(e) => {
                            handleChange(e, 0);
                          }}
                        >
                          <option defaultValue>Grade</option>
                          <option value="first class">First Class Honor</option>
                          <option value="second upper">
                            Second Class Upper
                          </option>
                          <option value="second lower">
                            Second Class Lower
                          </option>
                          <option value="pass">Pass</option>
                          <option value="GPA">GPA</option>
                        </select>

                        <label
                          htmlFor="mode_of_study"
                          className="col-form-label"
                        >
                          Choose mode of study
                        </label>
                        <select
                          className="form-select mb-3"
                          id="mode_of_study"
                          name="mode_of_study"
                          value={formArrays[0]?.mode_of_study}
                          onChange={(e) => {
                            handleChange(e, 0);
                          }}
                        >
                          <option defaultValue>Mode of study</option>
                          <option value="online">Online</option>
                          <option value="home">Remote</option>
                          <option value="physical">in Campus</option>
                        </select>

                        <label
                          htmlFor="level_of_study"
                          className="col-form-label"
                        >
                          Select level of study
                        </label>
                        <select
                          className="form-select mb-3"
                          id="level_of_study"
                          name="level_of_study"
                          value={formArrays[0]?.level_of_study}
                          onChange={(e) => {
                            handleChange(e, 0);
                          }}
                        >
                          <option defaultValue>Level Of Study</option>
                          {levels &&
                            levels.map((level, index) => (
                              <option key={index} value={level.id}>
                                {level.doc_name}
                              </option>
                            ))}
                        </select>

                        {/* <label htmlFor="project" className="col-form-label">Project/Thesis</label>
											<input className="form-control" id="project" type="file" name='project'
												onChange={(e) => {
													handleChange(e, index);
												}}
											/> */}
                      </div>
                    </div>

                    <div className="mt-4 d-flex justify-content-end gap-3">
                      <Link
                        to="/cv"
                        className="btn btn-outline-primary w-25 text-dark"
                      >
                        Back
                      </Link>
                      {loading ? (
                        <button
                          className="bg-orange-500 w-25 border flex gap-1 items-center justify-center py-2.5 text-white rounded"
                          type="button"
                          disabled
                        >
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                          Saving...
                        </button>
                      ) : (
                        <button
                          type="submit"
                          className="btn btn-outline-primary w-25 text-dark"
                        >
                          Next (3/5)
                        </button>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          ) : (
            formArrays?.length &&
            formArrays.map((formArray, index) => (
              <div key={formArray.education_index}>
                <div className="card mb-3 border-primary border-2 mt-5">
                  <center>
                    <h2 className="m-2 fs-3 " style={{ color: "#094886" }}>
                      Education Details
                    </h2>
                  </center>

                  <form onSubmit={handleSubmit} className="m-2">
                    <div className="d-flex flex-row-reverse gap-2">
                      {formArrays.length !== 1 ? (
                        <button
                          type="button"
                          onClick={() => handleRemoveArrays(index)}
                          className="btn btn-outline-danger "
                        >
                          <i className="fas fa-minus"></i> Remove Entry
                        </button>
                      ) : null}

                      <button
                        type="button"
                        onClick={() => handleAddArrays()}
                        className="btn btn-outline-primary "
                      >
                        <i className="fas fa-plus"></i> Add Another Entry
                      </button>
                    </div>

                    <div className="row">
                      <div className="col-sm-6 col-lg-4">
                        <div className="m-2">
                          <div className="mb-3 mt-3">
                            <label
                              htmlFor="institution_name"
                              className="form-label"
                            >
                              Institution
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="institution_name"
                              placeholder="enter institution name"
                              name="institution_name"
                              value={formArray?.institution_name}
                              onChange={(e) => {
                                handleChange(e, index);
                              }}
                            />
                          </div>
                          <div className="mb-3">
                            <label
                              htmlFor="area_of_study"
                              className="form-label"
                            >
                              Area of study
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="area_of_study"
                              placeholder="enter area of study"
                              name="area_of_study"
                              value={formArray.area_of_study}
                              onChange={(e) => {
                                handleChange(e, index);
                              }}
                            />
                          </div>
                          <div className="mb-3">
                            <label htmlFor="major" className="form-label">
                              Major/ Speciality
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="major"
                              placeholder="major"
                              name="major"
                              value={formArray.major}
                              onChange={(e) => {
                                handleChange(e, index);
                              }}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-sm-6 col-lg-4">
                        <div className="m-2">
                          <label
                            htmlFor="start_date"
                            className="col-form-label"
                          >
                            Start Date
                          </label>
                          <div className="col-auto mb-3">
                            <div className="input-group date" id="datepicker">
                              <input
                                type="date"
                                className="form-control"
                                id="start_date"
                                name="start_date"
                                value={formArray.start_date}
                                onChange={(e) => {
                                  handleChange(e, index);
                                }}
                              />
                            </div>
                          </div>

                          <label
                            htmlFor="is_current"
                            className="col-form-label"
                          >
                            Currently Studying Here?
                          </label>
                          <select
                            className="form-select mb-3"
                            id="is_current"
                            name="is_current"
                            value={formArray.is_current}
                            onChange={(e) => {
                              handleChange(e, index);
                            }}
                          >
                            <option defaultValue>Select</option>
                            <option value="false">No</option>
                            <option value="true">Yes</option>
                          </select>

                          <label htmlFor="end_date" className="col-form-label">
                            End Date
                          </label>
                          <div className="col-auto mb-3">
                            <div className="input-group date" id="datepicker">
                              <input
                                type="date"
                                className="form-control"
                                id="end_date"
                                name="end_date"
                                value={formArray.end_date}
                                onChange={(e) => {
                                  handleChange(e, index);
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-sm-6 col-lg-4">
                        <div className="m-2">
                          <label htmlFor="grade" className="col-form-label">
                            Select Grade
                          </label>
                          <select
                            className="form-select mb-3"
                            value={formArray.grade}
                            id="grade"
                            name="grade"
                            onChange={(e) => {
                              handleChange(e, index);
                            }}
                          >
                            <option defaultValue>Grade</option>
                            <option value="first class">
                              First Class Honor
                            </option>
                            <option value="second upper">
                              Second Class Upper
                            </option>
                            <option value="second lower">
                              Second Class Lower
                            </option>
                            <option value="pass">Pass</option>
                          </select>

                          <label
                            htmlFor="mode_of_study"
                            className="col-form-label"
                          >
                            Choose mode of study
                          </label>
                          <select
                            className="form-select mb-3"
                            id="mode_of_study"
                            name="mode_of_study"
                            value={formArray.mode_of_study}
                            onChange={(e) => {
                              handleChange(e, index);
                            }}
                          >
                            <option defaultValue>Mode of study</option>
                            <option value="online">Online</option>
                            <option value="home">Remote</option>
                            <option value="physical">in Campus</option>
                          </select>

                          <label
                            htmlFor="level_of_study"
                            className="col-form-label"
                          >
                            Select level of study
                          </label>
                          <select
                            className="form-select mb-3"
                            id="level_of_study"
                            name="level_of_study"
                            value={formArray.level_of_study}
                            onChange={(e) => {
                              handleChange(e, index);
                            }}
                          >
                            <option defaultValue>Level Of Study</option>
                            {levels &&
                              levels.map((level, index) => (
                                <option key={index} value={level.id}>
                                  {level.doc_name}
                                </option>
                              ))}
                          </select>

                          {/* <label htmlFor="project" className="col-form-label">Project/Thesis</label>
											<input className="form-control" id="project" type="file" name='project'
												onChange={(e) => {
													handleChange(e, index);
												}}
											/> */}
                        </div>
                      </div>

                      <div className="mt-4 d-flex justify-content-end gap-3">
                        <Link
                          to="/cv"
                          className="btn btn-outline-primary w-25 text-dark"
                        >
                          Back
                        </Link>
                        {loading ? (
                          <button
                            className="bg-orange-500 w-25 border flex gap-1 items-center justify-center py-2.5 text-white rounded"
                            type="button"
                            disabled
                          >
                            <span
                              className="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            Updating...
                          </button>
                        ) : (
                          <button
                            type="button"
                            className="btn btn-outline-warning w-25 text-dark"
                            onClick={() => {
                              handleUpdate(formArray.education_index);
                            }}
                          >
                            Update
                          </button>
                        )}
                        {loading ? (
                          <button
                            className="bg-orange-500 w-25 border flex gap-1 items-center justify-center py-2.5 text-white rounded"
                            type="button"
                            disabled
                          >
                            <span
                              className="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            Saving...
                          </button>
                        ) : (
                          <button
                            type="submit"
                            className="btn btn-outline-primary w-25 text-dark"
                          >
                            Next (3/5)
                          </button>
                        )}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </>
  );
};

export default Pg3;
