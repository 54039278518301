// const now = new Date();
// const isoString = now.toISOString();

const initialValue = {
   id: '',
   start_date: '',
   end_date: '',
   description: '',
   start_time: '',
   end_time: '',
   location: '',
   facilitators: '',
   url: '',
   reminders: '',
   time_zone: '',
   created_at: '',
};

const EventReducer = (state = initialValue, action) => {
   switch (action.type) {
      case 'SHOW_EVENT':
         return action.payload;

      case "UPDATE_EVENT":
      return action.payload

      case 'DELETE_EVENT':
         return initialValue;
      case 'CLOSE_EVENT':
         return initialValue;
      default:
         return state;
   }
};

export default EventReducer;
