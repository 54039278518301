import { useContext, useState, useEffect } from "react";
// import { Galleria } from 'primereact/galleria';
// import { DeferredContent } from 'primereact/deferredcontent';
import { DefaultPlayer as Video } from "react-html5video";
import "react-html5video/dist/styles.css";
import "./gallery.css";
import { toast } from "react-hot-toast";

import ProfileContext from "../../../Context/ProfileContext";
import axios from "../../../API/apiHandler";

const Library = () => {
  const { token } = useContext(ProfileContext);

  const [fetchedResources, setFetchedResources] = useState([]);

  useEffect(() => {
    

    const fetchResources = async () => {
      try {
        const res = await axios.get(
          `/api/v1/library/institution/${1}`,
          
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const resource = res?.data;
        resource.sort((a, b) => b.id - a.id);
        setFetchedResources(resource);
      } catch (error) {}
    };

    fetchResources();
    
  }, [token]);

  const [mediaUrls, setMediaUrls] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [downloadProgress, setDownloadProgress] = useState(0);

  useEffect(() => {

    setIsLoading(true);
    if (fetchedResources) {
      const fetchMedia = async () => {
        const urls = await Promise.all(
          fetchedResources.map(async (resource) => {
            const id = resource?.id;
            try {
              const res = await axios.get(
                `/api/v1/library/resources/stream/${id}`,
                {
                  onDownloadProgress: (progressEvent) => {
                    const { loaded, total } = progressEvent;
                    const progress = Math.round((loaded / total) * 100);
                    setDownloadProgress(progress);
                  },
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                  },
                  responseType: "blob",
                }
              );
              const contentType = res.headers.get("Content-Type");
              if (contentType.startsWith("video")) {
                const blob = new Blob([res.data], { type: "video/mp4" });
                const url = window.URL.createObjectURL(blob);
                return url;
              }
            } catch (error) {
              if (error.response?.data.detail) {
                toast.error(error.response?.data.detail);
                setIsLoading(false);
              }
            }
          })
        );
        setMediaUrls(urls.filter((url) => url !== null));
        setIsLoading(false);
      };
      fetchMedia();
    }
  }, [token, fetchedResources]);

  return (
    <div>
      <div className=" gallery mt-4">
        <h5 className="text-success fs-4">Library Resources</h5>
        <div className="video">
          {mediaUrls.map((url, index) =>
            isLoading ? (
              <div className="video">
                <span
                  className="text-warning"
                  style={{ marginLeft: "10px", fontSize: "16px" }}
                >
                  {downloadProgress}%
                </span>
              </div>
            ) : (
              <div className="video" key={index}>
                <div className="video-container">
                  <Video
                    autoPlay
                    controls={[
                      "PlayPause",
                      "Seek",
                      "Time",
                      "Volume",
                      "Fullscreen",
                    ]}
                    src={url}
                    type="video/mp4"
                    style={{
                      width: "100%",
                      height: "100%",
                      position: "absolute",
                      top: 0,
                      left: 0,
                    }}
                  >
                    your browser does not support the <code>video </code>element
                  </Video>
                </div>
              </div>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default Library;
