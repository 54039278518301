import React from "react";
import Community from "../components/Student/community/Community";
import Sidebar from "../components/Student/Sidebar";
const CommunityScreen = () => {
  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-2">
            <Sidebar />
          </div>
          <div className="col-12 col-md-10 mt-5">
            {/* <video
              autoPlay
              muted
              loop
              id="vid-bg"
              src="https://res.cloudinary.com/dnuafmn2i/video/upload/v1681205190/ink-67358_1_knrgry.mp4"
            /> */}
            <div >
              <Community />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommunityScreen;
