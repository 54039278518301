import { useEffect, useState } from "react";
// import ProfileContext from '../../../Context/ProfileContext';
import Card from "./Card";
import { ScrollTop } from "primereact/scrolltop";

import axios from "../../../API/apiHandler";

const Courses = () => {
  const [CourseData, setCourseData] = useState([]);

  useEffect(() => {
    

    const fetchCourses = async () => {
      try {
        const token = JSON.parse(localStorage.getItem("fastapi"));
        const response = await axios.get(
          "/api/v1/courses/published",
          
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            withCredentials: true,
          }
        );
        const courses = response?.data;
        courses.sort((a, b) => b.id - a.id);
        setCourseData(courses);
      } catch (error) {}
    };
    fetchCourses();
    
  }, []);

  // const {
  // 	levelofstudy,
  // 	setLevelofstudy,
  // 	genareaofstudy,
  // 	setGenAreaofstudy,
  // 	specificAreaofstudy,
  // 	setSpecificAreaofstudy,
  // 	modeofstudy,
  // 	setModeofstudy,
  // } = useContext(ProfileContext);

  const [search, setSearch] = useState("");
  return (
    <div>
      <div className="container mt-5" style={{ overflow: "scroll" }}>
        <div className="d-flex flex-column">
          <div className="row mb-2">
            <div className="col">
              <h3>Enrol Now</h3>
            </div>
          </div>

          {/* <div className="row">
						<div className="col">
							<div className="d-flex gap-2 justify-content-evenly">
								<div>
									<label htmlFor="levelofstudy" className="col-form-label">Select level of study</label>
									<select className="form-select mb-3" aria-label="levelofstudy" value={levelofstudy}
										onChange={(e) => {
											setLevelofstudy(e.target.value);
										}}>
										<option defaultValue>Level Of Study</option>
										<option value="diploma">diploma</option>
										<option value="undergraduate">Undergraduate</option>
										<option value="masters">Masters</option>
										<option value="PHD">pHD</option>
									</select>
								</div>

								<div>
									<label htmlFor="areaofstudy" className="col-form-label">General area of study</label>
									<select className="form-select mb-3" aria-label="areaofstudy" value={genareaofstudy}
										onChange={(e) => { setGenAreaofstudy(e.target.value); }}>
										<option defaultValue>Area Of Study</option>
										<option value="computer science">Computer Science</option>
										<option value="engineering">Engineering</option>
										<option value="business">Business</option>
										<option value="law">Law</option>
									</select>
								</div>

								<div>
									<label htmlFor="specificareaofstudy" className="col-form-label">Select specific area of study</label>
									<select className="form-select mb-3" aria-label="specificareaofstudy" value={specificAreaofstudy} onChange={(e) => { setSpecificAreaofstudy(e.target.value); }}>
										<option defaultValue>Area Of Study</option>
										<option value="computer science">Computer Science</option>
										<option value="engineering">Engineering</option>
										<option value="business">Business</option>
										<option value="law">Law</option>
									</select>
								</div>

								<div>
									<label htmlFor="modeofstudy" className="col-form-label">Select mode of study</label>
									<select className="form-select mb-3" aria-label="modeofstudy" value={modeofstudy} onChange={(e) => { setModeofstudy(e.target.value); }}>
										<option defaultValue>Mode Of Study</option>
										<option value="fulltime">Full Time</option>
										<option value="parttime">Part Time</option>
									</select>
								</div>
							</div>
						</div>
					</div> */}
          <div className="row">
            <div className="col mt-4">
              {/* full width search box filter */}
              <div className="input-group mb-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search for courses"
                  aria-label="Search for courses"
                  aria-describedby="button-addon2"
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                />
                <button
                  className="btn btn-outline-secondary"
                  type="button"
                  id="button-addon2"
                >
                  Search
                </button>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12 mt-3" style={{ overflow: "scroll" }}>
              {CourseData?.length > 0 ? (
                CourseData.filter((fetchedCourse) => {
                  return search.toLowerCase() === ""
                    ? fetchedCourse
                    : fetchedCourse.course_full_name
                        .toLowerCase()
                        .includes(search.toLowerCase());
                }).map((fetchedCourse, index) => (
                  <div className="" key={index}>
                    <Card
                      id={fetchedCourse.id}
                      name={fetchedCourse.course_full_name}
                      course_code={fetchedCourse.course_code}
                      summary={fetchedCourse.course_summary}
                      short_desc={fetchedCourse.course_short_desc}
                      intake={fetchedCourse.course_first_offered}
                      cost={fetchedCourse.course_full_amount}
                      study_area={fetchedCourse.study_area}
                      duration={fetchedCourse.course_length}
                      accreditation={fetchedCourse.course_accreditation}
                      category={fetchedCourse.category.cat_name}
                      institution={fetchedCourse.institution.inst_name}
                    />
                  </div>
                ))
              ) : (
                <div className="text-danger mt-5 text-center">
                  {CourseData?.length === 0 ? (
                    <span>Loading...</span>
                  ) : (
                    <div className="d-flex align-items-center justify-content-center">
                      <span
                        className="spinner-border spinner-border-sm text-success"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      <span>Loading published courses....</span>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
        <ScrollTop
          target="parent"
          threshold={100}
          className="w-2rem h-2rem border-round bg-primary"
          icon="pi pi-arrow-up text-base"
        />
      </div>
    </div>
  );
};

export default Courses;
