import React from "react";
// import { StrictMode } from 'react';
import { createRoot } from "react-dom/client";
import { ProfileProvider } from "./Context/ProfileContext";
import { CartProvider } from "./Context/CartContext";
import ContextWrapper  from "./Context/ContextWrapper";
// import ErrorBoundary from './utils/auth/ErrorBoundary';
import { disableReactDevTools } from "@fvilers/disable-react-devtools";

import store, { } from "./Store/store";
import { Provider } from "react-redux";
// import { PersistGate } from 'redux-persist/integration/react'
import App from "./App";

import "./main.css";

disableReactDevTools();

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);
root.render(
   <Provider store={store}>
      {/* <PersistGate loading={null} persistor={persistor}> */}
         <ProfileProvider>
            <CartProvider>
               <ContextWrapper>
                  <App />
               </ContextWrapper>
            </CartProvider>
         </ProfileProvider>
      {/* </PersistGate> */}
   </Provider>
);
