import { useState, useEffect } from "react";
import { toast } from "react-hot-toast";
import { Link } from "react-router-dom";
import axios from "../../../API/apiHandler";

const FundApplications = () => {
  const [applications, setApplications] = useState([]);

  useEffect(() => {
    const fetchApplications = async () => {
      try {
        const token = JSON.parse(localStorage.getItem("fastapi"));
        const response = await axios.get(
          "/api/v1/funding/apply/applications",

          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        setApplications(response?.data);
      } catch (error) {
        if (error.response?.data.detail) {
          toast.error(error.response?.data.detail);
        }
      }
    };
    fetchApplications();
  }, []);

  const Accept = async (id) => {
    try {
      const token = JSON.parse(localStorage.getItem("fastapi"));
      const response = await axios.put(
        `/api/v1/funding/apply/${id}/approve?approved=true`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (response.data) {
        toast.success("Application accepted");
      }
    } catch (error) {
      if (error.response?.data.detail) {
        toast.error(error.response?.data.detail);
      }
    }
  };

  const Reject = async (id) => {
    try {
      const token = JSON.parse(localStorage.getItem("fastapi"));
      const response = await axios.put(`/api/v1/funding/apply/${id}/deny`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      if (response.data) {
        toast.success("Application rejected");
      }
    } catch (error) {
      if (error.response?.data.detail) {
        toast.error(error.response?.data.detail);
      }
    }
  };

  return (
    <div className="container-fluid mt-2">
      <div className="row">
        <div className="col-6 mt-2">
          <h5 className="text-sucess fs-6">Funding Applications</h5>
        </div>
        <div className="col-6">
          <div className="d-flex justify-content-end">
            <Link to="/funding" className="btn btn-sm btn-outline-primary">
              <i className="fas fa-arrow-left"></i> Back
            </Link>
          </div>
        </div>
      </div>

      <div className="row mt-2">
        <div className="col">
          <div className="table-responsive">
            <table className="table table-striped table-hover">
              <thead>
                <tr>
                  <th className="text-primary">Title</th>
                  <th>Amount</th>
                  <th>Applicant Name</th>
                  <th>Applicant Phone</th>
                  <th>Area of Funding</th>
                  <th>Eligibility</th>
                  <th>Deadline</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr></tr>
                {applications.length === 0 ? (
                  <tr>
                    <td colSpan="9" className="text-center">
                      No applications yet
                    </td>
                  </tr>
                ) : (
                  applications.map((application, idx) => (
                    <tr key={idx}>
                      <td>{application?.funding?.funding_title}</td>
                      <td>{application?.funding?.funding_amount}</td>
                      <td>{application?.applicant?.last_name}</td>
                      <td>{application?.applicant?.phone_number}</td>
                      <td>{application?.funding?.funding_area}</td>
                      <td>{application?.funding?.funding_eligible_entities}</td>
                      <td>{application?.funding?.funding_deadline_date}</td>
                      <td>
                        {application?.accepted === "true" ? (
                          <span className="badge bg-success">Approved</span>
                        ) : application?.accepted === "false" ? (
                          <span className="badge bg-danger">Rejected</span>
                        ) : (
                          <span className="badge bg-warning">Pending</span>
                        )}
                      </td>
                      <td>
                        <button
                          type="button"
                          onClick={() => {
                            Accept(application.id);
                          }}
                          className="btn btn-sm btn-primary"
                          disabled={application?.accepted === "true"}
                        >
                          Approve
                        </button>
                        <button
                          type="button"
                          className="btn btn-sm btn-danger"
                          onClick={() => {
                            Reject(application.id);
                          }}
                        >
                          Reject
                        </button>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FundApplications;
