import React from "react";
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import axios from "../../../API/apiHandler";

const FundDoc = () => {
  const [fundingDoc, setFundingDoc] = useState({
    funding_id: 0,
    doc_name: "",
    doc_required_status: "",
    status: true,
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFundingDoc((prevfundingDoc) => {
      return {
        ...prevfundingDoc,
        [name]: value,
      };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const fundingDocs = {
      funding_id: fundingDoc.funding_id,
      doc_name: fundingDoc.doc_name,
      doc_required_status: fundingDoc.doc_required_status,
      status: fundingDoc.status,
    };
    const id = fundingDoc.funding_id;

    try {
      const token = JSON.parse(localStorage.getItem("token"));
      const response = await axios.post(
        `/api/v1/funding/document/${id}`,
        fundingDocs,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 201 || response.status === 200) {
        toast.success("funding document added successfully");
        window.location.reload();
      }
    } catch (error) {
      toast.error("Error adding funding document");
    }
  };

  const [fetchedfundings, setFetchedfundings] = useState([]);

  useEffect(() => {
    

    const fetchfundings = async () => {
      try {
        const token = JSON.parse(localStorage.getItem("fastapi"));
        const response = await axios.get(
          "/api/v1/funding",
          
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setFetchedfundings(response?.data);
      } catch (error) {}
    };
    fetchfundings();
    
  }, []);

  return (
    <div>
      <div className="d-grid">
        <button
          type="button"
          className="btn btn-warning shadow"
          style={{
            boxShadow: "inset 2px 2px 6px #222",
            border: "2px solid #011",
          }}
          data-bs-toggle="modal"
          data-bs-target="#fundingdoc"
        >
          <span className="">
            <i className="fas fa-plus-circle"></i>
            Add funding Doc
          </span>
        </button>
      </div>

      <div
        className="modal fade"
        style={{ overflow: "scroll !important" }}
        id="fundingdoc"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="fundingdocLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg ">
          <div className="modal-content">
            <div className="modal-header">
              <h1
                className="modal-title text-primary fs-5"
                id="fundingdocLabel"
              >
                Add a funding Document
              </h1>
              <button
                type="button"
                className="btn-close text-dark"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="modalcontainer text-dark">
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col mb-2">
                      <div>
                        <span> Select a funding :</span>
                        <select
                          className="form-select"
                          aria-label="Default select"
                          onChange={handleChange}
                          required
                          value={fundingDoc.funding_id}
                          name="funding_id"
                        >
                          {fetchedfundings?.length ? (
                            fetchedfundings?.map((fund, index) => (
                              <option value={fund.id} key={index}>
                                {fund.funding_title}
                              </option>
                            ))
                          ) : (
                            <option>No funding yet</option>
                          )}
                        </select>
                      </div>

                      <div className="inputBox">
                        <span>Doc Name :</span>
                        <input
                          type="text"
                          placeholder=""
                          onChange={handleChange}
                          required
                          value={fundingDoc.doc_name}
                          name="doc_name"
                        />
                      </div>

                      <div className="inputBox">
                        <span>Doc Status :</span>
                        <select
                          className="form-select"
                          aria-label="Default select"
                          onChange={handleChange}
                          required
                          value={fundingDoc.doc_required_status}
                          name="doc_required_status"
                        >
                          <option value="true">Required</option>
                          <option value="false">Not Required</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <input type="submit" value="ADD" className="submit-btn" />
                </form>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-outline-danger text-dark"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FundDoc;
