// import { Fieldset } from "primereact/fieldset";
import Sidebar from "../Sidebar";
import { Link } from "react-router-dom";

const Support = () => {
  const handleClick = () => {
    window.location.href = "mailto:academy@acts-net.org";
  };

  return (
    <div>
      <div className="row fs-5">
        <div className="col-12 col-md-3 col-xl-2 bd-sidebar">
          <Sidebar />
        </div>
        <div className="col-12 col-md-9 col-xl-10 bd-content">
          <div className="container">
            <div className="row mt-5">
              <div className="col-12">
                <div className="row">
                  <div className="col mt-5">
                    <h1>Support-Contact</h1>
                    <div className="card w-100 shadow">
                      <div className="card-body">
                        <h5 className="card-title">Contact Us</h5>
                        <p className="card-text">
                          If you have any questions, please contact us at{" "}
                          <Link onClick={handleClick}>
                            academy@acts-net.org
                          </Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Support;
