
import AdminDash from '../../components/admin/dashboard/AdminDash'
import Nav from '../../components/admin/Nav'

const AdminDashScreen = () => {
	return (
		<div className="container-fluid">
			<div className="row">
				<div className="col-12 col-md-2">
					<Nav />
				</div>
				<div className="col-12 col-md-10 mt-5" style={{overflow: 'scroll'}}>
					<AdminDash />
				</div>
			</div>
		</div>
	)
}

export default AdminDashScreen
