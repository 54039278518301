import React from 'react'
import Nav from '../../../components/admin/Nav'
import Scholarship from '../../../components/admin/scholarship/Scholarship'

const ScholarshipScreen = () => {
	return (
		<div className='container-fluid'>
			<div className="row">
				<div className="col-12 col-md-2">
					<Nav />
				</div>
				<div className="col-12 col-md-10 mt-5">
					<Scholarship />
				</div>
			</div>
		</div>
	)
}

export default ScholarshipScreen
