import { useContext, useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";

import ProfileContext from "../../../../Context/ProfileContext";
import axios from "../../../../API/apiHandler";

const UserProfile = () => {
  const navigate = useNavigate();
  const {
    firstname,
    lastname,
    otherName,
    dob,
    gender,
    country,
    state,
    city,

    formArrays,
    formObjects,

    file,
    token,
    auth,
  } = useContext(ProfileContext);

  const [populate, setPopulate] = useState({});
  const Fname = populate?.first_name;
  const Lname = populate?.last_name;
  const Oname = populate?.other_names;
  const DOB = populate?.dob;
  const cty = populate?.city;
  const contry = populate?.country;
  const gend = populate?.gender;
  const stat = populate?.state;
  const user = populate?.user_type;

  useEffect(() => {
    

    const fetchUser = async () => {
      try {
        const res = await axios.get(
          `/api/v1/user/profile/${auth}`,
          
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setPopulate(res?.data);
      } catch (error) {}
    };

    fetchUser();
    
  }, [token, auth]);

  const [educate, setEducate] = useState([]);
  // const inst = educate.map((educ) => (educ[0]?.institution_name))
  // const area = educate.map((educ) => (educ[0]?.area_of_study))
  // const maj = educate.map((educ) => (educ[0]?.major))
  // const edstart = educate.map((educ) => (educ[0]?.start_date))
  // const edend = educate.map((educ) => (educ[0]?.end_date))
  // const grad = educate.map((educ) => (educ[0]?.grade))
  // const level = educate.map((educ) => (educ[0]?.level_of_study))
  // const mode = educate.map((educ) => (educ[0]?.mode_of_study))

  useEffect(() => {
    

    const EduDetails = async () => {
      try {
        const res = await axios.get(
          `/api/v1/user/profile/education/${auth}`,
          
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        setEducate(res?.data);
      } catch (error) {}
    };

    EduDetails();
    
  }, [token, auth]);

  const [exper, setExper] = useState([]);

  // const pos = exper.map((xpe, index) => (xpe[0]?.position))
  // const org = exper.map((xpe, index) => (xpe[0]?.organization))
  // const ind = exper.map((xpe, index) => (xpe[0]?.industry))
  // const field = exper.map((xpe, index) => (xpe[0]?.field_of_practice))
  // const report = exper.map((xpe, index) => (xpe[0]?.reporting_to))
  // const loc = exper.map((xpe, index) => (xpe[0]?.location))
  // const start = exper.map((xpe, index) => (xpe[0]?.start_date))
  // const end = exper.map((xpe, index) => (xpe[0]?.end_date))

  useEffect(() => {
    

    const ExpDetails = async () => {
      try {
        const res = await axios.get(
          `/api/v1/user/profile/experience/${auth}`,
          
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        setExper(res?.data);
      } catch (err) {}
    };

    ExpDetails();
    
  }, [token, auth]);

  return (
    <div className="container ">
      <div className="row mb-3">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-lg-3">
                  <LazyLoadImage
                    src={
                      file ||
                      "https://bootdey.com/img/Content/avatar/avatar7.png"
                    }
                    style={{ width: "120px", height: "120px" }}
                    alt="user"
                    className="rounded-circle"
                  />
                </div>
                <div className="col-lg-9 mt-2">
                  <div className="d-flex fs-6 flex-column gap-3 profile-head">
                    <h4>{user || "User"} Information</h4>
                    <h5>
                      {Fname || firstname}&#160;{Lname || lastname}
                    </h5>
                    <h6>Role: {user || "Student"}</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12 mb-3">
          <div className="card">
            <div className="card-body">
              <div className="table-responsive">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>First Name</th>
                      <th>Surname</th>
                      <th>Other</th>
                      <th>Date Of Birth</th>
                      <th>Gender</th>
                      <th>Country</th>
                      <th>State</th>
                      <th>City</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="mb-2">
                      <td>{Fname || firstname}</td>
                      <td>{Lname || lastname}</td>
                      <td>{Oname || otherName}</td>
                      <td>{DOB || dob}</td>
                      <td>{gend || gender}</td>
                      <td>{contry || country}</td>
                      <td>{stat || state}</td>
                      <td>{cty || city}</td>
                    </tr>
                  </tbody>

                  <thead>
                    <tr>
                      <th>Institution</th>
                      <th>Area of Study</th>
                      <th>Grade/Score</th>
                      <th>Level of Study</th>
                      <th>Start Date</th>
                      <th>End Date</th>
                      <th>Major/ Speciality</th>
                      <th>Mode Of Study</th>
                    </tr>
                  </thead>
                  <tbody>
                    {educate.length > 0
                      ? educate.map((educ, index) => (
                          <tr className="mb-2" key={index}>
                            <td>{educ?.institution_name}</td>
                            <td>{educ?.area_of_study}</td>
                            <td>{educ?.grade}</td>
                            <td>
                              {educ?.level_of_study === 1
                                ? "Doctoral Degree"
                                : educ?.level_of_study === 2
                                ? "Masters Degree"
                                : educ?.level_of_study === 3
                                ? "Bachelors Degree"
                                : educ?.level_of_study === 4
                                ? "Post graduate Diploma"
                                : educ?.level_of_study === 5
                                ? "Post graduate Certificate"
                                : educ?.level_of_study === 6
                                ? "Associate Degree"
                                : educ?.level_of_study === 7
                                ? "Higher Diploma"
                                : educ?.level_of_study === 8
                                ? "Diploma"
                                : educ?.level_of_study === 9
                                ? "Certificate"
                                : null}
                            </td>
                            <td>{educ?.start_date}</td>
                            <td>{educ?.end_date}</td>
                            <td>{educ?.major}</td>
                            <td>{educ?.mode_of_study}</td>
                          </tr>
                        ))
                      : formArrays.map((formArr, index) => (
                          <tr className="mb-2" key={index}>
                            <td>{formArr?.institution_name}</td>
                            <td>{formArr?.area_of_study}</td>
                            <td>{formArr?.grade}</td>
                            <td>
                              {formArr?.level_of_study === 1
                                ? "Doctoral Degree"
                                : formArr?.level_of_study === 2
                                ? "Masters Degree"
                                : formArr?.level_of_study === 3
                                ? "Bachelors Degree"
                                : formArr?.level_of_study === 4
                                ? "Post graduate Diploma"
                                : formArr?.level_of_study === 5
                                ? "Post graduate Certificate"
                                : formArr?.level_of_study === 6
                                ? "Associate Degree"
                                : formArr?.level_of_study === 7
                                ? "Higher Diploma"
                                : formArr?.level_of_study === 8
                                ? "Diploma"
                                : formArr?.level_of_study === 9
                                ? "Certificate"
                                : null}
                            </td>
                            <td>{formArr?.start_date}</td>
                            <td>{formArr?.end_date}</td>
                            <td>{formArr?.major}</td>
                            <td>{formArr?.mode_of_study}</td>
                          </tr>
                        ))}
                  </tbody>

                  <thead>
                    <tr>
                      <th>Company</th>
                      <th>Position</th>
                      <th>Industry</th>
                      <th>Field of practice</th>
                      <th>Reporting to?</th>
                      <th>Location</th>
                      <th>Start Date</th>
                      <th>End Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {exper.length > 0
                      ? exper.map((exp, idx) => (
                          <tr className="mb-2" key={idx}>
                            <td>{exp?.organization}</td>
                            <td>{exp?.position}</td>
                            <td>{exp?.industry}</td>
                            <td>{exp?.field_of_practice}</td>
                            <td>{exp?.reporting_to}</td>
                            <td>{exp?.location}</td>
                            <td>{exp?.start_date}</td>
                            <td>{exp?.end_date}</td>
                          </tr>
                        ))
                      : formObjects.map((formObj, idx) => (
                          <tr className="mb-2" key={idx}>
                            <td>{formObj?.organization}</td>
                            <td>{formObj?.position}</td>
                            <td>{formObj?.industry}</td>
                            <td>{formObj?.field_of_practice}</td>
                            <td>{formObj?.reporting_to}</td>
                            <td>{formObj?.location}</td>
                            <td>{formObj?.start_dat}</td>
                            <td>{formObj?.end_dat}</td>
                          </tr>
                        ))}
                  </tbody>
                </table>
              </div>
            </div>

            <div className="card-footer">
              <div className="d-flex justify-content-end gap-4">
                <Link
                  to="/cv"
                  className="btn btn-outline-primary w-25 text-dark"
                >
                  Edit
                </Link>
                <button
                  className="btn btn-outline-info w-25 text-dark p-2"
                  type="button"
                  onClick={() => navigate("/dashboard")}
                >
                  <h5>Proceed</h5>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserProfile;
