import { PieChart, Pie, Cell, ResponsiveContainer, Legend } from "recharts";

import { useState, useEffect } from "react";
// import { toast } from "react-hot-toast";

import axios from "../../../API/apiHandler";

const RADIAN = Math.PI / 180;
const COLORS = ["#00C49F", "green", "#FF8042", "#011"];

const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

export default function BuyerProfilePieChart() {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    

    try {
      const token = JSON.parse(localStorage.getItem("fastapi"));
      axios
        .get(
          "/api/v1/users",
          
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setUsers(res?.data);
        });
    } catch (error) {}
    
  }, []);

  // const genderCounts = users.reduce((counts, user) => {
  // 	const gender = user.gender;
  // 	if (gender === 'male') {
  // 		counts.male++;
  // 	} else if (gender === 'female') {
  // 		counts.female++;
  // 	} else if (gender !== null) {
  // 		counts.other++;
  // 	}
  // 	return counts;
  // }, { male: 0, female: 0, other: 0 });

  // const data = [
  // 	{ name: 'Male', value: genderCount.male },
  // 	{ name: 'Female', value: genderCount.female },
  // 	{ name: 'Other', value: genderCount.other }
  // ]

  const genderCounts = users.reduce(
    (counts, user) => {
      const gender = user.gender;
      if (gender === "male") {
        counts.male++;
      } else if (gender === "female") {
        counts.female++;
      } else if (gender === null) {
        counts.null++;
      } else {
        counts.other++;
      }
      return counts;
    },
    { male: 0, female: 0, other: 0, null: 0 }
  );

  const data = [
    { name: "Male", value: genderCounts.male },
    { name: "Female", value: genderCounts.female },
    { name: "Transgender", value: genderCounts.other },
    { name: "Undecided", value: genderCounts.null },
  ];

  return (
    <>
      <strong className="text-blue-900 fs-5">User Profile</strong>
      <ResponsiveContainer width="100%" aspect={3 / 2.4}>
        <PieChart width={400} height={300}>
          <Pie
            data={data}
            cx="50%"
            cy="45%"
            labelLine={false}
            label={renderCustomizedLabel}
            outerRadius={105}
            fill="#8884d8"
            dataKey="value"
          >
            {data.map((_, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>
          <Legend />
        </PieChart>
      </ResponsiveContainer>
    </>
  );
}
