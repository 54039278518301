// import { useState } from 'react'
import { toast } from 'react-hot-toast'
import axios from '../../../API/apiHandler'

const Publish = ({ uuid }) => {

	const handlePublish = async (e) => {
		e.preventDefault()
		const course_id = uuid
		const enrollable = true
		const status = true

		const publishData = {
			course_id,
			enrollable,
			status
		}
		try {
			const token = JSON.parse(localStorage.getItem('fastapi'))
			const res = await axios.post('/api/v1/courses/publish', publishData, {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`,
				}
			})
			if (res.status === 201 || res.status === 200) {
				toast.success('Course Published Successfully')
				window.location.reload()
			}

		} catch (error) {
			toast.error('failed!!')
		}
	}

	return (
		<div>
			<button onClick={handlePublish} type="button" className="btn btn-warning shadow">
				Publish
			</button>
		</div>
	)
}

export default Publish
