import { useState } from "react";
import { toast } from "react-hot-toast";
import axios from "../../../API/apiHandler";

const CourseCategory = () => {
  const [catForm, setCatForm] = useState({
    cat_name: "",
    cat_desc: "",
    cat_short_desc: "",
    parent_id: 0,
  });

  const handleChange = (e) => {
    setCatForm({
      ...catForm,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const cat_name = catForm.cat_name;
    const cat_desc = catForm.cat_desc;
    const cat_short_desc = catForm.cat_short_desc;
    const parent_id = catForm.parent_id;

    const catData = {
      cat_name,
      cat_desc,
      cat_short_desc,
      parent_id,
    };

    try {
      const token = JSON.parse(localStorage.getItem("fastapi"));
      axios
        .post("/api/v1/courses/categories", catData, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          toast.success("Category added successfully");
          window.location.reload();
        });
    } catch (error) {
      toast.error("Error adding category");
    }
  };

  return (
    <div>
      <div className="row mb-3">
        <div className="col">
          <div className="d-grid">
            <button
              type="button"
              className="btn btn-warning btn-block shadow"
              style={{
                boxShadow: "inset 2px 2px 6px #222",
                border: "2px solid #011",
              }}
              data-bs-toggle="modal"
              data-bs-target="#addcat"
            >
              <span className="fs-5">
                <i className="fas fa-plus-circle"></i> Course Category (1)
              </span>
            </button>
          </div>

          <div
            className="modal fade"
            style={{ overflow: "scroll !important" }}
            id="addcat"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex="-1"
            aria-labelledby="addcatLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-lg ">
              <div className="modal-content">
                <div className="modal-header">
                  <h1
                    className="modal-title text-blue-900 fs-5"
                    id="addcatLabel"
                  >
                    New Course category
                  </h1>
                  <button
                    type="button"
                    className="btn-close text-dark"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                  <div className="modalcontainer text-dark">
                    <form onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="col">
                          <div className="inputBox">
                            <span>Name :</span>
                            <input
                              type="text"
                              placeholder="Computer science"
                              onChange={handleChange}
                              required
                              value={catForm.cat_name}
                              name="cat_name"
                            />
                          </div>

                          <div className="inputBox mb-3">
                            <span>category summary :</span>
                            <textarea
                              className="form-control"
                              row="8"
                              name="cat_desc"
                              placeholder="Enter detailed description"
                              onChange={handleChange}
                              required
                              value={catForm.cat_desc}
                            />
                          </div>
                          <div className="inputBox">
                            <span>Short Description :</span>
                            <textarea
                              className="form-control mb-4"
                              row="4"
                              placeholder="Enter short description"
                              onChange={handleChange}
                              required
                              value={catForm.cat_short_desc}
                              name="cat_short_desc"
                            />
                          </div>
                        </div>
                      </div>

                      <input
                        type="submit"
                        value="Confirm"
                        className="submit-btn"
                      />
                    </form>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary text-dark"
                    data-bs-dismiss="modal"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseCategory;
