import { Modal, Button } from "react-bootstrap";
import React from "react";
// import "./style.css"
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { deleteEventApi, closeEvent } from "../../Store";
// import { useNavigate } from "react-router-dom";

const Popping = ({ open,ShowEventsApi, handleClose, event }) => {
  // const navigate = useNavigate();
  const {
    id,
    title,
    description,
    start_date,
    end_date,
    location,
    url,
    facilitators,
  } = event;

  const modal = () => {
    return (
      <Modal show={open} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="text-capitalize">{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {description ? (
            <p className="lead">{description}</p>
          ) : (
            "No Descriptions Yet"
          )}
          <div className="row justify-content-between">
            <p className="col small text-muted text-center pb-0 mb-0">
              from: {start_date}
            </p>
            <p className="col small text-muted text-center pb-0 mb-0">
              to: {end_date}
            </p>
          </div>
          <div className="justify-content-start gap-2 mt-3 d-flex flex-column align-items-center">
            <p className="small text-muted  pb-0 mb-0">Location: {location}</p>
            <p className="small text-muted  pb-0 mb-0">
              URL: <Link to={url}>{url}</Link>
            </p>
            <p className="small text-muted  pb-0 mb-0">
              Facilitators: {facilitators}
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="warning" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  if (id) {
    return modal();
  } else {
    <p>there is no modal to preview</p>;
  }
};

function mapStateToProps({ event }) {
  return {
    event,
    //  modalStatus
  };
}

export default connect(mapStateToProps, { deleteEventApi, closeEvent })(
  Popping
);
