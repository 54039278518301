import { configureStore } from '@reduxjs/toolkit';
import categoryReducer from './categorySlice';
import productReducer from './productSlice';
import cartReducer from './cartSlice';
// import searchReducer from "./searchSlice";
import EventReducer from './eventReducer';
import EventsReducer from './eventsReducer';
import modalReducer from './modelReducer';

const store = configureStore({
   reducer: {
      category: categoryReducer,
      product: productReducer,
      cart: cartReducer,
      // search: searchReducer,
      event: EventReducer,
      events: EventsReducer,
      modalStatus: modalReducer,
   },
});

export default store;
