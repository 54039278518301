import { useState, useEffect } from "react";
import { toast } from "react-hot-toast";
// import styled from "styled-components";

import axios from "../../../API/apiHandler";

const Update = ({ id, topic }) => {
  const [libraryResources, setLibraryResources] = useState({});
  const [topicForm, setTopicForm] = useState({
    unit_id: id,
    topic_full_name: "",
    topic_short_code: "",
    topic_summary: "",
    topic_short_desc: "",
    topic_total_points: 1,
    mandatory: false,
    parent_id: 0,
    topic_core_text: 0,
    topic_core_audio: 0,
    topic_core_video: 0,
  });

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    setTopicForm((prevTopicForm) => {
      return {
        ...prevTopicForm,
        [name]: type === "checkbox" ? checked : value,
      };
    });
  };

  useEffect(() => {
    setTopicForm({
      unit_id: topic.unit_id,
      topic_full_name: topic.topic_full_name,
      topic_short_code: topic.topic_short_code,
      topic_summary: topic.topic_summary,
      topic_short_desc: topic.topic_short_desc,
      topic_total_points: topic.topic_total_points,
      mandatory: topic.mandatory,
      parent_id: topic.parent_id,
      topic_core_text: topic.topic_core_text,
      topic_core_audio: topic.topic_core_audio,
      topic_core_video: topic.topic_core_video,
    });
  }, [topic]);

  // useEffect(() => {

  //   const fetchTopic = async () => {
  //     const token = JSON.parse(localStorage.getItem("fastapi"));
  //     try {
  //       const response = await axios.get(
  //         `/api/v1/units/${id}`,

  //         {
  //           headers: {
  //             "Content-Type": "application/json",
  //             Authorization: `Bearer ${token}`,
  //           },
  //         }
  //       );
  //       if (response?.status === 200 || response?.status === 201) {
  //         setTopicForm({ ...topicForm, ...response?.data?.topics });
  //       }

  //     } catch (err) {}
  //   };
  //   fetchTopic();

  // }, [id, topicForm]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const unit_id = topicForm.unit_id;
    const topic_full_name = topicForm.topic_full_name;
    const topic_short_code = topicForm.topic_short_code;
    const topic_summary = topicForm.topic_summary;
    const topic_short_desc = topicForm.topic_short_desc;
    const topic_total_points = topicForm.topic_total_points;
    const mandatory = topicForm.mandatory;
    const parent_id = topicForm.parent_id;
    const topic_core_text = topicForm.topic_core_text;
    const topic_core_audio = topicForm.topic_core_audio;
    const topic_core_video = topicForm.topic_core_video;

    const topicData = {
      unit_id,
      topic_full_name,
      topic_short_code,
      topic_summary,
      topic_short_desc,
      topic_total_points,
      mandatory,
      parent_id,
      topic_core_text,
      topic_core_audio,
      topic_core_video,
    };

    try {
      const token = JSON.parse(localStorage.getItem("fastapi"));
      const response = await axios.put(
        `/api/v1/units/topics/${topic?.id}`,
        topicData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200 || response.status === 201) {
        toast.success("Topic updated ");
        window.location.reload();
      }
    } catch (err) {}
  };

  useEffect(() => {
    const fetchLibraryResources = async () => {
      const token = JSON.parse(localStorage.getItem("fastapi"));
      try {
        const response = await axios.get(`/api/v1/library/institution/${1}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        if (response?.status === 200 || response?.status === 201) {
          setLibraryResources(response?.data);
        }
      } catch (err) {}
    };

    fetchLibraryResources();
  }, []);

  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className=" mt-2">
              <button
                type="button"
                className="btn btn-warning py-3"
                data-bs-toggle="modal"
                data-bs-target="#updatetopic"
              >
                Update
              </button>

              <div
                className="modal fade"
                style={{ overflow: "scroll !important" }}
                id="updatetopic"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex="-1"
                aria-labelledby="updatetopicLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-lg ">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h1
                        className="modal-title text-success fs-5"
                        id="addschoolLabel"
                      >
                        Update topic details
                      </h1>
                      <button
                        type="button"
                        className="btn-close text-primary"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-body">
                      <div className="modalcontainer text-dark">
                        <form onSubmit={handleSubmit}>
                          <div className="row">
                            <div className="col">
                              <div>
                                <label
                                  className="form-label mt-3"
                                  htmlFor="topic_core_video"
                                >
                                  Core Video
                                </label>
                                <select
                                  className=" form-select"
                                  aria-label="Default select"
                                  onChange={handleChange}
                                  required
                                  value={topicForm.topic_core_video}
                                  name="topic_core_video"
                                >
                                  <option>Select video</option>
                                  {libraryResources?.length > 0 ? (
                                    libraryResources?.map(
                                      (resource, index) =>
                                        resource.resource_type ===
                                          "video/mp4" && (
                                          <option
                                            value={resource.id}
                                            key={index}
                                          >
                                            {resource.resource_title}{" "}
                                          </option>
                                        )
                                    )
                                  ) : (
                                    <option>No video found</option>
                                  )}
                                </select>
                              </div>

                              <div>
                                <label
                                  className="form-label mt-3"
                                  htmlFor="topic_core_audio"
                                >
                                  Core Audio
                                </label>
                                <select
                                  className=" form-select"
                                  aria-label="Default select"
                                  onChange={handleChange}
                                  required
                                  value={topicForm.topic_core_audio}
                                  name="topic_core_audio"
                                >
                                  <option>Select Audio</option>
                                  {libraryResources?.length > 0 ? (
                                    libraryResources.map(
                                      (resource, index) =>
                                        resource.resource_type ===
                                          "audio/mp3" && (
                                          <option
                                            value={resource.id}
                                            key={index}
                                          >
                                            {resource.resource_title}{" "}
                                          </option>
                                        )
                                    )
                                  ) : (
                                    <option>No Audio yet</option>
                                  )}
                                </select>
                              </div>

                              <div>
                                <label
                                  className="form-label mt-3"
                                  htmlFor="topic_core_text"
                                >
                                  Core Text
                                </label>
                                <select
                                  className=" form-select"
                                  aria-label="Default select"
                                  onChange={handleChange}
                                  required
                                  value={topicForm.topic_core_text}
                                  name="topic_core_text"
                                >
                                  <option>Select document</option>
                                  {libraryResources?.length > 0 ? (
                                    libraryResources.map(
                                      (resource, index) =>
                                        resource.resource_type ===
                                          "application/pdf" && (
                                          <option
                                            value={resource.id}
                                            key={index}
                                          >
                                            {resource.resource_title}{" "}
                                          </option>
                                        )
                                    )
                                  ) : (
                                    <option>No documents yet</option>
                                  )}
                                </select>
                              </div>

                              <div className="inputBox">
                                <span>topic name :</span>
                                <input
                                  type="text"
                                  placeholder="Suppot vector"
                                  onChange={handleChange}
                                  required
                                  value={topicForm.topic_full_name}
                                  name="topic_full_name"
                                />
                              </div>

                              <div className="inputBox">
                                <span>topic code :</span>
                                <input
                                  type="text"
                                  placeholder="COM 2301"
                                  onChange={handleChange}
                                  required
                                  value={topicForm.topic_short_code}
                                  name="topic_short_code"
                                />
                              </div>
                            </div>

                            <div className="inputBox mb-3">
                              <span>Summary :</span>
                              <textarea
                                className="form-control"
                                row="6"
                                name="topic_summary"
                                placeholder="Enter detailed description"
                                onChange={handleChange}
                                required
                                value={topicForm.topic_summary}
                              />
                            </div>
                            <div className="inputBox">
                              <span>Short Descrition :</span>
                              <textarea
                                className="form-control mb-4"
                                row="8"
                                placeholder="Enter short description"
                                onChange={handleChange}
                                required
                                value={topicForm.topic_short_desc}
                                name="topic_short_desc"
                              />
                            </div>

                            <div className="form-check d-flex justify-content-center mb-4">
                              <input
                                className="form-check-input me-2"
                                type="checkbox"
                                id="mandatory"
                                name="mandatory"
                                checked={topicForm.mandatory}
                                onChange={handleChange}
                              />
                              <label
                                htmlFor="mandatory"
                                className="form-check-label"
                              >
                                Mandatory
                              </label>
                            </div>
                          </div>

                          <input
                            type="submit"
                            value="Update"
                            className="submit-btn"
                          />
                        </form>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-secondary text-dark"
                        data-bs-dismiss="modal"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Update;
