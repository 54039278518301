import React, { useState, useEffect, useRef } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import axios from "../../../API/apiHandler";

export default function LongContentDemo() {
  const toast = useRef(null);
  const showSuccess = () => {
    toast.current.show({
      severity: "success",
      summary: "Success",
      detail: "Added Successfully",
      life: 4000,
    });
  };
  const showError = () => {
    toast.current.show({
      severity: "error",
      summary: "Error",
      detail: "Oops!! something went wrong",
      life: 4000,
    });
  };
  const [Institutions, setInstitutions] = useState();

  const [instructorForm, setInstructorForm] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    phone_number: "",
    department: "",
    inst_id: 1,
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setInstructorForm((prevInstructorForm) => {
      return {
        ...prevInstructorForm,
        [name]: value,
      };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const first_name = instructorForm.first_name;
    const last_name = instructorForm.last_name;
    const email = instructorForm.email;
    const password = instructorForm.password;
    const phone_num = instructorForm.phone_number;
    const department = instructorForm.department;
    const inst_id = instructorForm.inst_id;
    const phone_number = "+" + phone_num;

    const instructorData = {
      first_name,
      last_name,
      email,
      password,
      phone_number,
      department,
      inst_id,
    };

    try {
      const token = JSON.parse(localStorage.getItem("fastapi"));
      const response = await axios.post(
        "/api/v1/users/instructors",
        instructorData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      showSuccess();
      setInstructorForm(response?.data);
    } catch (error) {
      showError();
    }
  };

  useEffect(() => {
    
    
    const token = JSON.parse(localStorage.getItem("fastapi"));
    axios
      .get("/api/v1/institutions", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      })
      .then((response) => {
        setInstitutions(response?.data);
      });
      
    
  }, []);

  const [visible, setVisible] = useState(false);
  const footerContent = (
    <div>
      <Button
        label="Cancel"
        icon="pi pi-times"
        onClick={() => setVisible(false)}
        className="p-button-text"
      />
      {/* <Button type='submit' label="Confirm" icon="pi pi-check" onClick={handleSubmit} autoFocus /> */}
    </div>
  );

  return (
    <div className="card flex justify-content-center">
      <Toast ref={toast} />
      <Button
        label="Add Instructor"
        icon="pi pi-users"
        onClick={() => setVisible(true)}
      />
      <Dialog
        header="Create Instructor"
        visible={visible}
        style={{ width: "50vw" }}
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}
        onHide={() => setVisible(false)}
        footer={footerContent}
      >
        <div className="my-3">
          <div className=" text-dark">
            <form onSubmit={handleSubmit} className="mx-1 mx-md-4">
              <div className="d-flex flex-row align-items-center">
                <i className="fas fa-user fa-lg me-3 fa-fw"></i>
                <div className="form-outline flex-fill mb-0">
                  <label htmlFor="institution" className="col-form-label">
                    Select Institution
                  </label>

                  <select
                    className="form-select mb-3"
                    name="inst_id"
                    value={instructorForm.inst_id}
                    onChange={handleChange}
                  >
                    {Institutions?.map((institution, index) => (
                      <option
                        className="text-danger bg-secondary"
                        key={index}
                        value={institution.id}
                      >
                        {institution.inst_name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="d-flex flex-row align-items-center mb-3">
                <i className="fas fa-user fa-lg me-3 fa-fw"></i>
                <div className="form-outline flex-fill mb-0">
                  <label className="form-label" htmlFor="first-name">
                    First Name
                  </label>
                  <input
                    type="text"
                    id="first-name"
                    autoComplete="off"
                    className="form-control"
                    name="first_name"
                    required
                    onChange={handleChange}
                    value={instructorForm.first_name}
                  />
                </div>
              </div>

              <div className="d-flex flex-row align-items-center mb-3">
                <i className="fas fa-user fa-lg me-3 fa-fw"></i>
                <div className="form-outline flex-fill mb-0">
                  <label className="form-label" htmlFor="last-name">
                    Last Name
                  </label>
                  <input
                    type="text"
                    id="last-name"
                    autoComplete="off"
                    name="last_name"
                    onChange={handleChange}
                    required
                    value={instructorForm.last_name}
                    className="form-control"
                  />
                </div>
              </div>

              <div className="d-flex flex-row align-items-center mb-3">
                <i className="fas fa-envelope fa-lg me-3 fa-fw"></i>
                <div className="form-outline flex-fill mb-0">
                  <label className="form-label" htmlFor="email">
                    His/Her Email
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    onChange={handleChange}
                    autoComplete="off"
                    value={instructorForm.email}
                    required
                    className="form-control"
                  />
                </div>
              </div>

              <div className="d-flex flex-row align-items-center mb-3">
                <i className="fas fa-lock fa-lg me-3 fa-fw"></i>
                <div className="form-outline flex-fill mb-0">
                  <label className="form-label" htmlFor="password">
                    Password
                  </label>
                  <input
                    type="password"
                    id="password"
                    name="password"
                    onChange={handleChange}
                    value={instructorForm.password}
                    className="form-control"
                    required
                  />
                </div>
              </div>

              <div className="d-flex flex-row align-items-center mb-3">
                <i className="fas fa-phone fa-lg me-3 fa-fw"></i>
                <div className="form-outline flex-fill mb-0">
                  <label className="form-label" htmlFor="phone_number">
                    Phone
                  </label>

                  <PhoneInput
                    country={"ke"}
                    id="phone_number"
                    name="phone_number"
                    onChange={handleChange}
                    autoComplete="off"
                    placeholder="enter a valid mobile number"
                    value={instructorForm.phone_number}
                    required
                  />
                </div>
              </div>

              <div className="d-flex flex-row align-items-center mb-3">
                <i className="fas fa-phone fa-lg me-3 fa-fw"></i>
                <div className="form-outline flex-fill mb-0">
                  <label className="form-label" htmlFor="phone-number">
                    Department
                  </label>
                  <input
                    type="text"
                    id="phone-number"
                    name="department"
                    onChange={handleChange}
                    autoComplete="off"
                    value={instructorForm.department}
                    className="form-control"
                    required
                  />
                </div>
              </div>

              <div className="d-flex justify-content-center">
                <Button
                  type="submit"
                  label="Confirm"
                  icon="pi pi-check"
                  onClick={handleSubmit}
                  autoFocus
                  className="text-warning"
                />
              </div>
            </form>
          </div>
        </div>
      </Dialog>
    </div>
  );
}
