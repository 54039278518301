import { TabView, TabPanel } from "primereact/tabview";
import { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
// import CryptoJS from 'crypto-js';
import { toast } from "react-toastify";

import axios from "../../../API/apiHandler";
import ProfileContext from "../../../Context/ProfileContext";
// import Class from "./Class";

const StudyHub = () => {
  const [courses, setCourses] = useState([]);
  // const [courses, setcourses] = useState([]);
  const { auth } = useContext(ProfileContext);

  useEffect(() => {
    const fetchEnrolled = async () => {
      const token = JSON.parse(localStorage.getItem("fastapi"));
      try {
        const response = await axios.get(
          `/api/v1/studyhub/course/applications/${auth}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        // Filter out the courses where subscribed is "True" as a string
        const filteredCourses = response?.data?.filter(
          (course) => course?.application_details?.subscribed === "True"
        );

        if (response?.data) {
          setCourses(filteredCourses);
        }

        if (response) {
          setCourses(filteredCourses);
        }
      } catch (error) {}
    };

    fetchEnrolled();
  }, [auth]);

  const token = JSON.parse(localStorage.getItem("fastapi"));

  // useEffect(() => {
  //   const fetchPublishedCourse = async () => {
  //     try {
  //       const token = JSON.parse(localStorage.getItem("fastapi"));
  //       const requests = courses.map(async (course) => {
  //         const id = course?.course?.id;
  //         const response = await axios.get(`/api/v1/courses/${id}/published`, {
  //           headers: {
  //             "Content-Type": "application/json",
  //             Authorization: `Bearer ${token}`,
  //           },
  //         });
  //         return response?.data;
  //       });
  //       const courses = await Promise.all(requests);
  //       setcourses(courses);
  //     } catch (error) {
  //       if (error.response?.data.detail) {
  //         toast.error(error.response?.data.detail);
  //       }
  //     }
  //   };

  //   fetchPublishedCourse();
  // }, [courses]);

  const [unitProgress, setUnitProgress] = useState({});

  useEffect(() => {
    const fetchUnitProgress = async () => {
      if (!courses || courses.length === 0) {
        return;
      }

      try {
        const unitPromises = [];
        for (const course of courses) {
          for (const unit of course?.units) {
            const id = unit?.id;
            if (!id) {
              // If unit.id is undefined or null, skip this iteration
              continue;
            }
            try {
              const response = await axios.get(
                `/api/v1/course_progress/get-unit-progress/${auth}/${id}`,
                {
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                  },
                }
              );
              unitPromises.push(response.data);
            } catch (error) {
              // Handle errors if needed
              console.error(`Error fetching progress for unit ${id}:`, error);
            }
          }
        }

        const unitProgressData = await Promise.all(unitPromises);
        setUnitProgress(unitProgressData);
      } catch (error) {
        // Handle errors if needed
        console.error("Error fetching unit progress data:", error);
      }
    };

    fetchUnitProgress();
  }, [courses, token, auth]);

  // course progress
  // useEffect(() => {
  //   const checkCourseCompletion = async () => {
  //     if (!courses) {
  //       return;
  //     }

  //     try {
  //       // fetch progress for each unit
  //       const progressPromises = courses?.flatMap((course) =>
  //         course?.units.map(async (unit) => {
  //           try {
  //             const response = await axios.get(
  //               `/api/v1/course_progress/get-unit-progress/${auth}/${unit?.id}`,
  //               {
  //                 headers: {
  //                   "Content-Type": "application/json",
  //                   Authorization: `Bearer ${token}`,
  //                 },
  //               }
  //             );
  //             return response.data; // Return the response data
  //           } catch (error) {
  //             // Handle errors if needed
  //             console.error(
  //               `Error fetching progress for unit ${unit.id}:`,
  //               error
  //             );
  //             return null;
  //           }
  //         })
  //       );

  //       const progressResponses = await Promise.all(progressPromises);
  //       const progressData = progressResponses.map((response) => response.data);
  //       const allUnitsComplete = progressData.every(
  //         (response) => response?.user_unit_progress === 100
  //       );

  //       // const progressResponses = await Promise.all(progressPromises);
  //       // const allUnitsComplete = progressResponses.every(
  //       //   (response) => response?.user_unit_progress === 100
  //       // );

  //       if (allUnitsComplete) {
  //         if (!courses) {
  //           return;
  //         }
  //         const course_id = courses?.course_details?.id;
  //         console.log("course_id", course_id)
  //         // const date_completed = new Date().toISOString();
  //         const data = {
  //           course_id,
  //           user_course_progress: 100,
  //         };

  //         try {
  //           const res = await axios.post(
  //             "/api/v1/course_progress/course-progress",
  //             data,
  //             {
  //               headers: {
  //                 "Content-Type": "application/json",
  //                 Authorization: `Bearer ${token}`,
  //               },
  //             }
  //           );

  //           await axios.get(
  //             `/api/v1/course_progress/certificates/${auth}/${course_id}`,
  //             {
  //               headers: {
  //                 "Content-Type": "application/json",
  //                 Authorization: `Bearer ${token}`,
  //               },
  //             }
  //           );

  //           if (res) {
  //             toast.success("Course marked as complete");
  //             window.location.reload();
  //           }
  //         } catch (error) {
  //           toast.error(`Error marking course as complete`);
  //         }
  //       }
  //     } catch (error) {
  //       toast.error(`Oops! Something unexpected happened`);
  //       console.error("error", error);
  //     }
  //   };

  //   checkCourseCompletion();
  // }, [courses, auth, token]);

  useEffect(() => {
    const checkCourseCompletion = async () => {
      if (!courses) {
        return;
      }

      try {
        // Fetch progress data for all units
        const progressPromises = courses.flatMap((course) =>
          course.units.map(async (unit) => {
            try {
              const response = await axios.get(
                `/api/v1/course_progress/get-unit-progress/${auth}/${unit?.id}`,
                {
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                  },
                }
              );
              return response.data; // Return the response data
            } catch (error) {
              console.error(
                `Error fetching progress for unit ${unit.id}:`,
                error
              );
              return null;
            }
          })
        );

        const progressResponses = await Promise.all(progressPromises);
        const allUnitsComplete = progressResponses.every(
          (response) => response?.user_unit_progress === 100
        );

        if (allUnitsComplete) {
          for (const course of courses) {
            const course_id = course.course_details.id;
            console.log("Mark course as complete for course_id", course_id);
            const data = {
              course_id,
              user_course_progress: 100,
            };

            try {
              const res = await axios.post(
                "/api/v1/course_progress/course-progress",
                data,
                {
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                  },
                }
              );

              await axios.get(
                `/api/v1/course_progress/certificates/${auth}/${course_id}`,
                {
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                  },
                }
              );

              if (res) {
                toast.success("Course marked as complete");
                window.location.reload();
              }
            } catch (error) {}
          }
        }
      } catch (error) {
        toast.error("Oops! Something unexpected happened");
        console.error("error", error);
      }
    };

    checkCourseCompletion();
  }, [courses, auth, token]);

  return (
    <div>
      {courses?.length ? (
        <div className="p-5">
          <TabView scrollable>
            {courses &&
              courses.map((course, index) => {
                return (
                  <TabPanel
                    header={course?.course_details?.course_full_name}
                    key={index}
                  >
                    <div className=" container-fluid p-5">
                      <div className="row my-2">
                        <div className="col-md-12">
                          <h3 className="text-center fw-bold fs-5 mb-2">
                            {course?.course_details?.course_full_name}
                          </h3>
                          <h3 className="text-center fs-6 fw-bold mb-2">
                            Length: {course?.course_details?.course_length}
                          </h3>
                          {/* <small>{course?.course?.course_summary}</small> */}
                          <small
                            className="text-black"
                            style={{ whiteSpace: "pre-wrap" }}
                            dangerouslySetInnerHTML={{
                              __html: course?.course_details?.course_summary,
                            }}
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-12 mb-3">
                          <div className="card">
                            <div className="card-body">
                              <div className="table-responsive">
                                <table
                                  className="table table-striped data-table"
                                  style={{ width: "100%", overflowX: "scroll" }}
                                >
                                  <thead>
                                    <tr>
                                      <th></th>
                                      <th>Unit Title</th>
                                      <th>Unit Code</th>
                                      <th>Instructor</th>
                                      <th>Progress</th>
                                      <th>Notification</th>
                                      <th>Class</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {course?.units?.length ? (
                                      course?.units.map((unit) => {
                                        const progress =
                                          unitProgress?.length &&
                                          unitProgress.find(
                                            (progress) =>
                                              progress?.unit_id === unit?.id
                                          );

                                        return (
                                          <tr key={unit.id}>
                                            <td>
                                              {progress &&
                                              progress.user_unit_progress ===
                                                100 ? (
                                                <div className="form-check">
                                                  <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    id="complete"
                                                    name="complete"
                                                    defaultChecked
                                                    disabled
                                                  />
                                                </div>
                                              ) : (
                                                <div className="form-check">
                                                  <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    id="complete"
                                                    name="complete"
                                                    disabled
                                                  />
                                                </div>
                                              )}
                                            </td>
                                            <td>{unit?.unit_full_name}</td>
                                            <td>{unit?.unit_short_code}</td>
                                            <td>{unit?.unit_instructor}</td>
                                            <td>
                                              {progress ? (
                                                <div
                                                  className="progress"
                                                  style={{ height: "9px" }}
                                                >
                                                  <div
                                                    className="progress-bar"
                                                    style={{
                                                      width: `${progress?.user_unit_progress}%`,
                                                      height: "10px",
                                                    }}
                                                  ></div>
                                                  <small>
                                                    {
                                                      progress.user_unit_progress
                                                    }
                                                    %
                                                  </small>
                                                </div>
                                              ) : (
                                                <div>No Progress</div>
                                              )}
                                            </td>
                                            <td>
                                              {progress &&
                                              progress.user_unit_progress ===
                                                100 ? (
                                                <div className="badge bg-success">
                                                  Completed
                                                </div>
                                              ) : (
                                                <div className="badge bg-orange-500">
                                                  In Progress
                                                </div>
                                              )}
                                            </td>
                                            <td>
                                              <Link
                                                className="btn btn-outline-primary btn-sm"
                                                to={`/studyhub/class/${unit.id}`}
                                              >
                                                Class
                                              </Link>
                                            </td>
                                          </tr>
                                        );
                                      })
                                    ) : (
                                      <tr>
                                        <td colSpan="7">
                                          <div className="text-center mt-3 fs-6 text-danger">
                                            No Units found, Contact Admin
                                          </div>
                                        </td>
                                      </tr>
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </TabPanel>
                );
              })}
          </TabView>
        </div>
      ) : (
        <div className="row mt-5">
          <div className="col-md-12 mb-3">
            <div className="card">
              <div className="card-body">
                <div className="table-responsive">
                  <table
                    className="table table-striped data-table"
                    style={{ width: "100%" }}
                  >
                    <thead>
                      <tr>
                        <th></th>
                        <th>Unit Title</th>
                        <th>Unit Code</th>
                        <th>Instructor</th>
                        <th>Progress</th>
                        <th>Notification</th>
                        <th>Class</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td colSpan="7">
                          <div className="text-center mt-3 fs-6 text-success">
                            You haven't enrolled to any courses yet
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="d-flex justify-content-center p-2">
                <div className="spinner-border text-primary" role="status">
                  <span className="">...</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default StudyHub;
