import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useParams } from "react-router-dom";

import axios from "../../../API/apiHandler";
import Card from "./Card";
import CourseUnit from "./CourseUnit";

const Units = () => {
  const { id } = useParams();

  const [fetchedInstructors, setFetchedInstructors] = useState({});

  const [fetchedUnits, setFetchedUnits] = useState([]);

  const [unitForm, setUnitForm] = useState({
    inst_id: 1,
    unit_full_name: "",
    unit_short_code: "",
    unit_summary: "",
    status: true,
    unit_short_desc: "",
    unit_instructor: 0,
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setUnitForm((prevUnitForm) => {
      return {
        ...prevUnitForm,
        [name]: value,
      };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const inst_id = unitForm.inst_id;
    const unit_full_name = unitForm.unit_full_name;
    const unit_short_code = unitForm.unit_short_code;
    const unit_summary = unitForm.unit_summary;
    const status = unitForm.status;
    const unit_short_desc = unitForm.unit_short_desc;
    const unit_instructor = unitForm.unit_instructor;

    const unitData = {
      inst_id,
      unit_full_name,
      unit_short_code,
      unit_summary,
      status,
      unit_short_desc,
      unit_instructor,
    };

    try {
      const token = JSON.parse(localStorage.getItem("fastapi"));
      const response = await axios.post("/api/v1/units", unitData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      });

      if (response.status === 201 || response.status === 200) {
        toast.success("Unit added successfully");
        window.location.reload();
      }
    } catch (error) {
      toast.error("Error adding unit");
    }
  };

  useEffect(() => {
    

    const fetchUnits = async () => {
      const token = JSON.parse(localStorage.getItem("fastapi"));
      try {
        const response = await axios.get(
          "/api/v1/units",
          
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setFetchedUnits(response?.data);
      } catch (error) {}
    };
    fetchUnits();
    
  }, []);

  useEffect(() => {
    

    const fetchInstructors = async () => {
      const token = JSON.parse(localStorage.getItem("fastapi"));
      const response = await axios.get(
        `/api/v1/users/instructors/${1}`,
        
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setFetchedInstructors(response?.data);
    };

    (async () => {
      await fetchInstructors();
    })();
    
  }, []);

  const [CourseData, setCourseData] = useState({});

  useEffect(() => {
    

    const fetchCourses = async () => {
      try {
        const token = JSON.parse(localStorage.getItem("fastapi"));
        const response = await axios.get(
          `/api/v1/courses/${id}`,
          
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            withCredentials: true,
          }
        );
        setCourseData(response?.data);
      } catch (error) {}
    };

    fetchCourses();
    
  }, [id]);

  const name = CourseData?.course?.course_full_name;

  return (
    <div>
      <div className="container mt-5">
        <div className="row mb-3">
          <div className="col-12">
            <h4>{name} - Units</h4>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <div className="col">
              <div className="d-flex justify-content-evenly">
                <div className="d-grid">
                  <button
                    type="button"
                    className="btn btn-warning btn-block shadow"
                    style={{
                      boxShadow: "inset 2px 2px 6px #222",
                      border: "2px solid #011",
                    }}
                    data-bs-toggle="modal"
                    data-bs-target="#addunit"
                  >
                    <span className="fs-5">Add new Unit</span>
                  </button>
                </div>
                <CourseUnit />
              </div>

              <div
                className="modal fade"
                style={{ overflow: "scroll !important" }}
                id="addunit"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex="-1"
                aria-labelledby="addunitLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-lg ">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h1
                        className="modal-title text-success fs-5"
                        id="addschoolLabel"
                      >
                        New Unit details
                      </h1>
                      <button
                        type="button"
                        className="btn-close text-dark"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-body">
                      <div className="modalcontainer text-dark">
                        <form onSubmit={handleSubmit}>
                          <div className="row">
                            <div className="col">
                              <div className="inputBox">
                                <span>unit name :</span>
                                <input
                                  type="text"
                                  placeholder="machine learning"
                                  onChange={handleChange}
                                  required
                                  value={unitForm.unit_full_name}
                                  name="unit_full_name"
                                />
                              </div>

                              <div className="inputBox">
                                <span>unit summary :</span>
                                <input
                                  type="text"
                                  placeholder="summary"
                                  onChange={handleChange}
                                  required
                                  value={unitForm.unit_summary}
                                  name="unit_summary"
                                />
                              </div>

                              <div className="flex">
                                <div className="inputBox">
                                  <span>Unit short description :</span>
                                  <input
                                    type="text"
                                    placeholder="brief details"
                                    onChange={handleChange}
                                    required
                                    value={unitForm.unit_short_desc}
                                    name="unit_short_desc"
                                  />
                                </div>

                                <div className="inputBox">
                                  <span>unit code :</span>
                                  <input
                                    type="text"
                                    placeholder="COM 2301"
                                    onChange={handleChange}
                                    required
                                    value={unitForm.unit_short_code}
                                    name="unit_short_code"
                                  />
                                </div>
                              </div>

                              <div>
                                <label
                                  className="form-label mt-2"
                                  htmlFor="unit_instructor"
                                >
                                  Unit Instructor
                                </label>
                                <select
                                  className=" form-select"
                                  aria-label="Default select"
                                  onChange={handleChange}
                                  required
                                  value={unitForm.unit_instructor}
                                  name="unit_instructor"
                                >
                                  <option>Select Unit Intructor</option>
                                  {fetchedInstructors?.length > 0 ? (
                                    fetchedInstructors?.map(
                                      (instructor, index) => (
                                        <option
                                          value={instructor.id}
                                          key={index}
                                        >
                                          {instructor.first_name}{" "}
                                          {instructor.last_name}
                                        </option>
                                      )
                                    )
                                  ) : (
                                    <option>No Instructors yet</option>
                                  )}
                                </select>
                              </div>
                            </div>
                          </div>

                          <input
                            type="submit"
                            value="ADD"
                            className="submit-btn mt-4"
                          />
                        </form>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-secondary  text-dark"
                        data-bs-dismiss="modal"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container mt-3">
          <div className="d-flex flex-wrap gap-3">
            {fetchedUnits?.length > 0 ? (
              fetchedUnits?.map((fetchedunit, index) => (
                <div className="" key={index}>
                  <Card
                    title={fetchedunit.unit_full_name}
                    code={fetchedunit.unit_short_code}
                    short_desc={fetchedunit.unit_short_desc}
                    instructor={fetchedunit.unit_instructor}
                    id={fetchedunit.id}
                    institution={fetchedunit.institution.inst_name}
                    unit={fetchedunit}
                  />
                </div>
              ))
            ) : (
              <div className="text-danger mt-5 text-center">
                <span>No units yet...</span>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Units;
