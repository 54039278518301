import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import styled from "styled-components";
import { toast } from "react-hot-toast";

import { MdInfo } from "react-icons/md";
import { TbWorld } from "react-icons/tb";
import { FaShoppingCart } from "react-icons/fa";
import { RiClosedCaptioningFill } from "react-icons/ri";
//import { BiCheck } from "react-icons/bi";

import axios from "../../../API/apiHandler";
// import { useCartContext } from '../../../Context/CartContext'

import image from "../../../images/acts-logo@1x.png";

const ShopDetails = () => {
  const { id } = useParams();
  // const { addToCart } = useCartContext()

  const [fetchedShops, setFetchedShops] = useState([]);

  useEffect(() => {
    
    const fetchShop = async () => {
      try {
        const token = JSON.parse(localStorage.getItem("fastapi"));
        const res = await axios.get(
          `/api/v1/shop/${id}`,
          
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        setFetchedShops(res?.data);
      } catch (error) {}
    };

    fetchShop();
    
  }, [id]);

  const qty = fetchedShops?.item_qty;
  const name = fetchedShops?.item_name;
  const price = fetchedShops?.item_price;
  const model = fetchedShops?.item_model_no;
  const manufacturer = fetchedShops?.item_manufacturer;
  const summary = fetchedShops?.item_summary;
  const warranty = fetchedShops?.item_warranty_days;
  const code = fetchedShops?.item_code;
  const type = fetchedShops?.item_type;
  const discount = fetchedShops?.item_discount;
  const batch = fetchedShops?.item_batch_no;
  const category = fetchedShops?.category?.cat_name;

  const enrol = async () => {
    // await addToCart(id, name, price, manufacturer, type, discount, category)

    toast.success("shop added to cart");
  };

  return (
    <SingleshopWrapper>
      {fetchedShops ? (
        <div>
          <div className="shop-intro mx-auto grid">
            <div className="d-flex justify-content-between">
              <div className="shop-img">
                <img src={image} alt={name} />
              </div>
              <div>
                <div className="mb-3 shop-head">
                  <div className="text-success">
                    <h5>
                      {name}[{code}]
                    </h5>
                  </div>
                </div>

                <div className="shop-category bg-white text-dark text-capitalize fw-6 fs-6 mb-2 d-inline-block">
                  {category}
                </div>
                <ul className="shop-info">
                  <li>
                    <span className="fs-6">
                      Quantity: <span className="fw-6 opacity-08">{qty}</span>
                    </span>
                  </li>
                  <li className="flex">
                    <span>
                      <MdInfo />
                    </span>
                    <span className="fs-6 shop-info-txt fw-5">
                      Discount {discount}
                    </span>
                  </li>
                  <li className="flex">
                    <span>
                      <TbWorld />
                    </span>
                    <span className="fs-6 shop-info-txt fw-5">{model}</span>
                  </li>
                  <li className="flex">
                    <span>
                      <RiClosedCaptioningFill />
                    </span>
                    <span className="fs-6 shop-info-txt fw-5">{type}</span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="shop-details">
              <div className="shop-body">
                <p className="shop-para fs-18">{summary}</p>
                <div className="shop-rating flex">
                  <span className="rating-star-val fw-8 fs-16">
                    {manufacturer} [{batch}]
                  </span>
                  <span className="rating-count fw-5 fs-14">({warranty})</span>
                </div>
              </div>

              <div className="shop-foot">
                <div className="shop-price">
                  <span className="new-price fs-5 text-info fw-8">
                    $USD:&#160;{price}
                  </span>
                  {/* <span className='old-price fs-26 fw-6'>${actual_price}</span> */}
                </div>
              </div>

              <div className="shop-btn">
                <Link
                  to="/cart"
                  className="add-to-cart-btn d-inline-block fw-7 btn btn-outline-warning text-white"
                  onClick={enrol}
                >
                  <FaShoppingCart /> Add To Cart
                </Link>
              </div>
            </div>
          </div>

          <div className="shop-full bg-white text-dark">
            <div className="shop-learn mx-auto">
              <div className="shop-sc-title">Units</div>
              <ul className="shop-learn-list grid">
                {
                  // units && units?.map((learnItem, idx) => {
                  // 	return (
                  // 		<li key={idx}>
                  // 			<span><BiCheck /></span>
                  // 			<span className='fs-14 fw-5 opacity-09'>{learnItem}</span>
                  // 		</li>
                  // 	)
                  // })
                }
              </ul>
            </div>

            <div className="shop-content mx-auto">
              <div className="shop-sc-title"></div>
              <ul className="shop-content-list">
                {
                  // enrolment_criteria && enrolment_criteria?.map((contentItem, idx) => {
                  // 	return (
                  // 		<li key={idx}>
                  // 			<span>{contentItem}</span>
                  // 		</li>
                  // 	)
                  // })
                }
              </ul>
            </div>
          </div>
        </div>
      ) : (
        <div className="text-primary mt-5 text-center">
          <div className="d-flex align-items-center justify-content-center">
            <span
              className="fs-2 spinner-border spinner-border-sm text-success"
              role="status"
              aria-hidden="true"
            ></span>
            <span>Loading shop Info....</span>
          </div>
        </div>
      )}
    </SingleshopWrapper>
  );
};

const SingleshopWrapper = styled.div`
  background: #000;
  color: #fff;

  .shop-intro {
    padding: 40px 16px;
    max-width: 992px;

    .shop-details {
      padding-top: 20px;
    }

    .shop-category {
      padding: 0px 8px;
      border-radius: 6px;
    }

    .shop-head {
      font-size: 48px;
      line-height: 1.3;
      padding: 12px 0 0 0;
    }
    .shop-para {
      padding: 12px 0;
    }
    .rating-star-val {
      margin-right: 7px;
      padding-bottom: 5px;
      color: var(--clr-orange);
    }
    .students-count {
      margin-left: 8px;
    }
    .rating-count {
      margin-left: 6px;
      color: #d097f6;
    }
    .shop-info {
      li {
        margin-bottom: 2px;
        &:nth-child(2) {
          margin-top: 10px;
        }
      }
      .shop-info-txt {
        text-transform: capitalize;
        margin-left: 8px;
        margin-bottom: 4px;
      }
    }
    .shop-price {
      margin-top: 12px;
      .old-price {
        color: #eceb98;
        text-decoration: line-through;
        margin-left: 10px;
      }
    }
    .shop-btn {
      margin-top: 16px;
      .add-to-cart-btn {
        padding: 12px 28px;
        span {
          margin-left: 12px;
        }
      }
    }

    @media screen and (min-width: 880px) {
      grid-template-columns: repeat(2, 1fr);
      column-gap: 40px;
      .shop-details {
        padding-top: 0;
      }
      .shop-img {
        order: 2;
      }
    }

    @media screen and (min-width: 1400px) {
      grid-template-columns: 60% 40%;
    }
  }

  .shop-full {
    padding: 40px 16px;
    .shop-sc-title {
      font-size: 22px;
      font-weight: 700;
      margin: 12px 0;
    }
    .shop-learn {
      max-width: 992px;
      border: 1px solid rgba(0, 0, 0, 0.2);
      padding: 12px 28px 22px 28px;

      .shop-learn-list {
        li {
          margin: 5px 0;
          display: flex;
          span {
            &:nth-child(1) {
              opacity: 0.95;
              margin-right: 12px;
            }
          }
        }

        @media screen and (min-width: 992px) {
          grid-template-columns: repeat(2, 1fr);
        }
      }
    }

    .shop-content {
      max-width: 992px;
      margin-top: 30px;
      border: 1px solid rgba(0, 0, 0, 0.2);
      padding: 12px 28px 22px 28px;

      .shop-content-list {
        li {
          background-color: #f7f9fa;
          padding: 12px 18px;
          border: 1px solid rgba(0, 0, 0, 0.2);
          margin-bottom: 10px;
          font-weight: 800;
          font-size: 15px;
        }
      }
    }
  }
`;

export default ShopDetails;
