import React from 'react'
import Institution from '../../../components/admin/Institutions/Institution'
import Nav from '../../../components/admin/Nav'

const InstitutionScreen = () => {
	return (
		<div className='container-fluid'>
			<div className="row">
				<div className="col-12 col-md-2">
					|<Nav />
				</div>
				<div className="col-12 col-md-10 mt-5">
					<Institution />
				</div>
			</div>
		</div>
	)
}

export default InstitutionScreen
