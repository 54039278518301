import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { toast } from "react-hot-toast";

import axios from "../../../API/apiHandler";

const Update = ({ id, school }) => {
  const [banner, setBanner] = useState(null);

  const handleImage = (e) => {
    setBanner(e.target.files[0]);
  };

  const [institutions, setInstitutions] = useState({
    inst_name: "",
    inst_short_name: "",
    inst_detailed_desc: "",
    inst_short_desc: "",
    inst_reg_date: "",
    inst_industry: "",
    inst_city: "",
    inst_address: "",
    inst_country: "",
    inst_state: "",
    inst_employees_no: "",
    parent_id: 0,
  });

  const handleChange = (event) => {
    const { name, value, type, files } = event.target;
    setInstitutions((prevInstitutions) => {
      return {
        ...prevInstitutions,
        [name]: type === "file" ? files[0] : value,
      };
    });
  };

  useEffect(() => {
    setInstitutions(school);
  }, [school]);

  // useEffect(() => {
  //   const fetchInstitutions = async () => {
  //     try {
  //       const token = JSON.parse(localStorage.getItem("fastapi"));
  //       const response = await axios.get(
  //         `/api/v1/institutions/${id}`,

  //         {
  //           headers: {
  //             "Content-Type": "application/json",
  //             Authorization: `Bearer ${token}`,
  //           },
  //           withCredentials: true,
  //         }
  //       );
  //       if (response.status === 201 || response.status === 200) {
  //         setInstitutions({ ...institutions, ...response.data });
  //       }
  //     } catch (error) {}
  //   };
  //   fetchInstitutions();

  // }, [id, institutions]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const inst_name = institutions.inst_name;
    const inst_short_name = institutions.inst_short_name;
    const inst_detailed_desc = institutions.inst_detailed_desc;
    const inst_short_desc = institutions.inst_short_desc;
    const inst_reg_date = institutions.inst_reg_date;
    const inst_industry = institutions.inst_industry;
    const inst_city = institutions.inst_city;
    const inst_address = institutions.inst_address;
    const inst_country = institutions.inst_country;
    const inst_state = institutions.inst_state;
    const inst_employees_no = institutions.inst_employees_no;
    const parent_id = institutions.parent_id;

    const instData = {
      inst_name,
      inst_short_name,
      inst_detailed_desc,
      inst_short_desc,
      inst_reg_date,
      inst_industry,
      inst_city,
      inst_address,
      inst_country,
      inst_state,
      inst_employees_no,
      parent_id,
    };

    const data = new FormData();
    data.append("banner", banner);

    try {
      const token = JSON.parse(localStorage.getItem("fastapi"));
      const response = await axios.put(`/api/v1/institutions/${id}`, instData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      });
      if (response.status === 201 || response.status === 200) {
        toast.success("Institution updated..!");
      }
      setInstitutions(response.data);

      const res = await axios.put(`/api/v1/institutions/banner/${1}`, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      if (res.status === 201 || res.status === 200) {
        toast.success("BANNER added successfully...!");
        window.location.reload();
      }
    } catch (error) {}
  };

  return (
    <div>
      <div className="row">
        <div className="col-12">
          <button
            type="button"
            className="bg-blue-900 w-100 flex gap-1 items-center justify-center py-2 text-white rounded"
            data-bs-toggle="modal"
            data-bs-target="#addschool"
          >
            Update
          </button>
          <div
            className="modal fade"
            style={{ overflow: "scroll !important" }}
            id="addschool"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex="-1"
            aria-labelledby="addschoolLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-lg ">
              <div className="modal-content">
                <div className="modal-header">
                  <h1
                    className="modal-title text-primary fs-5"
                    id="addschoolLabel"
                  >
                    Update institution details
                  </h1>
                  <button
                    type="button"
                    className="btn-close text-dark"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                  <div className="modalcontainer text-dark">
                    <form onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="col">
                          <div className="row">
                            <div className="col mb-2">
                              <div>
                                <label htmlFor="banner" className="form-label">
                                  Update Banner
                                </label>
                                <div className="file-card">
                                  <div className="file-input">
                                    <input
                                      type="file"
                                      accept="image/*"
                                      id="banner"
                                      name="banner"
                                      className="form-group"
                                      onChange={handleImage}
                                    />
                                    <button
                                      type="button"
                                      className={
                                        banner === null
                                          ? "bg-info"
                                          : "bg-success"
                                      }
                                    >
                                      <i className="fa fa-upload"></i>
                                      {banner === null
                                        ? "Update Banner"
                                        : "Banner  Uploaded"}
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="inputBox">
                            <span>Institution name :</span>
                            <input
                              type="text"
                              placeholder="Moi University"
                              onChange={handleChange}
                              required
                              value={institutions.inst_name}
                              name="inst_name"
                            />
                          </div>
                          <div className="inputBox">
                            <span>address :</span>
                            <input
                              type="text"
                              placeholder=" street - locality"
                              onChange={handleChange}
                              required
                              value={institutions.inst_address}
                              name="inst_address"
                            />
                          </div>
                          <div className="inputBox">
                            <span>City :</span>
                            <input
                              type="text"
                              placeholder="Nairobi"
                              onChange={handleChange}
                              required
                              value={institutions.inst_city}
                              name="inst_city"
                            />
                          </div>

                          <div className="inputBox">
                            <span>Inst Industry :</span>
                            <input
                              type="text"
                              placeholder="Technology,agriculture"
                              onChange={handleChange}
                              required
                              value={institutions.inst_industry}
                              name="inst_industry"
                            />
                          </div>

                          <div className="flex">
                            <div className="inputBox">
                              <span>state :</span>
                              <input
                                type="text"
                                placeholder="Kenya"
                                onChange={handleChange}
                                required
                                value={institutions.inst_state}
                                name="inst_state"
                              />
                            </div>
                            <div className="inputBox">
                              <span>Institution Short name :</span>
                              <input
                                type="text"
                                placeholder="UON"
                                onChange={handleChange}
                                required
                                value={institutions.inst_short_name}
                                name="inst_short_name"
                              />
                            </div>
                          </div>

                          <div className="inputBox mb-3">
                            <span>Detailed Description :</span>
                            <textarea
                              className="form-control"
                              row="8"
                              name="inst_detailed_desc"
                              placeholder="Enter detailed description"
                              onChange={handleChange}
                              required
                              value={institutions.inst_detailed_desc}
                            />
                          </div>
                          <div className="inputBox">
                            <span>Short Description :</span>
                            <textarea
                              className="form-control mb-4"
                              row="4"
                              placeholder="Enter short description"
                              onChange={handleChange}
                              required
                              value={institutions.inst_short_desc}
                              name="inst_short_desc"
                            />
                          </div>

                          <div className="flex">
                            <div className="inputBox">
                              <span>Reg date :</span>
                              <input
                                type="date"
                                placeholder="12/12/2020"
                                onChange={handleChange}
                                required
                                value={institutions.inst_reg_date}
                                name="inst_reg_date"
                              />
                            </div>
                            <div className="inputBox">
                              <span>No of employees:</span>
                              <input
                                type="number"
                                placeholder="100"
                                onChange={handleChange}
                                required
                                value={institutions.inst_employees_no}
                                name="inst_employees_no"
                              />
                            </div>
                            <div className="inputBox">
                              <span>Country :</span>
                              <input
                                type="text"
                                placeholder="eldoret"
                                onChange={handleChange}
                                required
                                value={institutions.inst_country}
                                name="inst_country"
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <input
                        type="submit"
                        value="Confirm"
                        className="submit-btn"
                      />
                    </form>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary text-dark"
                    data-bs-dismiss="modal"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Update;
