import React, { useState, useEffect } from "react";
// import { Button } from 'primereact/button';
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
// import { Dialog } from "primereact/dialog";
import { toast } from "react-toastify";
import axios from "../../../API/apiHandler";

export default function Update() {
  const navigate = useNavigate();
  const { id } = useParams();

  const [eventData, setEventData] = useState({
    title: "",
    start_date: "",
    end_date: "",
    start_time: "",
    end_time: "",
    location: "",
    description: "",
    facilitators: "",
    url: "",
    reminders: "",
    time_zone: "",
    created_at: "",
  });

  const now = new Date();
  const isoString = now.toISOString();

  useEffect(() => {
    const fetchEvent = async () => {
      const result = await axios.get(`/api/v1/Events/events/${id}`, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (result.status === 200 || result.status === 201) {
        const eventDataFromApi = result.data; // Extract data from the API response
  
        // Update the state with the data from the API
        setEventData(eventDataFromApi);
      }
    };

    fetchEvent();
  }, [id]);

  const handleChange = (e) => {
    setEventData({
      ...eventData,
      [e.target.name]: e.target.value,
    });
  };

  const {
    title,
    start_date,
    end_date,
    start_time,
    end_time,
    location,
    description,
    facilitators,
    url,
    reminders,
    time_zone,
  } = eventData;

  const handleEventCreated = async (e) => {
    e.preventDefault();
    const token = JSON.parse(localStorage.getItem("fastapi"));

    const event = {
      title: eventData.title,
      start_time: eventData.start_time,
      end_time: eventData.end_time,
      start_date: eventData.start_date,
      end_date: eventData.end_date,
      location: eventData.location,
      url: eventData.url,
      description: eventData.description,
      reminders: eventData.reminders,
      time_zone: eventData.time_zone,
      facilitators: eventData.facilitators,
      created_at: isoString,
    };

    try {
      const res = await axios.put(`/api/v1/Events/events/${id}`, event, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      if (res.status === 201 || res.status === 200) {
        toast.success("updated...!");
        window.location.reload();
      }
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.detail);
      } else {
      }
    }
  };
  // const [visible, setVisible] = useState(false);

  return (
    <>
      <div classNameName="container d-flex justify-content-between align-items-center">
        {/* <Button label="Add Event" icon="pi pi-calendar" onClick={() => setVisible(true)} /> */}

        {/* <Dialog
          header="Update Event"
          visible={visible}
          style={{ width: "50vw" }}
          breakpoints={{ "960px": "75vw", "641px": "100vw" }}
          onHide={() => setVisible(false)}
        > */}
        <div className="d-flex justify-content-between align-items-center">
          <h1 className="text-center">Update {eventData.title} event</h1>
          <div className="d-flex justify-content-end">
            <Button
              variant="info"
              className="py-3 px-2"
              onClick={() => navigate("/events")}
            >
              Back
            </Button>
          </div>
        </div>

        <hr />

        <form className="p-4" onSubmit={handleEventCreated}>
          <div className="row g-3">
            <div className="col-md-6">
              <label for="title" className="form-label">
                Title
              </label>
              <input
                id="title"
                name="title"
                type="text"
                value={title}
                onChange={
                  handleChange
                  // (e) => setEventData({ ...eventData, title: e.target.value })
                }
                required
                className="form-control"
              />
            </div>
            <div className="col-md-6">
              <label for="location" className="form-label">
                Location
              </label>
              <input
                id="location"
                name="location"
                type="text"
                value={location}
                onChange={handleChange}
                required
                className="form-control"
              />
            </div>
            <div className="col-md-6">
              <label for="start_date" className="form-label">
                Start Date
              </label>
              <input
                id="start_date"
                name="start_date"
                type="date"
                value={start_date}
                onChange={handleChange}
                required
                className="form-control"
              />
            </div>
            <div className="col-md-6">
              <label for="end_date" className="form-label">
                End Date
              </label>
              <input
                id="end_date"
                name="end_date"
                type="date"
                value={end_date}
                onChange={handleChange}
                required
                className="form-control"
              />
            </div>
            <div className="col-md-6">
              <label for="start_time" className="form-label">
                Start Time
              </label>
              <input
                id="start_time"
                name="start_time"
                type="time"
                value={start_time}
                onChange={handleChange}
                required
                className="form-control"
              />
            </div>
            <div className="col-md-6">
              <label for="end_time" className="form-label">
                End Time
              </label>
              <input
                id="end_time"
                name="end_time"
                type="time"
                value={end_time}
                onChange={handleChange}
                required
                className="form-control"
              />
            </div>
            <div className="col-md-6">
              <label for="description" className="form-label">
                Description
              </label>
              <textarea
                id="description"
                name="description"
                value={description}
                onChange={handleChange}
                rows={3}
                cols={20}
                className="form-control"
              ></textarea>
            </div>
            <div className="col-md-6">
              <label for="facilitator" className="form-label">
                Facilitator
              </label>
              <input
                id="facilitator"
                name="facilitator"
                type="text"
                value={facilitators}
                onChange={handleChange}
                required
                className="form-control"
              />
            </div>
            <div className="col-md-6">
              <label for="url" className="form-label">
                URL
              </label>
              <input
                id="url"
                name="url"
                type="text"
                value={url}
                onChange={handleChange}
                required
                className="form-control"
              />
            </div>
            <div className="col-md-6">
              <label for="reminders" className="form-label">
                Reminders
              </label>
              <select
                id="reminders"
                name="reminders"
                type="text"
                value={reminders}
                onChange={handleChange}
                required
                className="form-select"
              >
                <option value="" disabled defaultValue>
                  Select a reminder
                </option>
                <option value="15">15 minutes before</option>
                <option value="30">30 minutes before</option>
                <option value="60">1 hour before</option>
                <option value="1440">1 day before</option>
              </select>
            </div>
            <div className="col-md-6">
              <label for="time_zone" className="form-label">
                Time Zone
              </label>
              <select
                id="time_zone"
                name="time_zone"
                type="text"
                value={time_zone}
                className="form-select"
                onChange={handleChange}
                required
                placeholder="EAT 3+"
              >
                <option value="" disabled defaultValue>
                  Select the TimeZone
                </option>
                <option value="Africa/Bujumbura">Africa/Bujumbura</option>
                <option value="Africa/Dar_es_Salaam">
                  Africa/Dar_es_Salaam
                </option>
                <option value="Africa/Djibouti">Africa/Djibouti</option>
                <option value="Africa/Juba">Africa/Juba</option>
                <option value="Africa/Kampala">Africa/Kampala</option>
                <option value="Africa/Khartoum">Africa/Khartoum</option>
                <option value="Africa/Kigali">Africa/Kigali</option>
                <option value="Africa/Lubumbashi">Africa/Lubumbashi</option>
                <option value="Africa/Lusaka">Africa/Lusaka</option>
                <option value="Africa/Maputo">Africa/Maputo</option>
                <option value="Africa/Maseru">Africa/Maseru</option>
                <option value="Africa/Mbabane">Africa/Mbabane</option>
                <option value="Africa/Mogadishu">Africa/Mogadishu</option>
                <option value="Africa/Nairobi">Africa/Nairobi</option>
                <option value="Africa/Ndjamena">Africa/Ndjamena</option>
                <option value="Africa/Tripoli">Africa/Tripoli</option>
                <option value="Africa/Tunis">Africa/Tunis</option>
                <option value="Africa/Windhoek">Africa/Windhoek</option>
              </select>
            </div>
          </div>
          <Button
            type="submit"
            variant="warning"
            className="mt-3 w-full py-3 px-2"
          >
            Update Event
          </Button>
        </form>
        {/* </Dialog> */}
      </div>
    </>
  );
}
