// import { toast } from 'react-hot-toast'
import { toast } from "react-toastify";
import axios from "../../../API/apiHandler";

const ApplyScholarship = ({ uuid }) => {
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const token = JSON.parse(localStorage.getItem("fastapi"));
      const res = await axios.post(`/api/v1/scholarships/apply/${uuid}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      if (res.data) {
        toast.success("application submitted...!");
      }
    } catch (error) {
    }
  };

  return (
    <div className="">
      <button
        className="btn btn-outline-primary text-dark"
        onClick={handleSubmit}
      >
        Apply
      </button>
    </div>
  );
};

export default ApplyScholarship;
