import { useState, useEffect } from "react";
import { InputTextarea } from "primereact/inputtextarea";
import { toast } from "react-hot-toast";
import LoadingOverlay from "../../Load/LoadingOverlay";
import axios from "../../../API/apiHandler";
import Card from "./Card";

const Market = () => {
  const [banner, setBanner] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [fetchedShops, setFetchedShops] = useState([]);

  const handleImage = (e) => {
    setBanner(e.target.files);
  };

  const [shopForm, setShopForm] = useState({
    cat_id: 0,
    owner_id: 3,
    item_qty: 0,
    item_name: "",
    item_price: 0,
    item_summary: "",
    item_model_no: "",
    item_short_desc: "",
    item_manufacturer: "",
    item_warranty_days: 0,
    item_code: "",
    item_type: "",
    item_discount: 0,
    item_batch_no: "",
  });

  const [fetchedCategory, setFetchedCategory] = useState([]);

  const [catForm, setCatForm] = useState({
    cat_name: "",
    cat_desc: "",
    cat_short_desc: "",
    parent_id: 0,
  });

  const handleChange = (e) => {
    setCatForm({
      ...catForm,
      [e.target.name]: e.target.value,
    });

    setShopForm({
      ...shopForm,
      [e.target.name]: e.target.value,
    });
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    const cat_id = shopForm.cat_id;
    const owner_id = shopForm.owner_id;
    const item_qty = shopForm.item_qty;
    const item_name = shopForm.item_name;
    const item_price = shopForm.item_price;
    const item_summary = shopForm.item_summary;
    const item_model_no = shopForm.item_model_no;
    const item_short_desc = shopForm.item_short_desc;
    const item_manufacturer = shopForm.item_manufacturer;
    const item_warranty_days = shopForm.item_warranty_days;
    const item_code = shopForm.item_code;
    const item_type = shopForm.item_type;
    const item_discount = shopForm.item_discount;
    const item_batch_no = shopForm.item_batch_no;

    const shopData = {
      cat_id,
      owner_id,
      item_qty,
      item_name,
      item_price,
      item_summary,
      item_model_no,
      item_short_desc,
      item_manufacturer,
      item_warranty_days,
      item_code,
      item_type,
      item_discount,
      item_batch_no,
    };

    try {
      const token = JSON.parse(localStorage.getItem("fastapi"));
      const response = await axios.post("/api/v1/shop", shopData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 201 || response.status === 200) {
        toast.success("Product added successfully");
      }

      const sid = response?.data?.id;
      const formdata = new FormData();
      // formdata.append("banner", banner);
      banner.forEach((image) => {
        formdata.append("banner", image);
      });

      const res = await axios.put(`/api/v1/shop/banner/${sid}`, formdata, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      if (res.status === 201 || res.status === 200) {
        toast.success("banner added");
        window.location.reload();
      }
    } catch (error) {
      if (error.response?.data.detail) {
        toast.error(error.response?.data.detail);
      } else {
        toast.error("something went wrong...!");
      }
    }
  };

  useEffect(() => {
    

    const fetchShop = async () => {
      setIsLoading(true);
      try {
        const token = JSON.parse(localStorage.getItem("fastapi"));
        const res = await axios.get(
          "/api/v1/shop",
          
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        res.data && setFetchedShops(res?.data);

      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    };
    fetchShop();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const cat_name = catForm.cat_name;
    const cat_desc = catForm.cat_desc;
    const cat_short_desc = catForm.cat_short_desc;
    const parent_id = catForm.parent_id;

    const catData = {
      cat_name,
      cat_desc,
      cat_short_desc,
      parent_id,
    };

    try {
      const token = JSON.parse(localStorage.getItem("fastapi"));

      const res = await axios.post("/api/v1/shop/categories", catData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      if (res.status === 201 || res.status === 200) {
        toast.success("category added...!");
        window.location.reload();
      }
    } catch (error) {
      toast.error("Something went wrong");
    }
  };

  useEffect(() => {
    
    
    const fetchCategory = async () => {
      try {
        const res = await axios.get("/api/v1/shop/categories", {
          headers: {
            "Content-Type": "application/json",
          },
        });
        if (res.status === 200 || res.status === 201) {
          setFetchedCategory(res?.data);
        }
        
      } catch (error) {}
    };

    fetchCategory();
    
  });
  return (
    <>
      {isLoading ? (
        <LoadingOverlay />
      ) : (
        <section className="container-fluid mt-3">
          <div className="row mb-4">
            <div className="d-flex justify-content-between align-items-center">
              <div className="">
                <h3 className="fs-5 fw-bold">Product Listing</h3>
              </div>
              <div className="">
                <div className="d-flex align-items-center gap-2 ms-auto">
                  <div className="d-grid">
                    <button
                      className="btn btn-warning shadow"
                      style={{ border: "1px solid #001" }}
                      data-bs-toggle="modal"
                      data-bs-target="#addshop"
                    >
                      <span className="fs-6">Add Product</span>
                    </button>
                  </div>
                  <div className="d-grid">
                    <button
                      className="btn btn-warning shadow"
                      style={{ border: "1px solid #001" }}
                      data-bs-toggle="modal"
                      data-bs-target="#addproductcategory"
                    >
                      <span className="fs-6">Add Category</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div
                className="modal fade"
                style={{ overflow: "scroll !important" }}
                id="addproductcategory"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex="-1"
                aria-labelledby="addproductLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-lg ">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h1
                        className="modal-title text-primary fs-5"
                        id="addproductLabel"
                      >
                        New Product Category
                      </h1>
                      <button
                        type="button"
                        className="btn-close text-dark"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-body">
                      <div className="modalcontainer text-dark">
                        <form onSubmit={handleSubmit}>
                          <div className="row">
                            <div className="col">
                              <div className="inputBox">
                                <span>cat name :</span>
                                <input
                                  type="text"
                                  placeholder="category name"
                                  onChange={handleChange}
                                  required
                                  value={catForm.cat_name}
                                  name="cat_name"
                                />
                              </div>

                              <div className=" mb-3">
                                <span>Product summary :</span>
                                <textarea
                                  className="form-control"
                                  row="8"
                                  name="cat_desc"
                                  placeholder=""
                                  onChange={handleChange}
                                  required
                                  value={catForm.cat_desc}
                                />
                              </div>
                              <div className="">
                                <span>Short Description :</span>
                                <textarea
                                  className="form-control mb-4"
                                  row="4"
                                  placeholder="Enter short description"
                                  onChange={handleChange}
                                  required
                                  value={catForm.cat_short_desc}
                                  name="cat_short_desc"
                                />
                              </div>
                            </div>
                          </div>

                          <input
                            type="submit"
                            value="Confirm"
                            className="submit-btn"
                          />
                        </form>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-secondary  text-dark"
                        data-bs-dismiss="modal"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="modal fade"
                style={{ overflow: "scroll !important" }}
                id="addshop"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex="-1"
                aria-labelledby="addshopLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-lg">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h1
                        className="modal-title text-primary fs-5"
                        id="addshopLabel"
                      >
                        New Market details
                      </h1>
                      <button
                        type="button"
                        className="btn-close text-dark"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-body">
                      <div className="modalcontainer text-dark">
                        <form onSubmit={submitHandler}>
                          <div className="row">
                            <div className="col">
                              <div>
                                <label htmlFor="banner" className="form-label">
                                  Upload photos
                                </label>
                                <div className="file-card">
                                  <div className="file-input">
                                    <input
                                      type="file"
                                      id="banner"
                                      className="form-group"
                                      onChange={handleImage}
                                      multiple
                                    />
                                    <button
                                      type="button"
                                      className={
                                        banner === null
                                          ? "bg-info"
                                          : "bg-success"
                                      }
                                    >
                                      <i className="fa fa-upload"></i>
                                      {banner === null
                                        ? "Upload Photos"
                                        : "Photos Uploaded"}
                                    </button>
                                  </div>
                                </div>
                              </div>

                              <div className="inputBox">
                                <span>Item Name :</span>
                                <input
                                  type="text"
                                  placeholder=""
                                  onChange={handleChange}
                                  required
                                  value={shopForm.item_name}
                                  name="item_name"
                                />
                              </div>

                              <div className="inputBox">
                                <span>Item Quantity :</span>
                                <input
                                  type="text"
                                  placeholder=""
                                  onChange={handleChange}
                                  required
                                  value={shopForm.item_qty}
                                  name="item_qty"
                                />
                              </div>

                              <div className="inputBox">
                                <span>Price :</span>
                                <input
                                  type="number"
                                  placeholder=""
                                  onChange={handleChange}
                                  required
                                  value={shopForm.item_price}
                                  name="item_price"
                                />
                              </div>

                              <div>
                                <span>Category :</span>
                                <select
                                  className="form-select"
                                  aria-label="Default select"
                                  onChange={handleChange}
                                  required
                                  value={shopForm.cat_id}
                                  name="cat_id"
                                >
                                  <option value="">Select category</option>
                                  {fetchedCategory?.length > 0 ? (
                                    fetchedCategory.map((category, index) => (
                                      <option key={index} value={category?.id}>
                                        {category?.cat_name}
                                      </option>
                                    ))
                                  ) : (
                                    <option>No category found</option>
                                  )}
                                </select>
                              </div>

                              <div className="flex">
                                <div className="inputBox">
                                  <span>Item type :</span>
                                  <input
                                    type="text"
                                    placeholder=""
                                    onChange={handleChange}
                                    required
                                    value={shopForm.item_type}
                                    name="item_type"
                                  />
                                </div>

                                <div className="inputBox">
                                  <span>Model Number :</span>
                                  <input
                                    type="text"
                                    placeholder=""
                                    onChange={handleChange}
                                    required
                                    value={shopForm.item_model_no}
                                    name="item_model_no"
                                  />
                                </div>
                              </div>

                              <div className="flex">
                                <div className="inputBox">
                                  <span>Item code :</span>
                                  <input
                                    type="text"
                                    placeholder=""
                                    onChange={handleChange}
                                    required
                                    value={shopForm.item_code}
                                    name="item_code"
                                  />
                                </div>

                                <div className="inputBox">
                                  <span>Discount:</span>
                                  <input
                                    type="number"
                                    placeholder=""
                                    onChange={handleChange}
                                    required
                                    value={shopForm.item_discount}
                                    name="item_discount"
                                  />
                                </div>
                                <div className="inputBox">
                                  <span>Warranty(days) :</span>
                                  <input
                                    type="number"
                                    placeholder=""
                                    onChange={handleChange}
                                    required
                                    value={shopForm.item_warranty_days}
                                    name="item_warranty_days"
                                  />
                                </div>
                              </div>

                              <div className="inputBox mb-3">
                                <span>Summary:</span>
                                <textarea
                                  className="form-control"
                                  row="10"
                                  name="item_summary"
                                  placeholder=""
                                  onChange={handleChange}
                                  required
                                  value={shopForm.item_summary}
                                />
                              </div>
                              <div className="inputBox">
                                <span className="p-float-label">
                                  <InputTextarea
                                    id="item_short_desc"
                                    name="item_short_desc"
                                    value={shopForm.item_short_desc}
                                    onChange={handleChange}
                                    rows={5}
                                    cols={30}
                                  />
                                  <label htmlFor="item_short_desc">
                                    <span>Short Description :</span>
                                  </label>
                                </span>
                              </div>

                              <div className="inputBox">
                                <span>Manufacturer :</span>
                                <input
                                  type="text"
                                  placeholder=""
                                  onChange={handleChange}
                                  required
                                  value={shopForm.item_manufacturer}
                                  name="item_manufacturer"
                                />
                              </div>

                              <div className="inputBox">
                                <span>Batch No. :</span>
                                <input
                                  type="text"
                                  placeholder=""
                                  onChange={handleChange}
                                  required
                                  value={shopForm.item_batch_no}
                                  name="item_batch_no"
                                />
                              </div>
                            </div>
                          </div>

                          <input
                            type="submit"
                            value="Confirm"
                            className="submit-btn"
                          />
                        </form>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-secondary  text-dark"
                        data-bs-dismiss="modal"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Mapping card */}
            <div className="row">
              <div className="col mb-3">
                {fetchedShops.length > 0 ? (
                  fetchedShops.map((fetchedShop, index) => (
                    <div className="" key={index}>
                      <Card
                        id={fetchedShop.id}
                        name={fetchedShop.item_name}
                        price={fetchedShop.item_price}
                        summary={fetchedShop.item_summary}
                        short_desc={fetchedShop.item_short_desc}
                        manufacturer={fetchedShop.item_manufacturer}
                        batch_no={fetchedShop.item_batch_no}
                        warranty={fetchedShop.item_warranty_days}
                        type={fetchedShop.item_type}
                        discount={fetchedShop.item_discount}
                        category={fetchedShop.category.cat_name}
                        model={fetchedShop.item_model_no}
                        code={fetchedShop.item_code}
                        img={fetchedShop.item_banner_img}
                      />
                    </div>
                  ))
                ) : (
                  <div className="text-danger mt-5 fs-5 text-center">
                    <span>No Products yet...</span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default Market;
