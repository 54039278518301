import React, { useEffect, useContext, useState, useReducer } from "react";
import { toast } from "react-toastify";

import reducer from "../Reducer/cart/cart_reducer";
import Logo from "../images/acts-logo@1x.png";

import axios from "../API/apiHandler";

import {
  ADD_COURSE_TO_CART,
  REMOVE_SHOP_ITEM_FROM_CART,
  ADD_SHOP_ITEM_TO_CART,
  REMOVE_CART_ITEM,
  CLEAR_CART,
  GET_CART_TOTAL,
  LOGOUT,
} from "../Reducer/actions";

const loadCartFromStorage = () => {
  let cart = localStorage.getItem("cart");
  if (cart) {
    cart = Array.from(JSON.parse(localStorage.getItem("cart")));
  } else {
    cart = [];
  }
  return cart;
};

const initialState = {
  cart: loadCartFromStorage(),
  total_items: 0,
  total_amount: 0,
  user: window.localStorage.getItem("fastapi")
    ? JSON.parse(window.localStorage.getItem("fastapi"))
    : null,
  shop: {
    cart: loadCartFromStorage(),
    total_items: 0,
    total_amount: 0,
  },
};

const CartContext = React.createContext();
export const CartProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const [courseIds, setCourseIds] = useState(() => {
    return JSON.parse(localStorage.getItem("courseIds"));
  });

  useEffect(() => {
    localStorage.setItem("courseIds", JSON.stringify(courseIds));
  }, [courseIds]);

  const addToCart = (
    id,
    name,
    intake,
    cost,
    duration,
    category,
    institution,
    intake_id
  ) => {
    dispatch({
      type: ADD_COURSE_TO_CART,
      payload: {
        id,
        name,
        intake,
        cost,
        duration,
        category,
        institution,
        intake_id,
      },
    });
  };

  const removeFromCart = (id) => {
    dispatch({ type: REMOVE_CART_ITEM, payload: id });
  };

  const addToCartShop = (id, name, price, category, short_desc, discount) => {
    dispatch({
      type: ADD_SHOP_ITEM_TO_CART,
      payload: { id, name, price, category, short_desc, discount },
    });
  };

  const removeShopItemFromCart = (id) => {
    dispatch({
      type: REMOVE_SHOP_ITEM_FROM_CART,
      payload: id,
    });
  };

  const clearCart = () => {
    dispatch({ type: CLEAR_CART });
  };

  const logout = () => {
    dispatch({ type: LOGOUT });
    window.localStorage.removeItem("fastapi");
    window.localStorage.removeItem("id");
    window.location.reload();
    toast.success("logged out!!");
  };

  useEffect(() => {
    dispatch({ type: GET_CART_TOTAL });
    localStorage.setItem("cart", JSON.stringify(state));
  }, [state]);

  const [CourseData, setCourseData] = useState([]);

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const token = JSON.parse(localStorage.getItem("fastapi"));
        const response = await axios.get("/api/v1/courses", {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          withCredentials: true,
        });
        const courses = response?.data;
        courses.sort((a, b) => b.id - a.id);
        response && setCourseData(courses);
      } catch (error) {
        // if (error.response?.data.detail) {}
      }
    };
    fetchCourses();
  }, []);

  return (
    <CartContext.Provider
      value={{
        ...state,
        addToCart,
        logout,
        addToCartShop,
        removeShopItemFromCart,
        removeFromCart,
        clearCart,
        CourseData,
        setCourseData,
        Logo,

        courseIds,
        setCourseIds,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

export const useCartContext = () => {
  return useContext(CartContext);
};
