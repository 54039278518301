import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { toast } from "react-toastify";

import { MdInfo } from "react-icons/md";
import { TbWorld } from "react-icons/tb";
import { RiClosedCaptioningFill } from "react-icons/ri";

import axios from "../../../API/apiHandler";

// import image from "../../../images/acts-logo@1x.png";

const FundDetails = () => {
  const { id } = useParams();

  const [fundData, setFundData] = useState({});

  useEffect(() => {
    const fetchFundings = async () => {
      try {
        const token = JSON.parse(localStorage.getItem("fastapi"));
        const response = await axios.get(
          `/api/v1/funding/${id}`,

          {
            headers: {
              "content-duration": "application/json",
              Authorization: `Bearer ${token}`,
            },
            withCredentials: true,
          }
        );
        setFundData(response?.data);
      } catch (error) {}
    };

    fetchFundings();
  }, [id]);

  const name = fundData?.funding_title;
  const cost = fundData?.funding_amount;
  const funding_area = fundData?.funding_area;
  const full_desc = fundData?.funding_full_desc;

  const eligibles = fundData?.funding_eligible_entities;
  const duration = fundData?.funding_duration;
  const deadline = fundData?.funding_deadline_date;

  const institution = fundData?.institution?.inst_name;
  const inst_short_name = fundData?.institution?.inst_short_name;

  const handleSubmit = async (e) => {
    e.preventDefault();

    const token = JSON.parse(localStorage.getItem("fastapi"));

    const data = {
      funding_id: id,
    };
    try {
      const res = await axios.post(
        "/api/v1/funding/applications/funding",
        data,
        {
          headers: {
            accept: "application/json",
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (res?.data) toast.success("application submitted...!");
    } catch (error) {
      toast.error("Something went wrong, try again later...!");
    }
  };

  return (
    <SingleCourseWrapper>
      {fundData ? (
        <div>
          <div className="course-intro mx-auto grid">
            <div className="d-flex justify-content-between">
              <div className="course-img">
                {/* <img src={image} alt={name} /> */}
              </div>
              <div>
                <div className="mb-3 course-head">
                  <div className="text-success">
                    <h5>{name}</h5>
                  </div>
                </div>

                <div className="course-category bg-white text-dark text-capitalize fw-6 fs-6 mb-2 d-inline-block">
                  {duration}
                </div>
                <ul className="course-info">
                  <li>
                    <span className="fs-6">
                      Course Length:{" "}
                      <span className="fw-6 opacity-08">{deadline}</span>
                    </span>
                  </li>
                  <li className="flex">
                    <span>
                      <MdInfo />
                    </span>
                    <span className="fs-6 course-info-txt fw-5">
                      first Offered {deadline}
                    </span>
                  </li>
                  <li className="flex">
                    <span>
                      <TbWorld />
                    </span>
                    <span className="fs-6 course-info-txt fw-5">
                      {duration}
                    </span>
                  </li>
                  <li className="flex">
                    <span>
                      <RiClosedCaptioningFill />
                    </span>
                    <span className="fs-6 course-info-txt fw-5">
                      {funding_area}
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="course-details">
              <div className="course-body">
                <p className="course-para fs-18">{full_desc}</p>
                <div className="course-rating flex">
                  <span className="rating-star-val fw-8 fs-16">
                    {institution} [{inst_short_name}]
                  </span>
                  <span className="rating-count fw-5 fs-14">({eligibles})</span>
                </div>
              </div>

              <div className="course-foot">
                <div className="course-price">
                  <span className="new-price fs-5 text-info fw-8">
                    $USD:&#160;{cost}
                  </span>
                  {/* <span className='old-price fs-26 fw-6'>${actual_price}</span> */}
                </div>
              </div>

              <div className="course-btn">
                <button
                  className="btn btn-primary btn-lg"
                  onClick={handleSubmit}
                >
                  Apply Now
                </button>
              </div>
            </div>
          </div>

          <div className="course-full bg-white text-dark">
            <div className="course-content mx-auto">
              <div className="course-sc-title">Application Requirements</div>
              {/* <ul className='course-content-list'>
							  <li className=''>
								  <span className='fs-6'>Minimum Grade: <span className='fw-6 opacity-08'>{doc_min_grade}</span></span>
							  </li>
							  <li className=''>
								  <span className='fs-6'>Required Status: <span className='fw-6 opacity-08'>{doc_required_status}</span></span>
								</li>
								<li className=''>
								  <span className='fs-6'>Start date: <span className='fw-6 opacity-08'>{doc_min_date_start}</span></span>
								</li>
								<li className=''>
								  <span className='fs-6'>End Date: <span className='fw-6 opacity-08'>{doc_min_date_end}</span></span>
								</li>
						  </ul> */}
            </div>
          </div>
        </div>
      ) : (
        <div className="text-primary mt-5 text-center">
          <div className="d-flex align-items-center justify-content-center">
            <span
              className="fs-2 spinner-border spinner-border-sm text-success"
              role="status"
              aria-hidden="true"
            ></span>
            <span>Loading Course Info....</span>
          </div>
        </div>
      )}
    </SingleCourseWrapper>
  );
};

const SingleCourseWrapper = styled.div`
  background: #b8b8b8;
  color: #000;
  font-weight: 600;
  font-size: 16px;

  .course-intro {
    padding: 40px 16px;
    max-width: 992px;

    .course-details {
      padding-top: 20px;
    }

    .course-category {
      padding: 0px 8px;
      border-radius: 6px;
    }

    .course-head {
      font-size: 42px;
      line-height: 1.3;
      padding: 10px 0 0 0;
    }
    .course-para {
      padding: 12px 0;
    }
    .rating-star-val {
      margin-right: 7px;
      padding-bottom: 5px;
      color: var(--clr-orange);
    }
    .students-count {
      margin-left: 8px;
    }
    .rating-count {
      margin-left: 6px;
      color: #fff;
    }
    .course-info {
      li {
        margin-bottom: 2px;
        &:nth-child(2) {
          margin-top: 10px;
        }
      }
      .course-info-txt {
        text-transform: capitalize;
        margin-left: 8px;
        margin-bottom: 4px;
      }
    }
    .course-price {
      margin-top: 12px;
      .old-price {
        color: #eceb98;
        text-decoration: line-through;
        margin-left: 10px;
      }
    }
    .course-btn {
      margin-top: 16px;
      .add-to-cart-btn {
        padding: 12px 28px;
        span {
          margin-left: 12px;
        }
      }
    }

    @media screen and (min-width: 880px) {
      grid-template-columns: repeat(2, 1fr);
      column-gap: 40px;
      .course-details {
        padding-top: 0;
      }
      .course-img {
        order: 2;
      }
    }

    @media screen and (min-width: 1400px) {
      grid-template-columns: 60% 40%;
    }
  }

  .course-full {
    padding: 40px 16px;
    .course-sc-title {
      font-size: 22px;
      font-weight: 700;
      margin: 12px 0;
    }
    .course-learn {
      max-width: 992px;
      border: 1px solid rgba(0, 0, 0, 0.2);
      padding: 12px 28px 22px 28px;

      .course-learn-list {
        li {
          margin: 5px 0;
          display: flex;
          span {
            &:nth-child(1) {
              opacity: 0.95;
              margin-right: 12px;
            }
          }
        }

        @media screen and (min-width: 992px) {
          grid-template-columns: repeat(2, 1fr);
        }
      }
    }

    .course-content {
      max-width: 992px;
      margin-top: 30px;
      border: 1px solid rgba(0, 0, 0, 0.2);
      padding: 12px 28px 22px 28px;

      .course-content-list {
        li {
          background-color: #f7f9fa;
          padding: 12px 18px;
          border: 1px solid rgba(0, 0, 0, 0.2);
          margin-bottom: 10px;
          font-weight: 800;
          font-size: 15px;
        }
      }
    }
  }
`;

export default FundDetails;
