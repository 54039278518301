import { useState, useEffect, useContext } from "react";
import { Button } from "primereact/button";
import { ScrollTop } from "primereact/scrolltop";
// import { toast } from "react-toastify";

import axios from "../../../API/apiHandler.js";
import LoadingOverlay from "../../Load/LoadingOverlay.jsx";
import BuyerProfilePieChart from "./BuyerProfilePieChart.jsx";
import ProfileContext from "../../../Context/ProfileContext.jsx";
import TransactionChart from "./TransactionChart";
import AddInstructor from "../Instructors/AddInstructor.jsx";
import IncomeTable from "./IncomeTable.jsx";

const AdminDash = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { token, auth } = useContext(ProfileContext);
  const [schools, setSchools] = useState([]);
  const [users, setUsers] = useState([]);
  const [courses, setCourses] = useState([]);

  useEffect(() => {
    const fetch = async () => {
      try {
        const token = JSON.parse(localStorage.getItem("fastapi"));
        const res = await axios.get("/api/v1/courses", {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        setCourses(res?.data);
      } catch (error) {}
    };
    fetch();
  }, []);

  useEffect(() => {
    setIsLoading(true);

    const fetch = async () => {
      try {
        const token = JSON.parse(localStorage.getItem("fastapi"));
        await axios
          .get(
            "/api/v1/institutions",

            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((res) => {
            setSchools(res?.data);
          });

        await axios
          .get(
            "/api/v1/users",

            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((res) => {
            setUsers(res?.data);
          });
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    };
    fetch();
  }, []);

  const handleClick = () => {
    window.location.href = "https://api.acts-pathways-academy.org/docs#";
  };
  const handleTawk = () => {
    window.location.href = "https://dashboard.tawk.to/";
  };

  const [payments, setPayments] = useState([]);

  useEffect(() => {
    const fetchPayment = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get("/api/v1/pespal/all-payments", {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        if (response) {
          const pay = response?.data;
          const uniquePayments = pay.reduce((accumulator, currentPayment) => {
            const existingPayment = accumulator.find(
              (payment) => payment.id === currentPayment.id
            );

            if (!existingPayment) {
              return [...accumulator, currentPayment];
            }

            return accumulator;
          }, []);

          const total = uniquePayments.reduce(
            (accumulator, currentValue) => accumulator + currentValue.amount,
            0
          );

          setPayments(total);
        }
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    };

    fetchPayment();
  }, [token, auth]);

  return (
    <>
      {isLoading ? (
        <LoadingOverlay />
      ) : (
        <div>
          <div
            className="container-lg-fluid container mt-5"
            style={{ overflow: "scroll" }}
          >
            <div className="row my-2">
              <div className="col-12">
                <div className="d-flex flex-wrap gap-3 align-items-center justify-content-center">
                  <AddInstructor />

                  <Button
                    label="View APIs"
                    icon="pi pi-external-link"
                    onClick={handleClick}
                  />
                  <Button
                    label="Customer Care"
                    icon="pi pi-external-link"
                    onClick={handleTawk}
                  />
                </div>
              </div>
            </div>
            <h1 className="text-primary fs-5">Dashboard</h1>
            <div className="row row-cols-1 row-cols-md-4 g-4">
              <div className="col">
                <div className="card bg-green h-100 border border-1 border-primary">
                  <div className="card-body bg-secondary">
                    <h5 className="card-title fs-3 text-warning">
                      {courses?.length || 0}
                    </h5>
                    <p className="card-text text-black">Total Courses.</p>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card bg-green h-100 border border-1 border-info">
                  <div className="card-body bg-dark">
                    <h5 className="card-title fs-3 text-warning">
                      {users?.length}
                    </h5>
                    <p className="card-text text-white">Total Users.</p>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card bg-green h-100 border border-1 border-warning">
                  <div className="card-body bg-blue-900">
                    <h5 className="card-title fs-3 text-warning">
                      {schools?.length}
                    </h5>
                    <p className="card-text text-white">Total Schools.</p>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card bg-green h-100 border border-1 border-primary">
                  <div className="card-body">
                    <h5 className="card-title fs-3 text-warning">
                      ${payments}
                    </h5>
                    <p className="card-text text-black">Total Income.</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-3 g-3">
              <div className="col-lg-7 col-md-12 ">
                <TransactionChart />
              </div>
              <div className="col-lg-5 col-md-12">
                <BuyerProfilePieChart />
              </div>
            </div>

            <div className="row mt-3 g-3">
              <div className="col-lg-12 col-md-12 ">
                <IncomeTable />
              </div>
              {/* <div className="col-lg-4 col-md-12">
								B
							</div> */}
            </div>
            <ScrollTop
              target="parent"
              threshold={100}
              className="w-2rem h-2rem border-round bg-primary"
              icon="pi pi-arrow-up text-base"
            />
          </div>
        </div>
      )}
    </>
  );
};

export default AdminDash;
