import React from "react";
import { useState, useEffect, useContext } from "react";
import axios from "../../../API/apiHandler";
import { toast } from "react-hot-toast";
import { Fieldset } from "primereact/fieldset";
import ProfileContext from "../../../Context/ProfileContext";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { FilterMatchMode } from "primereact/api";
import { InputText } from "primereact/inputtext";

const Applications = () => {
  const { token, auth } = useContext(ProfileContext);

  const [cert, setCert] = useState([]);
  const [course, setCourse] = useState([]);

  useEffect(() => {
    const fetchAppliedCourses = async () => {
      try {
        const response = await axios.get(
          "/api/v1/courses/applications/courses",

          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response) {
          setCourse(response?.data);
        }
      } catch (error) {
        if (error.response?.data.detail) {
          toast.error(error.response?.data.detail);
        }
      }
    };

    fetchAppliedCourses();
  }, [token]);

  useEffect(() => {
    const fetchX = async () => {
      try {
        const response = await axios.get(
          "/api/v1/course_progress/generate_all_certificate_data",

          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response) {
          setCert(response?.data?.certificates);
        }
      } catch (error) {}
    };

    fetchX();
  }, [token, auth]);

  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    user_id: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    course_id: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    course_name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    institution_name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    user_full_name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    // representative: { value: null, matchMode: FilterMatchMode.IN },
    id: { value: null, matchMode: FilterMatchMode.EQUALS },
    date_created: { value: null, matchMode: FilterMatchMode.EQUALS },
  });

  const header = (
    <div className="flex flex-wrap align-items-center justify-content-between gap-2">
      <span className="text-xl text-900 font-bold">Information</span>
      <div className="flex justify-content-end">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Keyword Search"
          />
        </span>
      </div>
      <Button icon="pi pi-refresh" rounded raised />
    </div>
  );

  const footer = (
    <h2 className="text-black">
      In total, {cert ? cert.length : 0} certificate
      {cert.length > 1 ? "s" : null} have been handed out.
    </h2>
  );

  const foot = (
    <h2 className="text-black">
      In total, there is {course ? course.length : 0} course application
      {cert.length > 1 ? "s" : null}
    </h2>
  );

  const paginatorLeft = <Button type="button" icon="pi pi-refresh" text />;
  const paginatorRight = <Button type="button" icon="pi pi-download" text />;

  return (
    <div>
      <div className="container">
        <div className="row mt-4">
          <div className="col-12 mt-3">
            <div className="mb-3">
              <Fieldset legend="Courses" toggleable>
                <div className="m-0">
                  <div>
                    <h3 className="text-blue-900 fs-4">Course Applications</h3>
                    <DataTable
                      value={course}
                      filters={filters}
                      filterDisplay="row"
                      header={header}
                      footer={foot}
                      tableStyle={{ minWidth: "60rem" }}
                      showGridlines
                      removableSort
                      paginator
                      rows={5}
                      rowsPerPageOptions={[10, 25, 60, 100]}
                      paginatorLeft={paginatorLeft}
                      paginatorRight={paginatorRight}
                      emptyMessage="No Course applications Found."
                    >
                      <Column
                        field="time_created"
                        header="Application Date"
                        sortable
                      />
                      <Column
                        field="course.course_full_name"
                        header="Course"
                        sortable
                      />
                      <Column
                        field="course.course_full_amount"
                        style={{ minWidth: "12rem" }}
                        header="Amount"
                        sortable
                      />
                      <Column
                        field="course.course_length"
                        header="Duration"
                        sortable
                      />
                      <Column
                        field="applicant.phone_number"
                        header="Mobile Number"
                      />
                      <Column
                        field="intake.intake_date_from"
                        header="Start Intake"
                        sortable
                      />
                      <Column
                        field="intake.intake_date_to"
                        header="End Intake"
                        sortable
                      />

                      <Column
                        field="applicant.first_name"
                        header="Applicant Name"
                        sortable
                      />
                      <Column
                        field="applicant.email"
                        header="Applicant Email"
                        sortable
                      />
                      <Column
                        field="applicant.country"
                        header="Applicant Country"
                        sortable
                      />
                    </DataTable>
                  </div>
                </div>
              </Fieldset>
            </div>

            <div className="mb-3">
              <Fieldset legend="Certificates" toggleable>
                <div className="m-0">
                  <div>
                    <h3 className="text-blue-900 fs-4">
                      Generated Certificates
                    </h3>
                    <DataTable
                      value={cert}
                      filters={filters}
                      filterDisplay="row"
                      header={header}
                      footer={footer}
                      tableStyle={{ minWidth: "60rem" }}
                      showGridlines
                      removableSort
                      paginator
                      rows={5}
                      rowsPerPageOptions={[10, 25, 60, 100]}
                      paginatorLeft={paginatorLeft}
                      paginatorRight={paginatorRight}
                      emptyMessage="No Certificates Found."
                    >
                      {/* <Column field="id" header="Serial" /> */}
                      <Column
                        field="user_full_name"
                        header="User Names"
                        sortable
                      />
                      <Column
                        field="course_name"
                        style={{ minWidth: "12rem" }}
                        header="Course"
                        sortable
                      />
                      <Column
                        field="date_created"
                        header="Date Awarded"
                        sortable
                      />
                      <Column field="cert_file" header="Certificate" sortable />
                      <Column
                        field="institution_name"
                        header="Institution"
                        sortable
                      />
                      <Column
                        field="cert_file"
                        header="Certificate"
                        sortable
                        body={(rowData) => (
                          <a
                            href={rowData.cert_file}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <Button
                              type="button"
                              icon="pi pi-download"
                              className="p-button-rounded p-button-success"
                              tooltip="View Certificate"
                              tooltipOptions={{ position: "bottom" }}
                            />
                          </a>
                        )}
                      />
                    </DataTable>
                  </div>
                </div>
              </Fieldset>
            </div>

            {/* <div className="mb-3">
              <Fieldset legend="Scholarship" toggleable>
                <div className="m-0"></div>
              </Fieldset>
            </div>

            <div className="mb-3">
              <Fieldset legend="Funding" toggleable>
                <div className="m-0"></div>
              </Fieldset>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Applications;
