import { useState, useEffect } from 'react'
import { toast } from 'react-hot-toast'
import axios from '../../../API/apiHandler'

//import {useCartContext} from '../../../Context/CartContext'

const CourseIntake = () => {
	//	const {CourseData} = useCartContext()

	const [CourseData, setCourseData] = useState([])


	const fetchCourses = async () => {
		try {
			const token = JSON.parse(localStorage.getItem('fastapi'))
			const response = await axios.get('/api/v1/courses',
				{
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${token}`
					},
					withCredentials: true
				})
			const courses = response?.data
			courses.sort((a, b) => b.id - a.id)
			setCourseData(courses)

		} catch (error) {
		}
	}

	useEffect(() => {
		fetchCourses()
	}, [])


	const [IntakeForm, setIntakeForm] = useState(
		{
			"course_id": 0,
			"intake_year": 0,
			"intake_month": "",
			"intake_title": "",
			"intake_date_from": "",
			"intake_date_to": "",
			"intake_study_mode": "",
			"status": true
		}
	)

	const handleChange = (e) => {
		setIntakeForm({
			...IntakeForm,
			[e.target.name]: e.target.value
		})
	}

	const handleSubmit = (e) => {
		e.preventDefault()

		const course_id = IntakeForm.course_id
		const intake_year = IntakeForm.intake_year
		const intake_month = IntakeForm.intake_month
		const intake_title = IntakeForm.intake_title
		const intake_date_from = IntakeForm.intake_date_from
		const intake_date_to = IntakeForm.intake_date_to
		const intake_study_mode = IntakeForm.intake_study_mode
		const status = IntakeForm.status

		const intakeData = {
			course_id,
			intake_year,
			intake_month,
			intake_title,
			intake_date_from,
			intake_date_to,
			intake_study_mode,
			status
		}

		try {
			const token = JSON.parse(localStorage.getItem('fastapi'))
			axios.post('/api/v1/courses/intakes', intakeData, {
				headers: {
					'Content-Type': 'application/json',
					'Authorization': `Bearer ${token}`
				}
			})
				.then(res => {
					toast.success('Intake Info added')
					window.location.reload()
				})
		} catch (error) {
			toast.error('Error adding Intake')
		}
	}


	return (
		<div>
			<div className="row mb-3">
				<div className="col">
					<div className="d-grid" >
						<button type="button" className="btn btn-warning btn-block shadow" style={{ boxShadow: 'inset 2px 2px 6px #222', border: '2px solid #011' }} data-bs-toggle="modal" data-bs-target="#addintake">
							<span className="fs-5">
								<i className="fas fa-plus-circle"></i> Add Intake (3)
							</span>
						</button>
					</div>

					<div className="modal fade" style={{ overflow: "scroll !important" }} id="addintake" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="addintakeLabel" aria-hidden="true">
						<div className="modal-dialog modal-lg ">
							<div className="modal-content">
								<div className="modal-header">
									<h1 className="modal-title text-blue-900 fs-5" id="addintakeLabel">New Course Intake</h1>
									<button type="button" className="btn-close text-dark" data-bs-dismiss="modal" aria-label="Close"></button>
								</div>
								<div className="modal-body">
									<div className="modalcontainer text-dark">

										<form onSubmit={handleSubmit}>
											<div className="row">
												<div className="col">

													<div>
														<span>Course courses :</span>
														<select className="form-select" aria-label="Default select" onChange={handleChange}
															required
															value={IntakeForm.course_id}
															name='course_id'>
															<option>Select Course</option>
															{CourseData?.length > 0 ? CourseData?.map((courses, index) => (
																<option value={courses.id} key={index}>{courses.course_full_name}</option>
															)

															) : <option>No courses yet</option>}
														</select>
													</div>

													<div className="inputBox">
														<span>Intake Year :</span>
														<input type="number" placeholder="2023" onChange={handleChange}
															required
															value={IntakeForm.intake_year}
															name='intake_year' />
													</div>

													<div className="inputBox">
														<span>Intake Month :</span>
														<input type="text" placeholder="April" onChange={handleChange}
															required
															value={IntakeForm.intake_month}
															name='intake_month' />
													</div>

													<div className="inputBox">
														<span>Intake Title :</span>
														<input type="text" placeholder="" onChange={handleChange}
															required
															value={IntakeForm.intake_title}
															name='intake_title' />
													</div>

													<div className="inputBox">
														<span>Intake Date (From) :</span>
														<input type="date" placeholder="" onChange={handleChange}
															required
															value={IntakeForm.intake_date_from}
															name='intake_date_from' />
													</div>

													<div className="inputBox">
														<span>Intake Date (To) :</span>
														<input type="date" placeholder="" onChange={handleChange}
															required
															value={IntakeForm.intake_date_to}
															name='intake_date_to' />
													</div>

													<div className="inputBox">
														<span>Study Mode :</span>
														<input type="text" placeholder="Online, On-Campus etc" onChange={handleChange}
															required
															value={IntakeForm.intake_study_mode}
															name='intake_study_mode' />
													</div>

												</div>
											</div>

											<input type="submit" value="ADD" className="submit-btn" />

										</form>

									</div>
								</div>
								<div className="modal-footer">
									<button type="button" className="btn btn-secondary text-dark" data-bs-dismiss="modal">Cancel</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default CourseIntake
