import Nav from '../../../components/admin/Nav'
import CoursesAdmin from '../../../components/admin/Courses/CoursesAdmin'

const CourseAdminScreen = () => {
	return (
		<div className='container-fluid'>
			<div className="row">
				<div className="col-12 col-md-2">
					<Nav />
				</div>
				<div className="col-12 col-md-10 mt-5">
					<CoursesAdmin />
				</div>
			</div>
		</div>
	)
}

export default CourseAdminScreen
