import { useCartContext } from "../../../Context/CartContext";

const CartCard = ({ cartItem, carts }) => {
  const { id, name, duration, intake, cost, category } = cartItem;

  const { removeFromCart } = useCartContext();
  return (
    <div>
      {cartItem && (
        <div className="card mb-3 " style={{ border: "2px solid #094886" }}>
          <div className="card-body">
            <div className="d-flex justify-content-between">
              <div className="d-flex flex-row flex-fill align-items-center">
                <div>
                  <img
                    src="https://picsum.photos/900"
                    className="img-fluid"
                    alt={name}
                    style={{ width: "44px", borderRadius: "3%" }}
                  />
                </div>
                <div className="ms-3">
                  <h5 className=" mb-3 text-success">{name}</h5>
                  <p className="small mb-0">Category: {category}</p>
                  <p className="small mt-2">Duration: {duration}</p>
                  <p className="small mt-2">Intake: {intake}</p>
                </div>
              </div>
              <div className="d-flex flex-column flex-xs-row justify-content-between align-items-center">
                <div>
                  <h5 className="mb-0">💰${cost}</h5>
                </div>
                <button
                  type="button"
                  className="btn"
                  onClick={() => removeFromCart(id)}
                >
                  <i className="fas fa-trash-alt fw-700 text-danger"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {carts && (
        <div className="card mb-3 ">
          <div className="card-body">
            <div className="d-flex justify-content-between">
              <div className="d-flex flex-row flex-fill align-items-center">
                <div>
                  <img
                    src="https://picsum.photos/300"
                    className="img-fluid"
                    alt={name}
                    style={{ width: "44px", borderRadius: "3%" }}
                  />
                </div>
                <div className="ms-3">
                  <h5 className=" mb-3 text-success">{name}</h5>
                  <p className="small mb-0">Category: {category}</p>
                  <p className="small mt-2">Duration: {duration}</p>
                </div>
              </div>
              <div className="d-flex flex-column flex-xs-row justify-content-between align-items-center">
                <div>
                  <h5 className="mb-0">💰${cost}</h5>
                </div>
                <button
                  type="button"
                  className="btn"
                  onClick={() => removeFromCart(id)}
                >
                  <i className="fas fa-trash-alt fw-700 text-danger"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CartCard;
