import React, { useState, useEffect, useReducer, useMemo } from "react";
import GlobalContext from "./GlobalContext";
import dayjs from "dayjs";
import axios from "../API/apiHandler";
import { toast } from "react-toastify";

async function savedEventsReducer(state, { type, payload }) {
  const token = JSON.parse(localStorage.getItem("fastapi"));
  switch (type) {
    case "push":
      try {
        // Perform Axios request to add the event
        await axios.post("/api/v1/Events", payload, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });
        // Handle successful response
        toast.success("Event added successfully");
        return [...state, payload];
      } catch (error) {
        // Handle error
        return state;
      }
    case "update":
      return state.map((evt) => (evt.id === payload.id ? payload : evt));
    case "delete":
      try {
        // Perform Axios request to delete the event
        await axios.delete(`/api/v1/Events/${payload.id}`, {
          headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        // Handle successful response
        toast.success("Event deleted");
        return state.filter((evt) => evt.id !== payload.id);
      } catch (error) {
        // Handle error
        return state;
      }
    default:
      throw new Error();
  }
}

function initEvents() {
  const storageEvents = localStorage.getItem("savedEvents");
  const parsedEvents = storageEvents ? JSON.parse(storageEvents) : [];
  return parsedEvents;
}

export default function ContextWrapper(props) {
  const [monthIndex, setMonthIndex] = useState(dayjs().month());
  const [smallCalendarMonth, setSmallCalendarMonth] = useState(null);
  const [daySelected, setDaySelected] = useState(dayjs());
  const [showEventModal, setShowEventModal] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [labels, setLabels] = useState([]);
  const [savedEvents, dispatchCalEvent] = useReducer(
    savedEventsReducer,
    [],
    initEvents
  );

  const [events, setEvents] = useState([]);
  useEffect(() => {
    

    const token = JSON.parse(localStorage.getItem("fastapi"));
    const fetchEvents = async () => {
      try {
        const res = await axios.get(
          "/api/v1/Events",
          
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (res) {
          setEvents(res?.data);
        }
      } catch (error) {
        if (error.response?.data.detail) {
          toast.error(error.response?.data.detail);
        } else {
        }
      }
    };
    fetchEvents();
    
  }, []);

  const filteredEvents = useMemo(() => {
    return savedEvents.filter((evt) =>
      labels
        .filter((lbl) => lbl.checked)
        .map((lbl) => lbl.label)
        .includes(evt.label)
    );
  }, [savedEvents, labels]);

  useEffect(() => {
    localStorage.setItem("savedEvents", JSON.stringify(savedEvents));
  }, [savedEvents]);

  useEffect(() => {
    setLabels((prevLabels) => {
      return [...new Set(savedEvents.map((evt) => evt.label))].map((label) => {
        const currentLabel = prevLabels.find((lbl) => lbl.label === label);
        return {
          label,
          checked: currentLabel ? currentLabel.checked : true,
        };
      });
    });
  }, [savedEvents]);

  useEffect(() => {
    if (smallCalendarMonth !== null) {
      setMonthIndex(smallCalendarMonth);
    }
  }, [smallCalendarMonth]);

  useEffect(() => {
    if (!showEventModal) {
      setSelectedEvent(null);
    }
  }, [showEventModal]);

  function updateLabel(label) {
    setLabels(labels.map((lbl) => (lbl.label === label.label ? label : lbl)));
  }

  return (
    <GlobalContext.Provider
      value={{
        monthIndex,
        setMonthIndex,
        smallCalendarMonth,
        setSmallCalendarMonth,
        daySelected,
        setDaySelected,
        showEventModal,
        setShowEventModal,
        dispatchCalEvent,
        selectedEvent,
        setSelectedEvent,
        savedEvents,
        setLabels,
        labels,
        updateLabel,
        filteredEvents,
      }}
    >
      {props.children}
    </GlobalContext.Provider>
  );
}
