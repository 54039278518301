import { toast } from "react-hot-toast";
import { Link } from "react-router-dom";
import axios from "../../../API/apiHandler";

const Card = (props) => {
  const {
    id,
    title,
    amount,
    duration,
    deadline,
    short_desc,
    location,
    type,
    institution,
  } = props;
  // eligibles,area,

  const token = JSON.parse(localStorage.getItem("fastapi"));
  const DeleteFund = async (id) => {
    try {
      const response = await axios.delete(`/api/v1/funding/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.data) {
        toast.success("Funding deleted");
        window.location.reload();
      }
    } catch (error) {
      if (error.response?.data.detail) {
        toast.error(error.response?.data.detail);
      }
    }
  };

  return (
    <div>
      <article className="fund mb-3">
        <div className="fund_publication">
          <img src="https://picsum.photos/200/" alt="" />
          <div>
            <span className="fund_author">
              <i className="fas fa-user"></i> {institution}
            </span>
            <span className="fund_date">
              <i className="fas fa-calendar-alt"></i> {deadline}
            </span>
            {location && (
              <span className="fund_location">
                <i className="fas fa-map-marker-alt"></i> {location}
              </span>
            )}
          </div>
        </div>
        <div className="fund_info">
          <h2 className="fund_title">{title}</h2>
          <div className="d-flex gap-4 justify-content-between align-items-center">
            <div className="d-flex gap-3">
              <span className="fund_subtitle">Type: {type}</span>
              <span className="fund_subtitle">Duration: {duration}months</span>
            </div>
            <span className="fund_subtitle">${amount}</span>
          </div>
          <p className="fund_description text-break">{short_desc}</p>
          <div className="d-flex gap-3 align-items-center">
            <Link
              to={`/fund/details/${id}`}
              className="btn btn-outline-info "
            >
              <i className="fas fa-info"></i> Details
            </Link>
            {/* delete */}
            <button
              type="button"
              className="btn btn-oultine-danger "
              onClick={() => DeleteFund(id)}
            >
              <i className="fas fa-trash"></i> Delete
            </button>
          </div>
        </div>
      </article>
    </div>
  );
};

export default Card;
