import React from 'react'
import Funding from '../../../components/admin/Funding/Funding'
import Nav from '../../../components/admin/Nav'

const FundingScreen = () => {
	return (
		<div>
			<div className="container-fluid">
				<div className="row">
					<div className="col-12 col-md-2">
						<Nav />
					</div>
					<div className="col-12 col-md-10 mt-5">
						<Funding />
					</div>
				</div>
			</div>
		</div>
	)
}

export default FundingScreen
