import React, { useState } from "react";
// import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";

import axios from "../../API/apiHandler";

const PasswordResetComponent = () => {
  const navigate = useNavigate();
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get("token");

  const [loading, setLoading] = useState(false);
  const handleResetPassword = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (newPassword !== confirmPassword) {
      setErrorMessage("Passwords do not match");
      setLoading(false);
      return;
    }

    if (newPassword.length < 6) {
      setErrorMessage("Password must be at least 6 characters long");
      setLoading(false);
      return;
    }

    if (!token) {
      setErrorMessage("Invalid procedure, use the link sent to your email");
      setLoading(false);
      return;
    }

    try {
      const res = await axios.post(
        `/api/v1/emailing-services/reset-password?token=${token}&new_password=${newPassword}`
      );
      setSuccessMessage(res.data.message);
      toast.success(res.data.message);
      navigate("/login");
    } catch (error) {
      setErrorMessage(error?.response?.data?.detail);
      toast.error(error.response.data?.detail);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="d-flex justify-content-center align-items-center vh-100 bg-primary">
      <div
        className="bg-white rounded-lg shadow border border-solid p-4 w-100"
        style={{ maxWidth: "430px" }}
      >
        <h2 className="text-center fw-bold mb-4">Reset Password</h2>

        <form onSubmit={handleResetPassword}>
          <div className="mb-3">
            <label htmlFor="newPassword" className="form-label">
              New Password:
            </label>
            <input
              type="password"
              id="newPassword"
              className="form-control"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              required
            />
          </div>
          <div className="mb-3">
            <label htmlFor="confirmPassword" className="form-label">
              Confirm Password:
            </label>
            <input
              type="password"
              id="confirmPassword"
              className="form-control"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
          </div>
          {loading ? (
            <button
              type="button"
              className="btn p-3 btn-success w-100"
              disabled
            >
              <i className="fas fa-spinner fa-spin" /> Resetting...
            </button>
          ) : (
            <button
              type="submit"
              className="btn p-3 btn-success fw-bold text-dark w-100"
            >
              Reset Password
            </button>
          )}
        </form>
        {errorMessage && <div className="text-danger mt-2">{errorMessage}</div>}
        {successMessage && (
          <div className="text-success mt-2">{successMessage}</div>
        )}
      </div>
    </div>
  );
};

export default PasswordResetComponent;
