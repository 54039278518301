import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { FilterMatchMode } from "primereact/api";
import { InputText } from "primereact/inputtext";

import { useState, useEffect } from "react";
import { toast } from "react-hot-toast";

import axios from "../../../API/apiHandler";
import Nav from "../Nav";
import Bg from "../../../img/pexels-tima-miroshnichenko-5198252-10@1x.png";

const Students = () => {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    

    try {
      const token = JSON.parse(localStorage.getItem("fastapi"));
      axios
        .get(
          "/api/v1/users",
          
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setUsers(res?.data);
        });
    } catch (error) {
      if (error.response.status === 401) {
        toast.error("You are not authorized to view this page");
      }
    }
    
  }, []);

  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const header = (
    <div className="flex flex-wrap align-items-center justify-content-between gap-2">
      <span className="text-xl text-900 font-bold">All Users</span>
      <div className="flex justify-content-end">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Keyword Search"
          />
        </span>
      </div>
      <Button icon="pi pi-refresh" rounded raised />
    </div>
  );

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    city: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    // representative: { value: null, matchMode: FilterMatchMode.IN },
    // status: { value: null, matchMode: FilterMatchMode.EQUALS },
    profile_completed: { value: null, matchMode: FilterMatchMode.EQUALS },
  });

  const footer = (
    <h3 className="text-blue-900">
      In total there are {users ? users.length : 0} users.
    </h3>
  );

  const paginatorLeft = <Button type="button" icon="pi pi-refresh" text />;
  const paginatorRight = <Button type="button" icon="pi pi-download" text />;
  return (
    <>
      <div
        style={{
          backgroundImage: `url(${Bg})`,
          backgroundRepeat: "no-repeat",
          width: "100%",
          minHeight: "100vh",
        }}
      >
        <div className="container-fluid">
          <div className="row mt-5">
            <div className="col-12 col-md-2">
              <Nav />
            </div>
            <div className="col-12 col-md-10 mt-5">
              <DataTable
                value={users}
                filters={filters}
                filterDisplay="row"
                header={header}
                footer={footer}
                tableStyle={{ minWidth: "60rem" }}
                showGridlines
                removableSort
                paginator
                rows={5}
                rowsPerPageOptions={[5, 10, 25, 50]}
                paginatorLeft={paginatorLeft}
                paginatorRight={paginatorRight}
                emptyMessage="No users found."
              >
                <Column field="id" header="Id" sortable />
                <Column field="user_type" header="User Role" sortable />
                <Column
                  field="first_name"
                  style={{ minWidth: "12rem" }}
                  header="First Name"
                  sortable
                />
                <Column field="last_name" header="Surname" sortable />
                <Column field="email" header="Email" sortable />
                <Column field="phone_number" header="Phone" sortable />
                <Column field="city" header="City" sortable />
                <Column
                  field="profile_completed"
                  header="Profile Completed"
                  sortable
                />
              </DataTable>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Students;
