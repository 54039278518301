import React from "react";
import { useState, useEffect } from "react";
// import { toast } from 'react-hot-toast'
import { toast } from "react-toastify";

import axios from "../../../API/apiHandler";

const AddMedia = ({ uuid, type, title }) => {
  const [libraryResources, setLibraryResources] = useState({});
  const [resId, setResId] = useState(null);

  const [file, setFile] = useState(null);

  const handleChange = (e) => {
    setFile(e.target.files[0]);
  };

  const [isLoading, setIsLoading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  const submitMedia = async (e) => {
    e.preventDefault();

    if (file === null) {
      toast.error("Please select a file");
    }

    setIsLoading(true);

    const formData = new FormData();
    formData.append("file", file);

    try {
      const token = JSON.parse(localStorage.getItem("fastapi"));

      const res = await axios.post(
        `/api/v1/library/resources/${resId}`,
        formData,
        {
          onUploadProgress: (progressEvent) => {
            const { loaded, total } = progressEvent;
            const progress = Math.round((loaded / total) * 100);
            setUploadProgress(progress);
          },
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (res.status === 201 || res.status === 200) {
        toast.success(`${title} ${type} successfully uploaded`);
        window.location.reload();
      }
    } catch (err) {
      if (err.response.data.detail) {
        toast.error(err.response.data.detail);
      } else {
        toast.error("Failed, Try Again later");
        setIsLoading(false);
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const fetchLibraryResources = async () => {
      const token = JSON.parse(localStorage.getItem("fastapi"));
      try {
        const response = await axios.get(`/api/v1/library/institution/${1}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        if (response?.status === 200 || response?.status === 201) {
          setLibraryResources(response?.data);
        }
      } catch (err) {
        if (err.response.data.detail) {
          toast.error(err.response.data.detail);
        } else {
        }
      }
    };

    fetchLibraryResources();
  }, []);

  return (
    <>
      {type && type === "video/mp4" ? (
        <button
          type="button"
          className="btn m-1 btn-warning shadow"
          data-bs-toggle="modal"
          data-bs-target="#addvideo"
        >
          🎥 video
        </button>
      ) : type && type === "audio/mp3" ? (
        <button
          type="button"
          className="btn  btn-warning shadow"
          data-bs-toggle="modal"
          data-bs-target="#addaudio"
        >
          🎥 audio
        </button>
      ) : type && type === "application/pdf" ? (
        <button
          type="button"
          className="btn btn-warning shadow"
          data-bs-toggle="modal"
          data-bs-target="#adddoc"
        >
          🎥 pdf
        </button>
      ) : null}

      <div
        className="modal fade"
        style={{ overflow: "scroll !important" }}
        id="addaudio"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="addaudioLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog  ">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title text-primary fs-5" id="addaudioLabel">
                New Audio {}
              </h1>
              <button
                type="button"
                className="btn-close text-dark"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="modalcontainer text-dark">
                <form onSubmit={submitMedia}>
                  <div className="row">
                    <div className="col">
                      <div className="row">
                        {isLoading ? (
                          <div className="d-flex justify-content-center align-content-center">
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <progress
                                value={uploadProgress}
                                max="100"
                                style={{ width: "100%", height: "10px" }}
                              />
                              <span
                                style={{ marginLeft: "10px", fontSize: "16px" }}
                              >
                                {uploadProgress}%
                              </span>
                            </div>
                          </div>
                        ) : (
                          <div className="col mb-2">
                            <div className="mb-2">
                              <label
                                className="form-label mt-3"
                                htmlFor="core_audio"
                              >
                                {" "}
                                Audio Resource
                              </label>
                              <select
                                className=" form-select"
                                aria-label="Default select"
                                onChange={(e) => {
                                  setResId(e.target.value);
                                }}
                                required
                                value={resId}
                                name="resId"
                              >
                                <option>Select Resource</option>
                                {libraryResources?.length > 0 ? (
                                  libraryResources.map(
                                    (resource, index) =>
                                      resource.resource_type ===
                                        "audio/mp3" && (
                                        <option value={resource.id} key={index}>
                                          {resource.resource_title}{" "}
                                        </option>
                                      )
                                  )
                                ) : (
                                  <option>No Resource</option>
                                )}
                              </select>
                            </div>
                            <div>
                              <label htmlFor="file" className="form-label">
                                Audio{" "}
                              </label>
                              <div className="file-card">
                                <div className="file-input">
                                  <input
                                    type="file"
                                    id="file"
                                    name="file"
                                    required
                                    accept="audio/*"
                                    className="form-group"
                                    onChange={handleChange}
                                  />
                                  <button
                                    className={
                                      file === null ? "" : "bg-success"
                                    }
                                  >
                                    <i className="fa fa-upload"></i>
                                    {file === null
                                      ? "load Audio"
                                      : "audio loaded"}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  {isLoading ? (
                    <div className="d-flex align-items-center justify-content-center">
                      <div className="spinner-border text-info" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    </div>
                  ) : (
                    <input
                      type="submit"
                      value="Upload"
                      className="submit-btn"
                    />
                  )}
                </form>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary  text-dark"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        style={{ overflow: "scroll !important" }}
        id="adddoc"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="adddocLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog  ">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title text-success fs-5" id="adddocLabel">
                Upload Doc {}
              </h1>
              <button
                type="button"
                className="btn-close text-dark"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="modalcontainer text-dark">
                <form onSubmit={submitMedia}>
                  <div className="row">
                    <div className="col">
                      <div className="row">
                        {isLoading ? (
                          <div className="d-flex justify-content-center align-content-center">
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <progress
                                value={uploadProgress}
                                max="100"
                                style={{ width: "100%", height: "10px" }}
                              />
                              <span
                                style={{ marginLeft: "10px", fontSize: "16px" }}
                              >
                                {uploadProgress}%
                              </span>
                            </div>
                          </div>
                        ) : (
                          <div className="col mb-2">
                            <div className="col mb-2">
                              <div className=" mb-2">
                                <label
                                  className="form-label mt-3"
                                  htmlFor="topic_core_text"
                                >
                                  Core Text
                                </label>
                                <select
                                  className=" form-select"
                                  aria-label="Default select"
                                  onChange={(e) => {
                                    setResId(e.target.value);
                                  }}
                                  required
                                  value={resId}
                                  name="resId"
                                >
                                  <option>Document resource</option>
                                  {libraryResources?.length > 0 ? (
                                    libraryResources.map(
                                      (resource, index) =>
                                        resource.resource_type ===
                                          "application/pdf" && (
                                          <option
                                            value={resource.id}
                                            key={index}
                                          >
                                            {resource.resource_title}{" "}
                                          </option>
                                        )
                                    )
                                  ) : (
                                    <option>No resource</option>
                                  )}
                                </select>
                              </div>
                              <div>
                                <label htmlFor="file" className="form-label">
                                  {" "}
                                  Document
                                </label>
                                <div className="file-card">
                                  <div className="file-input">
                                    <input
                                      type="file"
                                      id="file"
                                      name="file"
                                      required
                                      accept="application/pdf"
                                      className="form-group"
                                      onChange={handleChange}
                                    />
                                    <button
                                      className={
                                        file === null ? "" : "bg-success"
                                      }
                                    >
                                      <i className="fa fa-upload"></i>
                                      {file === null
                                        ? "load Document"
                                        : "Document loaded"}
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  {isLoading ? (
                    <div className="d-flex align-items-center justify-content-center">
                      <div className="spinner-border text-info" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    </div>
                  ) : (
                    <input
                      type="submit"
                      value="Upload"
                      className="submit-btn"
                    />
                  )}
                </form>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary  text-dark"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        style={{ overflow: "scroll !important" }}
        id="addvideo"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="addvideoLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog  ">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title text-success fs-5" id="addvideoLabel">
                Upload Video {}
              </h1>
              <button
                type="button"
                className="btn-close text-dark"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="modalcontainer text-dark">
                <form onSubmit={submitMedia}>
                  <div className="row">
                    <div className="col">
                      <div className="row">
                        {isLoading ? (
                          <div className="d-flex justify-content-center align-content-center">
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "1",
                              }}
                            >
                              <progress
                                value={uploadProgress}
                                max="100"
                                style={{ width: "100%", height: "10px" }}
                              />
                              <span
                                style={{ marginLeft: "10px", fontSize: "16px" }}
                              >
                                {uploadProgress}%
                              </span>
                            </div>
                          </div>
                        ) : (
                          <div className="col mb-2">
                            <div className=" mb-2">
                              <label
                                className="form-label mt-3"
                                htmlFor="core_video"
                              >
                                Video Resource
                              </label>
                              <select
                                className=" form-select"
                                aria-label="Default select"
                                onChange={(e) => {
                                  setResId(e.target.value);
                                }}
                                required
                                value={resId}
                                name="resId"
                              >
                                <option>Select resource</option>
                                {libraryResources?.length > 0 ? (
                                  libraryResources?.map(
                                    (resource, index) =>
                                      resource.resource_type ===
                                        "video/mp4" && (
                                        <option value={resource.id} key={index}>
                                          {resource.resource_title}{" "}
                                        </option>
                                      )
                                  )
                                ) : (
                                  <option>No resource</option>
                                )}
                              </select>
                            </div>

                            <div>
                              <label htmlFor="file" className="form-label">
                                Video
                              </label>
                              <div className="file-card">
                                <div className="file-input">
                                  <input
                                    type="file"
                                    id="file"
                                    name="file"
                                    required
                                    accept="video/*"
                                    className="form-group"
                                    onChange={handleChange}
                                  />
                                  <button
                                    className={
                                      file === null ? "" : "bg-success"
                                    }
                                  >
                                    <i className="fa fa-upload"></i>
                                    {file === null
                                      ? "load Video"
                                      : "Video loaded"}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  {isLoading ? (
                    <div className="d-flex align-items-center justify-content-center">
                      <div className="spinner-border text-info" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    </div>
                  ) : (
                    <input
                      type="submit"
                      value="Upload"
                      className="submit-btn"
                    />
                  )}
                </form>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary  text-dark"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddMedia;
