import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import axios from "../../../API/apiHandler";

const ScholarshipDoc = () => {
  const [scholarshipDoc, setScholarshipDoc] = useState({
    sch_id: 0,
    doc_type_id: 1,
    doc_min_grade: "",
    doc_required_status: "",
    doc_min_date_start: "",
    doc_min_date_end: "",
    status: true,
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setScholarshipDoc((prevScholarshipDoc) => {
      return {
        ...prevScholarshipDoc,
        [name]: value,
      };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const sch_id = scholarshipDoc.sch_id;
    const doc_type_id = scholarshipDoc.doc_type_id;
    const doc_min_grade = scholarshipDoc.doc_min_grade;
    const doc_required_status = scholarshipDoc.doc_required_status;
    const doc_min_date_start = scholarshipDoc.doc_min_date_start;
    const doc_min_date_end = scholarshipDoc.doc_min_date_end;
    const status = scholarshipDoc.status;

    const scholarshipDocs = {
      sch_id,
      doc_type_id,
      doc_min_grade,
      doc_required_status,
      doc_min_date_start,
      doc_min_date_end,
      status,
    };

    const id = scholarshipDoc.sch_id;

    try {
      const token = JSON.parse(localStorage.getItem("token"));
      const response = await axios.post(
        `/api/v1/scholarships/document/${id}`,
        scholarshipDocs,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 201 || response.status === 200) {
        toast.success("Scholarship document added successfully");
        window.location.reload();
      }
    } catch (error) {
      toast.error("Error adding scholarship document");
    }
  };

  const [fetchedScholarships, setFetchedScholarships] = useState([]);

  const fetchScholarships = async () => {
    try {
      const token = JSON.parse(localStorage.getItem("fastapi"));
      const response = await axios.get("/api/v1/scholarships", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      setFetchedScholarships(response?.data);
    } catch (error) {}
  };

  useEffect(() => {
    fetchScholarships();
  }, []);

  return (
    <div>
      <div className="d-grid">
        <button
          type="button"
          className="btn btn-warning shadow"
          style={{
            boxShadow: "inset 2px 2px 6px #222",
            border: "2px solid #011",
          }}
          data-bs-toggle="modal"
          data-bs-target="#scholarshipdoc"
        >
          <span className="">
            <i className="fas fa-plus-circle"></i>
            Add Scholarship Doc
          </span>
        </button>
      </div>

      <div
        className="modal fade"
        style={{ overflow: "scroll !important" }}
        id="scholarshipdoc"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="scholarshipdocLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg ">
          <div className="modal-content">
            <div className="modal-header">
              <h1
                className="modal-title text-primary fs-5"
                id="scholarshipdocLabel"
              >
                Add a scholarship Document
              </h1>
              <button
                type="button"
                className="btn-close text-dark"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="modalcontainer text-dark">
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col mb-2">
                      <div>
                        <span> Select a Scholarship :</span>
                        <select
                          className="form-select"
                          aria-label="Default select"
                          onChange={handleChange}
                          required
                          value={scholarshipDoc.sch_id}
                          name="sch_id"
                        >
                          {fetchedScholarships?.length ? (
                            fetchedScholarships.map((sch, index) => (
                              // <option value="0">Select a scholarship</option>
                              <option value={sch.id} key={index}>
                                {sch.sch_title}
                              </option>
                            ))
                          ) : (
                            <option>No scholarship yet</option>
                          )}
                        </select>
                      </div>

                      <div>
                        <span> Document type :</span>
                        <select
                          className="form-select"
                          aria-label="Default select"
                          onChange={handleChange}
                          required
                          value={scholarshipDoc.doc_type_id}
                          name="doc_type_id"
                        >
                          {fetchedScholarships?.length > 0 ? (
                            fetchedScholarships?.map((doc, index) => (
                              <option value={doc.id} key={index}>
                                {doc.doc_title}
                              </option>
                            ))
                          ) : (
                            <option>No document found</option>
                          )}
                        </select>
                      </div>

                      <div className="inputBox">
                        <span>Min Grade :</span>
                        <input
                          type="text"
                          placeholder=""
                          onChange={handleChange}
                          required
                          value={scholarshipDoc.doc_min_grade}
                          name="doc_min_grade"
                        />
                      </div>

                      <div className="inputBox">
                        <span>Doc Status :</span>
                        <input
                          type="text"
                          placeholder=""
                          onChange={handleChange}
                          required
                          value={scholarshipDoc.doc_required_status}
                          name="doc_required_status"
                        />
                      </div>
                      <div className="flex"></div>

                      <div className="flex">
                        <div className="inputBox mb-3">
                          <span>Start Date :</span>
                          <input
                            type="date"
                            placeholder=""
                            onChange={handleChange}
                            required
                            value={scholarshipDoc.doc_min_date_start}
                            name="doc_min_date_start"
                          />
                        </div>

                        <div className="inputBox mb-3">
                          <span>Deadline :</span>
                          <input
                            type="date"
                            className="form-control"
                            name="doc_min_date_end"
                            placeholder=""
                            onChange={handleChange}
                            required
                            value={scholarshipDoc.doc_min_date_end}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <input type="submit" value="ADD" className="submit-btn" />
                </form>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-outline-danger  text-dark"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScholarshipDoc;
