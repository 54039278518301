import { Link } from "react-router-dom";
import UpdateUnit from "./UpdateUnit";
import { toast } from "react-hot-toast";
import axios from "../../../API/apiHandler";

const Card = (props) => {
  const { title, code, short_desc, id, unit } = props;
  // institution,instructor,

  const deleteUnit = async (id) => {
    try {
      const token = JSON.parse(localStorage.getItem("fastapi"));
      await axios.delete(`/api/v1/units/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      toast.success("Unit deleted");
      window.location.reload();
    } catch (error) {}
  };

  return (
    <div className="card shadow">
      <div className="card-body">
        <h5
          className="card-title text-center mb-4"
          style={{ color: "#094886" }}
        >
          {title}
        </h5>
        <h6
          className="card-subtitle mb-3 text-muted text-center"
          style={{ color: "#094886" }}
        >
          Code: {code}
        </h6>
        <p className="card-text">{short_desc}.</p>
        <div className="d-flex justify-content-between gap-3 align-items-center mt-4">
          <div className="mx-2">
            {/* //delete unit */}
            <button
              type="button"
              onClick={() => deleteUnit(id)}
              className="btn btn-danger p-3"
            >
              <i className="fas fa-trash-alt text-danger"></i> Delete
            </button>
          </div>
          <div>
            <UpdateUnit uuid={id} unit={unit} />
          </div>
          <div className="">
            <Link
              to={`/institution/course/unit/topics/${id}`}
              className="btn btn-warning p-3"
            >
              <i className="fas fa-arrow-right"></i> Topics
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Card;
