import { useContext, useState } from "react";
import { toast } from "react-hot-toast";
import { useNavigate, Link } from "react-router-dom";
import ProfileContext from "../../../../Context/ProfileContext";
import axios from "../../../../API/apiHandler";
import Bg from "../../../../img/pexels-kampus-production-5940841-1@1x.png";

const Pg4 = () => {
  const navigate = useNavigate();

  const { formObjects, setFormObjects, token, auth } =
    useContext(ProfileContext);

  // const [, setExper] = useState([]);

  const handleChange = (e, index) => {
    const values = [...formObjects];
    values[index][e.target.name] = e.target.value;
    setFormObjects(values);
  };

  const handleAddForm = () => {
    setFormObjects([
      ...formObjects,
      {
        position: "",
        organization: "",
        industry: "",
        field_of_practice: "",
        reporting_to: "",
        location: "",
        start_date: "",
        end_date: "",
        is_current: false,
        role_description: "",
        role_reference: "",
        role_achievements: "",
      },
    ]);
  };

  const handleRemoveForm = (index) => {
    const values = [...formObjects];
    values.splice(index, 1);
    setFormObjects(values);
  };

  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const experiencePromises = formObjects.map(async (value) => {
        const experienceData = {
          position: value.position,
          organization: value.organization,
          industry: value.industry,
          field_of_practice: value.field_of_practice,
          reporting_to: value.reporting_to,
          location: value.location,
          start_date: value.start_date,
          end_date: value.end_date,
          is_current: value.is_current,
          role_description: value.role_description,
          role_reference: value.role_reference,
          role_achievements: value.role_achievements,
        };

        const formData = new FormData();
        for (const key in experienceData) {
          formData.append(key, experienceData[key]);
        }

        const response = await axios.post(
          "/api/v1/user/profile/experience",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.status === 201 || response.status === 200) {
          return true; // Success indicator for each experience data
        } else {
          return false; // Error indicator for each experience data
        }
      });

      const results = await Promise.all(experiencePromises);

      if (results.every((result) => result === true)) {
        toast.success("Experience Details Saved...");
        setLoading(false);
        navigate("/profile");
      } else {
        toast.error("Failed to save experience details");
      }
    } catch (err) {}
  };

  const handleUpdate = async (idx) => {
    try {
      // Find the form data with the matching experience_index
      const updatedForm = formObjects.find(
        (form) => form.experience_index === idx
      );

      if (!updatedForm) {
        return toast.error(
          "This experience Info does not exist, click Next to save it"
        );
      }

      const experienceData = {
        position: updatedForm.position,
        organization: updatedForm.organization,
        industry: updatedForm.industry,
        field_of_practice: updatedForm.field_of_practice,
        reporting_to: updatedForm.reporting_to,
        location: updatedForm.location,
        start_date: updatedForm.start_date,
        end_date: updatedForm.end_date,
        is_current: updatedForm.is_current,
        role_description: updatedForm.role_description,
        role_reference: updatedForm.role_reference,
        role_achievements: updatedForm.role_achievements,
      };

      const response = await axios.put(
        `/api/v1/user/profile/update-experience?user_id=${auth}&index_id=${idx}`,
        experienceData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 201 || response.status === 200) {
        toast.success("experience Details Updated...");
        navigate("/profile");
      }
    } catch (err) {
      toast.error("Failed to update experience details");
    }
  };

  return (
    <>
      <div
        className="container-fluid"
        style={{
          backgroundImage: `url(${Bg})`,
          backgroundRepeat: "no-repeat",
          width: "100%",
          minHeight: "100vh",
          loading: "lazy",
        }}
      >
        <div className="container p-3">
          {formObjects?.length === 0 ? (
            <div key={0}>
              <div className="card mb-3 border-info border-5 mt-5">
                <center>
                  <h2 className="m-3 fs-3 " style={{ color: "#094886" }}>
                    Professional Experience
                  </h2>
                </center>
                <form onSubmit={handleSubmit} className="">
                  <div className="d-flex gap-3 flex-row-reverse">
                    {/* {formObjects.length !== 1 ? (
                      <button
                        type="button"
                        onClick={() => handleRemoveForm(index)}
                        className="btn btn-outline-danger "
                      >
                        <i className="fas fa-minus"></i> Remove Entry
                      </button>
                    ) : null} */}
                    <button
                      type="button"
                      onClick={() => handleAddForm()}
                      className="btn btn-outline-primary "
                    >
                      <i className="fas fa-plus"></i> Add Another Entry
                    </button>
                  </div>
                  <div className="row">
                    <div className="col-sm-6 col-lg-4">
                      <div className="m-2">
                        <div className="mb-3 mt-3">
                          <label htmlFor="organization" className="form-label">
                            Organization{" "}
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="organization"
                            placeholder="enter organization's name"
                            name="organization"
                            value={formObjects[0]?.organization}
                            onChange={(e) => handleChange(e, 0)}
                          />
                        </div>
                        <div className="mb-3">
                          <label htmlFor="position" className="form-label">
                            Position
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="position"
                            placeholder="your position"
                            name="position"
                            value={formObjects[0]?.position}
                            onChange={(e) => {
                              handleChange(e, 0);
                            }}
                          />
                        </div>
                        <div className="mb-3 mt-3">
                          <label htmlFor="industry" className="form-label">
                            Industry
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="industry"
                            placeholder="enter industry"
                            name="industry"
                            value={formObjects[0]?.industry}
                            onChange={(e) => {
                              handleChange(e, 0);
                            }}
                          />
                        </div>

                        <div className="mb-3">
                          <label htmlFor="reporting_to" className="form-label">
                            Reporting to:
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="reporting_to"
                            placeholder="your supervisor"
                            name="reporting_to"
                            value={formObjects[0]?.reporting_to}
                            onChange={(e) => {
                              handleChange(e, 0);
                            }}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-6 col-lg-4">
                      <div className="m-2">
                        <label htmlFor="start_date" className="col-form-label">
                          Start Date
                        </label>
                        <div className="col-auto mb-3">
                          <div className="input-group date" id="datepicker">
                            <input
                              type="date"
                              className="form-control"
                              name="start_date"
                              id="start_date"
                              value={formObjects[0]?.start_date}
                              onChange={(e) => {
                                handleChange(e, 0);
                              }}
                            />
                          </div>
                        </div>

                        <label htmlFor="is_current" className="col-form-label">
                          Currently Working Here?
                        </label>
                        <select
                          className="form-select mb-3"
                          aria-label="is_current"
                          id="is_current"
                          name="is_current"
                          value={formObjects[0]?.is_current}
                          onChange={(e) => {
                            handleChange(e, 0);
                          }}
                        >
                          <option defaultValue>Select</option>
                          <option value="false">No</option>
                          <option value="true">Yes</option>
                        </select>

                        <label htmlFor="end_date" className="col-form-label">
                          End Date
                        </label>
                        <div className="col-auto mb-3">
                          <div className="input-group date" id="datepicker">
                            <input
                              type="date"
                              className="form-control"
                              name="end_date"
                              id="end_date"
                              value={formObjects[0]?.end_date}
                              disabled={formObjects[0]?.is_current === true}
                              onChange={(e) => {
                                handleChange(e, 0);
                              }}
                            />
                          </div>
                        </div>

                        <div className="mb-3 mt-3">
                          <label htmlFor="location" className="form-label">
                            Location{" "}
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="location"
                            placeholder="enter location"
                            name="location"
                            value={formObjects[0]?.location}
                            onChange={(e) => {
                              handleChange(e, 0);
                            }}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-6 col-lg-4">
                      <div className="m-2">
                        <div className="mb-3 mt-3">
                          <label
                            htmlFor="role_description"
                            className="form-label"
                          >
                            Role Description
                          </label>
                          <textarea
                            placeholder="summary of your this job."
                            className="form-control"
                            id="role_description"
                            rows="5"
                            name="role_description"
                            value={formObjects[0]?.role_description}
                            onChange={(e) => {
                              handleChange(e, 0);
                            }}
                          />
                        </div>

                        <div className="mb-3 mt-3">
                          <label
                            htmlFor="role_achievements"
                            className="form-label"
                          >
                            Role Achievements
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="role_achievements"
                            placeholder=" your achievements"
                            name="role_achievements"
                            value={formObjects[0]?.role_achievements}
                            onChange={(e) => {
                              handleChange(e, 0);
                            }}
                          />
                        </div>

                        <div className="mb-3 mt-3">
                          <label
                            htmlFor="field_of_practice"
                            className="form-label"
                          >
                            Field of Practice
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="field_of_practice"
                            placeholder=" your field of practice"
                            name="field_of_practice"
                            value={formObjects[0]?.field_of_practice}
                            onChange={(e) => {
                              handleChange(e, 0);
                            }}
                          />
                        </div>

                        <div className="mb-3 mt-3">
                          <label
                            htmlFor="role_reference"
                            className="form-label"
                          >
                            Employer Referral
                          </label>
                          <input
                            type="email"
                            className="form-control"
                            id="role_reference"
                            placeholder="reference email"
                            name="role_reference"
                            value={formObjects[0]?.role_reference}
                            onChange={(e) => {
                              handleChange(e, 0);
                            }}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="mt-4 d-flex justify-content-end gap-4">
                      <Link
                        to="/edu"
                        className="btn btn-outline-primary w-25 text-dark"
                      >
                        Back
                      </Link>
                      {loading ? (
                        <button
                          className="bg-orange-500 w-25 border flex gap-1 items-center justify-center py-2.5 text-white rounded"
                          type="button"
                          disabled
                        >
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                          Saving...
                        </button>
                      ) : (
                        <button
                          type="submit"
                          className="btn btn-outline-primary w-25 text-dark"
                        >
                          Next (4/5)
                        </button>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          ) : (
            formObjects?.map((formObject, index) => (
              <div key={index}>
                <div className="card mb-3 border-info border-5 mt-5">
                  <center>
                    <h2 className="m-3 fs-3 " style={{ color: "#094886" }}>
                      Professional Experience
                    </h2>
                  </center>

                  <form onSubmit={handleSubmit} className="">
                    <div className="d-flex gap-3 flex-row-reverse">
                      {formObjects.length !== 1 ? (
                        <button
                          type="button"
                          onClick={() => handleRemoveForm(index)}
                          className="btn btn-outline-danger "
                        >
                          <i className="fas fa-minus"></i> Remove Entry
                        </button>
                      ) : null}

                      <button
                        type="button"
                        onClick={() => handleAddForm()}
                        className="btn btn-outline-primary "
                      >
                        <i className="fas fa-plus"></i> Add Another Entry
                      </button>
                    </div>
                    <div className="row">
                      <div className="col-sm-6 col-lg-4">
                        <div className="m-2">
                          <div className="mb-3 mt-3">
                            <label
                              htmlFor="organization"
                              className="form-label"
                            >
                              Organization{" "}
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="organization"
                              placeholder="enter organization's name"
                              name="organization"
                              value={formObject.organization}
                              onChange={(e) => handleChange(e, index)}
                            />
                          </div>
                          <div className="mb-3">
                            <label htmlFor="position" className="form-label">
                              Position
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="position"
                              placeholder="your position"
                              name="position"
                              value={formObject.position}
                              onChange={(e) => {
                                handleChange(e, index);
                              }}
                            />
                          </div>
                          <div className="mb-3 mt-3">
                            <label htmlFor="industry" className="form-label">
                              Industry
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="industry"
                              placeholder="enter industry"
                              name="industry"
                              value={formObject.industry}
                              onChange={(e) => {
                                handleChange(e, index);
                              }}
                            />
                          </div>

                          <div className="mb-3">
                            <label
                              htmlFor="reporting_to"
                              className="form-label"
                            >
                              Reporting to:
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="reporting_to"
                              placeholder="your supervisor"
                              name="reporting_to"
                              value={formObject.reporting_to}
                              onChange={(e) => {
                                handleChange(e, index);
                              }}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-sm-6 col-lg-4">
                        <div className="m-2">
                          <label
                            htmlFor="start_date"
                            className="col-form-label"
                          >
                            Start Date
                          </label>
                          <div className="col-auto mb-3">
                            <div className="input-group date" id="datepicker">
                              <input
                                type="date"
                                className="form-control"
                                name="start_date"
                                id="start_date"
                                value={formObject.start_date}
                                onChange={(e) => {
                                  handleChange(e, index);
                                }}
                              />
                            </div>
                          </div>

                          <label
                            htmlFor="is_current"
                            className="col-form-label"
                          >
                            Currently Working Here?
                          </label>
                          <select
                            className="form-select mb-3"
                            aria-label="is_current"
                            id="is_current"
                            name="is_current"
                            value={formObject.is_current}
                            onChange={(e) => {
                              handleChange(e, index);
                            }}
                          >
                            <option defaultValue>Select</option>
                            <option value="false">No</option>
                            <option value="true">Yes</option>
                          </select>

                          <label htmlFor="end_date" className="col-form-label">
                            End Date
                          </label>
                          <div className="col-auto mb-3">
                            <div className="input-group date" id="datepicker">
                              <input
                                type="date"
                                className="form-control"
                                name="end_date"
                                id="end_date"
                                value={formObject.end_date}
                                disabled={formObject.is_current === true}
                                onChange={(e) => {
                                  handleChange(e, index);
                                }}
                              />
                            </div>
                          </div>

                          <div className="mb-3 mt-3">
                            <label htmlFor="location" className="form-label">
                              Location{" "}
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="location"
                              placeholder="enter location"
                              name="location"
                              value={formObject.location}
                              onChange={(e) => {
                                handleChange(e, index);
                              }}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-sm-6 col-lg-4">
                        <div className="m-2">
                          <div className="mb-3 mt-3">
                            <label
                              htmlFor="role_description"
                              className="form-label"
                            >
                              Role Description
                            </label>
                            <textarea
                              placeholder="summary of your this job."
                              className="form-control"
                              id="role_description"
                              rows="5"
                              name="role_description"
                              value={formObject.role_description}
                              onChange={(e) => {
                                handleChange(e, index);
                              }}
                            />
                          </div>

                          <div className="mb-3 mt-3">
                            <label
                              htmlFor="role_achievements"
                              className="form-label"
                            >
                              Role Achievements
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="role_achievements"
                              placeholder=" your achievements"
                              name="role_achievements"
                              value={formObject.role_achievements}
                              onChange={(e) => {
                                handleChange(e, index);
                              }}
                            />
                          </div>

                          <div className="mb-3 mt-3">
                            <label
                              htmlFor="field_of_practice"
                              className="form-label"
                            >
                              Field of Practice
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="field_of_practice"
                              placeholder=" your field of practice"
                              name="field_of_practice"
                              value={formObject.field_of_practice}
                              onChange={(e) => {
                                handleChange(e, index);
                              }}
                            />
                          </div>

                          <div className="mb-3 mt-3">
                            <label
                              htmlFor="role_reference"
                              className="form-label"
                            >
                              Employer Referral
                            </label>
                            <input
                              type="email"
                              className="form-control"
                              id="role_reference"
                              placeholder="reference email"
                              name="role_reference"
                              value={formObject.role_reference}
                              onChange={(e) => {
                                handleChange(e, index);
                              }}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="mt-4 d-flex justify-content-end gap-4">
                        <Link
                          to="/edu"
                          className="btn btn-outline-primary w-25 text-dark"
                        >
                          Back
                        </Link>

                        {loading ? (
                          <button
                            className="bg-orange-500 w-25 border flex gap-1 items-center justify-center py-2.5 text-white rounded"
                            type="button"
                            disabled
                          >
                            <span
                              className="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            Updating...
                          </button>
                        ) : (
                          <button
                            type="button"
                            className="btn btn-outline-warning w-25 text-dark"
                            onClick={() => {
                              handleUpdate(formObject.experience_index);
                            }}
                          >
                            Update
                          </button>
                        )}
                        {loading ? (
                          <button
                            className="bg-orange-500 w-25 border flex gap-1 items-center justify-center py-2.5 text-white rounded"
                            type="button"
                            disabled
                          >
                            <span
                              className="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            Saving...
                          </button>
                        ) : (
                          <button
                            type="submit"
                            className="btn btn-outline-primary w-25 text-dark"
                          >
                            Next (4/5)
                          </button>
                        )}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </>
  );
};

export default Pg4;
