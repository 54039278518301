import { Link } from "react-router-dom";
import { toast } from "react-hot-toast";
import axios from "../../../API/apiHandler";

// import ApplyFund from "./ApplyFund";

const Card = (props) => {
  const {
    id,
    title,
    amount,
    type,
    location,
    deadline,
    duration,
    short_desc,
    institution,
  } = props;
  // full_desc, area,eligibles,
  const handleSubmit = async (e) => {
    e.preventDefault();

    const token = JSON.parse(localStorage.getItem("fastapi"));

    const data = {
      funding_id: id,
    };
    try {
      const res = await axios.post(
        "/api/v1/funding/applications/funding",
        data,
        {
          headers: {
            accept: "application/json",
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (res?.data) toast.success("application submitted...!");
    } catch (error) {
      toast.error("Something went wrong, try again later...!");
    }
  };
  return (
    <div>
      <article className="fund mb-3">
        <div className="fund_publication bg-blue-900">
          <img src="https://picsum.photos/200/" alt={title} />
          <div>
            <span className="fund_author">
              <i className="fas fa-user"></i> {institution}
            </span>
            <span className="fund_date">
              <i className="fas fa-calendar-alt"></i> {deadline}
            </span>
            {location && (
              <span className="fund_location">
                <i className="fas fa-map-marker-alt"></i> {location}
              </span>
            )}
          </div>
        </div>
        <div className="fund_info">
          <h2 className="fund_title">{title}</h2>
          <div className="d-flex gap-4 justify-content-between align-items-center">
            <div className="d-flex gap-3">
              <span className="fund_subtitle">Type: {type}</span>
              <span className="fund_subtitle">Duration: {duration} months</span>
            </div>
            <span className="fund_subtitle">${amount}</span>
          </div>
          <p className="fund_description text-break">{short_desc}</p>
          <div className="d-flex gap-3 align-items-center mt-2">
            <Link
              to={`/fundings/details/${id}`}
              className="btn btn-success p-3 card_cta"
            >
              <i className="fas fa-info"></i> Details
            </Link>
            <button
              className="btn btn-outline-primary text-dark"
              onClick={handleSubmit}
            >
              Apply
            </button>
          </div>
        </div>
      </article>
    </div>
  );
};

export default Card;
