import { Outlet, useLocation, Navigate, } from "react-router-dom";
import { useContext } from "react";
import { toast } from 'react-hot-toast';
import jwt_decode from 'jwt-decode';
import ProfileContext from '../../Context/ProfileContext';

const AdminRoute = () => {
	const { token, setToken } = useContext(ProfileContext);
	const location = useLocation();

	try {
		const decoded = jwt_decode(token);
		const { sub } = decoded

		if (!sub.startsWith( 'admin')) {
			toast.error("You are not authorized to access this page.");
			window.localStorage.clear();
			return <Navigate to={"/"} state={{ from: location }} replace={true} />
		}

		return <Outlet />;
	} catch (error) {
		toast.error("Invalid token. Please log in again.");
		setToken("");
		return <Navigate to={"/login"} state={{ from: location }} replace={true} />;
	}
};

export default AdminRoute;