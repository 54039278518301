import { useState, useEffect } from "react";
// import ProfileContext from '../../../Context/ProfileContext';

import axios from "../../../API/apiHandler";

import Card from "./Card";

const Scholarship = () => {
  const [fetchedScholarships, setFetchedScholarships] = useState([]);

  // const {
  // 	levelofstudy,
  // 	setLevelofstudy,
  // 	genareaofstudy,
  // 	setGenAreaofstudy,
  // 	location,
  // 	setLocation,
  // 	eligibility,
  // 	setEligibility,
  // } = useContext(ProfileContext);

  const fetchScholarships = async () => {

    try {
      const token = JSON.parse(localStorage.getItem("fastapi"));
      const response = await axios.get("/api/v1/scholarships", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      response && setFetchedScholarships(response?.data);
    } catch (error) {}
  };

  useEffect(() => {
    fetchScholarships();
  }, []);

  const [search, setSearch] = useState("");

  return (
    <div>
      <div>
        <div className="container mt-5" style={{ overflow: "scroll" }}>
          <div className="d-flex flex-column">
            <div className="row mb-2">
              <div className="col">
                <h3>Apply Now</h3>
              </div>
            </div>

            {/* <div className="row">
							<div className="col">
								<div className="d-flex gap-2 justify-content-evenly">
									<div>
										<label htmlFor="levelofstudy" className="col-form-label">Select level of study</label>
										<select className="form-select mb-3" aria-label="levelofstudy" value={levelofstudy}
											onChange={(e) => {
												setLevelofstudy(e.target.value);
											}}>
											<option defaultValue>Level Of Study</option>
											<option value="diploma">diploma</option>
											<option value="undergraduate">Undergraduate</option>
											<option value="masters">Masters</option>
											<option value="PHD">pHD</option>
										</select>
									</div>

									<div>
										<label htmlFor="areaofstudy" className="col-form-label">General area of study</label>
										<select className="form-select mb-3" aria-label="areaofstudy" value={genareaofstudy}
											onChange={(e) => { setGenAreaofstudy(e.target.value); }}>
											<option defaultValue>Area Of Study</option>
											<option value="computer science">Computer Science</option>
											<option value="engineering">Engineering</option>
											<option value="business">Business</option>
											<option value="law">Law</option>
										</select>
									</div>

									<div>
										<label htmlFor="location" className="col-form-label">Select Your Location</label>
										<select className="form-select mb-3" aria-label="location" value={location} onChange={(e) => { setLocation(e.target.value); }}>
											<option defaultValue>Location</option>
											<option value="Nairobi">Nairobi</option>
											<option value="Mombasa">Mombasa</option>
											<option value="Kisumu">Kisumu</option>
											<option value="Nakuru">Nakuru</option>
										</select>
									</div>

									<div>
										<label htmlFor="eligibility" className="col-form-label">Eligibility </label>
										<select className="form-select mb-3" aria-label="eligibility" value={eligibility} onChange={(e) => { setEligibility(e.target.value); }}>
											<option defaultValue>Eligibility</option>
											<option value="visa">Access to VISA</option>
											<option value="18+">Over 18 years</option>
											<option value="financial">Financially stable</option>
											<option value="english">English Proficiency</option>
										</select>
									</div>
								</div>
							</div>
						</div> */}

            <div className="row">
              <div className="col mt-4">
                {/* full width search box filter */}
                <div className="input-group mb-3">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search for Scholarship"
                    aria-label="Search for Scholarship"
                    aria-describedby="button-addon2"
                    onChange={(e) => {
                      setSearch(e.target.value);
                    }}
                  />
                  <button
                    className="btn btn-outline-secondary"
                    type="button"
                    id="button-addon2"
                  >
                    Search
                  </button>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12 mt-3">
                {fetchedScholarships.length > 0 ? (
                  fetchedScholarships
                    .filter((fetchedScholarship) => {
                      return search.toLowerCase() === ""
                        ? fetchedScholarship
                        : fetchedScholarship.sch_title
                            .toLowerCase()
                            .includes(search.toLowerCase());
                    })
                    .map((fetchedScholarship, index) => (
                      <div className="" key={index}>
                        <Card
                          id={fetchedScholarship.id}
                          title={fetchedScholarship.sch_title}
                          amount={fetchedScholarship.sch_amount}
                          eligibles={fetchedScholarship.sch_eligibility}
                          deadline={fetchedScholarship.sch_deadline_date}
                          short_desc={fetchedScholarship.sch_short_desc}
                          full_desc={fetchedScholarship.sch_full_desc}
                          type={fetchedScholarship.sch_type_of_funding}
                          area={fetchedScholarship.sch_area_of_study}
                          institution={fetchedScholarship.institution.inst_name}
                          level={fetchedScholarship.sch_level_of_study}
                          location={fetchedScholarship.sch_location}
                          duration={fetchedScholarship.sch_duration}
                        />
                      </div>
                    ))
                ) : (
                  <div className="text-danger mt-5 text-center">
                    <span>No scholarships yet....</span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Scholarship;
