import React from 'react'
import Sidebar from '../../../components/Student/Sidebar'
import Settings from '../../../components/Student/Settings/Settings'

const SettingsScreen = () => {
	return (
		<div>
			<div className="container-fluid">
				<div className="row">
					<div className="col-12 col-md-2">
						<Sidebar />
					</div>
					<div className="col-12 col-md-10 mt-4">
						<Settings />
					</div>
				</div>
			</div>
		</div>
	)
}

export default SettingsScreen
