import { useContext, useEffect, useState } from "react";
import ProfileContext from "../../../Context/ProfileContext";
import LoadingOverlay from "../../Load/LoadingOverlay.jsx";
import { toast } from "react-hot-toast";
import axios from "../../../API/apiHandler";

const PWD_REGEX = /^(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.*[0-9])(?=.*[a-z]).{8,}$/;

const Settings = () => {
  const { token, auth, file } = useContext(ProfileContext);
  const [fetchedProfile, setFetchedProfile] = useState("");

  const [resetPsd, setResetPsd] = useState({
    current_password: "",
    new_password: "",
    confirm_password: "",
  });

  const { new_password, confirm_password } = resetPsd;
  function handleChange(event) {
    const { name, value } = event.target;
    setResetPsd((prevResetPsd) => {
      return {
        ...prevResetPsd,
        [name]: value,
      };
    });
  }

  const [loading, setLoading] = useState(false);
  const [showPwd, setShowPwd] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const old_password = resetPsd.current_password;
    const new_password = resetPsd.new_password;
    const confirm_new_password = resetPsd.confirm_password;

    const url = `/api/v1/users/users/${auth}/reset_password?old_password=${encodeURIComponent(
      old_password
    )}&new_password=${encodeURIComponent(
      new_password
    )}&confirm_new_password=${encodeURIComponent(confirm_new_password)}`;

    const v5 = PWD_REGEX.test(new_password);

    if (!v5)
      toast.error(
        "password must have at least one uppercase letter, have at least one special character(!, @, #, $, %, ^, &, *), have at least one numerical digit, have at least one lowercase letter, have at least 8 characters"
      );

    if (new_password !== confirm_password)
      toast.error("Your new password & confirm password dont match");

    try {
      const res = await axios.put(url, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (res.status === 200 || res.status === 201) {
        toast.success("password changed!!");
      }
    } catch (err) {
      if (err.response.data.detail) {
        toast.error(err.response.data.detail);
      } else {
        toast.error("An unexpected error occurred. Please try again later.");
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    

    const fetchUser = async () => {
      try {
        const res = await axios.get(
          `/api/v1/user/profile/${auth}`,
          
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setFetchedProfile(await res.data);
      } catch (err) {
        if (err.response.data.detail) toast.error(err.response.data.detail);
      }
    };
    fetchUser();
    
  }, [token, auth]);

  return (
    <div className="container-fluid mt-5">
      <div className=" mt-5 fs-5">
        {
          fetchedProfile.first_name ? (
            <div className="row justify-content-center">
              <div className="col-12 col-lg-10 col-xl-8 mx-auto">
                <h2 className="h2 mt-5 page-title">Settings</h2>
                <div className="mt-4">
                  <div className="row mt-5 align-items-center">
                    <div className="col-md-3 text-center mb-5">
                      <div className="avatar avatar-xl">
                        <img
                          src={
                            file ||
                            "https://bootdey.com/img/Content/avatar/avatar6.png"
                          }
                          alt="..."
                          className="avatar-img rounded-circle"
                          style={{ height: "130px", width: "130px" }}
                        />
                      </div>
                    </div>
                    <div className="col">
                      <div className="row align-items-center">
                        <div className="col-md-7">
                          <h4 className="mb-1">
                            {fetchedProfile.first_name}&#160;
                            {fetchedProfile.last_name}
                          </h4>
                          <p className="small mb-3">
                            <span className="badge badge-dark text-warning">
                              {fetchedProfile.city}
                            </span>
                          </p>
                        </div>
                      </div>
                      <div className="row mb-4">
                        <div className="col-md-7">
                          <p className="text-muted">{}</p>
                        </div>
                        <div className="col">
                          <p className="small mb-0 text-muted">
                            {fetchedProfile.nationality}
                          </p>
                          <p className="small mb-0 text-muted">
                            Role:{fetchedProfile.user_type}
                          </p>
                          <p className="small mb-0 text-muted">
                            {fetchedProfile.phone_number}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr className="my-4" />
                  <form onSubmit={handleSubmit}>
                    <div className="row mb-4">
                      <div className="col-md-6">
                        <div
                          className="form-group"
                          style={{ position: "relative" }}
                        >
                          <label htmlFor="current_password">Old Password</label>
                          <input
                            className="form-control"
                            type={showPwd ? "text" : "password"}
                            id="current_password"
                            onChange={handleChange}
                            autoComplete="off"
                            placeholder="enter old passcode"
                            name="current_password"
                            value={resetPsd.current_password}
                            required
                          />
                          <button
                            style={{
                              background: "none",
                              border: "none",
                              fontSize: "1.3em",
                              cursor: "pointer",
                              position: "absolute",
                              right: 10,
                              top: "65%",
                              transform: "translateY(-50%)",
                            }}
                            type="button"
                            onClick={() => setShowPwd(!showPwd)}
                          >
                            {showPwd ? (
                              <i
                                className="fa fa-eye-slash"
                                style={{ color: "#333" }}
                              />
                            ) : (
                              <i
                                className="fa fa-eye"
                                style={{ color: "#999" }}
                              />
                            )}
                          </button>
                        </div>
                        <div
                          className="form-group"
                          style={{ position: "relative" }}
                        >
                          <label htmlFor="new_password">New Password</label>
                          <input
                            className="form-control"
                            type={showPwd ? "text" : "password"}
                            id="new_password"
                            onChange={handleChange}
                            autoComplete="off"
                            placeholder="enter new passcode"
                            name="new_password"
                            value={resetPsd.new_password}
                            required
                            style={{
                              border:
                                new_password === confirm_password
                                  ? "2px solid green"
                                  : "2px solid red",
                            }}
                          />
                          <button
                            style={{
                              background: "none",
                              border: "none",
                              fontSize: "1.3em",
                              cursor: "pointer",
                              position: "absolute",
                              right: 10,
                              top: "65%",
                              transform: "translateY(-50%)",
                            }}
                            type="button"
                            onClick={() => setShowPwd(!showPwd)}
                          >
                            {showPwd ? (
                              <i
                                className="fa fa-eye-slash"
                                style={{ color: "#333" }}
                              />
                            ) : (
                              <i
                                className="fa fa-eye"
                                style={{ color: "#999" }}
                              />
                            )}
                          </button>
                        </div>
                        <div
                          className="form-group"
                          style={{ position: "relative" }}
                        >
                          <label htmlFor="confirm_password">
                            Confirm Password
                          </label>
                          <input
                            className="form-control"
                            type={showPwd ? "text" : "password"}
                            id="confirm_password"
                            onChange={handleChange}
                            autoComplete="off"
                            placeholder="confirm your passcode"
                            name="confirm_password"
                            value={resetPsd.confirm_password}
                            required
                            style={{
                              border:
                                new_password === confirm_password
                                  ? "2px solid green"
                                  : "2px solid red",
                            }}
                          />
                          <button
                            style={{
                              background: "none",
                              border: "none",
                              fontSize: "1.3em",
                              cursor: "pointer",
                              position: "absolute",
                              right: 10,
                              top: "65%",
                              transform: "translateY(-50%)",
                            }}
                            type="button"
                            onClick={() => setShowPwd(!showPwd)}
                          >
                            {showPwd ? (
                              <i
                                className="fa fa-eye-slash"
                                style={{ color: "#333" }}
                              />
                            ) : (
                              <i
                                className="fa fa-eye"
                                style={{ color: "#999" }}
                              />
                            )}
                          </button>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <p className="mb-2">Password Requirements</p>
                        <p className="small text-muted mb-2">
                          To create a new password, you have to meet all of the
                          following requirements:
                        </p>
                        <ul className="small text-muted pl-4 mb-0">
                          <li>Minimum 8 character</li>
                          <li>At least one special character(@ # $ % & - +)</li>
                          <li>At least one number(1,2,3,4)</li>
                          <li>Can't be the same as a previous password</li>
                        </ul>
                      </div>
                    </div>
                    <hr />
                    {loading ? (
                      <button className="btn btn-outline-warning btn-lg text-warning btn-block w-100">
                        <div className="d-flex justify-content-center">
                          <div
                            className="spinner-border text-success"
                            role="status"
                          >
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        </div>
                      </button>
                    ) : (
                      <button
                        className="btn btn-success btn-lg text-warning btn-block w-100"
                        disabled={new_password !== confirm_password}
                        type="submit"
                      >
                        Save
                      </button>
                    )}
                  </form>
                </div>
              </div>
            </div>
          ) : (
            <LoadingOverlay />
          )
          // <div className="container mt-5 min-vh-100">
          // 	<div className='text-danger mt-5 text-center'>
          // 		<div className='d-flex align-items-center justify-content-center mt-5'>
          // 			<span className=" spinner-border spinner-border-sm text-success" role="status" aria-hidden="true"></span>
          // 			<span>Loading...</span>
          // 		</div>
          // 	</div>
          // </div>
        }
      </div>
    </div>
  );
};

export default Settings;
