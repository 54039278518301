// import { useState } from 'react'
//import { Link } from 'react-router-dom'

import Sidebar from '../Sidebar'
import Scheduler from './Scheduler'

const Conferencing = () => {

	const RedirectToExternal = ({ url }) => {
		window.open('https://live.acts-pathways-academy.org/b', '_blank');
		return null;
	};

	// const [date, setDate] = useState(new Date());
	// const [selectedDate, setSelectedDate] = useState(null);
	// const today = new Date();
	// const monthNames = [
	// 	'January', 'February', 'March', 'April', 'May', 'June', 'July',
	// 	'August', 'September', 'October', 'November', 'December'
	// ];
	// const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

	// const firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
	// const lastDayOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);



	return (
		<div>
			<div className="container">
				<div className="row ">
					<div className="col-12 col-md-2 mb-4">
						<Sidebar />
					</div>

					<div className="col-12 col-md-10 mt-5">
						<div className="d-flex mt-5 justify-content-end align-items-center">
							<button onClick={RedirectToExternal} className="btn btn-warning border">
								<i className="fa fa-video-camera"></i> &nbsp;Start a meeting
								<i className="fa fa-arrow-right"></i>
							</button>
						</div>

						<div className="container-fluid">
							<div className="row">
								<div className="col-12 mt-4">
									<Scheduler />
								</div>
							</div>
						</div>

					</div>
				</div>
			</div>
		</div>
	)
}

export default Conferencing
