import React from 'react'
import Scholarship from '../../../components/Student/Scholarship/Scholarship'
import Sidebar from '../../../components/Student/Sidebar'

const SchorlarshipScreen = () => {
	return (
		<div className='container-fluid'>
			<div className="row">
				<div className="col-12 col-md-2">
					<Sidebar />
				</div>
				<div className="col-12 col-md-10 mt-5">
					<Scholarship />
				</div>
			</div>
		</div>
	)
}

export default SchorlarshipScreen
