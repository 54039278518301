import { Link, Navigate } from "react-router-dom";
import { TabView, TabPanel } from "primereact/tabview";
// import CryptoJS from 'crypto-js';
import { useState, useEffect } from "react";
import { toast } from "react-hot-toast";
import { useParams } from "react-router-dom";
// import ReactAudioPlayer from 'react-audio-player';
import { DefaultPlayer as Video } from "react-html5video";
import "react-html5video/dist/styles.css";
import ProfileContext from "../../../Context/ProfileContext";
import { useContext } from "react";

import axios from "../../../API/apiHandler";
const Class = () => {
  const { token, auth } = useContext(ProfileContext);

  const { id } = useParams();
  // const location = useLocation();
  // const { courses } = location.state;

  const [fetchedTopics, setFetchedTopics] = useState({});

  const [isLoading, setIsLoading] = useState(false);
  const [downloadProgress, setDownloadProgress] = useState(0);

  const [percentWatchedArr, setPercentWatchedArr] = useState([]);

  const [courses, setCourses] = useState([]);

  useEffect(() => {
    const fetchEnrolled = async () => {
      const token = JSON.parse(localStorage.getItem("fastapi"));
      try {
        const response = await axios.get(
          "/api/v1/studyhub/course/applications",
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response) {
          setCourses(response?.data);
        }
      } catch (error) {}
    };

    fetchEnrolled();
  }, []);

  const isValidUnitId =
    courses?.length &&
    courses.some((course) =>
      course?.units.some((unit) => unit.id === parseInt(id))
    );

  // fetch topics progress
  useEffect(() => {
    const fetchTopics = async () => {
      setIsLoading(true);
      try {
        const topics = fetchedTopics?.topics;
        const numTopics = topics.length;
        const progressArr = [];

        // Fetch progress for each topic
        for (let i = 0; i < numTopics; i++) {
          const topicId = topics[i].id;
          const res = await axios.get(
            `/api/v1/course_progress/topic-progress/${auth}/${topicId}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            }
          );
          const topicProgressArr = res?.data;
          const newPercentWatchedArr = fetchedTopics.topics.map((topic) => {
            const topicProgress = topicProgressArr.find(
              (progress) => progress.topic_id === topic.id
            );
            return topicProgress ? topicProgress?.user_topic_progress : 0;
          });
          setPercentWatchedArr(newPercentWatchedArr);
        }

        setPercentWatchedArr(progressArr);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };

    fetchTopics();
  }, [auth, fetchedTopics, token]);

  // handle time update
  const handleTimeUpdate = async (event, index) => {
    const percentWatched =
      (event.target.currentTime / event.target.duration) * 100;
    const newPercentWatchedArr = [...percentWatchedArr];
    newPercentWatchedArr[index] = percentWatched;
    setPercentWatchedArr(newPercentWatchedArr);

    const numTopics = fetchedTopics?.topics.length;

    if (percentWatched === 100) {
      toast.success("watched 100%");
      const topicId = fetchedTopics?.topics[index].id;
      const data = {
        topic_id: topicId,
        user_topic_progress: 100,
      };
      console.log(data);
      try {
        const res = await axios.post(
          "/api/v1/course_progress/topic-progress",
          data,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        // Handle successful submission
        if (res.status === 200 || res.status === 201) {
          toast.success(
            `${fetchedTopics.topics[index].topic_full_name} completed successfully`
          );
        }
      } catch (error) {
        toast.error("could not save topic progress");
        console.error(error);
      }
    }

    if (newPercentWatchedArr.length === numTopics) {
      const isCompleted = newPercentWatchedArr.every(
        (perWatched) => perWatched === 100
      );
      if (isCompleted) {
        const unit_id = fetchedTopics?.id;
        const data = {
          unit_id: unit_id,
          user_unit_progress: 100,
        };

        try {
          const res = await axios.post(
            "/api/v1/course_progresscreate-/unit-progress",
            data,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            }
          );
          if (res.status === 200 || res.status === 201) {
            toast.success(
              `${fetchedTopics.unit_full_name} completed successfully`
            );
          }
        } catch (error) {
          toast.error("could not save unit progress");
        }
      }
    }
  };

  useEffect(() => {
    const fetchUnit = async () => {
      const token = JSON.parse(localStorage.getItem("fastapi"));
      try {
        // if (isValidUnitId) {
          const response = await axios.get(`/api/v1/units/${id}`, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          });
          setFetchedTopics(response?.data);
        // } else {
        //   toast.error("Apologies, You are not enrolled to this unit");
        //   Navigate("/studyhub");
        // }
      } catch (error) {
        console.log(error);
      }
    };

    fetchUnit();
  }, [id]);

  const [vid, setVid] = useState([]);
  const [doc, setDoc] = useState();
  const [audio, setAudio] = useState();

  useEffect(() => {
    setIsLoading(true);

    Promise.all(
      fetchedTopics?.topics?.map(async (topic) => {
        const id = topic?.topic_core_video;
        try {
          const token = JSON.parse(localStorage.getItem("fastapi"));
          const res = await axios.get(
            `/api/v1/library/resources/stream/${id}`,
            {
              onDownloadProgress: (progressEvent) => {
                const { loaded, total } = progressEvent;
                const progress = Math.round((loaded / total) * 100);
                setDownloadProgress(progress);
              },
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
              responseType: "blob",
            }
          );
          const blob = new Blob([res?.data], { type: "video/mp4" });
          const url = window.URL.createObjectURL(blob);
          return url;
        } catch (error) {}
      })
    ).then((urls) => {
      setVid(urls);
      setIsLoading(false);
    });
  }, [fetchedTopics?.topics]);

  useEffect(() => {
    const fetchDoc = async () => {
      setIsLoading(true);

      const token = JSON.parse(localStorage.getItem("fastapi"));

      fetchedTopics?.topics?.map(async (topic) => {
        const id = topic?.topic_core_text;
        try {
          const response = await axios.get(
            `/api/v1/library/resources/stream/${id}`,
            {
              onDownloadProgress: (progressEvent) => {
                const { loaded, total } = progressEvent;
                const progress = Math.round((loaded / total) * 100);
                setDownloadProgress(progress);
              },
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
              responseType: "blob",
            }
          );
          if (response) {
            const blob = new Blob([response?.data], {
              type: "application/pdf",
            });
            const url = URL.createObjectURL(blob);
            setDoc(url);
          }
        } catch (error) {
        } finally {
          setIsLoading(false);
        }
      });
    };

    fetchDoc();
  }, [fetchedTopics?.topics]);

  useEffect(() => {
    setIsLoading(true);

    const fetchAudio = async () => {
      const token = JSON.parse(localStorage.getItem("fastapi"));

      fetchedTopics?.topics?.map(async (topic) => {
        const id = topic?.topic_core_audio;

        try {
          const response = await axios.get(
            `/api/v1/library/resources/stream/${id}`,
            {
              onDownloadProgress: (progressEvent) => {
                const { loaded, total } = progressEvent;
                const progress = Math.round((loaded / total) * 100);
                setDownloadProgress(progress);
              },
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
              responseType: "blob",
            }
          );
          if (response) {
            const blob = new Blob([response?.data], { type: "audio/mp3" });
            const url = window.URL.createObjectURL(blob);
            setAudio(url);
          }
        } catch (error) {
        } finally {
          setIsLoading(false);
        }
      });
    };

    fetchAudio();
  }, [fetchedTopics?.topics]);

  return (
    <>
      {fetchedTopics?.id ? (
        <div className="container-fluid p-6">
          <div className="row bg-black mb-3" style={{ height: "130px" }}>
            <div className=" col-12 w-full">
              <div className="d-flex w-full mt-3 justify-content-between">
                <h3 className="text-primary fs-5">Class</h3>
                <Link to="/studyhub" className="btn btn-outline-primary">
                  <i className="fas fa-arrow-left"></i>
                  Go Back
                </Link>
              </div>
              <div
                className="row mt-4"
                style={{ height: "50px", backgroundColor: "#094886" }}
              >
                <div className="col-12 d-flex align-items-center justify-content-between">
                  <div></div>
                  <h4 className="fs-5 text-light text-center">
                    {fetchedTopics?.unit_full_name}
                  </h4>
                  <div></div>
                  {/* <div
                    className="progress bg-light"
                    style={{
                      height: "50px",
                      width: "50px",
                      borderRadius: "50%",
                    }}
                  >
                    <div
                      className="progress-bar bg-warning progress-bar-striped progress-bar-animated"
                      style={{ width: "3%", height: "8px" }}
                    ></div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>

          <div className="row mb-3">
            <div className=" col mx-6 border">
              <p className="fw-600 text-black">{fetchedTopics?.unit_summary}</p>
            </div>
          </div>

          <div className="mt-3">
            <TabView>
              {fetchedTopics?.topics?.length > 0 ? (
                fetchedTopics?.topics?.map((topic, index) => (
                  <TabPanel header={`Topic ${index + 1}`} key={index}>
                    <div className="row mb-2">
                      <div className="col-12">
                        <hr />
                        <div className="d-flex justify-content-between align-items-center mt-2">
                          <h5 className="card-title text-primary fs-5">
                            {topic?.topic_full_name}
                          </h5>
                          <div>
                            {doc?.length > 0 && (
                              <button
                                className="btn btn-outline-primary btn-sm py-3"
                                onClick={() => window.open(doc, "_blank")}
                              >
                                <i className="fas fa-file-pdf"></i> Read Pdf
                              </button>
                            )}
                          </div>

                          <div>
                            {audio?.length > 0 && (
                              <button
                                className="btn btn-outline-primary btn-sm py-2"
                                onClick={() => window.open(audio, "_blank")}
                              >
                                <i className="fas fa-file-audio"></i> Play Mp3
                              </button>
                            )}
                          </div>
                          {/* <div>
                            <button className="btn btn-primary btn-sm">
                              <i className="fas fa-pen"></i> Quiz
                            </button>
                          </div> */}
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-12 mb-2">
                        <p className="card-text">{topic?.topic_summary}</p>
                      </div>
                    </div>

                    <div className="row my-5">
                      <div className="col-12">
                        {vid && (
                          <div className=" mb-2">
                            <div className="progress">
                              <div
                                className="progress-bar bg-warning progress-bar-striped progress-bar-animated"
                                style={{
                                  width: `${percentWatchedArr[index]}%`,
                                }}
                                role="progressbar"
                                aria-valuenow={percentWatchedArr[index]}
                                aria-valuemin="0"
                                aria-valuemax="100"
                              ></div>
                            </div>
                          </div>
                        )}

                        {isLoading ? (
                          <div
                            style={{
                              position: "relative",
                              width: "100%",
                              height: "100%",
                            }}
                          >
                            <video
                              src={vid[index]}
                              style={{ width: "100%", height: "100%" }}
                              controls // Enable video controls (play, pause, progress bar)
                              preload="auto" // Enable downloading of video metadata
                            />
                            <progress
                              max="100"
                              value={downloadProgress}
                              style={{
                                color: "#ffd700",
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                              }}
                            >
                              {downloadProgress}%
                            </progress>
                          </div>
                        ) : (
                          vid?.length > 0 &&
                          vid[index] && (
                            <Video
                              controls={[
                                "PlayPause",
                                "Seek",
                                "Time",
                                "Volume",
                                "Fullscreen",
                              ]}
                              src={vid[index]}
                              key={topic?.id}
                              onTimeUpdate={(e) => handleTimeUpdate(e, index)}
                              // src={vid[currentIndex]}
                              // onTimeUpdate={(event) => handleTimeUpdate(currentIndex, event)}
                              type="video/mp4"
                              style={{ width: "100%", height: "100%" }}
                            >
                              your browser does not support the{" "}
                              <code>video </code>element
                            </Video>
                          )
                        )}
                      </div>
                    </div>
                  </TabPanel>
                ))
              ) : (
                <div className="card-body">
                  <h5 className="card-title text-center fs-3">
                    Oops!! No Topics have been added to this unit.
                  </h5>
                </div>
              )}
            </TabView>
          </div>
        </div>
      ) : (
        <div className="d-flex justify-content-center align-items-center min-vh-100">
          <div className="spinner-border text-primary" role="status">
            <span className="">...</span>
          </div>
        </div>
      )}
    </>
  );
};

export default Class;
