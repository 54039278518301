import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useParams } from "react-router-dom";
import axios from "../../../API/apiHandler";

const CourseUnit = ({ uuid }) => {
  const { id } = useParams();
  const [fetchedUnits, setFetchedUnits] = useState([]);

  const [courseUnitForm, setCourseUnitForm] = useState({
    unit_id: 0,
    course_id: id,
    unit_visible: true,
    unit_mandatory: true,
    unit_total_points: 1,
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setCourseUnitForm((prevCourseUnitForm) => {
      return {
        ...prevCourseUnitForm,
        [name]: value,
      };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const unit_id = courseUnitForm.unit_id;
    const course_id = courseUnitForm.course_id;
    const unit_visible = courseUnitForm.unit_visible;
    const unit_mandatory = courseUnitForm.unit_mandatory;
    const unit_total_points = courseUnitForm.unit_total_points;

    const courseUnitData = {
      unit_id,
      course_id,
      unit_visible,
      unit_mandatory,
      unit_total_points,
    };

    const prerequisites = {
      unit_id,
      parent_id: 1,
      mandatory: true,
      status: true,
    };

    try {
      const token = JSON.parse(localStorage.getItem("fastapi"));
      await axios.post("/api/v1/courses/unit", courseUnitData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      });
      await axios.post("/api/v1/units/prerequisite", prerequisites, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      toast.success("Course Unit Created Successfully");
    } catch (error) {
      toast.error("Course Unit Creation Failed");
    }
  };

  const fetchUnits = async () => {
    const token = JSON.parse(localStorage.getItem("fastapi"));
    try {
      const response = await axios.get("/api/v1/units", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      setFetchedUnits(response?.data);
    } catch (error) {}
  };

  useEffect(() => {
    fetchUnits();
  }, []);

  return (
    <div>
      <div className="d-grid">
        <button
          type="button"
          className="btn btn-info btn-block shadow"
          style={{
            boxShadow: "inset 2px 2px 6px #222",
            border: "2px solid #017",
          }}
          data-bs-toggle="modal"
          data-bs-target="#addunitcourse"
        >
          <span className="fs-5">Create Course Unit</span>
        </button>
      </div>

      <div
        className="modal fade"
        style={{ overflow: "scroll !important" }}
        id="addunitcourse"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="addunitcourseLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg ">
          <div className="modal-content">
            <div className="modal-header">
              <h1
                className="modal-title text-success fs-5"
                id="addunitcourseLabel"
              >
                Assign a Unit to Course
              </h1>
              <button
                type="button"
                className="btn-close text-dark"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="modalcontainer text-dark">
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col">
                      <div>
                        <label className="form-label mt-2" htmlFor="unit_id">
                          Select Unit{" "}
                        </label>
                        <select
                          className=" form-select"
                          aria-label="Default select"
                          onChange={handleChange}
                          required
                          value={courseUnitForm.unit_id}
                          name="unit_id"
                        >
                          <option>Units</option>
                          {fetchedUnits?.length > 0 ? (
                            fetchedUnits?.map((unit, index) => (
                              <option value={unit.id} key={index}>
                                {unit.unit_full_name}
                              </option>
                            ))
                          ) : (
                            <option>No Units yet</option>
                          )}
                        </select>
                      </div>
                    </div>
                  </div>

                  <input
                    type="submit"
                    value="ADD"
                    className="submit-btn mt-2"
                  />
                </form>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary  text-dark"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseUnit;
