import React from 'react'

import Sidebar from '../../../components/Student/Sidebar'
import Courses from '../../../components/Student/Courses/Courses'

const CoursesScreen = () => {
	return (
		<div className="container-fluid">
			<div className="row">
				<div className="col-12 col-md-2">
					<Sidebar />
				</div>
				<div className="col-12 col-md-10 mt-5" style={{overflow: 'scroll'}}>
					<Courses />
				</div>
			</div>
		</div>
	)
}

export default CoursesScreen
