import { useState, useEffect, useContext } from "react";
import ProfileContext from "../../../Context/ProfileContext";
// import LoadingOverlay from '../../Load/LoadingOverlay'
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { FilterMatchMode } from "primereact/api";
import { InputText } from "primereact/inputtext";
import axios from "../../../API/apiHandler";
// import { toast } from "react-hot-toast";

const IncomeTable = () => {
  const { token, auth } = useContext(ProfileContext);

  const [payments, setPayments] = useState([]);

  useEffect(() => {
    const fetchPayment = async () => {
      try {
        const response = await axios.get("/api/v1/pespal/all-payments", {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        if (response) {
          const pay = response?.data;
          const uniquePayments = pay.reduce((accumulator, currentPayment) => {
            const existingPayment = accumulator.find(
              (payment) => payment.id === currentPayment.id
            );

            if (!existingPayment) {
              return [...accumulator, currentPayment];
            }

            return accumulator;
          }, []);

          uniquePayments.sort((a, b) => b.id - a.id);

          setPayments(uniquePayments);
        }
      } catch (error) {}
    };

    fetchPayment();
  }, [token, auth]);

  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const header = (
    <div className="flex flex-wrap align-items-center justify-content-between gap-2">
      <span className="text-xl text-900 font-bold">All Transactions</span>
      <div className="flex justify-content-end">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Keyword Search"
          />
        </span>
      </div>
      <Button icon="pi pi-refresh" rounded raised />
    </div>
  );

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    confirmation_code: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    currency: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    // representative: { value: null, matchMode: FilterMatchMode.IN },
    payment_status_description: {
      value: null,
      matchMode: FilterMatchMode.EQUALS,
    },
    created_date: { value: null, matchMode: FilterMatchMode.EQUALS },
  });

  const footer = (
    <h2 className="text-black">
      In total there are {payments ? payments.length : 0} payments made so far.
    </h2>
  );

  const paginatorLeft = <Button type="button" icon="pi pi-refresh" text />;
  const paginatorRight = <Button type="button" icon="pi pi-download" text />;

  return (
    <div>
      <h3 className="text-blue-900 fs-4">Latest Transactions</h3>
      <DataTable
        value={payments}
        filters={filters}
        filterDisplay="row"
        header={header}
        footer={footer}
        tableStyle={{ minWidth: "60rem" }}
        showGridlines
        removableSort
        paginator
        rows={5}
        rowsPerPageOptions={[10, 25, 60, 100]}
        paginatorLeft={paginatorLeft}
        paginatorRight={paginatorRight}
        emptyMessage="No Payments Found."
      >
        <Column field="confirmation_code" header="Confirmation Code" />
        <Column field="currency" header="Currency" sortable />
        <Column
          field="created_date"
          style={{ minWidth: "12rem" }}
          header="Date"
          sortable
        />
        <Column field="amount" header="Amount" sortable />
        <Column field="currency" header="Currency" sortable />
        <Column
          field="merchant_reference"
          header="Merchant Reference"
          sortable
        />
        {/* <Column field="payee" header="Merchant" sortable /> */}
        <Column field="payment_status_description" header="Status" sortable />
      </DataTable>
    </div>
  );
};

export default IncomeTable;
