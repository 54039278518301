import React, { useContext, useState } from "react";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import { LazyLoadImage } from "react-lazy-load-image-component";
// import { Password } from 'primereact/password';
// import { Divider } from "primereact/divider";

import axios from "../../API/apiHandler";
import ProfileContext from "../../Context/ProfileContext";
import Logo from "../../images/acts-logo@1x.png";
import RegImg from "../../images/draw1.webp";

const USER_REGEX = /^[A-z][A-z0-9-_]{2,20}$/;
const PWD_REGEX = /^(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.*[0-9])(?=.*[a-z]).{8,}$/;
const EMAIL_REGEX =
  /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
const KENYAN_PHONE_REGEX = /^(\+254|0)(7[0-9]{8})$/;

const Register = () => {
  const navigate = useNavigate();
  const [showPwd, setShowPwd] = useState(false);
  const [tel, setTel] = useState("");
  const { setPh } = useContext(ProfileContext);
  setPh(tel);

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    regcheckbox: false,
  });
  const { firstName, lastName, email, password } = formData;

  function handleChange(event) {
    const { name, value, type, checked } = event.target;
    setFormData((prevFormData) => {
      return {
        ...prevFormData,
        [name]: type === "checkbox" ? checked : value,
      };
    });
  }

  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);

    const first_name = formData.firstName;
    const last_name = formData.lastName;
    const policy_agreed = formData.regcheckbox;
    const email = formData.email;
    const phone_number = "+" + tel;
    const password = formData.password;

    const v1 = USER_REGEX.test(first_name);
    const v2 = USER_REGEX.test(last_name);
    const v3 = EMAIL_REGEX.test(email);
    const v4 = KENYAN_PHONE_REGEX.test(phone_number);
    const v5 = PWD_REGEX.test(password);

    if (!v1 || !v2) {
      toast.error("name must be valid 3-19 letters");
      setLoading(false);
      return;
    }
    if (!v3 || !v4) {
      toast.error("Email or phone number format is invalid");
      setLoading(false);
      return;
    }
    if (!v5) {
      toast.error(
        "password must have at least one uppercase letter, have at least one special character(!, @, #, $, %, ^, &, *), have at least one numerical digit, have at least one lowercase letter, have at least 8 characters"
      );
      setLoading(false);
      return;
    }

    const data = {
      first_name,
      last_name,
      policy_agreed,
      email,
      phone_number,
      password,
    };

    try {
      const res = await axios.post(
        "/api/v1/auth/register",
        JSON.stringify(data),
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );
      if (res.status === 201 || res.status === 200) {
        toast.success("Success, Account Created!!");
        navigate("/login");
      }
    } catch (err) {
      if (err.response.data.detail) {
        toast.error(err.response.data.detail);
      } else if (!err?.response) {
        toast.error("No server response");
      } else if (err.response.status === 409) {
        toast.error("Email or phone number already exists");
      } else {
        return toast.error("unexpected error, registration failed");
      }
    } finally {
      setLoading(false);
    }
  };

  // const header = <div className="font-bold mb-3">Pick a password</div>;
  // const footer = (
  //   <>
  //     <Divider />
  //     <p className="mt-2">Suggestions</p>
  //     <ul className="pl-2 ml-2 mt-0 line-height-3">
  //       <li>At least one lowercase</li>
  //       <li>At least one uppercase</li>
  //       <li>At least one numeric</li>
  //       <li>Minimum 8 characters</li>
  //     </ul>
  //   </>
  // );

  return (
    <section
      className=""
      style={{ minHeight: "100vh", backgroundColor: "#094886" }}
    >
      <div className="container h-100">
        <div className="row d-flex justify-content-center align-items-center h-100">
          <div className="col-lg-12 col-xl-11">
            <div className="card text-black" style={{ borderRadius: "25px" }}>
              <div className="card-body">
                <div className="row justify-content-center">
                  <div className="col-md-10 col-lg-6 col-xl-5 order-2 order-lg-1">
                    <span className=" d-flex justify-content-center align-items-center my-4">
                      <img
                        className="fa-2x me-1"
                        src={Logo}
                        alt="logo"
                        style={{ width: "90px" }}
                      />
                      <h2 className="fs-4 fw-bold">Create An Account</h2>
                    </span>

                    <form onSubmit={handleSubmit} className="">
                      <div className="d-flex flex-row align-items-center mb-3">
                        <i className="fas fa-user fa-lg me-2 fa-fw"></i>
                        <div className="form-outline flex-fill mb-0">
                          <label className="form-label" htmlFor="first-name">
                            First Name
                          </label>
                          <input
                            type="text"
                            id="first-name"
                            placeholder="enter your firstname"
                            autoComplete="off"
                            className="form-control"
                            name="firstName"
                            required
                            onChange={handleChange}
                            value={firstName}
                          />
                        </div>
                      </div>

                      <div className="d-flex flex-row align-items-center mb-3">
                        <i className="fas fa-user fa-lg me-2 fa-fw"></i>
                        <div className="form-outline flex-fill mb-0">
                          <label className="form-label" htmlFor="last-name">
                            Last Name
                          </label>
                          <input
                            type="text"
                            id="last-name"
                            autoComplete="off"
                            name="lastName"
                            onChange={handleChange}
                            placeholder="enter your sirname"
                            required
                            value={formData.lastName}
                            className="form-control"
                          />
                        </div>
                      </div>

                      <div className="d-flex flex-row align-items-center mb-3">
                        <i className="fas fa-envelope fa-lg me-2 fa-fw"></i>
                        <div className="form-outline flex-fill mb-0">
                          <label className="form-label" htmlFor="email">
                            Your Email
                          </label>
                          <input
                            type="email"
                            id="email"
                            name="email"
                            onChange={handleChange}
                            autoComplete="off"
                            value={email}
                            placeholder="enter valid email address"
                            required
                            className="form-control"
                          />
                        </div>
                      </div>

                      <div className="d-flex flex-row align-items-center mb-3">
                        <i className="fas fa-lock fa-lg me-2 fa-fw"></i>
                        <div
                          className="form-outline flex-fill mb-0"
                          style={{ position: "relative" }}
                        >
                          <label className="form-label" htmlFor="password">
                            Password
                          </label>
                          <input
                            type={showPwd ? "text" : "password"}
                            id="password"
                            className="form-control form-control-lg"
                            onChange={handleChange}
                            autoComplete="off"
                            placeholder="enter a password"
                            name="password"
                            value={formData.password}
                            required
                            // header={header}
                            // footer={footer}
                          />
                          <button
                            style={{
                              background: "none",
                              border: "none",
                              fontSize: "1.3em",
                              cursor: "pointer",
                              position: "absolute",
                              right: 10,
                              top: "75%",
                              transform: "translateY(-50%)",
                            }}
                            type="button"
                            onClick={() => setShowPwd(!showPwd)}
                          >
                            {showPwd ? (
                              <i
                                className="fa fa-eye-slash"
                                style={{ color: "#333" }}
                              />
                            ) : (
                              <i
                                className="fa fa-eye"
                                style={{ color: "#999" }}
                              />
                            )}
                          </button>
                        </div>
                      </div>

                      <div className="d-flex flex-row align-items-center mb-3">
                        <i className="fas fa-phone fa-lg fa-fwn me-3 mt-4"></i>
                        <div className="form-outline flex-fill mb-0">
                          <label htmlFor="tel" className="form-label">
                            Your Phone Number
                          </label>
                          <PhoneInput
                            country={"ke"}
                            id="tel"
                            name="tel"
                            onChange={setTel}
                            autoComplete="off"
                            placeholder="enter your valid mobile number"
                            value={tel}
                            required
                          />
                        </div>
                      </div>

                      <div className="form-check gap-2 d-flex justify-content-center mb-4">
                        <input
                          className="form-check-input me-2 p-2 border-2"
                          type="checkbox"
                          id="regcheckbox"
                          name="regcheckbox"
                          checked={formData.regcheckbox}
                          onChange={handleChange}
                          required
                        />
                        <label
                          htmlFor="regcheckbox"
                          className="form-check-label"
                        >
                          By Checking this box, I agree that I have read and
                          agreed to Acts Pathways Academy privacy policy, cookie
                          policy and
                          <Link to="#">Terms of service</Link>
                        </label>
                      </div>

                      <div className="d-flex justify-content-center mx-4 mb-2 mb-lg-3">
                        {loading ? (
                          <button className="btn btn-outline-warning btn-lg text-warning btn-block w-100">
                            <div className="d-flex justify-content-center">
                              <div
                                className="spinner-border text-success"
                                role="status"
                              >
                                <span className="visually-hidden">
                                  Loading...
                                </span>
                              </div>
                            </div>
                          </button>
                        ) : (
                          <button
                            type="submit"
                            className="btn btn-info btn-lg text-warning btn-block w-100"
                            disabled={
                              !firstName ||
                              !lastName ||
                              !email ||
                              !password ||
                              !tel
                            }
                            style={{ color: "#094886" }}
                          >
                            Sign Up
                          </button>
                        )}
                      </div>
                    </form>
                    {/* already have an account */}
                    <div className="text-center">
                      <p className="text-muted text-center">
                        Already have an account?{" "}
                        <Link to="/login" className="text-info">
                          Login
                        </Link>
                      </p>
                    </div>
                  </div>

                  <div className="col-md-10 col-lg-6 col-xl-7 d-flex align-items-center order-1 order-lg-2">
                    <LazyLoadImage
                      src={RegImg}
                      className="img-fluid"
                      alt="regImage"
                      effect="blur"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Register;
