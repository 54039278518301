import { useState, useEffect, useContext } from "react";
import { Ripple } from "primereact/ripple";
import { Link } from "react-router-dom";
import axios from "../../../API/apiHandler";
import LoadingOverlay from "../../Load/LoadingOverlay.jsx";
import ProfileContext from "../../../Context/ProfileContext";
import { toast } from "react-toastify";

const Dashboard = () => {
  const { token, auth } = useContext(ProfileContext);
  const [isLoading, setIsLoading] = useState(false);
  const [enrolled, setEnrolled] = useState([]);
  const [courses, setCourses] = useState([]);

  const [certificates, setCertificates] = useState(null);

  useEffect(() => {
    const fetchUserCerts = async () => {
      try {
        const response = await axios.get(
          `/api/v1/course_progress/generate_certificate_data/${auth}`,

          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const certs = response?.data;
        setCertificates(certs?.certificates);
      } catch (error) {
        toast.error("Error fetching certs");
      }
    };

    fetchUserCerts();
  }, [auth, token]);

  // useEffect(() => {
  //   const checkCourseCompletion = async () => {
  //     if (!courses) {
  //       return;
  //     }

  //     const progressPromises = courses.map((course) =>
  //       axios.get(
  //         `/api/v1/course_progress/course-progress/${auth}/${course?.id}`,
  //         {
  //           headers: {
  //             "Content-Type": "application/json",
  //             Authorization: `Bearer ${token}`,
  //           },
  //         }
  //       )
  //     );

  //     try {
  //       const progressResponses = await Promise.all(progressPromises);
  //       const progressData = progressResponses.map((response) => response.data);

  //       const allCoursesComplete =
  //         progressData?.length &&
  //         progressData.some(
  //           (progress) => progress?.user_course_progress === 100
  //         );

  //       if (allCoursesComplete) {
  //         const certificatePromises = courses.map((course) =>
  //           axios.get(
  //             // `/api/v1/course_progress/certificate-urls?user_id=${encodeURIComponent(
  //             //   auth
  //             // )}&course_id=${encodeURIComponent(course.id)}`,
  //             `/api/v1/course_progress/certificates/${auth}/${course?.id}`,
  //             {
  //               headers: {
  //                 "Content-Type": "application/json",
  //                 Authorization: `Bearer ${token}`,
  //               },
  //             }
  //           )
  //         );

  //         const certificateResponses = await Promise.all(certificatePromises);
  //         const generatedCertificates = certificateResponses.map(
  //           (response) => response.data
  //         );
  //         setCertificates(generatedCertificates);

  //         toast.success("Certificate created");
  //       }

  //       // if (allCoursesComplete) {
  //       //   const certificatePromises = courses.map((course) =>
  //       //     axios.post(
  //       //       `/api/v1/course_progress/certificates/${auth}/${course.id}`,
  //       //       {},
  //       //       {
  //       //         headers: {
  //       //           "Content-Type": "application/json",
  //       //           Authorization: `Bearer ${token}`,
  //       //         },
  //       //       }
  //       //     )
  //       //   );

  //       //   const certificateResponses = await Promise.all(certificatePromises);

  //       //   const generatedCertificates = certificateResponses
  //       //     .filter(
  //       //       (response) => response.data && response.data.certificate_url
  //       //     )
  //       //     .map((response) => response.data.certificate_url);

  //       //   setCertificates(generatedCertificates);

  //       //   toast.success("Certificate created");
  //       // }
  //     } catch (error) {
  //       toast.error("Error fetching progress or generating certificates");
  //     }
  //   };

  //   checkCourseCompletion();
  // }, [auth, token, courses]);

  useEffect(() => {
    setIsLoading(true);

    const fetchCourses = async () => {
      try {
        const token = JSON.parse(localStorage.getItem("fastapi"));
        const response = await axios.get(
          "/api/v1/courses/published",

          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            withCredentials: true,
          }
        );
        const courses = response?.data;
        courses?.length && courses?.sort((a, b) => b.id - a.id);
        setEnrolled(courses);
      } catch (error) {}
    };
    fetchCourses();
  }, []);

  useEffect(() => {
    const fetchEnrolled = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(
          `/api/v1/studyhub/course/applications/${auth}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response) {
          setCourses(response?.data);
        }
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    };

    fetchEnrolled();
  }, [token, auth]);

  const [randomCourses, setRandomCourses] = useState([]);
  useEffect(() => {
    const shuffledCourses = enrolled.sort(() => 0.5 - Math.random());
    const selectedCourses = shuffledCourses.slice(0, 2);
    setRandomCourses(selectedCourses);
  }, [enrolled]);

  const [payments, setPayments] = useState([]);
  useEffect(() => {
    const fetchPayment = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(
          `/api/v1/pespal/payment-details?user_id=${auth}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response) {
          const pay = response?.data;
          // select the most recent payment from "created_date": "2023-10-17T12:14:24.127000"
          const sortedPayments = pay?.sort(
            (a, b) => new Date(b.created_date) - new Date(a.created_date)
          );
          const latestPayment = sortedPayments[0];
          setPayments(latestPayment);
        }
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    };

    fetchPayment();
  }, [token, auth]);

  return (
    <div className="mt-5">
      {isLoading ? (
        <LoadingOverlay />
      ) : (
        <div>
          <div className="container-lg-fluid container  mt-5">
            <h1 className="fs-5">Dashboard</h1>
            <div className="row row-cols-1 row-cols-md-4 g-4">
              <div className="col">
                <div className="card bg-green h-100 border border-1 border-primary">
                  <div className="card-body bg-secondary">
                    <h5 className="card-title fs-3 text-warning">
                      {courses ? courses?.length : 0}
                    </h5>
                    <p className="card-text text-black fw-bold">
                      Enrolled Courses.
                      <Ripple />
                    </p>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card bg-green h-100 border border-1 border-info">
                  <div className="card-body bg-dark">
                    <div className="d-flex justify-content-between align-items-center">
                      <h5 className="card-title fs-3 text-warning">
                        {certificates?.length || 0}
                      </h5>
                      <i
                        className="pi pi-check"
                        style={{ fontSize: "3.5rem", color: "blue" }}
                      ></i>
                    </div>
                    <Ripple />
                    <p className="card-text text-white fw-bold">
                      Completed Courses.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card bg-green h-100 border border-1 border-warning">
                  <div className="card-body bg-blu-900">
                    <h5 className="card-title fs-2 text-warning">
                      {courses?.length - certificates?.length || 0}
                    </h5>
                    <p className="card-text text-black fw-bold">
                      Courses in Progress.
                      <Ripple />
                    </p>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card bg-green h-100 border border-1 border-primary">
                  <div className="card-body">
                    <div className="d-flex justify-content-between align-items-center">
                      <h3 className="card-title fs-3 text-warning">
                        ${payments ? payments?.amount : 0}
                      </h3>
                      <i
                        className="pi pi-money-bill"
                        style={{ fontSize: "3.5rem", color: "blue" }}
                      ></i>
                    </div>
                    <Ripple />
                    <p className="card-text text-black fw-bold">
                      Latest Payment.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-3 g-3">
              <div className="col-lg-8 col-md-12 ">
                <div className="card ">
                  <div className="card-title">
                    <h5
                      className=" text-center fs-5 fw-bold mb-3"
                      style={{ color: "#094886" }}
                    >
                      Popular Courses
                    </h5>
                  </div>
                  <div className="card_body">
                    {randomCourses.map((course) => (
                      <div key={course.id} className="card border mb-2">
                        <div className="card-body">
                          <h5
                            className="card-title fs-5"
                            style={{ color: "#094886" }}
                          >
                            {course.course_full_name}
                          </h5>
                          <p className="card-text">
                            {course.course_short_desc}
                          </p>
                          <Link
                            to={`/details/${course.id}`}
                            className="btn btn-outline-primary mt-3 p-3"
                            style={{
                              backgroundColor: "#094886",
                              color: "#fff",
                            }}
                          >
                            Learn More <i className="pi pi-arrow-right" />
                          </Link>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-12">
                <div className="card w-100">
                  <div className="card-title">
                    <h5
                      className="text-center fs-5 fw-bold mb-3"
                      style={{ color: "#094886" }}
                    >
                      Certificate{certificates?.length > 1 && "s"}
                    </h5>
                  </div>
                  <div className="card-body">
                    <ul className="list-group">
                      {certificates?.length ? (
                        certificates.map((certificate, index) => {
                          return (
                            <li
                              key={index}
                              className="list-group-item d-flex align-items-center"
                              onClick={() =>
                                window.open(certificate.cert_file, "_blank")
                              }
                              style={{ cursor: "pointer" }}
                            >
                              <iframe
                                src={certificate?.cert_file}
                                title={certificate?.course_name}
                                className="me-3"
                                style={{ height: "64px", width: "64px" }}
                              />
                              <div className="flex flex-col">
                                <h6 className="mb-0">
                                  {certificate?.course_name ||
                                    `Certificate ${index + 1}`}
                                </h6>
                                <p className="text-muted mb-0">
                                  {/* 2023-10-02T11:17:23.263094 */}
                                  {certificate?.date_created?.slice(0, 10)}
                                </p>
                              </div>
                            </li>
                          );
                        })
                      ) : (
                        <h6 className="text-center">
                          You currently have no certificates.
                        </h6>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Dashboard;
