import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { useContext } from "react";
import { toast } from "react-toastify";
// import ApplyScholarship from "./ApplyScholarship";
import axios from "../../../API/apiHandler";
import ProfileContext from "../../../Context/ProfileContext";

const Card = (props) => {
  const { token } = useContext(ProfileContext);
  const {
    id,
    title,
    amount,
    deadline,
    type,
    institution,
    short_desc,
    location,
    duration,
  } = props;
  // eligibles,area,level
  const [img, setImg] = useState(null);

  useEffect(() => {
    const fetchImg = async () => {
      try {
        const res = await axios.get(
          `/api/v1/users/avatar/${id}`,

          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            responseType: "arraybuffer",
          }
        );
        if (res?.data) {
          const base64 = btoa(
            new Uint8Array(res.data).reduce(
              (data, byte) => data + String.fromCharCode(byte),
              ""
            )
          );
          setImg(`data:;base64,${base64}`);
        }
      } catch (error) {}
    };
    fetchImg();
  }, [token, id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      sch_id: id,
    };

    try {
      const res = await axios.post(
        "/api/v1/scholarships/applications/scholarship",
        data,
        {
          headers: {
            accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (res) {
        toast.success("application submitted...!");
      }
    } catch (error) {}
  };

  return (
    <div>
      <article className="fund mb-3">
        <div className="fund_publication">
          <img src={img || "https://picsum.photos/200/"} alt="" />
          <div>
            <span className="fund_author">
              <i className="fas fa-user"></i> {institution}
            </span>
            <span className="fund_date">
              <i className="fas fa-calendar-alt"></i> {deadline}
            </span>
            {location && (
              <span className="fund_location">
                <i className="fas fa-map-marker-alt"></i> {location}
              </span>
            )}
          </div>
        </div>
        <div className="fund_info">
          <h2 className="fund_title">{title}</h2>
          <div className="d-flex gap-4 justify-content-between align-items-center">
            <div className="d-flex gap-3">
              <span className="fund_subtitle">Type: {type}</span>
              <span className="fund_subtitle">Duration: {duration} months</span>
            </div>
            <span className="fund_subtitle">${amount}</span>
          </div>
          <p className="fund_description text-break">{short_desc}</p>
          <div className="d-flex gap-3 align-items-center mt-2">
            <Link to={`/scholarship/${id}`} className="btn btn-success">
              Details
            </Link>
            <button
              className="btn btn-outline-primary text-dark"
              onClick={handleSubmit}
            >
              Apply
            </button>
          </div>
        </div>
      </article>
    </div>
  );
};

export default Card;
