import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { toast } from "react-hot-toast";

import { TbWorld } from "react-icons/tb";

import axios from "../../../API/apiHandler";
// import { useCartContext } from '../../../Context/CartContext'
import image from "../../../images/acts-logo@1x.png";

const CourseDetails = () => {
  const { id } = useParams();
  const [fetchedTopics, setFetchedTopics] = useState({});

  useEffect(() => {
    

    const fetchTopics = async () => {
      const token = JSON.parse(localStorage.getItem("fastapi"));
      try {
        const response = await axios.get(
          `/api/v1/units/${id}`,
          
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setFetchedTopics(response?.data);
      } catch (err) {
        if (err.response.data.detail) {
          toast.error(err.response.data.detail);
        } else {
        }
      }
    };
    fetchTopics();
    
  }, [id]);

  const topic = fetchedTopics?.topics.find((topic) => topic.id === id);

  const [doc, setDoc] = useState({});
  const [audio, setAudio] = useState({});
  const [vid, setVid] = useState({});

  useEffect(() => {
    

    const fetchLibraryResources = async () => {
      const token = JSON.parse(localStorage.getItem("fastapi"));
      try {
        const response = await axios.get(
          `/api/v1/library/resources/stream/${topic?.topic_core_text}`,
          
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response?.status === 200 || response?.status === 201) {
          const blob = new Blob([response?.data], { type: "application/pdf" });
          const url = URL.createObjectURL(blob);
          setDoc(url);
        }

      } catch (err) {}
    };

    const fetchLibraryResource = async () => {
      const token = JSON.parse(localStorage.getItem("fastapi"));
      try {
        const response = await axios.get(
          `/api/v1/library/resources/stream/${topic?.topic_core_audio}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response?.status === 200 || response?.status === 201) {
          const blob = new Blob([response?.data], { type: "audio/mp3" });
          const url = window.URL.createObjectURL(blob);
          setAudio(url);
        }
      } catch (err) {
        if (err.response.data.detail) {
          toast.error(err.response.data.detail);
        } else {
        }
      }
    };

    const fetchLibraryResourc = async () => {
      const token = JSON.parse(localStorage.getItem("fastapi"));
      try {
        const response = await axios.get(
          `/api/v1/library/resources/stream/${topic?.topic_core_video}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response?.status === 200 || response?.status === 201) {
          const blob = new Blob([response?.data], { type: "video/mp4" });
          const url = window.URL.createObjectURL(blob);
          setVid(url);
        }
      } catch (err) {
        if (err.response.data.detail) {
          toast.error(err.response.data.detail);
        } else {
        }
      }
    };

    fetchLibraryResources();
    fetchLibraryResource();
    fetchLibraryResourc();
  }, [
    id,
    topic?.topic_core_text,
    topic?.topic_core_audio,
    topic?.topic_core_video,
  ]);

  const topic_name = topic?.topic_full_name;
  const code = topic?.topic_short_code;
  const summary = topic?.topic_summary;
  const short_desc = topic?.topic_short_desc;
  const unit = fetchedTopics?.unit_full_name;
  const unit_code = fetchedTopics?.unit_short_code;
  const unit_summary = fetchedTopics?.unit_summary;

  return (
    <SingleCourseWrapper>
      {topic_name ? (
        <div>
          <div className="course-intro mx-auto grid">
            <div className="d-flex justify-content-between">
              <div className="course-img d-xs-none">
                <img src={image} alt={topic_name} />
              </div>
              <div>
                <div className="mb-3 course-head">
                  <div className="text-success text-break">
                    <h5>
                      {topic_name} [{code}]
                    </h5>
                  </div>
                </div>

                <ul className="course-info">
                  <li className="flex">
                    <span>
                      <TbWorld />
                    </span>
                    <span className="fs-6 course-info-txt fw-5">
                      {topic_name}
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="course-details">
              <div className="course-body">
                <p
                  className="course-para fs-18"
                  style={{ whiteSpace: "pre-wrap" }}
                  dangerouslySetInnerHTML={{ __html: short_desc }}
                />

                <div className="course-rating flex">
                  <span className="rating-star-val fw-8 fs-16">
                    {unit} [{unit_code}]
                  </span>
                  {/* <span className='rating-count fw-5 fs-14'>({course_study_level})</span> */}
                </div>
              </div>
            </div>
          </div>

          <div className="course-full bg-white text-dark">
            {/* buttons to redirect and view video, audio, pdf */}
            <div className="d-flex justify-content-between mb-3">
              <div>
                {doc?.length > 0 && (
                  <button
                    className="btn btn-outline-primary btn-sm"
                    onClick={() => window.open(doc, "_blank")}
                  >
                    <i className="fas fa-file-pdf"></i> View Pdf
                  </button>
                )}
              </div>
              <div>
                {audio?.length > 0 && (
                  <button
                    className="btn btn-outline-primary btn-sm"
                    onClick={() => window.open(audio, "_blank")}
                  >
                    <i className="fas fa-file-audio"></i> Listen Mp3
                  </button>
                )}
              </div>
              <div>
                {vid?.length > 0 && (
                  <button
                    className="btn btn-outline-primary btn-sm"
                    onClick={() => window.open(vid, "_blank")}
                  >
                    <i className="fas fa-file-video"></i> Watch Video
                  </button>
                )}
              </div>
            </div>

            <div className="course-learn mx-auto">
              <div className="course-sc-title">Unit: {unit}</div>
              <div className="course-learn-list grid">
                <p
                  className="course-para fs-18"
                  style={{ whiteSpace: "pre-wrap" }}
                  dangerouslySetInnerHTML={{ __html: unit_summary }}
                />
              </div>
            </div>

            <div className="course-content mx-auto">
              <div className="course-sc-title">Topic: {topic_name}</div>
              <p
                className="course-para fs-18"
                style={{ whiteSpace: "pre-wrap" }}
                dangerouslySetInnerHTML={{ __html: summary }}
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="text-primary mt-5 text-center">
          <div className="d-flex align-items-center justify-content-center">
            <span
              className="fs-2 spinner-border spinner-border-sm text-success"
              role="status"
              aria-hidden="true"
            ></span>
            <span>Loading Topic Info....</span>
          </div>
        </div>
      )}
    </SingleCourseWrapper>
  );
};

const SingleCourseWrapper = styled.div`
  background: #000;
  color: #fff;

  .course-intro {
    padding: 40px 16px;
    max-width: 992px;

    .course-details {
      padding-top: 20px;
    }

    .course-category {
      padding: 0px 8px;
      border-radius: 6px;
    }

    .course-head {
      font-size: 48px;
      line-height: 1.3;
      padding: 12px 0 0 0;
    }
    .course-para {
      padding: 12px 0;
    }
    .rating-star-val {
      margin-right: 7px;
      padding-bottom: 5px;
      color: var(--clr-orange);
    }
    .students-count {
      margin-left: 8px;
    }
    .rating-count {
      margin-left: 6px;
      color: #d097f6;
    }
    .course-info {
      li {
        margin-bottom: 2px;
        &:nth-child(2) {
          margin-top: 10px;
        }
      }
      .course-info-txt {
        text-transform: capitalize;
        margin-left: 8px;
        margin-bottom: 4px;
      }
    }
    .course-price {
      margin-top: 12px;
      .old-price {
        color: #eceb98;
        text-decoration: line-through;
        margin-left: 10px;
      }
    }
    .course-btn {
      margin-top: 16px;
      .add-to-cart-btn {
        padding: 12px 28px;
        span {
          margin-left: 12px;
        }
      }
    }

    @media screen and (min-width: 880px) {
      grid-template-columns: repeat(2, 1fr);
      column-gap: 40px;
      .course-details {
        padding-top: 0;
      }
      .course-img {
        order: 2;
      }
    }

    @media screen and (min-width: 1400px) {
      grid-template-columns: 60% 40%;
    }
  }

  .course-full {
    padding: 40px 16px;
    .course-sc-title {
      font-size: 22px;
      font-weight: 700;
      margin: 12px 0;
    }
    .course-learn {
      max-width: 992px;
      border: 1px solid rgba(0, 0, 0, 0.2);
      padding: 12px 28px 22px 28px;

      .course-learn-list {
        li {
          margin: 5px 0;
          display: flex;
          span {
            &:nth-child(1) {
              opacity: 0.95;
              margin-right: 12px;
            }
          }
        }

        @media screen and (min-width: 992px) {
          grid-template-columns: repeat(2, 1fr);
        }
      }
    }

    .course-content {
      max-width: 992px;
      margin-top: 30px;
      border: 1px solid rgba(0, 0, 0, 0.2);
      padding: 12px 28px 22px 28px;

      .course-content-list {
        li {
          background-color: #f7f9fa;
          padding: 12px 18px;
          border: 1px solid rgba(0, 0, 0, 0.2);
          margin-bottom: 10px;
          font-weight: 800;
          font-size: 15px;
        }
      }
    }
  }
`;

export default CourseDetails;
