import { HashLink as Link } from "react-router-hash-link";
import { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay } from "swiper";

import LoadingOverlay from "../../components/Load/LoadingOverlay";
import axios from "../../API/apiHandler";

import "./style.css";
import Logo from "../../images/acts-logo@1x.png";
const Home = () => {
  SwiperCore.use([Autoplay]);

  const [enrolled, setEnrolled] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const fetchCourses = async () => {
    try {
      const response = await axios.get("/api/v1/courses/published", {
        headers: {
          "Content-Type": "application/json",
        },
      });
      const courses = response?.data;
      setEnrolled(courses);
    } catch (error) {}
  };

  useEffect(() => {
    fetchCourses();
  }, []);

  const [randomCourses, setRandomCourses] = useState([]);
  useEffect(() => {

    const shuffledCourses = enrolled.sort(() => 0.5 - Math.random());
    const selectedCourses = shuffledCourses.slice(0, enrolled?.length);
    setRandomCourses(selectedCourses);

    
  }, [enrolled]);

  useEffect(() => {
    setIsLoading(false);
  }, []);

  const handleClicka = () => {
    window.location.href = "mailto:academy@acts-net.org";
  };
  const ig = () => {
    window.location.href = "https://www.instagram.com/actspathways/";
  };
  const flickR = () => {
    window.location.href = "https://www.flickr.com/photos/virtual_academy/";
  };
  const tweet = () => {
    window.location.href = "https://twitter.com/ActsPathways";
  };
  const fb = () => {
    window.location.href = "https://www.facebook.com/ACTSNET";
  };
  const summer = () => {
    window.location.href = "https://forms.office.com/r/nbPW7M0dkv";
  };
  const physical = () => {
    window.location.href = "https://forms.gle/kxkLJ5jUZwTGfJVx9";
  };
  const launch = () => {
    window.location.href =
      "https://drive.google.com/file/d/1I6IkZ_y1dkn00suJayX5SBMnjc-wpjCH/view";
  };
  const interview = () => {
    window.location.href = "https://youtu.be/lwWQmdlYVC4";
  };

  return (
    <>
      {isLoading ? (
        <LoadingOverlay />
      ) : (
        <div
          className="container-fluid"
          data-bs-spy="scroll"
          data-bs-target=".navbar"
          data-bs-offset="70"
        >
          <div className="top-nav" id="home">
            <div className="container">
              <div className="row justify-content-between">
                <div className="col-auto">
                  <span onClick={handleClicka} style={{ cursor: "pointer" }}>
                    <i className="bx bxs-envelope"></i> academy@acts-net.org
                  </span>
                </div>
                <div className="col-auto social-icons">
                  <Link onClick={fb} style={{ backgroundColor: "#FFF" }}>
                    <i className="bx bxl-facebook text-info"></i>
                  </Link>
                  <Link onClick={tweet} style={{ backgroundColor: "#FFF" }}>
                    <i className="bx bxl-twitter text-primary"></i>
                  </Link>
                  <Link onClick={ig} style={{ backgroundColor: "#FFF" }}>
                    <i className="bx bxl-instagram text-danger"></i>
                  </Link>
                  <Link onClick={flickR} style={{ backgroundColor: "#FFF" }}>
                    <i className="bx bxl-flickr text-dark"></i>
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <nav className="navbar navbar-expand-lg navbar-light bg-white sticky-top">
            <div className="container-fluid">
              <Link className="navbar-brand" to="#">
                <img
                  src={Logo}
                  style={{
                    width: "13%",
                    height: "13%",
                    // hide on small screen
                    className: "d-none d-md-block",
                    // backgroundColor: "#FFA500",
                  }}
                  // className="border border-info"
                  alt="APA Logo"
                />
                {/* <span className="dot fs-5">.</span> */}
              </Link>
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarNav"
                aria-controls="navbarNav"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div className="collapse navbar-collapse" id="navbarNav">
                <ul className="navbar-nav fs-6 fw-semibold ms-auto text-primary text-bold">
                  <li className="nav-item">
                    <Link className="nav-link" smooth to="#home">
                      Home
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" smooth to="#about">
                      About
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" smooth to="#services">
                      Services
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" smooth to="#blog">
                      News
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" smooth to="/schedule">
                      Events
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" smooth to="/our-courses">
                      Courses
                    </Link>
                  </li>
                </ul>
                <Link to="/login" className="btn btn-primary ms-lg-3">
                  Login
                </Link>
              </div>
            </div>
          </nav>

          <div className="owl-carousel owl-theme hero-slider">
            <div className="slide slide1">
              <div className="container">
                <div className="row">
                  <div className="col-12 text-center text-white">
                    <h6 className="text-white text-uppercase">
                      Knowledge for better livelihoods
                    </h6>
                    <h1 className="display-3 my-3 text-uppercase">
                      Borderless access to
                      <br />
                      knowledge and training on STI
                    </h1>

                    <div className="d-flex justify-content-center align-items-center gap-2">
                      <Link to="/signup" className="btn btn-brand p-3">
                        Get Started <i className="bx bx-chevron-right"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <section id="about">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-8 text-center mb-4">
                  <p className="mx-auto text-wrap text-center mb-4 fs-5">
                    Welcome to ACTS Pathways Academy! <br /> ACTS Pathways
                    Academy (APA) is a one of kind virtual academy established
                    in 2023 under the African Centre for Technology Studies
                    (ACTS) to provide access to borderless, flexible,
                    informative training to foster skills, knowledge and
                    collaborations on STI. This is in-line with ACTS’s Mission
                    to strengthen the capacity and policies of African countries
                    and institutions to harness science, technology and
                    innovation for sustainable development and Vision -
                    Knowledge for better livelihoods. In addition to providing
                    training, the virtual academy will be hosting summer schools
                    in partnership with relevant institutions. This year, APA
                    will be hosting the Decoloniality Pathways Summer School
                    2023 from 14th to 21st August 2023 in partnership with The
                    University of Natural Resources and Life Sciences,
                    Vienna (BOKU), University of Innsbruck, Consortium for
                    Transdisciplinary Futures Research (CTFR) & Africa Uni-Net.
                    Theme: ''Decolonizing Knowledge and Learning Systems in the
                    Global South''  For more information on the Decoloniality
                    Pathways Summer School{" "}
                    <strong className="text-primary" onClick={handleClicka}>
                      email: academy@acts-net.org
                    </strong>
                  </p>
                  <h2 className="text-uppercase">
                    What’s in our Training Centre?
                  </h2>
                  <p className="fs-5">
                    Our Training Centre is full of a wide range of courses, such
                    as Climate Change Modeling for Decision Making, Climate
                    Policy and Leadership, Climate Change Finance and much more.
                    We’re also adding new courses all the time.{" "}
                    <Link to="/our-courses" className="text-primary">
                      Click here
                    </Link>{" "}
                    to see an up-to-date list of the courses our subscribers
                    currently benefit from.
                  </p>
                </div>
                <div className="col-lg-5 py-5">
                  <div className="row">
                    <div className="col-12">
                      <div className="info-box">
                        <i className="bx bx-award fs-1 display-1"></i>
                        <div className="ms-4">
                          <h5>APA Mission</h5>
                          <p>
                            We provide access to borderless, flexible,
                            informative training to foster skills, knowledge and
                            collaborations on STI
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 mt-4">
                      <div className="info-box">
                        <i className="fas fa-eye fs-1 display-1"></i>
                        <div className="ms-4">
                          <h5>APA Vision</h5>
                          <p>
                            To Strengthen Sustainable Development through STI
                            training.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 mt-4">
                      <div className="info-box">
                        <i className="bx bx-award fs-1 display-1"></i>
                        <div className="ms-4">
                          <h5>ACTS Mission</h5>
                          <p>
                            Strengthen the capacity and policies of African
                            countries and institutions to harness science,
                            technology and innovation for sustainable
                            development.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 mt-4">
                      <div className="info-box">
                        <i className="fas fa-eye fs-1 display-1"></i>
                        <div className="ms-4">
                          <h5>ACTS Vision</h5>
                          <p>Knowledge for better livelihoods</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-5">
                  <img
                    style={{
                      borderRadius: "38%",
                      border: "17px solid #FFA500",
                    }}
                    src={require("./img/about.jpg")}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </section>

          <section id="blog" className="event1">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-12 py-5">
                  <div className="row">
                    <div className="col-12">
                      <div className="text-center mb-4 ">
                        <h6 className="text-dark fs-3">Recent News</h6>
                        <h1 className="text-black fs-4">Updates</h1>
                      </div>
                      <p className=" mx-auto text-center"></p>

                      <div className="row g-4">
                        <div className="col-lg-4 col-md-6">
                          <div className="card" style={{ height: "100%" }}>
                            <div className="card ">
                              <img
                                src={Logo}
                                className="card-img-top"
                                alt="..."
                              />
                              <div className="card-body">
                                <h5 className="card-title">
                                  Register for in-person or Live Virtual
                                  Classes.
                                </h5>
                                <p className="card-text">
                                  Courses currently on offer include:
                                  <ul className="mt-3">
                                    <li>
                                      Climate Change Modelling for Decision
                                      Making
                                    </li>
                                    <li>Climate Finance</li>
                                    <li>Climate Policy and Leadership</li>
                                    <li>
                                      Science Communication for Scientist or
                                      Communicating science for non-scientists
                                    </li>
                                    <li>
                                      Fundraising Strategies and Grant Writing
                                    </li>
                                    <li>
                                      GIS & Remote Sensing for decision support
                                    </li>
                                  </ul>
                                </p>

                                <Link
                                  onClick={physical}
                                  className="btn btn-primary border border-1"
                                >
                                  Register Here
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-4 col-md-6">
                          <div className="card" style={{ height: "100%" }}>
                            <div className="card ">
                              <img
                                src={Logo}
                                className="card-img-top"
                                alt="..."
                              />
                              <div className="card-body">
                                <h5 className="card-title">
                                  Missed the launch of the ACTS Pathways
                                  Academy?
                                </h5>
                                <p className="card-text">
                                  Watch the key highlights of the events
                                  (including the speeches) here
                                </p>

                                <Link
                                  onClick={launch}
                                  className="btn btn-primary border border-1"
                                >
                                  Watch
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <div className="card" style={{ height: "100%" }}>
                            <div className="card ">
                              <img
                                src={Logo}
                                className="card-img-top"
                                alt="..."
                              />
                              <div className="card-body">
                                <h5 className="card-title">
                                  Live TV interview by Dr. Joel Onyango
                                </h5>
                                <p className="card-text">
                                  Missed the Live TV interview of Dr. Joel
                                  Onyango (ACTS Pathways Academy)? <br />
                                  Watch the interview here
                                </p>

                                <Link
                                  onClick={interview}
                                  className="btn btn-primary border border-1"
                                >
                                  Watch
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <div className="card" style={{ height: "100%" }}>
                            <div className="card ">
                              <img
                                src={Logo}
                                className="card-img-top"
                                alt="..."
                              />
                              <div className="card-body">
                                <h5 className="card-title">Summer Schools</h5>
                                <p className="card-text">
                                  The virtual academy will be hosting summer
                                  schools in partnership with relevant
                                  institutions. This year, APA will be hosting
                                  the Decoloniality Pathways Summer School 2023
                                  from 14th to 21st August 2023 in partnership
                                  with The University of Natural Resources and
                                  Life Sciences, Vienna (BOKU), University of
                                  Innsbruck, Consortium for Transdisciplinary
                                  Futures Research (CTFR) & Africa Uni-Net.
                                  <br /> Theme: "Decolonizing Knowledge and
                                  Learning Systems in the Global South" <br />{" "}
                                  <br />
                                  <Link onClick={summer}>Register here</Link>
                                  <br />
                                  For more information on the Decoloniality
                                  Pathways Summer School email:
                                  academy@acts-net.org
                                </p>

                                <Link
                                  onClick={summer}
                                  className="btn btn-primary border border-1"
                                >
                                  Register Here
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section id="services" className="text-center">
            <div className="container">
              <div className="row mb-3">
                <div className="col-12">
                  <div className="intro">
                    <h4 className="fs-3">Our Services</h4>
                    <h1 className="fs-4">What We Do?</h1>
                  </div>
                </div>
              </div>

              <div className="row g-4">
                <div className="col-lg-4 col-md-6">
                  <div className="service h-100">
                    <i className="fas fa-graduation-cap fs-1 display-1"></i>
                    <h5 className="fw-bold fs-3">Training of Professionals</h5>
                    <p>
                      Our professional courses are designed to building
                      knowledge, skills and competence in individuals, a group
                      or team. These courses will provide opportunities for
                      employees to refine skills, learn new capabilities as well
                      as expand their networks.{" "}
                      <Link to="/our-courses" className="text-info">
                        Available Courses
                      </Link>{" "}
                      For more information please fill the form below.
                      Alternatively you can reach out to us directly via{" "}
                      <span onClick={handleClicka} className="text-primary">
                        e:mail academy@acts-net.org
                      </span>
                    </p>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="service h-100">
                    <i className="fas fa-user-tie fs-1 display-1"></i>
                    <h5 className="fw-bold fs-3">Executive Training Courses</h5>
                    <p>
                      Our Executive training courses aim to improve leadership
                      and management performance. These courses will transform
                      the trainees by challenging them to understand their
                      strengths and talents and utilize them to unlock their
                      full potential. For more information please fill the form
                      below. Alternatively you can reach out to us directly via{" "}
                      <span onClick={handleClicka} className="text-primary">
                        e:mail academy@acts-net.org
                      </span>
                    </p>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="service  h-100">
                    <i className="fas fa-school fs-1 display-1"></i>
                    <h5 className="fw-bold fs-3">Summer Schools</h5>
                    <p>
                      In addition to providing training, the virtual academy
                      will be hosting summer schools in partnership with
                      relevant institutions. This year, APA will be hosting the
                      Decoloniality Pathways Summer School 2023 from 14th to
                      21st August 2023 in partnership with The University of
                      Natural Resources and Life Sciences, Vienna (BOKU),
                      University of Innsbruck, Consortium for Transdisciplinary
                      Futures Research (CTFR) & Africa Uni-Net. Theme:
                      ''Decolonizing Knowledge and Learning Systems in the
                      Global South'' For more information on the Decoloniality
                      Pathways Summer School{" "}
                      <span onClick={handleClicka} className="text-primary">
                        e:mail academy@acts-net.org
                      </span>
                    </p>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="service  h-100">
                    <i className="fas fa-book-reader fs-1 display-1"></i>
                    <h5 className="fw-bold fs-3">Self-paced learning</h5>
                    <p>
                      Through our self-paced learning service, we give you
                      control over your learning experience, allowing you to
                      decide when, where, and how you want to learn. For more
                      information please fill the form below. Alternatively you
                      can reach out to us directly via{" "}
                      <span onClick={handleClicka} className="text-primary">
                        e:mail academy@acts-net.org
                      </span>
                    </p>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="service  h-100">
                    <i className="fas fa-chalkboard-teacher fs-1 display-1"></i>
                    <h5 className="fw-bold fs-3">Bespoke training</h5>
                    <p>
                      We offer specifically designed or tailor made courses to
                      meet your requirement. If you are interested in a bespoke
                      course for yourself or organization please fill the form
                      below. Alternatively you can reach out to us directly via
                      <span onClick={handleClicka} className="text-primary">
                        e:mail academy@acts-net.org
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section id="" className="">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-12 py-5">
                  <div className="row">
                    <div className="col-12">
                      <div className="text-center mb-4 ">
                        <h6 className="text-black fs-3">Patners</h6>
                        <h1 className="text-dark fs-4">Our Patners</h1>
                      </div>
                      <div className="mb-3">
                        <p className="text-center">
                          <strong className="fs-6">APA Partners</strong>: ACTS,
                          EFD and University of Zimbabwe
                        </p>

                        <Swiper spaceBetween={70} slidesPerView={6}>
                          <SwiperSlide>
                            <img
                              src={require("./img/patners/ACTS_LOGO.png")}
                              alt=""
                            />
                          </SwiperSlide>
                          <SwiperSlide>
                            {" "}
                            <img
                              src={require("./img/patners/EFD.png")}
                              alt=""
                            />
                          </SwiperSlide>
                          <SwiperSlide>
                            {" "}
                            <img
                              src={require("./img/patners/University_of_Zimbabwe_LOGO.png")}
                              alt=""
                            />
                          </SwiperSlide>
                        </Swiper>
                      </div>

                      {/* <div className="mt-3">
                        <p className="text-center">
                          <strong className="fs-6">
                            Summer School Partners
                          </strong>
                          : Resources and Life Sciences, Vienna (BOKU),
                          University of Innsbruck, Blue Empowerment Project,
                          Consortium for Transdisciplinary Futures Research
                          (CTFR) & Africa Uni-Net.
                        </p>
                        <Swiper
                          spaceBetween={{ default: 4, sm: 8, md: 12, lg: 16 }} // Adjust spacing for different breakpoints
                          slidesPerView={{ default: 2, sm: 3, md: 4, lg: 5 }} // Number of slides per view for different screen sizes
                          autoplay={{
                            delay: 2000,
                            disableOnInteraction: false,
                            pauseOnMouseEnter: true,
                          }}
                          grabCursor={true}
                          modules={[Autoplay]}
                          centeredSlides={true}
                        >
                          <SwiperSlide>
                            <img
                              src={require("./img/patners/ACTS_LOGO.png")}
                              alt=""
                            />
                          </SwiperSlide>
                          <SwiperSlide>
                            <img
                              src={require("./img/patners/africa_uninet-.png")}
                              alt=""
                            />
                          </SwiperSlide>
                          <SwiperSlide>
                            <img
                              src={require("./img/patners/BE Logo.png")}
                              alt=""
                            />
                          </SwiperSlide>
                          <SwiperSlide>
                            <img
                              src={require("./img/patners/BOKU.png")}
                              alt=""
                            />
                          </SwiperSlide>
                          <SwiperSlide>
                            <img
                              src={require("./img/patners/CTFR.png")}
                              alt=""
                            />
                          </SwiperSlide>
                          <SwiperSlide>
                            <img
                              src={require("./img/patners/universitat-innsbruck-logo-.png")}
                              alt=""
                            />
                          </SwiperSlide>
                        </Swiper>
                      </div> */}

                      <div className="mt-3">
                        <p className="text-center">
                          <strong className="fs-6">
                            Summer School Partners
                          </strong>
                          : Resources and Life Sciences, Vienna (BOKU),
                          University of Innsbruck, Blue Empowerment Project,
                          Consortium for Transdisciplinary Futures Research
                          (CTFR) & Africa Uni-Net.
                        </p>
                        <Swiper
                          spaceBetween={16} // Adjust spacing as needed
                          slidesPerView={1} // Number of slides per view
                          breakpoints={{
                            // Adjust for different screen sizes
                            640: {
                              slidesPerView: 2,
                              spaceBetween: 20,
                            },
                            768: {
                              slidesPerView: 3,
                              spaceBetween: 30,
                            },
                            1024: {
                              slidesPerView: 4,
                              spaceBetween: 40,
                            },
                          }}
                          autoplay={{
                            delay: 1000,
                            disableOnInteraction: false,
                            pauseOnMouseEnter: true,
                          }}
                          grabCursor={true}
                        >
                          <SwiperSlide>
                            <img
                              src={require("./img/patners/ACTS_LOGO.png")}
                              alt=""
                            />
                          </SwiperSlide>
                          <SwiperSlide>
                            <img
                              src={require("./img/patners/africa_uninet-.png")}
                              alt=""
                            />
                          </SwiperSlide>
                          <SwiperSlide>
                            <img
                              src={require("./img/patners/BE Logo.png")}
                              alt=""
                            />
                          </SwiperSlide>
                          <SwiperSlide>
                            <img
                              src={require("./img/patners/BOKU.png")}
                              alt=""
                            />
                          </SwiperSlide>
                          <SwiperSlide>
                            <img
                              src={require("./img/patners/CTFR.png")}
                              alt=""
                            />
                          </SwiperSlide>
                          <SwiperSlide>
                            <img
                              src={require("./img/patners/universitat-innsbruck-logo-.png")}
                              alt=""
                            />
                          </SwiperSlide>
                        </Swiper>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <footer>
            <div className="footer-top text-center">
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-lg-6 text-center">
                    <p className="mb-2">
                      For Enquiries reach us at{" "}
                      <span onClick={handleClicka}>academy@acts-net.org</span>
                    </p>
                    <h4 className="navbar-brand">
                      ACTS Pathways Academy<span className="dot fs-4">.</span>
                    </h4>
                    <p>
                      Our vision is knowledge for better livelihoods. Our
                      Mission is to strengthen the capacity and policies of
                      African countries and institutions to harness science,
                      technology and innovation for sustainable development
                    </p>
                    <div className="col-auto social-icons">
                      <Link onClick={fb}>
                        <i className="bx bxl-facebook"></i>
                      </Link>
                      <Link onClick={tweet}>
                        <i className="bx bxl-twitter"></i>
                      </Link>
                      <Link onClick={ig}>
                        <i className="bx bxl-instagram"></i>
                      </Link>
                      <Link onClick={flickR}>
                        <i className="bx bxl-flickr"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="footer-bottom text-center">
              <p className="mb-0">Copyright@2023. All rights Reserved</p>
            </div>
          </footer>

          <div
            className="modal fade"
            id="exampleModal"
            tabIndex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered modal-xl">
              <div className="modal-content">
                <div className="modal-body p-0">
                  <div className="container-fluid">
                    <div className="row gy-4">
                      <div className="col-lg-4 col-sm-12 bg-cover mod-img">
                        <div></div>
                      </div>
                      <div className="col-lg-8">
                        <form className="p-lg-5 text-dark col-12 row g-3">
                          <div>
                            <h1>Get in touch</h1>
                            <p>
                              Feel free to contact us and we'll get back to you
                              as soon as possible
                            </p>
                          </div>
                          <div className="col-lg-6">
                            <label htmlFor="userName" className="form-label">
                              First name
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Steven"
                              id="userName"
                              aria-describedby="emailHelp"
                            />
                          </div>
                          <div className="col-lg-6">
                            <label htmlFor="userName" className="form-label">
                              Last name
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Zion"
                              id="userName"
                              aria-describedby="emailHelp"
                            />
                          </div>
                          <div className="col-12">
                            <label htmlFor="userName" className="form-label">
                              Email address
                            </label>
                            <input
                              type="email"
                              className="form-control"
                              placeholder="stevenzion@example.com"
                              id="userName"
                              aria-describedby="emailHelp"
                            />
                          </div>
                          <div className="col-12">
                            <label
                              htmlFor="exampleInputEmail1"
                              className="form-label"
                            >
                              Enter Message
                            </label>
                            <textarea
                              name=""
                              placeholder="This is looking great and nice."
                              className="form-control"
                              id=""
                              rows="5"
                            ></textarea>
                          </div>

                          <div className="col-12">
                            <button type="submit" className="btn btn-brand">
                              Send Message
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Home;
