import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { useCartContext } from "../../../Context/CartContext";
import { useEffect, useState, useContext } from "react";
import axios from "../../../API/apiHandler";
import ProfileContext from "../../../Context/ProfileContext";

const Card = (props) => {
  const { token } = useContext(ProfileContext);

  const { id, name, price, category, short_desc, discount } = props;

  const { shop, addToCartShop, removeShopItemFromCart } = useCartContext();
  const total_items = shop.total_items;

  const add = async () => {
    await addToCartShop(id, name, price, category, short_desc, discount);
    toast.success(`${name} added to cart`);
  };
  // , batch_no manufacturer, type,id,

  // const {addToCartShop} = useCartContext()
  // const enrol = async () => {
  //   // await addToCartShop(id, name, price, manufacturer, type, discount, category )
  //   toast.success("item added to cart");
  // };
  const [pics, setPics] = useState([]);
  useEffect(() => {
    

    const fetchImg = async () => {
      try {
        const res = await axios.get(
          `/api/v1/shop/banner/${id}`,
          
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            responseType: "arraybuffer",
          }
        );
        if (res?.data) {
          const base64 = btoa(
            new Uint8Array(res.data).reduce(
              (data, byte) => data + String.fromCharCode(byte),
              ""
            )
          );
          setPics(`data:;base64,${base64}`);
        }
      } catch (error) {
        if (error.response?.data.detail) {
          toast.error(error.response?.data.detail);
        }
      }
    };
    fetchImg();
    
  }, [token, id]);

  return (
    <>
      <div className="shop fs-6">
        <div className="shop__image">
          <img src={pics[0]} alt="..." />
        </div>
        <div className="shop__content">
          <div className="shop__content__tag">
            <span className="tag">{category}</span>
          </div>
          <p className="shop__content__title">{name}</p>
          <p className="shop__content__info">{short_desc}</p>
          <div className="shop__content__config">
            <div className="price">
              <span className="price fs-4">${price}</span>
              {/* <span className="prev_price fs-5"> ${price + discount}</span> */}
            </div>
            <div className="colors">
              <span className="color color1"></span>
              <span className="color color2"></span>
              <span className="color color3"></span>
            </div>
          </div>
          <div className="qty d-flex align-items-center my-4">
            <div className="qty-text">Quantity:</div>
            <div className="qty-change d-flex align-items-center mx-3">
              <button
                type="button"
                className="qty-decrease d-flex align-items-center justify-content-evenly"
                onClick={() => addToCartShop()}
              >
                <i className="fas fa-minus"></i>
              </button>
              <div className="qty-value d-flex align-items-center justify-content-center">
                {total_items}
              </div>
              <button
                type="button"
                className="qty-increase d-flex align-items-center justify-content-center"
                onClick={() => removeShopItemFromCart()}
              >
                <i className="fas fa-plus"></i>
              </button>
            </div>
            {/* {product?.stock === 0 ? (
              <div className="qty-error text-uppercase bg-danger text-white fs-12 ls-1 mx-2 fw-5">
                out of stock
              </div>
            ) : (
              ""
            )} */}
          </div>
          <div className="shop__content__action">
            <button onClick={add} className="cart">
              <i className="fa-solid fa-cart-plus"></i> Add to Cart
            </button>
          </div>
        </div>
      </div>

      <div className="socials">
        <Link to="#" target="_blank" title="Visit our instagram">
          <i className="fa-solid fa-globe" />
        </Link>
        <Link to="#" target="_blank" title="Visit our twitter">
          <i className="fa-brands fa-github" />
        </Link>
      </div>
    </>
  );
};

export default Card;
