import axios from "../../../API/apiHandler";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { useState, useEffect, useContext } from "react";
import ProfileContext from "../../../Context/ProfileContext";
import LoadingOverlay from "../../Load/LoadingOverlay";
// import { toast } from "react-toastify";

const Transactions = () => {
  const { token, auth } = useContext(ProfileContext);
  const [isLoading, setIsLoading] = useState(false);

  const [payments, setPayments] = useState([]);

  useEffect(() => {
    const fetchPayment = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(
          `/api/v1/pespal/payment-details?user_id=${auth}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response?.data?.length > 0) {
          const pay = response?.data;
          pay.sort((a, b) => b.id - a.id);
          setPayments(pay);
        }
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    };

    fetchPayment();
  }, [token, auth]);

  const [visible, setVisible] = useState(false);
  const footerContent = (
    <div>
      <Button
        label="Cancel"
        icon="pi pi-times"
        onClick={() => setVisible(false)}
        className="p-button-text"
      />
      {/* <Button type='submit' label="Confirm" icon="pi pi-check" onClick={handleSubmit} autoFocus /> */}
    </div>
  );

  return (
    <div>
      <Button
        label="Transactions"
        icon="pi pi-users"
        onClick={() => setVisible(true)}
      />

      <Dialog
        header="Payment History"
        visible={visible}
        style={{ width: "50vw" }}
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}
        onHide={() => setVisible(false)}
        footer={footerContent}
      >
        <div className="p-4">
          <div className="">
            <div className="container">
              {isLoading ? (
                <LoadingOverlay />
              ) : (
                <div className=" text-dark">
                  <div className="row">
                    <h3>
                      <span className="badge bg-success mb-2">
                        Total Transactions: {payments?.length}
                      </span>
                    </h3>
                    <div className="col-12">
                      <div className="table-responsive">
                        <table className="table table-light table-hover">
                          <thead>
                            <tr>
                              <th>Currency</th>
                              <th>payment_method</th>
                              <th>Status</th>
                              <th>Date</th>
                              <th>payment status description</th>
                              <th>Amount</th>
                              <th>Confirmation code</th>
                            </tr>
                          </thead>
                          <tbody>
                            {payments.length > 0 ? (
                              payments.map((payment, idx) => (
                                <tr key={idx}>
                                  <td>{payment?.currency}</td>
                                  <td>{payment?.payment_method}</td>
                                  <td>
                                    {payment?.status_code === 1
                                      ? "SUCCESS"
                                      : payment?.status_code === 2
                                      ? "FAILED"
                                      : payment?.status_code === 3
                                      ? "REVERSED"
                                      : payment?.status_code === 0
                                      ? "INVALID"
                                      : "UNKNOWN"}
                                  </td>
                                  <td>{payment?.created_date}</td>
                                  <td>{payment?.payment_status_description}</td>
                                  <td>{payment?.amount}</td>
                                  <td>{payment?.confirmation_code}</td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td colSpan="7" className="text-center">
                                  No Transactions
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default Transactions;
