import { Link } from "react-router-dom";
// import Update from "./Update";
import { toast } from "react-hot-toast";
import axios from "../../../API/apiHandler";

const Card = (props) => {
  const {
    id,
    // sch,
    level,
    area,
    title,
    deadline,
    short_desc,
    type,
    institution,
  } = props;
  const token = JSON.parse(localStorage.getItem("fastapi"));

  const deleteSch = async (id) => {
    try {
      const response = await axios.delete(`/api/v1/scholarships/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      if (response.data) {
        toast.success("Scholarship deleted");
        window.location.reload();
      }
    } catch (error) {
      if (error.response?.data.detail) {
        toast.error(error.response?.data.detail);
      }
    }
  };

  return (
    <article className="fund mb-3">
      <div className="fund_publication">
        <img src="https://picsum.photos/200/" alt="" />
        <div>
          <span className="fund_author">
            <i className="fas fa-user"></i> {institution}
          </span>
          <span className="fund_date">
            <i className="fas fa-calendar-alt"></i> {deadline}
          </span>
          {level && (
            <span className="fund_level">
              <i className="fas fa-map-marker-alt"></i> {level}
            </span>
          )}
        </div>
      </div>
      <div className="fund_info">
        <h2 className="fund_title">{title}</h2>
        <div className="d-flex gap-4 justify-content-between align-items-center">
          <span className="fund_subtitle">Type: {type}</span>
          <span className="fund_subtitle">Area: {area}</span>
        </div>
        <p className="fund_description text-break">{short_desc}</p>
        <div className="d-flex gap-3 align-items-center">
          {/* <Update uuid={id} sch={sch} /> */}
          <Link to={`/sch/details/${id}`} className="btn btn-outline-info p-2">
            Details
          </Link>
          <button
            type="button"
            className="btn btn-outline-danger p-2"
            onClick={() => deleteSch(id)}
          >
            <i className="fas fa-trash"></i> Delete
          </button>
        </div>
      </div>
    </article>
  );
};

export default Card;
