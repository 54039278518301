import React from 'react'
import Funding from '../../../components/Student/Funding/Funding'
import Sidebar from '../../../components/Student/Sidebar'

const FundingsScreen = () => {
	return (
		<div className="container-fluid">
			<div className="row">
				<div className="col-12 col-md-2">
					<Sidebar />
				</div>
				<div className="col-12 col-md-10 mt-5">
					<Funding />
				</div>
			</div>
		</div>
	)
}

export default FundingsScreen
