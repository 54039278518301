import React from "react";
import { useState, useEffect } from "react";
import { toast } from "react-hot-toast";
import axios from "../../../API/apiHandler";

const Criteria = () => {
  const token = JSON.parse(localStorage.getItem("fastapi"));
  const [CourseData, setCourseData] = useState([]);

  useEffect(() => {
    

    const fetchCourses = async () => {
      try {
        const token = JSON.parse(localStorage.getItem("fastapi"));
        const response = await axios.get(
          "/api/v1/courses",
          
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const courses = response?.data;
        courses.sort((a, b) => b.id - a.id);
        setCourseData(courses);
      } catch (error) {}
    };
    fetchCourses();

    
  }, []);

  const [CriteriaForm, setCriteriaForm] = useState({
    course_id: 0,
    level_of_study: 0,
    specific_cert: "",
    grade_pass: "",
    status: true,
    start_date: "",
    end_date: "",
  });

  const handleChange = (e) => {
    setCriteriaForm({
      ...CriteriaForm,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const criteriaData = {
      course_id: CriteriaForm.course_id,
      level_of_study: CriteriaForm.level_of_study,
      specific_cert: CriteriaForm.specific_cert,
      grade_pass: CriteriaForm.grade_pass,
      status: CriteriaForm.status,
      start_date: CriteriaForm.start_date,
      end_date: CriteriaForm.end_date,
    };

    try {
      axios
        .post("/api/v1/courses/enrolment_criteria", criteriaData, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          toast.success("enrolment criteria added");
          window.location.reload();
        });
    } catch (error) {
      toast.error("Error adding enrolment criteria");
    }
  };

  const [levels, setLevels] = useState([]);
  useEffect(() => {
    

    const level = async () => {
      try {
        const res = await axios.get(
          "/api/v1/courses/documents",
          
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        setLevels(res?.data);
      } catch (err) {}
    };

    level();

    
  }, [token]);

  return (
    <div>
      <div className="row mb-3">
        <div className="col">
          <div className="d-grid">
            <button
              type="button"
              className="btn btn-warning btn-block shadow"
              style={{
                boxShadow: "inset 2px 2px 6px #222",
                border: "2px solid #011",
              }}
              data-bs-toggle="modal"
              data-bs-target="#criteria"
            >
              <span className="fs-5">
                <i className="fas fa-plus-circle"></i> Enrolment Criteria (4)
              </span>
            </button>
          </div>

          <div
            className="modal fade"
            style={{ overflow: "scroll !important" }}
            id="criteria"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex="-1"
            aria-labelledby="criteriaLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-lg ">
              <div className="modal-content">
                <div className="modal-header">
                  <h1
                    className="modal-title text-blue-900 fs-5"
                    id="criteriaLabel"
                  >
                    New Course Enrolment Criteria
                  </h1>
                  <button
                    type="button"
                    className="btn-close text-dark"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                  <div className="modalcontainer text-dark">
                    <form onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="col">
                          <div>
                            <span>Course :</span>
                            <select
                              className="form-select"
                              aria-label="Default select"
                              onChange={handleChange}
                              required
                              value={CriteriaForm.course_id}
                              name="course_id"
                            >
                              <option>Select Course</option>
                              {CourseData?.length > 0 ? (
                                CourseData?.map((courses, index) => (
                                  <option value={courses.id} key={index}>
                                    {courses.course_full_name}
                                  </option>
                                ))
                              ) : (
                                <option>No courses yet</option>
                              )}
                            </select>
                          </div>

                          <div className="inputBox">
                            <label
                              htmlFor="level_of_study"
                              className="col-form-label"
                            >
                              Select level of study
                            </label>
                            <select
                              className="form-select mb-3"
                              aria-label="level_of_study"
                              id="level_of_study"
                              name="level_of_study"
                              value={CriteriaForm.level_of_study}
                              onChange={handleChange}
                            >
                              <option defaultValue>Level Of Study</option>
                              {levels &&
                                levels.map((level, index) => (
                                  <option key={index} value={level.id}>
                                    {level.doc_name}
                                  </option>
                                ))}
                            </select>
                          </div>

                          <div className="inputBox">
                            <span>Specific Certificate :</span>
                            <input
                              type="text"
                              placeholder="certificate name"
                              onChange={handleChange}
                              required
                              value={CriteriaForm.specific_cert}
                              name="specific_cert"
                            />
                          </div>

                          <div className="inputBox">
                            <span>Passing Grade :</span>
                            <input
                              type="text"
                              placeholder=""
                              onChange={handleChange}
                              required
                              value={CriteriaForm.grade_pass}
                              name="grade_pass"
                            />
                          </div>

                          <div className="inputBox">
                            <span>Start Date :</span>
                            <input
                              type="date"
                              placeholder=""
                              onChange={handleChange}
                              required
                              value={CriteriaForm.intake_date_from}
                              name="start_date"
                            />
                          </div>

                          <div className="inputBox">
                            <span>End Date :</span>
                            <input
                              type="date"
                              placeholder=""
                              onChange={handleChange}
                              required
                              value={CriteriaForm.intake_date_to}
                              name="end_date"
                            />
                          </div>
                        </div>
                      </div>

                      <input type="submit" value="ADD" className="submit-btn" />
                    </form>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary text-dark"
                    data-bs-dismiss="modal"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Criteria;
