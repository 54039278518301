import { useState, useEffect } from "react";
import { toast } from "react-hot-toast";
// import { useParams } from "react-router-dom";

import axios from "../../../API/apiHandler";

const Update = ({ fund }) => {
  const [fundUpdate, setFundUpdate] = useState({
    inst_id: 0,
    funding_title: "",
    funding_amount: 0,
    funding_area: "",
    funding_type: "",
    funding_location: "",
    funding_duration: 0,
    funding_eligible_entities: "",
    funding_deadline_date: "",
    funding_short_desc: "",
    funding_full_desc: "",
    published: true,
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFundUpdate((prevfundUpdate) => {
      return {
        ...prevfundUpdate,
        [name]: value,
      };
    });
  };

  useEffect(() => {
    setFundUpdate(
      fund
      //   {
      //   inst_id: fund.inst_id,
      //   funding_title: fund.funding_title,
      //   funding_amount: fund.funding_amount,
      //   funding_area: fund.funding_area,
      //   funding_type: fund.funding_type,
      //   funding_location: fund.funding_location,
      //   funding_duration: fund.funding_duration,
      //   funding_eligible_entities: fund.funding_eligible_entities,
      //   funding_deadline_date: fund.funding_deadline_date,
      //   funding_short_desc: fund.funding_short_desc,
      //   funding_full_desc: fund.funding_full_desc,
      //   published: fund.published,
      // }
    );
  }, [fund]);

  // useEffect(() => {

  //   const existingFunding = async () => {
  //     try {
  //       const token = JSON.parse(localStorage.getItem("fastapi"));
  //       const response = await axios.get(
  //         `/api/v1/funding/${uuid}`,

  //         {
  //           headers: {
  //             Authorization: `Bearer ${token}`,
  //             "Content-Type": "application/json",
  //           },
  //         }
  //       );
  //       if (response.status === 200 || response.status === 201) {
  //         setFundUpdate({ ...fundUpdate, ...response.data });
  //       }
  //     } catch (error) {}
  //   };
  //   existingFunding();

  // }, [uuid, fundUpdate]);

  const handleUpdate = async (e) => {
    e.preventDefault();
    const inst_id = fundUpdate.inst_id;
    const funding_title = fundUpdate.funding_title;
    const funding_amount = fundUpdate.funding_amount;
    const funding_area = fundUpdate.funding_area;
    const funding_type = fundUpdate.funding_type;
    const funding_location = fundUpdate.funding_location;
    const funding_duration = fundUpdate.funding_duration;
    const funding_eligible_entities = fundUpdate.funding_eligible_entities;
    const funding_deadline_date = fundUpdate.funding_deadline_date;
    const funding_short_desc = fundUpdate.funding_short_desc;
    const funding_full_desc = fundUpdate.funding_full_desc;
    const published = fundUpdate.published;

    const fundupdate = {
      inst_id,
      funding_title,
      funding_amount,
      funding_area,
      funding_type,
      funding_location,
      funding_duration,
      funding_eligible_entities,
      funding_deadline_date,
      funding_short_desc,
      funding_full_desc,
      published,
    };

    try {
      const token = JSON.parse(localStorage.getItem("fastapi"));
      const response = await axios.put(
        `/api/v1/funding/${fund?.id}`,
        fundupdate,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status === 201 || response.status === 200) {
        toast.success("updated successfully");
        window.location.reload();
      }
    } catch (error) {
      toast.error("Couldn't update funding");
    }
  };

  return (
    <>
      <div>
        <div className="d-grid">
          <button
            type="button"
            className="btn btn-warning text-warning shadow"
            style={{
              boxShadow: "inset 2px 2px 6px #222",
              border: "2px solid #011",
            }}
            data-bs-toggle="modal"
            data-bs-target="#fundupdates"
          >
            <span className="">
              <i className="fas fa-plus-circle"></i>
              Update
            </span>
          </button>
        </div>

        <div
          className="modal fade container w-full"
          style={{ overflow: "scroll !important" }}
          id="fundupdates"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex="-1"
          aria-labelledby="fundupdatesLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg w-full">
            <div className="modal-content">
              <div className="modal-header">
                <h1
                  className="modal-title text-primary fs-5"
                  id="fundupdatesLabel"
                >
                  Update funding details
                </h1>
                <button
                  type="button"
                  className="btn-close text-dark"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className="modalcontainer text-dark">
                  <form onSubmit={handleUpdate}>
                    <div className="row">
                      <div className="col">
                        <div className="inputBox">
                          <span>funding Title :</span>
                          <input
                            type="text"
                            placeholder=""
                            onChange={handleChange}
                            name="funding_title"
                            required
                            value={fundUpdate.funding_title}
                          />
                        </div>
                        <div className="inputBox">
                          <span>Area of funding :</span>
                          <input
                            type="text"
                            placeholder=""
                            onChange={handleChange}
                            required
                            value={fundUpdate.funding_area}
                            name="funding_area"
                          />
                        </div>
                        <div className="inputBox">
                          <span>Amount :</span>
                          <input
                            type="text"
                            placeholder=""
                            onChange={handleChange}
                            required
                            value={fundUpdate.funding_amount}
                            name="funding_amount"
                          />
                        </div>
                        <div className="flex">
                          <div className="inputBox">
                            <span>Type Of Funding :</span>
                            <input
                              type="text"
                              placeholder=""
                              onChange={handleChange}
                              required
                              value={fundUpdate.funding_type}
                              name="funding_type"
                            />
                          </div>
                        </div>

                        <div className="flex">
                          <div className="inputBox">
                            <span>Location :</span>
                            <input
                              type="text"
                              placeholder=""
                              onChange={handleChange}
                              required
                              value={fundUpdate.funding_location}
                              name="funding_location"
                            />
                          </div>

                          <div className="inputBox">
                            <span>Duration :</span>
                            <input
                              type="text"
                              placeholder=""
                              onChange={handleChange}
                              required
                              value={fundUpdate.funding_duration}
                              name="funding_duration"
                            />
                          </div>

                          <div className="inputBox mb-3">
                            <span>Deadline :</span>
                            <input
                              type="date"
                              className="form-control"
                              name="funding_deadline_date"
                              placeholder=""
                              onChange={handleChange}
                              required
                              value={fundUpdate.funding_deadline_date}
                            />
                          </div>
                        </div>
                        <div className="inputBox">
                          <span>Eligibility :</span>
                          <input
                            type="text"
                            placeholder=""
                            onChange={handleChange}
                            required
                            value={fundUpdate.funding_eligible_entities}
                            name="funding_eligible_entities"
                          />
                        </div>

                        <div className="inputBox">
                          <span>Short Description :</span>
                          <textarea
                            className="form-control mb-4"
                            row="4"
                            placeholder="Enter short description"
                            onChange={handleChange}
                            required
                            value={fundUpdate.funding_short_desc}
                            name="funding_short_desc"
                          />
                        </div>

                        <div className="inputBox">
                          <span>Full Description :</span>
                          <textarea
                            className="form-control mb-4"
                            row="4"
                            placeholder="Enter detailedf description"
                            onChange={handleChange}
                            required
                            value={fundUpdate.funding_full_desc}
                            name="funding_full_desc"
                          />
                        </div>
                      </div>
                    </div>

                    <input
                      type="submit"
                      value="Update"
                      className="submit-btn"
                    />
                  </form>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-outline-danger text-dark"
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Update;
