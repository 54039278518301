import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import styled from "styled-components";
import { toast } from "react-hot-toast";

import { MdInfo } from "react-icons/md";
import { TbWorld } from "react-icons/tb";
import { FaShoppingCart } from "react-icons/fa";
import { RiClosedCaptioningFill } from "react-icons/ri";
import { BiCheck } from "react-icons/bi";

import axios from "../../../API/apiHandler";
import { useCartContext } from "../../../Context/CartContext";
import image from "../../../images/acts-logo@1x.png";

const CourseDetails = (props) => {
  const { id } = useParams();
  const { addToCart } = useCartContext();

  const [CourseData, setCourseData] = useState({});
  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const token = JSON.parse(localStorage.getItem("fastapi"));
        const response = await axios.get(
          `/api/v1/courses/${id}/published`,

          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            withCredentials: true,
          }
        );
        setCourseData(response?.data);
      } catch (error) {}
    };

    fetchCourses();
  }, [id]);

  const course_accreditation = CourseData?.course?.course_accreditation;
  const course_code = CourseData?.course?.course_code;
  const intake = CourseData?.course?.course_first_offered;
  const cost = CourseData?.course?.course_full_amount;
  const name = CourseData?.course?.course_full_name;
  const duration = CourseData?.course?.course_length;
  const course_study_level = CourseData?.course?.course_study_level;
  const course_summary = CourseData?.course?.course_summary;
  const course_study_area = CourseData?.course?.course_study_area;

  const category = CourseData?.course?.category?.cat_name;

  const institution = CourseData?.course?.institution?.inst_name;
  // const inst_short_name = CourseData?.course?.institution.inst_short_name;

  const lastIntake = CourseData?.intakes?.length
    ? CourseData.intakes[CourseData.intakes.length - 1]
    : null;

  const intake_id = lastIntake ? lastIntake.id : null;
  const starting = lastIntake ? lastIntake?.intake_date_from : null;
  const ending = lastIntake ? lastIntake?.intake_date_to : null;

  const currentDate = new Date();
  const startDate = new Date(starting);
  const endDate = new Date(ending);

  const enrol = async () => {
    await addToCart(
      id,
      name,
      intake,
      cost,
      duration,
      category,
      institution,
      intake_id
    );
    toast.success("Course added to cart");
  };

  return (
    <SingleCourseWrapper>
      {name ? (
        <div>
          <div className="course-intro mx-auto grid">
            <div className="d-flex justify-content-between">
              <div className="course-img d-sm-none">
                <img src={image} alt={name} />
              </div>
              <div>
                <div className="mb-3 course-head">
                  <div className="text-primary text-break">
                    <h5 className="fw-8 fs-26 text-capitalize text-dark mb-0">
                      {name} [{course_code}]
                    </h5>
                  </div>
                </div>

                <div className="course-category bg-white text-dark text-capitalize fw-6 fs-6 mb-2 d-inline-block">
                  {category}
                </div>
                <ul className="course-info">
                  <li>
                    <span className="fs-6">
                      Course Length:{" "}
                      <span className="fw-6 opacity-08">{duration}</span>
                    </span>
                  </li>
                  <li className="flex">
                    <span>
                      <MdInfo />
                    </span>
                    <span className="fs-6 course-info-txt fw-5">
                      first Offered {intake}
                    </span>
                  </li>
                  <li className="flex">
                    <span>
                      <TbWorld />
                    </span>
                    <span className="fs-6 course-info-txt fw-5">
                      {course_accreditation}
                    </span>
                  </li>
                  <li className="flex">
                    <span>
                      <RiClosedCaptioningFill />
                    </span>
                    <span className="fs-6 course-info-txt fw-5">
                      {course_study_area}
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="course-details">
              <div className="course-body">
                <p
                  className="course-para fs-18"
                  style={{ whiteSpace: "pre-wrap" }}
                  dangerouslySetInnerHTML={{ __html: course_summary }}
                />

                <div className="course-rating flex">
                  <span className="rating-star-val fw-8 fs-16">
                    <div>
                      {startDate > currentDate ? (
                        <span className="badge bg-success fs-6">Upcoming</span>
                      ) : endDate < currentDate ? (
                        <span className="badge bg-danger fs-6">Closed</span>
                      ) : (
                        <span className="badge bg-primary fs-6">Ongoing</span>
                      )}
                    </div>
                  </span>
                  <span className="rating-count fw-5 fs-14">
                    ({course_study_level})
                  </span>
                </div>
              </div>

              <div className="course-foot">
                <div className="course-price">
                  <span className="new-price fs-5 text-light fw-8">
                    USD:&#160;${cost}
                  </span>
                  {/* <span className='old-price fs-26 fw-6'>${actual_price}</span> */}
                </div>
              </div>

              <div className="course-btn">
                <Link
                  to="/cart"
                  className="add-to-cart-btn d-inline-block fw-7 btn btn-outline-primary text-white"
                  onClick={enrol}
                  disabled={endDate < currentDate}
                >
                  <div className="d-flex gap-2 align-items-center">
                    <FaShoppingCart />
                    <p>Enrol Now</p>
                  </div>
                </Link>
              </div>
            </div>
          </div>

          <div className="course-full bg-white text-dark">
            <div className="course-learn mx-auto">
              <div className="course-sc-title">Units</div>
              <ul className="course-learn-list grid">
                {CourseData?.units &&
                  CourseData?.units?.map((learnItem, idx) => {
                    return (
                      <li key={idx}>
                        <div className="d-flex align-items-center gap-2">
                          <BiCheck />
                          <span className="fs-14 fw-5 opacity-09">
                            {learnItem?.unit_full_name}
                          </span>
                        </div>
                      </li>
                    );
                  })}
              </ul>
            </div>

            <div className="course-content mx-auto">
              <div className="course-sc-title">Enrolment Criteria</div>
              {CourseData?.enrolment_criteria &&
                CourseData?.enrolment_criteria.map((Item, idx) => {
                  return (
                    <ul key={idx} className="course-content-list">
                      <li>
                        <span className="fs-14 fw-5 opacity-09">
                          Specific Certifcate: {Item?.specific_cert}
                        </span>
                      </li>
                      <li>
                        <span className="fs-14 fw-5 opacity-09">
                          Passing Grade: {Item?.grade_pass}
                        </span>
                      </li>
                      <li>
                        <span className="fs-14 fw-5 opacity-09">
                          Start Date: {Item?.start_date}
                        </span>
                      </li>
                      <li>
                        <span className="fs-14 fw-5 opacity-09">
                          End Date: {Item?.end_date}
                        </span>
                      </li>
                      <li>
                        <span className="fs-14 fw-5 opacity-09">
                          Level of Study: {Item?.doc_name}
                        </span>
                      </li>
                    </ul>
                  );
                })}
            </div>
          </div>
        </div>
      ) : (
        <div className="text-primary mt-5 text-center">
          <div className="d-flex align-items-center justify-content-center">
            <span
              className="fs-2 spinner-border spinner-border-sm text-success"
              role="status"
              aria-hidden="true"
            ></span>
            <span>Loading Course Info....</span>
          </div>
        </div>
      )}
    </SingleCourseWrapper>
  );
};

const SingleCourseWrapper = styled.div`
  background: #b8b8b8;
  color: #000;
  font-weight: 600;
  font-size: 16px;

  .course-intro {
    padding: 40px 16px;
    max-width: 992px;

    .course-details {
      padding-top: 20px;
    }

    .course-category {
      padding: 0px 8px;
      border-radius: 6px;
    }

    .course-head {
      font-size: 42px;
      line-height: 1.3;
      padding: 10px 0 0 0;
    }
    .course-para {
      padding: 12px 0;
    }
    .rating-star-val {
      margin-right: 7px;
      padding-bottom: 5px;
      color: var(--clr-orange);
    }
    .students-count {
      margin-left: 8px;
    }
    .rating-count {
      margin-left: 6px;
      color: #fff;
    }
    .course-info {
      li {
        margin-bottom: 2px;
        &:nth-child(2) {
          margin-top: 10px;
        }
      }
      .course-info-txt {
        text-transform: capitalize;
        margin-left: 8px;
        margin-bottom: 4px;
      }
    }
    .course-price {
      margin-top: 12px;
      .old-price {
        color: #eceb98;
        text-decoration: line-through;
        margin-left: 10px;
      }
    }
    .course-btn {
      margin-top: 16px;
      .add-to-cart-btn {
        padding: 12px 28px;
        span {
          margin-left: 12px;
        }
      }
    }

    @media screen and (min-width: 880px) {
      grid-template-columns: repeat(2, 1fr);
      column-gap: 40px;
      .course-details {
        padding-top: 0;
      }
      .course-img {
        order: 2;
        // hide image on small screen
        display: none;
      }
    }

    @media screen and (min-width: 1400px) {
      grid-template-columns: 60% 40%;
    }
  }

  .course-full {
    padding: 40px 16px;
    .course-sc-title {
      font-size: 22px;
      font-weight: 700;
      margin: 12px 0;
    }
    .course-learn {
      max-width: 992px;
      border: 1px solid rgba(0, 0, 0, 0.2);
      padding: 12px 28px 22px 28px;

      .course-learn-list {
        li {
          margin: 5px 0;
          display: flex;
          span {
            &:nth-child(1) {
              opacity: 0.95;
              margin-right: 12px;
            }
          }
        }

        @media screen and (min-width: 992px) {
          grid-template-columns: repeat(2, 1fr);
        }
      }
    }

    .course-content {
      max-width: 992px;
      margin-top: 30px;
      border: 1px solid rgba(0, 0, 0, 0.2);
      padding: 12px 28px 22px 28px;

      .course-content-list {
        li {
          background-color: #f7f9fa;
          padding: 12px 18px;
          border: 1px solid rgba(0, 0, 0, 0.2);
          margin-bottom: 10px;
          font-weight: 800;
          font-size: 15px;
        }
      }
    }
  }
`;

export default CourseDetails;
