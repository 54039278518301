import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { ConfirmDialog } from "primereact/confirmdialog";
import { Button } from "primereact/button";

import ProfileContext from "../../../../Context/ProfileContext";
import { useCartContext } from "../../../../Context/CartContext";

import axios from "../../../../API/apiHandler";
import LoadingOverlay from "../../../Load/LoadingOverlay.jsx";

export default function Tracking() {
  const [showOrderPopup, setShowOrderPopup] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { token, auth, setOrderId } = useContext(ProfileContext);

  const { courseIds } = useCartContext();

  // Convert the array of courseIds to a string with multiple course_ids parameters
  const courseIdsString = courseIds
    .map((courseId) => `course_ids=${courseId}`)
    .join("&");
    console.log(courseIdsString);

  // Get the URLSearchParams object from the current URL
  const urlParams = new URLSearchParams(window.location.search);

  // Get the value of OrderTrackingId
  const orderTrackingId = urlParams.get("OrderTrackingId");

  useEffect(() => {
    setLoading(true);
    const fetchUser = async () => {
      try {
        const res = await axios.get(
          // `/api/v1/pespal/get-payment-status/?orderTrackingId=${orderTrackingId}&course_ids=${courseIds}`,
          `/api/v1/pespal/get-payment-status/?orderTrackingId=${orderTrackingId}&${courseIdsString}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log(res.data);
        if (res) {
          const status = res?.data?.status_code;
          if (status === 1) {
            setShowOrderPopup(true);
            toast.success(res.data.message);
            setOrderId("");
          } else {
            setOrderId("");
            toast.warning("something went wrong while processing your payment");
            navigate("/cart");
          }
        }
      } catch (err) {
      } finally {
        setLoading(false);
      }
    };

    if (orderTrackingId) {
      fetchUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderTrackingId, auth, token, setOrderId]);

  loading && <LoadingOverlay />;

  if (showOrderPopup) {
    return (
      <div className="h-screen container">
        <ConfirmDialog
          visible={showOrderPopup}
          onHide={() => setShowOrderPopup(false)}
          message="Welcome, Proceed to Learning Hub?"
          header="Confirmation"
          className="text-lg"
          footer={
            <div>
              <Button
                onClick={() => navigate("/cart")}
                className="p-button-text text-lg"
                label="Payment History"
                icon="pi pi-check"
              />
              <div className=""></div>
              <Button
                onClick={() => navigate("/studyhub")}
                className="p-button-text text-lg"
                label="Start Learning"
                icon="pi pi-check"
              />
            </div>
          }
          icon="pi pi-info-circle"
        />
      </div>
    );
  }
}
