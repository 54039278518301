import React from 'react'
import Nav from '../../components/admin/Nav'
import Market from '../../components/admin/Market/Market'

const MarketScreen = () => {
	return (
		<div className="container-fluid">
			<div className="row">
				<div className="col-12 col-md-2">
					<Nav />
				</div>
				<div className="col-12 col-md-10 mt-5">
					<Market />
				</div>
			</div>
		</div>
	)
}

export default MarketScreen
