export const OPEN_SIDEBAR = 'OPEN_SIDEBAR';
export const CLOSE_SIDEBAR = 'CLOSE_SIDEBAR';
export const GET_COURSES = 'GET_COURSES';
export const GET_SINGLE_COURSE = 'GET_SINGLE_COURSE';
export const GET_CATEGORIES = 'GET_CATEGORIES';
export const ADD_COURSE_TO_CART = 'ADD_COURSE_TO_CART';
export const ADD_SHOP_ITEM_TO_CART = 'ADD_SHOP_ITEM_TO_CART';
export const REMOVE_SHOP_ITEM_FROM_CART = 'REMOVE_SHOP_ITEM_FROM_CART';
export const REMOVE_CART_ITEM = 'REMOVE_CART_ITEM';
export const CLEAR_CART = 'CLEAR_CART';
export const GET_CART_TOTAL = 'GET_CART_TOTAL';
export const LOGOUT = 'LOGOUT';
