import { useState } from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { toast } from 'react-toastify';
import axios from '../../../API/apiHandler'

const CellModal = () => {

	const [eventData, setEventData] = useState({
		title: '',
		startDate: '',
		endDate: '',
		startTime: '',
		endTime: '',
		location: '',
		description: '',
		facilitator: '',
		url: '',
		reminders: '',
		time_zone: '',
		created_at: '',
	});

	const now = new Date();
	const isoString = now.toISOString();


	const handleChange = (e) => {
		setEventData({
			...eventData,
			[e.target.name]: e.target.value,
		created_at: isoString,
		});
	};

	// const handleDateChange = (name, date) => {
	// 	setEventData({
	// 		...eventData,
	// 		[name]: date,
	// 	});
	// };


	const { title, startDate, endDate, startTime, endTime, location, description, facilitator, url, reminders, time_zone } = eventData


	const handleEventCreated = async (e) => {
		e.preventDefault()
		const token = JSON.parse(localStorage.getItem('fastapi'));

		const event = {
			title: eventData.title,
			start_time: eventData.startTime,
			end_time: eventData.endTime,
			start_date: eventData.startDate,
			end_date: eventData.endDate,
			location: eventData.location,
			url: eventData.url,
			description: eventData.description,
			reminders: eventData.reminders,
			time_zone: eventData.time_zone,
			facilitators: eventData.facilitator,
			created_at: eventData.created_at,
		}

		try {
			const res = await axios.post('/api/v1/Events', event, {
				headers: {
					Authorization: `Bearer ${token}`,
					'Content-Type': 'application/json'
				}
			});
			if (res.status === 201 || res.status === 200) {
				toast.success('Event created successfully');
				window.location.reload()
			}
		} catch (error) {
			if (error.response) {
				toast.error(error.response.data.detail);
			} else {
			}
		}
	}
	const [visible, setVisible] = useState(false);

	return (
		<>
			<div classNameName="card d-flex justify-content-center">
				<Button label="Add Event" icon="pi pi-calendar" onClick={() => setVisible(true)} />
				<Dialog header="Create an Event"
					visible={visible}
					style={{ width: '50vw' }}
					breakpoints={{ '960px': '75vw', '641px': '100vw' }}
					onHide={() => setVisible(false)}
				>
					<form onSubmit={handleEventCreated}>
						<div className="row g-3">
							<div className="col-md-6">
								<label for="title" className="form-label">Title</label>
								<input id="title" name="title" type="text" value={title} onChange={handleChange} required className="form-control" />
							</div>
							<div className="col-md-6">
								<label for="location" className="form-label">Location</label>
								<input id="location" name="location" type="text" value={location} onChange={handleChange} required className="form-control" />
							</div>
							<div className="col-md-6">
								<label for="startDate" className="form-label">Start Date</label>
								<input id="startDate" name="startDate" type="date" value={startDate} onChange={handleChange} required className="form-control" />
							</div>
							<div className="col-md-6">
								<label for="endDate" className="form-label">End Date</label>
								<input id="endDate" name="endDate" type="date" value={endDate} onChange={handleChange} required className="form-control" />
							</div>
							<div className="col-md-6">
								<label for="startTime" className="form-label">Start Time</label>
								<input id="startTime" name="startTime" type="time" value={startTime} onChange={handleChange} required className="form-control" />
							</div>
							<div className="col-md-6">
								<label for="endTime" className="form-label">End Time</label>
								<input id="endTime" name="endTime" type="time" value={endTime} onChange={handleChange} required className="form-control" />
							</div>
							<div className="col-md-6">
								<label for="description" className="form-label">Description</label>
								<textarea id="description" name="description" value={description} onChange={handleChange} rows={3} cols={20} className="form-control"></textarea>
							</div>
							<div className="col-md-6">
								<label for="facilitator" className="form-label">Facilitator</label>
								<input id="facilitator" name="facilitator" type="text" value={facilitator} onChange={handleChange} required className="form-control" />
							</div>
							<div className="col-md-6">
								<label for="url" className="form-label">URL</label>
								<input id="url" name="url" type="text" value={url} onChange={handleChange} required className="form-control" />
							</div>
							<div className="col-md-6">
								<label for="reminders" className="form-label">Reminders</label>
								<select id="reminders" name="reminders" type="text" value={reminders} onChange={handleChange} required className='form-select' >
									<option value="" disabled defaultValue>Select a reminder</option>
									<option value="15">15 minutes before</option>
									<option value="30">30 minutes before</option>
									<option value="60">1 hour before</option>
									<option value="1440">1 day before</option>
								</select>
							</div>
							<div className="col-md-6">
								<label for="time_zone" className="form-label">Time Zone</label>
								<select id="time_zone" name="time_zone" type="text" value={time_zone} className='form-select' onChange={handleChange} required placeholder='EAT 3+' >
									<option value="" disabled defaultValue>Select the TimeZone</option>
									<option value="Africa/Bujumbura">Africa/Bujumbura</option>
									<option value="Africa/Dar_es_Salaam">Africa/Dar_es_Salaam</option>
									<option value="Africa/Djibouti">Africa/Djibouti</option>
									<option value="Africa/Juba">Africa/Juba</option>
									<option value="Africa/Kampala">Africa/Kampala</option>
									<option value="Africa/Khartoum">Africa/Khartoum</option>
									<option value="Africa/Kigali">Africa/Kigali</option>
									<option value="Africa/Lubumbashi">Africa/Lubumbashi</option>
									<option value="Africa/Lusaka">Africa/Lusaka</option>
									<option value="Africa/Maputo">Africa/Maputo</option>
									<option value="Africa/Maseru">Africa/Maseru</option>
									<option value="Africa/Mbabane">Africa/Mbabane</option>
									<option value="Africa/Mogadishu">Africa/Mogadishu</option>
									<option value="Africa/Nairobi">Africa/Nairobi</option>
									<option value="Africa/Ndjamena">Africa/Ndjamena</option>
									<option value="Africa/Tripoli">Africa/Tripoli</option>
									<option value="Africa/Tunis">Africa/Tunis</option>
									<option value="Africa/Windhoek">Africa/Windhoek</option>

								</select>
							</div>
						</div>
						<button className='enrol_cta btn bg-blue-900 mt-3 text-white' type='submit'>
							Save Event
						</button>
					</form>


				</Dialog>
			</div>
		</>
	)
}

export default CellModal
