import React, { useState, useEffect, useContext } from "react";
import { Dialog } from "primereact/dialog";
import { Link } from "react-router-dom";
// import { toast } from "react-hot-toast";
// import logo from "../../../images/acts-logo@1x.png";
import ProfileContext from "../../../Context/ProfileContext";
import axios from "../../../API/apiHandler";

const MsgCard = ({ message, msg }) => {
  const { token } = useContext(ProfileContext);
  const iso = message?.date_created;
  const dateObj = new Date(iso);
  const [visible, setVisible] = useState(false);

  const date = dateObj.toISOString().substring(0, 10);
  const time = dateObj.toTimeString().substring(0, 8);

  const id = message?.user_id;
  const [sender, setSender] = useState({});

  useEffect(() => {
    

    const fetchUser = async () => {
      try {
        const res = await axios.get(
          `/api/v1/user/profile/${id}`,
          
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setSender(res?.data);
      } catch (err) {}
    };

    fetchUser();
    
  }, [token, id]);

  return (
    <>
      <div className="mb-4 bg-primary rounded-1" style={{ maxWidth: "70%" }}>
        <div className="flex flex-row items-center">
          <div className="rounded-full h-8 w-8 bg-blue-500">
            {/* <img src={logo} alt="logo" className="h-3 w-3 rounded-full" /> */}
          </div>
          <div className="ml-2">
            <Link className="" onClick={() => setVisible(true)}>
              <p className="text-warning font-semibold text-capitalize">
                {sender?.first_name || msg.sender}
              </p>
            </Link>
            <p className="text-gray-600 p-2 fw-semibold fs-6">
              {message?.content || "Welcome to APA Community Chat"}
            </p>
            <small className="text-gray-400 text-xs ">
              {date} {time}
            </small>
          </div>
        </div>
      </div>

      <div className="card flex justify-content-center">
        <Dialog
          header="Bio"
          visible={visible}
          modal={false}
          style={{ width: "25vw" }}
          onHide={() => setVisible(false)}
        >
          <div className="flex justify-content-start gap-1 flex-column">
            <p className="">FirstName: {sender?.first_name || msg.sender}</p>
            <p className="">LastName: {sender?.last_name}</p>
            <p className="">
              Email: <i className="pi pi-lock text-danger"></i>
            </p>
            <p className="">
              Phone: <i className="pi pi-lock text-danger"></i>
            </p>
            <p className="">Gender: {sender?.gender}</p>
            <p className="">Country: {sender?.country}</p>
            <p className="">City: {sender?.city}</p>
          </div>
        </Dialog>
      </div>
    </>
  );
};

export default MsgCard;
