import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
// import { MdInfo } from "react-icons/md";
import { TbWorld } from "react-icons/tb";
import { RiClosedCaptioningFill } from "react-icons/ri";
import { toast } from "react-hot-toast";

import axios from "../../../API/apiHandler";
import image from "../../../images/acts-logo@1x.png";
import Update from "./Update";

const ScholarshipDetails = () => {
  const { id } = useParams();
  const [schData, setSchData] = useState([]);

  useEffect(() => {
    

    const fetchScholarship = async () => {
      try {
        const token = JSON.parse(localStorage.getItem("fastapi"));
        const response = await axios.get(
          `/api/v1/scholarships/${id}`,
          
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setSchData(response?.data);
      } catch (error) {
        if (error.response?.data.detail) {
          toast.error(error.response?.data.detail);
        }
      }
    };

    fetchScholarship();
    
  }, [id]);

  return (
    <SingleCourseWrapper>
      {schData ? (
        schData.map((sch, idx) => (
          <div key={idx}>
            <div className="course-intro mx-auto grid">
              <div className="d-flex justify-content-between">
                <div className="course-img">
                  <img src={image} alt={sch?.sch_title} />
                </div>
                <div>
                  <div className="mb-3 course-head">
                    <div className="text-blue-900">
                      <h5>{sch?.sch_title}</h5>
                    </div>
                  </div>

                  <div className="course-category bg-white text-dark text-capitalize fw-6 fs-6 mb-2 d-inline-block">
                    {sch?.sch_type_of_funding}
                  </div>
                  <ul className="course-info">
                    <li>
                      <span className="fs-6">
                        Application Deadline:{" "}
                        <span className="fw-6 opacity-08">
                          {sch?.sch_deadline_date}
                        </span>
                      </span>
                    </li>
                    {/* <li className='flex'>
                      <span><MdInfo /></span>
                      <span className='fs-6 course-info-txt fw-5'>first Offered {deadline}</span>
                    </li> */}
                    <li className="flex">
                      <span>
                        <TbWorld />
                      </span>
                      <span className="fs-6 course-info-txt fw-5">
                        Eligibility: {sch?.sch_eligibility}
                      </span>
                    </li>
                    <li className="flex">
                      <span>
                        <RiClosedCaptioningFill />
                      </span>
                      <span className="fs-6 course-info-txt fw-5">
                        Area: {sch?.sch_area_of_study}
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="course-details">
                <div className="course-body">
                  <p className="course-para fs-18">{sch?.sch_full_desc}</p>
                  <div className="course-rating flex">
                    <span className="rating-star-val fw-8 fs-16">
                      {sch?.institution.inst_name} [
                      {sch?.institution.inst_short_name}]
                    </span>
                    <span className="rating-count fw-5 fs-14">
                      ({sch?.sch_level_of_study})
                    </span>
                  </div>
                </div>

                <div className="course-foot">
                  <div className="course-price">
                    <span className="new-price fs-5 text-info fw-8">
                      $USD:&#160;{sch?.sch_amount}
                    </span>
                    {/* <span className='old-price fs-26 fw-6'>${actual_price}</span> */}
                  </div>
                </div>

                <div className="course-btn">
                  <Update sch={sch} />
                </div>
              </div>
            </div>

            <div className="course-full bg-white text-dark">
              <div className="course-content mx-auto">
                <div className="course-sc-title">Application Requirements</div>
                {schData.map((doc, idx) => (
                  <ul key={idx} className="course-content-list">
                    <li className="">
                      <span className="fs-6">
                        Minimum Grade:{" "}
                        <span className="fw-6 opacity-08">
                          {doc?.documents?.doc_min_grade}
                        </span>
                      </span>
                    </li>
                    <li className="">
                      <span className="fs-6">
                        Required Status:{" "}
                        <span className="fw-6 opacity-08">
                          {doc?.documents?.doc_required_status}
                        </span>
                      </span>
                    </li>
                    <li className="">
                      <span className="fs-6">
                        Start date:{" "}
                        <span className="fw-6 opacity-08">
                          {doc?.documents?.doc_min_date_start}
                        </span>
                      </span>
                    </li>
                    <li className="">
                      <span className="fs-6">
                        End Date:{" "}
                        <span className="fw-6 opacity-08">
                          {doc?.documents?.doc_min_date_end}
                        </span>
                      </span>
                    </li>
                    <li className="">
                      <span className="fs-6">
                        {doc?.documents?.doc_type?.doc_type}:{" "}
                        <span className="fw-6 opacity-08">
                          {doc?.documents?.doc_type.doc_name}
                        </span>
                      </span>
                    </li>
                  </ul>
                ))}
              </div>
            </div>
          </div>
        ))
      ) : (
        <div className="text-primary mt-5 text-center">
          <div className="d-flex align-items-center justify-content-center">
            <span
              className="fs-2 spinner-border spinner-border-sm text-primary"
              role="status"
              aria-hidden="true"
            ></span>
            <span>Loading Scholarship Info....</span>
          </div>
        </div>
      )}
    </SingleCourseWrapper>
  );
};

const SingleCourseWrapper = styled.div`
  background: #000;
  color: #fff;

  .course-intro {
    padding: 40px 16px;
    max-width: 992px;

    .course-details {
      padding-top: 20px;
    }

    .course-category {
      padding: 0px 8px;
      border-radius: 6px;
    }

    .course-head {
      font-size: 48px;
      line-height: 1.3;
      padding: 12px 0 0 0;
    }
    .course-para {
      padding: 12px 0;
    }
    .rating-star-val {
      margin-right: 7px;
      padding-bottom: 5px;
      color: var(--clr-orange);
    }
    .students-count {
      margin-left: 8px;
    }
    .rating-count {
      margin-left: 6px;
      color: #d097f6;
    }
    .course-info {
      li {
        margin-bottom: 2px;
        &:nth-child(2) {
          margin-top: 10px;
        }
      }
      .course-info-txt {
        text-transform: capitalize;
        margin-left: 8px;
        margin-bottom: 4px;
      }
    }
    .course-price {
      margin-top: 12px;
      .old-price {
        color: #eceb98;
        text-decoration: line-through;
        margin-left: 10px;
      }
    }
    .course-btn {
      margin-top: 16px;
      .add-to-cart-btn {
        padding: 12px 28px;
        span {
          margin-left: 12px;
        }
      }
    }
    .course-img {
      margin-top: 20px;
      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: contain;
        border-radius: 0.8rem;
      }
    }

    @media screen and (min-width: 880px) {
      grid-template-columns: repeat(2, 1fr);
      column-gap: 40px;
      .course-details {
        padding-top: 0;
      }
      .course-img {
        order: 2;
      }
    }

    @media screen and (min-width: 1400px) {
      grid-template-columns: 60% 40%;
    }
  }

  .course-full {
    padding: 40px 16px;
    .course-sc-title {
      font-size: 22px;
      font-weight: 700;
      margin: 12px 0;
    }
    .course-learn {
      max-width: 992px;
      border: 1px solid rgba(0, 0, 0, 0.2);
      padding: 12px 28px 22px 28px;

      .course-learn-list {
        li {
          margin: 5px 0;
          display: flex;
          span {
            &:nth-child(1) {
              opacity: 0.95;
              margin-right: 12px;
            }
          }
        }

        @media screen and (min-width: 992px) {
          grid-template-columns: repeat(2, 1fr);
        }
      }
    }

    .course-content {
      max-width: 992px;
      margin-top: 30px;
      border: 1px solid rgba(0, 0, 0, 0.2);
      padding: 12px 28px 22px 28px;

      .course-content-list {
        li {
          background-color: #f7f9fa;
          padding: 12px 18px;
          border: 1px solid rgba(0, 0, 0, 0.2);
          margin-bottom: 10px;
          font-weight: 800;
          font-size: 15px;
        }
      }
    }
  }
`;

export default ScholarshipDetails;
